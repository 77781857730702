import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TranslationProvider } from "./TranslationContext";
import { UserProvider } from "./UserContext";
import { ConfigProvider } from './ConfigContext';
import { FeedbackProvider } from "./Context/FeedbackContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="440272242930-ppbgq5l5j2rkvm773trise640gpigjev.apps.googleusercontent.com">
    <ConfigProvider>
      <UserProvider>
        <TranslationProvider>
          <FeedbackProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </FeedbackProvider>
        </TranslationProvider>
      </UserProvider>
    </ConfigProvider>
  </GoogleOAuthProvider>
);

reportWebVitals();
