import React, { useEffect, useRef, useState } from 'react';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../commonComponent/Breadcrumbs';

const PdfViewer = () => {
  const { id } = useParams();
  const config = useConfig();
  const iframeRef = useRef(null);
  const { tabs } = useFeedback();
  const [fileType, setFileType] = useState(null); // State to track file type
  const [isMobile, setIsMobile] = useState(false);
  const getFileUrl = `${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_ASSIGNMENT_PATH}/${id}`;

  // Check the file extension to determine if it's an image, PDF, or video
  useEffect(() => {
    const fileExtension = getFileUrl.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      setFileType('image');
    } else if (fileExtension === 'pdf') {
      setFileType('pdf');
    } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
      setFileType('video');
    } else {
      setFileType('unsupported');
    }
  }, [getFileUrl]);

  // Unique key to store scroll position for each PDF
  const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(getFileUrl)}`;

  // Save scroll position when message is received from iframe
  const handleMessage = (event) => {
    if (event.origin !== config?.LITTERA_CDN_BASE_URL) return;
    if (event.data?.scrollPosition !== undefined) {
      localStorage.setItem(pdfScrollKey, event.data.scrollPosition.toString());
    }
  };

  useEffect(() => {
    // Listen for postMessage from iframe
    window.addEventListener('message', handleMessage);

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener('message', handleMessage);
    };
  }, [pdfScrollKey, getFileUrl]);
  const Phoneurl = `https://docs.google.com/gview?url=${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_ASSIGNMENT_PATH}/${id}&embedded=true`;
  const DesktopUrl = `${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_ASSIGNMENT_PATH}/${id}#toolbar=0`;
  // Function to check${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_ASSIGNMENT_PATH}/${id}
  const checkIfMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  // Set up event listener to track window size on load and resize
  useEffect(() => {
    checkIfMobile(); // Initial check
    window.addEventListener('resize', checkIfMobile);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Prevent saving with Ctrl (or Cmd) + S
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
      }
    };
    // Add event listener on mount
    window.addEventListener('keydown', handleKeyDown);
    // Also add the event listener to the iframe itself (if it's present)
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentWindow.document;
      iframeDocument.addEventListener('keydown', handleKeyDown);
    }
    // Cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentWindow.document;
        iframeDocument.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);
  return (
    <>
      <Breadcrumb paths={[{ title: 'Assignment List', url: '/Assignment/AssignmentView' }, { title: 'View Attachment', url: '/Feedback/Feedback' }]} />

      <div className="h-full w-full flex justify-center items-center">
        <div className="w-full h-full flex justify-center">
          {/* Render PDF in iframe */}
          {fileType === 'pdf' && (
            <div className="h-[100%] w-[100%] flex justify-center">
              <div className="h-full w-full">
                <iframe
                  ref={iframeRef}
                  src={isMobile ? Phoneurl : DesktopUrl}
                  className="h-full w-full"
                  frameBorder="0"
                  title="PDF Viewer"
                ></iframe>
              </div>
            </div>
          )}

          {/* Render Image in another frame */}
          {fileType === 'image' && (
            <div className="image-container w-full h-full flex justify-center">
              <img src={getFileUrl} alt="Attachment" className="max-w-full max-h-full object-contain" />
            </div>
          )}

          {/* Render Video in another frame */}
          {fileType === 'video' && (
            <div className="video-container w-full h-full flex justify-center">
              <video controls className="max-w-full max-h-full object-contain">
                <source src={getFileUrl} type={`video/${getFileUrl.split('.').pop().toLowerCase()}`} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}

          {/* Handle unsupported file types */}
          {fileType === 'unsupported' && (
            <div className="w-full h-full flex justify-center items-center">
              <p>Unsupported file type</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
