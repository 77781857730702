import React, { useEffect , lazy , Suspense } from 'react';
import Loader from '../../pages/Loader.js';
import { CircularProgress } from '@mui/material';
// import MyComponent from '../Content/StringToHtml';

const MyComponent = lazy(()=>import("../Content/StringToHtml.jsx"))

const HTML = ({htmlContent}) => {

    useEffect(() => {
        console.log("htmlContentdata" , htmlContent)
    }, [htmlContent])
    
    return (
    
    <div className='h-[76vh] max-phone:min-h-fit max-phone:max-h-[66vh] w-full p-2  '  >
        <div className='overflow-y-auto h-full w-full border-2 flex flex-col items-center gap-2 p-2'>
            <div className=' flex h-fit w-[100%] flex-col items-center max-phone:text-center gap-2'>
            <Suspense 
                fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                }
            >
                    <MyComponent className="prose" sandbox="allow-scripts" htmlContent={htmlContent} />
                </Suspense>
            </div>
        </div>
    </div>
  );
};

export default HTML;




// import React, { useEffect, useRef } from 'react';
// import MyComponent from '../Content/StringToHtml';

// const HTML = ({ htmlContent }) => {
//     const containerRef = useRef(null);
    
//     useEffect(() => {
//         const handleAnchorClick = (event) => {
//             // Check if the clicked element is an anchor tag (<a>)
//             if (event.target.tagName.toLowerCase() === 'a') {
//                 // Prevent default behavior to avoid page reload
//                 event.preventDefault();

//                 // Extract the href attribute
//                 const href = event.target.getAttribute('href');

//                 // Open the URL in a new tab/window
//                 window.open(href, '_blank');
//             }
//         };

//         // Add event listener to the container div to catch clicks on anchor tags
//         if (containerRef.current) {
//             containerRef.current.addEventListener('click', handleAnchorClick);
//         }

//         // Clean up by removing event listener when component unmounts
//         return () => {
//             if (containerRef.current) {
//                 containerRef.current.removeEventListener('click', handleAnchorClick);
//             }
//         };
//     }, [htmlContent]);

//     return (
//         <div className='h-[76vh] max-phone:min-h-fit max-phone:max-h-[58vh] w-full p-2'>
//             <div className='overflow-y-auto h-full w-full border-2 flex flex-col items-center gap-2 p-2' ref={containerRef}>
//                 <div className='flex h-fit w-[100%] flex-col items-center max-phone:text-center gap-2'>
//                     <MyComponent className="prose" sandbox="allow-scripts" htmlContent={htmlContent?.globalWysiwagText} />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default HTML;
