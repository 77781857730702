import { Mail, Phone, PhotoCamera } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { GiCalendar, GiRotaryPhone, GiSmartphone } from 'react-icons/gi'
import { FaLocationDot } from 'react-icons/fa6'
import { useFeedback } from '../../../Context/FeedbackContext'
import { useConfig } from '../../../ConfigContext'

const SidePanel = () => {
    const { 
        userProfile, 
        showeditpage,
    } = useFeedback()
    const config = useConfig()

    useEffect(() => {
        if (!showeditpage) {
            setuploadingProfile(null); 
        }
    }, [showeditpage])
    
    const { uploadingProfile, setuploadingProfile , imageFormData, setImageFormData } = useFeedback();
    const [fileError, setFileError] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          // Validate file size (16 MB)
          if (file.size > 16 * 1024 * 1024) {
            setFileError('File size should not exceed 16 MB');
            setuploadingProfile(null); // Clear preview on error
            return;
          }
    
          // Validate file type (only images: jpg, jpeg, png, gif)
          const acceptedImageFormats = ['jpg', 'jpeg', 'png', 'gif'];
          const fileExtension = file.name.split('.').pop().toLowerCase();
    
          if (!acceptedImageFormats.includes(fileExtension)) {
            setFileError(`Invalid file type. Allowed formats: ${acceptedImageFormats.join(', ')}`);
            setuploadingProfile(null); // Clear preview on error
            return;
          }
    
          setImageFormData(file);
    
          const reader = new FileReader();
          reader.onloadend = () => {
            setuploadingProfile(reader.result); // Set the image preview
            setFileError(null); // Clear any previous error message
          };
          reader.readAsDataURL(file); // Read the file as a data URL
        }
    };
    
    return (
        <div className='h-full  px-8 py-6 rounded-xl border-2 bg-white'>   
            <div className='flex max-lg:justify-start max-lg:gap-8 max-lg:px-0 max-lg:flex-row flex-col justify-center items-center gap-2 px-10 mb-6'>
                <div className='flex max-lg:justify-start max-lg:gap-8 max-lg:px-0 max-lg:flex-row flex-col justify-center items-center gap-2 px-10'>
                    {showeditpage ? (
                        <div className='rounded-[50%] h-[15vh] w-[15vh] mt-4 border-[2px] flex items-center justify-center p-2 overflow-hidden'>
                            <img
                                className='rounded-[50%] h-full w-full object-cover'
                                src={uploadingProfile || 
                                    (userProfile?.items[0]?.ag_photo_path === "" ? 
                                        `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : 
                                        `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${userProfile?.items[0]?.ag_photo_path}`)
                                }
                                onError={(e) => {
                                    e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg`; // Fallback if image fails to load
                                }}
                                alt="Profile"
                            />
                        </div>
                    ) : (
                        <div className='rounded-[50%] h-[15vh] w-[15vh] mt-4 border-[2px] flex items-center justify-center p-2 overflow-hidden'>
                            <img
                                className='rounded-[50%] h-full w-full object-cover'
                                src={userProfile?.items[0]?.ag_photo_path === "" ? 
                                    `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : 
                                    `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${userProfile?.items[0]?.ag_photo_path}`}
                                onError={(e) => {
                                    e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg`; // Fallback if image fails to load
                                }}
                                alt="Profile"
                            />
                        </div>
                    )}
                </div>

                <div className='flex flex-col items-center max-lg:items-start'>
                    {
                        showeditpage && (
                        <>
                            <label htmlFor="file-upload">
                                <PhotoCamera className='my-2' fontSize='25px' style={{ cursor: 'pointer' }} />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }} // Hide the input field
                                onChange={handleImageChange}
                            />
                        </>
                        )
                    }
                    
                    <h5 className='capitalize'>{userProfile?.items[0]?.agencyname}</h5>
                    <h6 className='gap-1 items-start flex'>
                        <FaLocationDot fontSize='15px' />
                        :
                        <span className='flex-wrap text-center'>
                            {userProfile?.items[0]?.ag_Address}
                        </span>
                    </h6>
                </div>
            </div>

            <div className='flex flex-col gap-2'>
                <h5>Connect :</h5>
                <span className='flex items-center gap-2'>
                    <Phone fontSize='25px'/> : <span className='text-blue-500 cursor-pointer'>{userProfile?.items[0]?.ag_mobileno}</span>
                </span>
                <span className='flex items-center gap-2'>
                    <GiRotaryPhone fontSize='18 px'/> : <span className='text-blue-500 cursor-pointer'>{userProfile?.items[0]?.ag_phone}</span>
                </span>
                <span className='flex items-center gap-2'>
                    <Mail fontSize='25px'/> : <span className='text-blue-500 cursor-pointer'>{userProfile?.items[0]?.ag_email}</span>
                </span>
            </div>
        </div>
    )
}

export default SidePanel