import React, { useState, useEffect, useContext,useRef } from "react";
import { Collapse, Modal, Button, Input, Tooltip, Avatar, Badge } from "antd";
import { CommentOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import styled from "styled-components";
import { useConfig } from "../../ConfigContext";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaReply } from "react-icons/fa6";
import { TranslationContext } from "../../TranslationContext";
import TestComponent from "../modals/TestModal";

const { Panel } = Collapse;

const CommentsModal = ({ closeModal, participantId, thistrainings }) => {
  const { translations } = useContext(TranslationContext);
  const config = useConfig();
  const [openPanel, setOpenPanel] = useState(null); // Tracks which panel is open
  const [searchText, setSearchText] = useState(""); // Search input state
  const [data, setData] = useState([]); // Data to hold comments
  const [isLoading, setIsLoading] = useState(false); // Loading state for API calls
  const [commentText, setCommentText] = useState(""); // Stores the comment text
  const [replyText, setReplyText] = useState(""); // Stores the reply text
  const [reload, setReload] = useState(false); // State to trigger re-fetch
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchQuery, setSearchQuery] = useState('');

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await axios.post(
        `${config.REACT_APP_API_URL}/DeleteComment`,
        { commentId },
        {
          headers: {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Comment deleted successfully");
        setReload(!reload); // Trigger refetch
      } else {
        console.error("Error deleting comment:", response.data);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const column = "taac_comment"
      const participantid = user.userdetails.agencyid;
      const url = `${config.REACT_APP_API_URL}/Get_Comments?assignmentid=${participantId}&trainingid=${thistrainings}&PageNumber=1&PageSize=10&participantid=${participantid}`;
      let payload = null;
      if (searchQuery && searchQuery.trim() !== "") {
        payload = {
          searchCriteria: [
            {
              column: column,
              value: searchQuery,
              condition: '=',
              nextoperator: 'Or',
            },
          ],
        };
      }
      try {
        const response = await axios.post(url, payload, {
          headers: {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
          },
        });
        setData(response.data.items); 
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [participantId, reload, searchQuery]);

  const uid = uuidv4();

  const handleSendReply = async (participantid) => {
    const commentedby = user.userdetails.agencyid;
    if (!replyText.trim()) {
      console.log("Reply text is empty");
      return;
    }

    const payload = {
      taac_commentid: uid,
      taac_AssignmentID: participantId,
      taac_Participantid: participantid,
      taac_commentedby: commentedby,
      taac_comment: replyText,
      taac_createdon: new Date().toISOString().split("T")[0],
    };

    try {
      const response = await axios.post(
        `${config.REACT_APP_API_URL}/InsertComment`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        setReplyText(""); // Clear reply text after sending
        toast.success("Reply Sent Successfully!");
        setReload(!reload); // Trigger refetch
      } else {
        console.error("Error sending reply:", response.data);
      }
    } catch (error) {
      console.error("Error making API request:", error);
    }
  };

  const handleSendComment = async () => {
    if (!commentText.trim()) {
      console.log("Comment text is empty");
      return;
    }

    const payload = {
      taac_commentid: uuidv4(),
      taac_AssignmentID: participantId,
      taac_Participantid: user.userdetails.agencyid,
      taac_commentedby: user.userdetails.agencyid,
      taac_comment: commentText,
      taac_createdon: new Date().toISOString().split("T")[0],
    };

    try {
      const response = await axios.post(
        `${config.REACT_APP_API_URL}/InsertComment`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        setCommentText(""); // Clear comment text after sending
        toast.success("Comment Sent Successfully!");
        setReload(!reload); // Trigger refetch
      } else {
        console.error("Error sending comment:", response.data);
      }
    } catch (error) {
      console.error("Error making API request:", error);
    }
  };

  const handlePanelChange = (key) => {
    setOpenPanel(openPanel === key ? null : key);
  };
 const modalRef = useRef(null);
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  return (
  <>
    <StyledModal
      visible={true}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={closeModal}>
          OK
        </Button>,
      ]}
      centered={true}
    >
      <div ref={modalRef} className="modal-header">
        <h3>{translations["Comments"] || "Comments"}</h3>
      </div>

      <div style={{ width: "100%", marginBottom: 20 }}>
        <Input
          placeholder={translations["Search Participant Name"] || "Search Participant Name"}
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ width: "100%" }}
          prefix={<SearchOutlined />}
        />
      </div>

      <div className="comments-container">
        <Collapse accordion activeKey={openPanel ? [openPanel] : []} onChange={handlePanelChange} style={{ marginTop: "10px" }}>
          {data?.map((item) => (
            <Panel
              header={
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Avatar
                    size={40}
                    src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${item.participant[0]?.participantphoto}`}
                    style={{ marginRight: 10 }}
                  />
                  <span>{item.participant[0]?.participantname}</span>
                  <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                    <Tooltip title="Comment">
                      <Button icon={<CommentOutlined />} style={{ backgroundColor: "#f5f5f5", border: "none", marginLeft: "10px" }} />
                    </Tooltip>
                    <Badge count={item.participant[0]?.comment?.length || 0} style={{ backgroundColor: "#FFA500" }} showZero />
                    <Tooltip title="Reply">
                      <Button icon={<FaReply />} style={{ backgroundColor: "#f5f5f5", border: "none", marginLeft: "10px" }} />
                    </Tooltip>
                  </div>
                </div>
              }
              key={item.assignment}
            >
              {item.participant[0]?.comment.map((comment, commentIndex) => (
                <div key={commentIndex} style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ marginBottom: "0px", fontSize: "11px" }}>
                        Comment By {comment.commentedby} On {moment(comment.taac_createdon).format("DD-MM-YYYY HH:mm:ss")}
                      </p>
                      <div style={{ fontSize: "15px" }}>{comment.taac_comment}</div>
                    </div>
                  </div>
                </div>
              ))}

              <div style={{ marginBottom: "10px" }}>
                <Input.TextArea
                  rows={4}
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  placeholder={translations["Write your reply"] || "Write your reply..."}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  ref={modalRef}
                />
                <div style={{ textAlign: "right" }}>
                  <Button type="primary" style={{ marginTop: "10px" }} onClick={() => handleSendReply(item.participant[0]?.participantid)}>
                    {translations["Send Reply"] || "Send Reply"}
                  </Button>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </StyledModal>
   
    </>
  );
};

// Styled Modal with scrollable content area
const StyledModal = styled(Modal)`
  .ant-modal {
    width: 55% !important;
    max-height: 90vh; /* Max height for the modal */
    overflow: hidden; /* Ensure no overflow on the modal itself */
  }
  .ant-modal-body {
    max-height: calc(90vh - 100px); /* Keep the body height less than the full modal */
    overflow-y: auto; /* Enable scrolling */
  }
  .comments-container {
    max-height: 70vh; /* Adjust this to fit the content */
    overflow-y: auto;
  }
  .ant-collapse-header .ant-collapse-expand-icon {
    display: none !important;
  }
  @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;
    }
  }
`;

export default CommentsModal;
