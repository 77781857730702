import React from 'react'
import MeetingList from '../componentHome/meeting/MeetingList'
import TestNavbar from '../components/teastpage/TestNavbar'

const MeetingListPage = () => {
  return (
   <>
   
    <MeetingList></MeetingList>
   
   </>
  )
}

export default MeetingListPage;