import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '../../../ThemeProvider'
import { IoCloseOutline } from 'react-icons/io5'
import { FaCertificate , FaCalendar } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import { FiEdit } from 'react-icons/fi'
import { HiMiniQuestionMarkCircle } from 'react-icons/hi2'
import { WiTime6, WiTime9 } from 'react-icons/wi'
import { MdOutlineDone } from 'react-icons/md'
import { HiOutlineBan } from 'react-icons/hi'
import { IoIosCall  } from 'react-icons/io'
import { BsCalendar3  } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { useConfig } from '../../../ConfigContext'
import axios from 'axios'
import { useFeedback } from '../../../Context/FeedbackContext'
import { useUser } from '../../../UserContext'
import { TranslationContext } from '../../../TranslationContext'
import { CircularProgress } from '@mui/material'

const TestResultModal = ({pagetype}) => {
    const { translations } = useContext(TranslationContext);
    const { theme } = useTheme()
    const config = useConfig()
    const { 
        trainingDetails, 
        summarydata,
        testresultdata
    } = useFeedback()

    function calculateTestResult(summarydata, testresultdata) {
        return summarydata * testresultdata;
    }
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        return `${day}-${month}-${year} `;
    };

    if (summarydata === null || testresultdata === null ) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
          </div>
        );
    }

    return (
        <div 
            style={{ borderColor: theme?.palette?.primary?.main , borderWidth:'1px' , borderStyle: 'solid' }} 
            className={`h-fit max-sm:shrink-0 w-full rounded-xl p-8 relative`}
        >
            <div
                style={{ borderColor: theme?.palette?.primary?.main }} 
                className="flex justify-between max-sm:border-b">
                <div
                    className="flex items-center gap-8 max-sm:pb-4 max-sm:flex-col max-sm:items-start"
                >
                    <div 
                        style={{ borderColor: theme?.palette?.primary?.main , borderWidth:'1px' , borderStyle: 'solid' }} 
                        className='whitespace-nowrap h-40 w-40 p-2 rounded-full flex items-center justify-center overflow-hidden'
                    >   
                        <img 
                            className='h-full w-full rounded-full'
                            
                            src={
                                summarydata?.ag_photo_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : 
                                `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${summarydata?.ag_photo_path}`
                            }
                            onError={(e) => {
                                e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg`; 
                            }}
                            alt={trainingDetails?.img_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${trainingDetails?.img_path}`}
                        />
                    </div>

                    <div>
                        
                        <h4 
                            title={summarydata?.agencyName} 
                        >
                            {summarydata?.agencyName} 
                        </h4>

                        <h5 className='flex items-center gap-4' >
                            <IoIosCall fontSize={18}/>
                            {translations["Contact_no"] || "Contact No"} : {summarydata?.ag_mobileno}  
                        </h5>

                        <h5 className='flex items-center gap-4' ><GrMail fontSize={18}/>
                            {translations["Email_ID"] || "Email Id"} : {summarydata?.ag_email}
                        </h5>

                    </div>
                </div>
            </div>

            <div className="flex gap-16 pt-6 max-lg:flex-col max-lg:gap-6">

                <div className="flex max-lg:w-full w-1/2 flex-col gap-6">
                    
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FaCertificate fontSize={18} /> 
                            {translations["Test_Name"] || "Test Name"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.ttttt_content_desc}
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FaCalendar fontSize={18} />
                            {translations["Difficulty_level"] || "Difficulty Level"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.diffifulty_level_name}
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <HiMiniQuestionMarkCircle fontSize={18} /> 
                            {translations["Total_question"] || "Total Question"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.totalQuestion}
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <IoCloseOutline fontSize={18} /> 
                            {translations["Wrong_ans"] || "Wrong Answer"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.incorrectTotal}
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-center gap-4'>
                            <BsCalendar3 fontSize={14} />
                            {translations["Test_date"] || "Test Date"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {formatDate(summarydata?.questionTimeStart)}
                        </span>
                    </div>

                </div>

                <div className="flex flex-col gap-6">
                    
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FiEdit fontSize={18} />
                            {translations["Attempt_for"] || "Attempt For"} : 
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.t_Name}
                        </span>
                    </div>
                    
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <WiTime9 fontSize={18} />
                            {translations["Total_tast_time"] || "Total Tast Time"} :  
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {calculateTestResult(summarydata?.assesmentTime , testresultdata?.length)} Min
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <MdOutlineDone fontSize={18} />
                            {translations["Correct_ans"] || "Correct Answer"} : 
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.correctTotal }
                        </span>
                    </div>
                    
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <HiOutlineBan fontSize={18} />
                            {translations["Not_answered"] || "Not Answered"} :  
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                        {summarydata?.noAnsweredTotal}

                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <WiTime6 fontSize={18} />
                            {translations["Test_time"] || "Test Time"} :  
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {summarydata?.testtime ? parseFloat(summarydata?.testtime).toFixed(2) : null} Min
                        </span>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default TestResultModal