import React, { useEffect } from 'react';
import axios from 'axios';
import { useConfig } from '../../ConfigContext';

const RedirectPage = () => {
    const config = useConfig();

    useEffect(() => {
        const fetchAndRedirect = async () => {
            const apiKey = config?.REACT_APP_API_KEY;
            const getApiUrl = "http://p9:8085/api/Urls";
            // process.env.REACT_URL_SHORTNER_PATH;
            const shortUrl = window.location.pathname.split(`/`).pop(); // Adjust as needed

            if (!getApiUrl || !apiKey) {
                console.error("API URL or API Key is missing from the configuration.");
                return;
            }
            try {
                const response = await axios.get(config.REACT_URL_SHORTNER_PATH, {
                    headers: {
                        "Content-Type": "application/json",
                        'API-Key': apiKey,
                    }
                });

                const urls = response.data;

                if (!Array.isArray(urls)) {
                    console.error("Unexpected response format:", response.data);
                    return;
                }

                const matchedUrl = urls.find(url => url.shortFormat === shortUrl);

                if (matchedUrl) {
                    window.location.href = decodeURIComponent(matchedUrl.longFormat);
                } else {
                    console.error("No matching long URL found.");
                }
            } catch (error) {
                console.error("Error fetching or redirecting:", error);
            }
        };

        fetchAndRedirect();
    }, [config]);

    return (
        <div>Redirecting...</div>
    );
};

export default RedirectPage;
