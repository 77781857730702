import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import MainpainalPage from "../components/dasboard/MainpainalPage";
import TestNavbar from "../components/teastpage/TestNavbar";

const DasboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <MainpainalPage />
  );
};

export default DasboardPage;