// import React, { useEffect, useState } from 'react';
// import { FaPlay, FaRegMessage } from 'react-icons/fa6';
// import { LiaStickyNoteSolid } from 'react-icons/lia';
// import { useFeedback } from '../../Context/FeedbackContext';
// import { useConfig } from '../../ConfigContext';
// import { Tooltip } from 'react-tooltip';

// const AllContent = ({ onFileSelect, onHtmlSelect, showComments, showNotes }) => {
//     const config = useConfig();
//     const { Video, Audio, pdf, WYISIWYG, Image, Cdn , filtercontent , tabs } = useFeedback();

//     const allContent = [
//         ...Video.map(item => ({ type: 'Video', data: item })),
//         ...Audio.map(item => ({ type: 'Audio', data: item })),
//         ...pdf.map(item => ({ type: 'Pdf', data: item })),
//         ...WYISIWYG.map(item => ({ type: 'WYISIWYG', data: item })),
//         ...Image.map(item => ({ type: 'Image', data: item })),
//         ...Cdn.map(item => ({ type: 'Cdn', data: item })),
//     ];

//     const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];

//     const getFileExtension = (filePath) => {
//         const extension = filePath.split('.').pop().toLowerCase();
//         return extension;
//     };

//     const isImage = (filePath) => {
//         const extension = getFileExtension(filePath);
//         return allowedImageExtensions.includes(extension);
//     };

//     const fileSelectHandler = (file) => {
//         onFileSelect(file);
//     };

//     const htmlFileSelectHandler = (file) => {
//         onHtmlSelect(file);
//     };

//     const showCommentHandler = (data) => {
//         showComments(data);
//     };

//     const showNotesHandler = (data) => {
//         showNotes(data);
//     };

//     // useEffect(() => {
//     //     if (allContent?.length > 0) {
//     //       const firstItem = allContent[0];

//     //       if (firstItem) {
//     //         if (firstItem.type === 'WYISIWYG' || firstItem.type === 'Cdn') {
//     //           htmlFileSelectHandler(firstItem.data);
//     //         } else {
//     //           fileSelectHandler(firstItem.data);
//     //         }
//     //       }
//     //     }
//     // }, [])

//     const handleClicksa = (item, type) => {
//         // Check if the item data is already present in tabs state
//         // const isDataPresent = tabs?.some(tab => tab.data === item.data);

//         // if (!isDataPresent) {
//           // Call the appropriate handler based on type
//           if (type === 'WYISIWYG' || type === 'Cdn') {
//             htmlFileSelectHandler(item.data);
//           } else {
//             fileSelectHandler(item.data);
//           }
//         // }
//         // else {
//         //   console.log('Data is already present in tabs.');
//         // }
//     };

//     const handleClick = (item, type) => {
//         console.log('Handling click for item:', item); // Log the item being clicked
//         console.log('Content type:', type); // Log the type of content
//         console.log('firstItem' , item)

//         // Check if the item data's ttsam_globalcontentid is already present in tabs state
//         const isDataPresent = tabs?.some(tab => {
//             const tabGlobalId = tab?.ttsam_globalcontentid
//             const itemGlobalId = item.data?.ttsam_globalcontentid; // Safely access item ttsam_globalcontentid

//           console.log('Comparing tab ttsam_globalcontentid:', tabGlobalId); // Log the current tab's ID
//           console.log('Against item ttsam_globalcontentid:', itemGlobalId); // Log the item ID

//           return tabGlobalId === itemGlobalId; // Compare safely
//         });

//         console.log('Is data present in tabs:', isDataPresent); // Log whether the data is present

//         if (!isDataPresent) {
//           // Call the appropriate handler based on type
//           if (type === 'WYISIWYG' || type === 'Cdn') {
//             console.log('Calling htmlFileSelectHandler with data:', item.data); // Log data before calling
//             htmlFileSelectHandler(item.data);
//           } else {
//             console.log('Calling fileSelectHandler with data:', item.data); // Log data before calling
//             fileSelectHandler(item.data);
//           }
//         } else {
//           console.log('Data with ttsam_globalcontentid is already present in tabs.'); // Log if data is already present
//         }
//     };

//     useEffect(() => {
//         console.log('allContent:', allContent);
//         if (allContent?.length > 0) {
//             const firstItem = allContent[0];
//             console.log('First item:', firstItem);
//             if (firstItem) {
//                 handleClick(firstItem, firstItem.type);
//                 console.log('First item and type:', firstItem, firstItem.type);
//             }
//         }
//     }, []);

//     const renderGroupedContent = (type, items) => (
//         <div className='max-md:flex-row bg-white max-md:min-w-fit  w-full flex flex-col items-center gap-2 border-2 p-2'>
//             <h4 className='uppercase max-md:text-2xl  max-md:px-4'>{items.length === 1 ? type : `${type}s`}</h4>
//             {items?.map((item, index) => {
//                 const thumbnailPath = item.data.globalthumbnailPath;
//                 const filePath = item.data.globalFilePath;
//                 const isValidImage = filePath && isImage(filePath);
//                 console.log("allitems" , allContent)

//                 const imageSrc = thumbnailPath !== ""
//                     ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${thumbnailPath}`
//                     : isValidImage
//                     ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${filePath}`
//                     : null;

//                 return (
//                     <div key={index} className='videodivv max-md:w-80 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                         {imageSrc ? (
//                             <img
//                                 src={imageSrc}
//                                 className='relative h-full w-full object-cover object-center'
//                                 alt=""
//                             />
//                         ) : (
//                             null
//                         )}

//                         <div
//                             onClick={() => handleClick(item, type)}
//                             // onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)}
//                             className='absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2'
//                             style={{ backdropFilter: 'blur(5px)' , zIndex:"1" }}
//                         >

//                             <h5
//                                 data-tooltip-float
//                                 data-tooltip-id='my-tooltip'
//                                 data-tooltip-content={item.data.ttsad_title}
//                                 data-tooltip-place='top'
//                                 className='text-black cursor-pointer w-[45%]'
//                                 style={{ textDecoration: "none", color: "black" }}
//                             >
//                                 {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                 {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                             </h5>
//                             <Tooltip id='my-tooltip' />

//                             <div className='flex h-full  items-center w-[50%] justify-between'>
//                                 <FaPlay
//                                     data-tooltip-id="my-tooltip"
//                                     data-tooltip-content='Play'
//                                     data-tooltip-place='top'
//                                     style={{ cursor: "pointer" , zIndex:"1" }}
//                                     fontSize={26}
//                                     color='black'
//                                 />
//                                 <div className='h-fit py-10 z-10 relative w-10 flex flex-col items-center justify-center gap-4'>
//                                     <FaRegMessage
//                                         data-tooltip-id="my-tooltip"
//                                         data-tooltip-content='comments'
//                                         data-tooltip-place='left'
//                                         onClick={(e) => {
//                                             e.stopPropagation(); // Prevent click event from bubbling up
//                                             showCommentHandler(item.data);
//                                         }}
//                                         style={{ cursor: "pointer" }}
//                                         className='absolute top-0 '
//                                         opacity={.7}
//                                         fontSize={17}
//                                     />
//                                     <LiaStickyNoteSolid
//                                         data-tooltip-id="my-tooltip"
//                                         data-tooltip-content='Notes'
//                                         data-tooltip-place='left'
//                                         onClick={(e) => {
//                                             e.stopPropagation();
//                                             showNotesHandler(item.data);
//                                         }}
//                                         style={{ cursor: "pointer" }}
//                                         className='absolute bottom-0 '
//                                         opacity={.9}
//                                         fontSize={22}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 );
//             })}
//         </div>
//     );

//     return (
//         <div className='px-2 py-2  max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
//             <div className='h-full  shadow-xl max-md:border-x-2 max-md:flex-row w-full overflow-y-auto flex flex-col gap-2'>
//             {allContent?.length > 0 ? (
//                 filtercontent === "All Content" ? (
//                     // Display all types of content
//                     ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image', 'Cdn'].map(type => {
//                         const itemsOfType = allContent?.filter(item => item.type === type);
//                         return itemsOfType.length > 0 ? renderGroupedContent(type, itemsOfType) : null;
//                     })
//                 ) : (
//                     // Display only the content of the specified type if valid
//                     ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image', 'Cdn'].includes(filtercontent) ? (
//                         renderGroupedContent(filtercontent, allContent?.filter(item => item.type === filtercontent))
//                     ) : (
//                         <p className='w-full flex justify-center max-md:mt-2.5'>CONTENT NOT AVAILABLE...</p>
//                     )
//                 )
//             ) : (
//                 <p className='w-full flex justify-center max-md:mt-2.5'>CONTENT NOT AVAILABLE...</p>
//             )}

//             </div>
//         </div>
//     );
// };

// export default AllContent;

import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaRegMessage } from "react-icons/fa6";
import { LiaStickyNoteSolid } from "react-icons/lia";
import { useFeedback } from "../../Context/FeedbackContext";
import { useConfig } from "../../ConfigContext";
import { Tooltip } from "react-tooltip";
import {Windowopen} from '../../componentHome/utils/Validation';

const AllContent = ({
  onFileSelect,
  onHtmlSelect,
  showComments,
  showNotes,
}) => {
  const config = useConfig();
  const { Video, Audio, pdf, WYISIWYG, Image, Cdn, filtercontent, tabs } = useFeedback();
  const user = JSON.parse(localStorage.getItem('user'))
  
  const allContent = [
    ...Video.map((item) => ({ type: "Video", data: item })),
    ...Image.map((item) => ({ type: "Image", data: item })),
    ...WYISIWYG.map((item) => ({ type: "WYISIWYG", data: item })),
    ...Cdn.map((item) => ({ type: "Cdn", data: item })),
    ...pdf.map((item) => ({ type: "Pdf", data: item })),
    ...Audio.map((item) => ({ type: "Audio", data: item })),
  ];

  // const prevAllContentRef = useRef(allContent);

  // useEffect(() => {
  //   const data = allContent[0];
  //   const type = data?.type || "defaultType";

  //   const hasChanged =
  //     JSON.stringify(prevAllContentRef?.current) !== JSON.stringify(allContent);
  //   const isFirstRender =
  //     prevAllContentRef?.current === undefined ||
  //     prevAllContentRef?.current?.length === 0;

  //   if (type === 'Cdn') {
  //     return; 
  //   }

  //   if (hasChanged || isFirstRender) {
  //     if (data) {
  //       handleFileSelect(data?.data, type);
  //     }
  //   }

  //   prevAllContentRef.current = allContent;
  // }, [allContent]);

  const prevAllContentRef = useRef(allContent);
  const prevTypeRef = useRef();

  useEffect(() => {
    const data = allContent[0];
    const type = data?.type || "defaultType";
  
    // Check if it's the first render or content has changed.
    const isFirstRender = !prevAllContentRef.current || prevAllContentRef.current.length === 0;
    
    // Directly compare the content of the first item and its type.
    const isContentChanged = prevAllContentRef.current?.[0]?.data !== data?.data || prevAllContentRef.current?.[0]?.type !== type;
    
    // Handle the case for type 'Cdn' to skip the file select
    if (type === 'Cdn') {
      return;
    }
  
    // If it’s the first render, content has changed, or the type has changed, we need to trigger handleFileSelect
    if (isFirstRender || isContentChanged) {
      if (data) {
        handleFileSelect(data?.data, type);
      }
    }
  
    // Update refs to store the latest values
    prevAllContentRef.current = allContent;
    prevTypeRef.current = type;
  
  }, [allContent]);
  
  const allowedImageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];

  const getFileExtension = (filePath) => {
    const extension = filePath?.split(".").pop().toLowerCase();
    return extension;
  };

  const isImage = (filePath) => {
    const extension = getFileExtension(filePath);
    return allowedImageExtensions.includes(extension);
  };

  const fileSelectHandler = (file) => {
    onFileSelect(file);
  };

  const htmlFileSelectHandler = (file) => {
    onHtmlSelect(file);
  };

  const showCommentHandler = (data) => {
    showComments(data);
  };

  const showNotesHandler = (data) => {
    showNotes(data);
  };

  const handleFileSelects = (data, type) => {
    if (tabs?.length === 0) {
      if (type === "WYISIWYG" || type === "Cdn") {
        htmlFileSelectHandler(data);
      } else {
        fileSelectHandler(data);
      }
      return;
    }

    const existingTab = tabs?.find(
      (tab) => tab.ttsam_globalcontentid === data.ttsam_globalcontentid
    );

    if (existingTab) {
      return;
    }

    if (type === "WYISIWYG" || type === "Cdn") {
      htmlFileSelectHandler(data);
    } else {
      fileSelectHandler(data);
    }
  };
  
  const openCdn = (data) => {
    console.log('opener',data)
    const url = data?.globalWysiwagText;
    const agencyId = user?.userdetails?.agencyid;
    const contentid = data?.ttsam_globalcontentid;
    const urlWithQuery = `${url}?userid=${encodeURIComponent(agencyId)}&contentid=${encodeURIComponent(contentid)}`
    Windowopen(urlWithQuery);
  };

  const handleFileSelect = (data, type) => {
    if (tabs?.length === 0) {
      if (type === "WYISIWYG" || type === "Cdn") {
        if (type === "Cdn") {
          openCdn(data); // Call openCdn if type is 'Cdn'
        } else {
          htmlFileSelectHandler(data);
        }
      } 
      else {
        fileSelectHandler(data);
      }
      return;
    }

    const existingTab = tabs?.find(
      (tab) => tab.ttsam_globalcontentid === data.ttsam_globalcontentid
    );

    if (existingTab) {
      return;
    }

    if (type === "WYISIWYG" || type === "Cdn") {
      if (type === "Cdn") {
        openCdn(data); // Call openCdn if type is 'Cdn'
      } else {
        htmlFileSelectHandler(data);
      }
    } else {
      fileSelectHandler(data);
    }
  };

  const renderGroupedContent = (type, items) => (
    <div className="max-md:flex-row bg-white max-md:min-w-fit  w-full flex flex-col items-center gap-2 border-2 p-2">
      <h4 className="uppercase max-md:text-2xl  max-md:px-4">
        {items.length === 1 ? type : `${type}s`}
      </h4>
      {items.map((item, index) => {
        const thumbnailPath = item.data.globalthumbnailPath;
        const filePath = item.data.globalFilePath;
        const isValidImage = filePath && isImage(filePath);

        const imageSrc =
          thumbnailPath !== ""
            ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${thumbnailPath}`
            : isValidImage
            ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${filePath}`
            : null;

        return (
          <div
            key={index}
            className="videodivv max-md:w-80 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase"
          >
            {imageSrc ? (
              <img
                src={imageSrc}
                className="relative h-full w-full object-cover object-center"
                alt=""
              />
            ) : null}

            <div
              onClick={() => handleFileSelect(item.data, type)}
              // onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)}
              className="absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2"
              style={{ backdropFilter: "blur(5px)", zIndex: "1" }}
            >
              <h5
                data-tooltip-float
                data-tooltip-id="my-tooltip"
                data-tooltip-content={item.data.ttsad_title}
                data-tooltip-place="top"
                className="text-black cursor-pointer w-[45%]"
                style={{ textDecoration: "none", color: "black" }}
              >
                {item.data.ttsad_title.split(" ").slice(0, 3).join(" ")}
                {item.data.ttsad_title.split(" ").length > 3 ? "..." : ""}
              </h5>
              <Tooltip id="my-tooltip" />

              <div className="flex h-full  items-center w-[50%] justify-between">
                <FaPlay
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Play"
                  data-tooltip-place="top"
                  style={{ cursor: "pointer", zIndex: "1" }}
                  fontSize={26}
                  color="black"
                />
                <div className="h-fit py-10 z-10 relative w-10 flex flex-col items-center justify-center gap-4">
                  <FaRegMessage
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="comments"
                    data-tooltip-place="left"
                    onClick={(e) => {
                      e.stopPropagation();
                      showCommentHandler(item.data);
                    }}
                    style={{ cursor: "pointer" }}
                    className="absolute top-0 "
                    opacity={0.7}
                    fontSize={17}
                  />
                  <LiaStickyNoteSolid
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Notes"
                    data-tooltip-place="left"
                    onClick={(e) => {
                      e.stopPropagation();
                      showNotesHandler(item.data);
                    }}
                    style={{ cursor: "pointer" }}
                    className="absolute bottom-0 "
                    opacity={0.9}
                    fontSize={22}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="px-2 py-2  max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto">
      <div className="h-full  shadow-xl max-md:border-x-2 max-md:flex-row w-full overflow-y-auto flex flex-col gap-2">
        {allContent.length > 0 ? (
          filtercontent === "All Content" ? (
            ["Video", "Image", "WYISIWYG", "Cdn", "Pdf", "Audio"].map(
              (type) => {
                const itemsOfType = allContent.filter(
                  (item) => item.type === type
                );
                return itemsOfType.length > 0
                  ? renderGroupedContent(type, itemsOfType)
                  : null;
              }
            )
          ) : // Display only the content of the specified type if valid
          ["Video", "Image", "WYISIWYG", "Cdn", "Pdf", "Audio"].includes(
              filtercontent
            ) ? (
            renderGroupedContent(
              filtercontent,
              allContent.filter((item) => item.type === filtercontent)
            )
          ) : (
            <p className="w-full flex justify-center max-md:mt-2.5">
              CONTENT NOT AVAILABLE...
            </p>
          )
        ) : (
          <p className="w-full flex justify-center max-md:mt-2.5">
            CONTENT NOT AVAILABLE...
          </p>
        )}
      </div>
    </div>
  );
};

export default AllContent;
