import { Mail, Phone, PhotoCamera } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { GiCalendar, GiRotaryPhone, GiSmartphone } from 'react-icons/gi'
import { FaCalendar, FaCity, FaCreditCard, FaLocationArrow, FaLocationDot, FaPhone, FaUser } from 'react-icons/fa6'
import { BsCalendar3 } from 'react-icons/bs'
import { LiaUniversitySolid } from 'react-icons/lia'
import { FaAdjust } from 'react-icons/fa'
import { useTheme } from '../../../ThemeProvider'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaEye, FaTimes } from 'react-icons/fa'; // Eye and Close icons
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';

const StaticPage = () => {
    const { userProfile , showeditpage , setshoweditpage } = useFeedback()
    const { theme } = useTheme()
    const config = useConfig()
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        return `${day}-${month}-${year} `;
    };


    return (
        <>
            <div className='flex max-sm:py-6  max-sm:px-8  h-full py-14 px-10'>
                <div className='w-full flex flex-col gap-6'>
                    
                    <span className='flex max-lg:justify-start max-lg:w-full w-1/3 items-center gap-4'>
                        <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                            <FaUser fontSize='15px'/>Name :
                        </span>
                        <span className='capitalize whitespace-nowrap'>{userProfile?.items[0]?.agencyname}</span>
                    </span>

                    <div className='flex gap-40 max-xl:flex-col max-xl:gap-6'>
                        <span className='flex w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <BsCalendar3 fontSize='15px'/> DOB :
                            </span>
                            <span className=''>{formatDate(userProfile?.items[0]?.ag_dob)}</span>
                        </span>

                        <span className='flex w-1/3 whitespace-nowrap max-lg:w-fit items-center  gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <FaAdjust fontSize='15px'/> Gender :
                            </span>
                            <span className='capitalize'>{userProfile?.items[0]?.ag_gender == 1 ? 'Male' : userProfile?.items[0]?.ag_gender == 0 ? "Female" : "Prefer not to disclose" }</span>
                        </span>
                    </div>
                    
                    <span className='flex max-lg:justify-start max-lg:w-full w-full items-center gap-4'>
                        <span className='w-1/6  whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                            <FaLocationDot fontSize='15px'/> Address :
                        </span>
                        <span className='capitalize whitespace-nowrap'>{userProfile?.items[0]?.ag_Address}</span>
                    </span>
                    
                    <span className='flex max-lg:justify-start max-lg:w-full w-1/3 items-center  gap-4'>
                        <span className='w-1/2 max-lg:w-fit whitespace-nowrap flex gap-4 items-center'>
                            <FaLocationArrow fontSize='15px'/> Pin Code :
                        </span>
                        <span className='whitespace-nowrap'>{userProfile?.items[0]?.ag_pincode} </span>
                    </span>

                    <div className='flex gap-40 max-xl:flex-col max-xl:gap-6'>
                        <span className='flex w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <FaCity fontSize='15px'/> City :
                            </span>
                            <span className='shrink-0 whitespace-nowrap capitalize'>{userProfile?.items[0]?.ag_address_city}</span>
                        </span>

                        <span className='flex w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <LiaUniversitySolid fontSize='15px'/> State :
                            </span>
                            <span className='shrink-0 whitespace-nowrap uppercase'>{userProfile?.items[0]?.ag_address_state}</span>
                        </span>
                    </div>

                    <div className='flex gap-40 max-lg:flex-col max-lg:gap-4'>
                        <span className='flex whitespace-nowrap w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <GiSmartphone fontSize='15px'/> Mobile No :
                            </span>
                            <span className=''>{userProfile?.items[0]?.ag_mobileno}</span>
                        </span>

                        <span className='flex whitespace-nowrap gap-20 max-lg:gap-4 w-1/3 max-lg:w-fit items-center '>
                            <span className='w-1/2  max-lg:w-fit flex gap-4 items-center'>
                                <Mail fontSize='15px'/> Email :
                            </span>
                            <span className='pl-2 max-lg:pl-0'>{userProfile?.items[0]?.ag_email}</span>
                        </span>
                    </div>

                    <div className='flex whitespace-nowrap gap-40 max-lg:flex-col max-lg:gap-4'>
                        <span className='flex w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <GiRotaryPhone fontSize='15px'/> Phone :</span>
                            <span className=''>{userProfile?.items[0]?.ag_phone}</span>
                        </span>

                        <span className='flex whitespace-nowrap w-1/3 max-lg:w-fit items-center gap-4'>
                            <span className='w-1/2 max-lg:w-fit flex gap-4 items-center'>
                                <FaPhone fontSize='15px'/> Alt Mobile No :</span>
                            <span className=''>{userProfile?.items[0]?.ag_alternative_mobileno}</span>
                        </span>
                    </div>

                    <span className='flex max-lg:justify-start  max-sm:flex-col max-lg:w-full items-start gap-4'>
                        <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                            <FaCreditCard fontSize='15px'/>Signature :</span>
                        <span className=''></span>
                            {
                                userProfile?.items[0]?.ag_sign_path === "" ? (
                                    null
                                ) :(
                                    <div className='rounded-lg mx-4 h-[20vh] max-sm:mx-0 max-sm:h-full max-sm:w-full w-[20vw] border-[2px] flex items-center justify-center p-2 overflow-hidden'>
                                        <img
                                            className='rounded-lg h-full w-full object-cover'
                                            src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${userProfile?.items[0]?.ag_sign_path}`}
                                            alt="Profile"
                                        />
                                    </div>
                                )
                            }
                    </span>

                </div>
            </div>

            <div className={`px-10 py-8 border-t-2 m-0 flex justify-end w-full`}>
            
                <button onClick={()=>setshoweditpage(true)} style={{backgroundColor: theme?.palette?.primary?.main}} className='px-12 text-white py-4 rounded-md '>
                    Edit Details
                </button>
           
            </div>
        </>
    )
}

export default StaticPage
