import React, { useState, useEffect, useContext } from "react";
import loadConfig from "../../config"; // Assuming your config file is in ../../config
import "./main.css";
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
  Button,
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  InputAdornment,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import RightSidePanel from "./RightSidePanel";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUpload from "../fromscomponent/FileUpload";
import SecondSidePanel from "./SecondSidePanel";
import { NavLink, useNavigate } from "react-router-dom";
import PaginationExample from "./MobileTab";
import CustomPagination from "./CoustmPagination";
import { useUser } from "../../UserContext";
import Breadcrumb from "../commonComponent/Breadcrumbs";
import { TranslationContext } from "../../TranslationContext";
import DmsJobPosition from "./DmsJobPosition";
import DmsActivity from "./DmsActivity";
import Activty_Activity from "./Activity_Activity";

const drawerWidth = 240;

const ActivityAnt = () => {
  // const { setDesignationId } = useUser();
  const { translations } = useContext(TranslationContext);
  const [formData, setFormData] = useState({
    ttca_name: "",
    ttca_description: "",
    ttcf_hod_designation_id: "",
    ttcf_objective: "",

  });
  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user)
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState([]);
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4); // Number of items per page
  const [selectedOption, setSelectedOption] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeListItem, setActiveListItem] = useState(null);
  const [selectitem, Setselectitem] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // State for total records

  const [hfEditId, setHfEditId] = useState("");
  const [error, setError] = useState('');

  const [filterText, setFilterText] = useState(""); // State for filter text
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [config, setConfig] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [isVisibleArray, setIsVisibleArray] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [nameError, setNameError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
  });
  // const totalRecords = 100; // Example total number of records
  // const itemsPerPage = 10;
  // //==== new add for ui start=== //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Fetch user and client data
    const fetchUserAndClientData = async () => {
      try {
        // Fetch user data
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);

        // Fetch client data
        const clientData = JSON.parse(localStorage.getItem('clientData'));
        console.log(clientData);

        // Check if both user and clientData exist
        if (user && clientData) {
          // Set loading to false if user and clientData are available
          setLoading(false);
        } else {
          // Simulate loading for 5 seconds
          const timer = setTimeout(() => {
            setLoading(false); // After 5 seconds, set loading to false
            navigate('/login-page'); // Redirect to login page
          }, 2000);

          return () => clearTimeout(timer); // Clean up timer on component unmount
        }
      } catch (error) {
        console.error('Error fetching user and clientData:', error);
      }
    };

    fetchUserAndClientData();
  }, [navigate]);
  // Function to handle file selection from FileUpload component

  useEffect(() => {
    if (hfEditId) {
      setIsEditMode(true); // Editing mode
    } else {
      setIsEditMode(false); // Create mode
    }
  }, [hfEditId]);


  // const uploadedFileNames = selectedFiles.map(file => {
  //   return generateGUID() + "_" + file.file.name;
  // });
  // Function to load config data
  const fetchConfig = async () => {
    try {
      console.log("Loading config data...");
      const configData = await loadConfig(); // Assuming loadConfig fetches your config object
      console.log("Config data loaded:", configData);
      setConfig(configData);
      console.log("ccc", configData);
      fetchAdditionalData(configData);
      return configData;


    } catch (error) {
      console.error("Error loading config:", error);
    }
  };


  const createdby = "7AB3FE43-ECCC-7C2A-24FA-FFF9EEEE5DD6"
  useEffect(() => {
    const initializeConfig = async () => {
      const configData = await fetchConfig();
      if (configData) {
        setLoading(false);
        fetchAdditionalData(configData); // Fetch additional data once config is loaded
      }
    };
    initializeConfig();
  }, []);


  // Function to handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'ttca_name') {

    }
  };
  const handleBlur = (event) => {
    const { name } = event.target;
    if (name === 'ttca_name') {
      const isValidName = validateInputsName();
      setNameError(!isValidName);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: isValidName ? '' : 'Name already exists!',
      }));
    }
  };
  const validateInputsName = () => {
    // Check if ttca_name or ttca_description already exists in sidebar
    const existingName = sidebar.some(
      (item) =>
        item.sidebaritem &&
        typeof item.sidebaritem === 'string' &&
        item.sidebaritem.toLowerCase().trim() === formData.ttca_name.toLowerCase().trim() &&
        item.itemId !== hfEditId // Exclude the current item being edited
    );

    if (existingName) {
      toast.error("Name already exists!");
      setNameError(true);
      return false; // Return false to indicate validation failed
    } else {
      setNameError(false);
    }

    return true; // Return true if validation passes
  };


  // Function to handle pagination change
  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  //   fetchAdditionalData(config, value); // Fetch data for the selected page
  // };

  // Function to handle delete operation
  const handleDelete = (itemId) => {
    setUserIdToDelete(itemId);
    setShowModal(true);
  };

  const handleDeleteConfrom = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const employeeid = user.userdetails.agencyid;
      const branchid = user.userdetails.branchid;




      const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
      const docdate = createdon;
      const actiondate = createdon;

      const headers = {
        "Content-Type": "application/json",
        APIKey: config.REACT_APP_API_KEY,
      };

      const payload = {
        tttds_info_desc: null,
        doc_id: userIdToDelete, // Use item.applicationid from myData
        createdon: createdon,
        createdby: employeeid,
        branchid: branchid,
        docremark: "",
        docdate: docdate,
        actiondate: actiondate,
        CreatedBy_empid: employeeid,
        fwd_empid: employeeid,
        tat_type_id: 131,
        doc_status: -1,
        attached_doc: null,
        attached_doc_name: null,
        doctype: 0,
        draftletter: null,
        tttds_is_final: 0,
        tttds_letter_type: 0,
        docremarkenc: null,
        draftletterenc: null,
      };

      const response = await axios.post(`${config.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
        headers: headers,
      });

      console.log("API Response:", response.data);
      toast.success("Delete successful!");
      setShowModal(false);
      fetchAdditionalData(config);
      //  fetchAdditionalDataDMS(config);
      // Update myData array to remove the sent item


    } catch (error) {
      console.error("Error sending data:", error);
      toast.error(`Error sending data: ${error.message}`);
    }
  };

  const handelClick = (option) => {

    console.log("option", option);
    setHfEditId(option.itemId);
    // (option.itemId);

    setFormData({
      ttca_name: option.sidebaritem,
      ttca_description: option.sidebarsubitem,
      ttca_code: option.funccode,
    });
  };

  // Function to fetch additional data with pagination
  const fetchAdditionalData = async (config) => {
    console.log("AAA in fun", config)
    // console.log("AAA", config.REACT_APP_API_KEY)
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetActivity`, {
        headers,

      });

      console.log(config.REACT_APP_API_URL)

      // Handle the response data as needed
      if (response && response.data) {
        const { items, totalRecords } = response.data; // Assuming your API response has totalRecords
        const options = items.map((item) => ({
          sidebaritem: item.ttca_name,
          sidebarsubitem: item.ttca_description,
          itemId: item.ttca_id,
          funccode: item.ttca_code,
        }));
        console.log(options);

        setSidebar(options);
        setTotalRecords(totalRecords); // Update totalRecords state
      }
      console.log("All data", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to fetch initial data


  // Function to validate form inputs





  const generateGUID = () => {
    return uuidv4();
  };



  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form inputs
    let isValid = true;
    const errors = {
      name: '',
      description: '',
    };

    if (!formData.ttca_name.trim()) {
      errors.name = 'Activity Name is required';
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (!formData.ttca_description.trim()) {
      errors.description = 'Description is required';
      setDescError(true);
      isValid = false;
    } else {
      setDescError(false);
    }

    if (!isValid) {
      setFormErrors(errors);
      return; // Stop submission if validation fails
    }

    let isValidname = validateInputsName(); // Call validateInputs here
    if (!isValidname) {
      return; // Stop submission if validation fails
    }
    // File Upload
    const createdon = new Date().toISOString().slice(0, 19).replace('T', ' '); // Format createdon
    const docdate = createdon; // Assuming docdate should be the same as createdon
    const actiondate = createdon; // Assuming actiondate should be the same as createdon

    // Save Data
    const newId = hfEditId || generateGUID(); // Assuming generateGUID is a function to generate IDs

    const postData = {
      ttca_id: newId,
      ttca_name: formData.ttca_name,
      ttca_description: formData.ttca_description,
      ttca_branchid: user.userdetails.branchid,
      ttca_createdby: user.userdetails.agencyid,
      ttca_createdon: createdon,
      dms: {
        doc_id: newId,
        createdon: createdon,
        createdby: user.userdetails.agencyid,
        branchid: user.userdetails.branchid,
        docdate: docdate,
        actiondate: actiondate,
        createdBy_empid: user.userdetails.agencyid,
        fwd_empid: user.userdetails.agencyid,
        tat_type_id: 131,
        doc_status: 0,
        attached_doc: null,
        attached_doc_name: null,
        doctype: 0,
        draftletter: null,
        tttds_is_final: 0,
        tttds_letter_type: 0,
        docremarkenc: '',
        draftletterenc: null
      },
    };

    const headers = {
      "Content-Type": "application/json",
      APIKey: config.REACT_APP_API_KEY,
    };

    try {
      const saveResponse = await axios.post(`${config.REACT_APP_API_URL}/SaveActivity?createdby=${createdby}`, postData, {
        headers,
        createdby
      });
      // Update hfEditId and formData with new ID
      if (!hfEditId) {
        setHfEditId(newId);
        setFormData(prevData => ({
          ...prevData,
          ttca_code: saveResponse.data // Update formData with the new ID
        }));
      }

      toast.success(hfEditId ? `Activity Updated successfully Your Activity code ${saveResponse.data}` : `Activity saved successfully Your Activity code ${saveResponse.data}`);
      console.log("activitydata", saveResponse);
      console.log("eee", hfEditId)
      fetchAdditionalData(config);
      toggleSidePanel();
    } catch (saveError) {
      toast.error(hfEditId ? "Error updating item:" : "Error inserting item:", saveError);
      console.error('Error saving data:', saveError);
    }
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const clearInputs = () => {
    setFormData({
      ttca_name: "",
      ttca_description: "",
      ttca_code: "",
    });

    setHfEditId("");

  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const applyFilter = () => {
    if (!filterText) {
      return sidebar; // If no filter text, return original sidebar items
    }

    return sidebar.filter((item) =>
      item.sidebaritem.toLowerCase().includes(filterText.toLowerCase()) ||
      item.sidebarsubitem.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const paginatedData = () => {
    // Step 1: Apply the filter to the entire dataset
    const filteredData = applyFilter();

    // Step 2: Calculate pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Step 3: Return paginated data from filtered results
    return filteredData.slice(startIndex, endIndex);
  };

  const handleSearchChange = (event) => {
    setFilterText(event.target.value);
    setCurrentPage(1); // Reset page number when search text changes
  };




  const handleCancel = () => {
    setShowModal(false);
  };
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    ); // Display loading message while waiting for user data
  }

  const paths = [
    { title: 'Home', url: '/' },
    { title: translations["Activities"] ||'Dashboard', url: '/dashboard' },
    { title: translations["Activities"] || "Activities", url: '/job-activity' }
  ];


  return (
    <>
      <div className="box"
        style={{
          width: isMobile ? "100%" : "98%",
          marginLeft: isMobile ? "0" : "0",
        }}
      >
        <Breadcrumb paths={paths} />

        {/* <PaginationExample /> */}
        <Box
          sx={{
            display: "none",
            "@media (max-width: 667px)": { display: "block" },
          }}
        >
          {/* Search Bar and Add Button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "60px",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              style={{ margin: "auto", width: "80%" }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <FilterListIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={filterText}
              onChange={handleSearchChange}
            />

            <Button variant="contained" color="primary" style={{ margin: "auto", width: "80%", marginTop: "15px", marginBottom: "15px" }}>
              Add
            </Button>
          </Box>

          {/* Items List */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto", // Enable vertical scrolling

              px: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                overflowX: "auto", // Enable horizontal scrolling
                pb: 2,
              }}
            >
              {paginatedData().map((option, index) => (
                <Box
                  key={index}
                  onClick={() => handelClick(option)}
                  sx={{
                    minWidth: "250px", // Adjust the card width as needed
                    border: "1px solid #ccc",
                    p: 2,
                    mr: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: option.selected ? "0px 0px 5px 2px rgba(0,0,0,0.2)" : "none",
                    borderColor: option.selected ? "blue" : "#ccc",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">{option.sidebaritem}</Typography>
                    <IconButton >
                      <DeleteIcon onClick={() => handleDelete(option.itemId)} style={{ fontSize: "2.2rem" }} />
                    </IconButton>
                  </Box>
                  <Typography variant="body1">{option.sidebarsubitem}</Typography>
                </Box>
              ))}
            </Box>
            <Pagination
              count={Math.ceil(totalRecords / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ToastContainer />
          <Drawer
            variant="permanent"
            sx={{
              width: isMobile ? "100%" : drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: isMobile ? "100%" : drawerWidth,
                boxSizing: "border-box",
              },
              "@media (max-width:600px)": {
                position: "fixed",
                zIndex: "1100",
                width: "100vw",
              },
            }}
          >
            <Box sx={{ overflow: "auto" }}>
              <List sx={{ display: "flex", flexDirection: "column" }}>
                <ListItem>
                  <TextField
                    label="Search Name/Description"
                    variant="outlined"
                    fullWidth
                    value={filterText}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <FilterListIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </ListItem>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px", width: "92%", fontSize: "15px", backgroundColor: theme.palette.primary.main, }}
                  onClick={() => clearInputs("")}
                >
                  {translations["create_new"] || "Create New"}
                </Button>
                {paginatedData().map((option, index) => (
                  <div
                    key={index}
                    className={`custom-button ${activeListItem === option ? "active-button" : ""
                      }`}
                    onClick={() => handelClick(option)}
                  >
                    <div className="custom-button-content">
                      <div className="custom-button-title">
                        {option.sidebaritem}
                      </div>
                      <div className="custom-button-ttca_description">
                        {option.sidebarsubitem}
                      </div>
                    </div>
                    <IconButton
                      className="delete-button"
                      onClick={() => handleDelete(option.itemId)}
                    >
                      <DeleteIcon style={{ fontSize: "2.2rem" }} />
                    </IconButton>
                  </div>
                ))}
              </List>
            </Box>
            <Pagination
              count={Math.ceil(totalRecords / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >

            <Box>
              <form onSubmit={handleSubmit}>
                {isEditMode && (
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TextField
                      label={translations["Activity Code"] || "Activity Code"}
                      variant="outlined"
                      value={formData.ttca_code}
                      name="ttca_code"
                      fullWidth
                      inputProps={{ readOnly: true, maxLength: 500 }}
                      InputLabelProps={{
                        shrink: formData.ttca_code ? true : false, // Shrink label if value exists
                        style: {
                          fontSize: "15px",
                          color: "#808080", // Optional: Adjust label color
                        },
                      }}
                      InputProps={{
                        style: {
                          fontSize: "15px",
                          color: "black", // Optional: Change text color if needed
                          backgroundColor: "#f0f0f0", // Optional: Change background color
                          cursor: "not-allowed", // Optional: Change cursor style
                          textAlign: 'center', // Center text within the input
                        },
                        readOnly: true,
                      }}
                    />
                  </Box>
                )}

                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {formData.ttca_name.length}/500
                  </Typography>
                  <TextField
                    label={translations["Enter Activity Name"] || "Enter Activity Name"}
                    variant="outlined"
                    value={formData.ttca_name}
                    name="ttca_name"
                    onChange={handleChange}
                    onBlur={handleBlur}  // Add this line
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    sx={{ marginBottom: "8px" }}
                    InputLabelProps={{
                      style: {
                        fontSize: "15px",
                        color: "#808080",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                      },

                    }}
                    error={nameError}
                    helperText={formErrors.name}
                  />
                </Box>
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {formData.ttca_description.length}/2000
                  </Typography>
                  <TextField
                    label={translations["Enter Description "] || "Enter Description "}
                    variant="outlined"
                    name="ttca_description"
                    value={formData.ttca_description}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{ maxLength: 2000 }}
                    sx={{ marginBottom: "20px" }}
                    InputLabelProps={{
                      style: {
                        fontSize: "15px",
                        color: "#808080",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                      },

                    }}
                    error={descError}
                    helperText={formErrors.description}
                  />
                </Box>






                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mr: 2, fontSize: "16px", backgroundColor: theme.palette.primary.main, }}
                  >
                    {translations["Save"] || "Save"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ fontSize: "16px" }}
                    onClick={() => clearInputs("")}
                  >
                    {translations["cancel"] || "Cancel"}
                  </Button>
                </Box>
              </form>
            </Box>
            <Typography
              variant="h6"
              noWrap
              style={{
                fontSize: "22px",
                marginTop: "20px",
                borderBottom: "2px solid blue",
                width: "150px", textAlign: "center"
              }}
            >
              {/* Job Position */}
              {translations["Job Position"] || "Job Position"}
            </Typography>

            <NavLink to="/job-position">
              <Button
                variant="outlined"
                color="primary"
                style={{ fontSize: "15px", margin: "10px", }}
              >
                {translations["Add Job Position"] || "Add Job Position"}
                {/* Add Job Position */}
              </Button>
            </NavLink>

          </Box>
          <Activty_Activity hfEditId={hfEditId} />

          <DmsActivity
            isSidePanelOpen={isSidePanelOpen}
            toggleSidePanel={toggleSidePanel}
            fetchAdditionalData={fetchAdditionalData}
          />

        </Box>

        {showModal && (
          <div className="main-modal">
            <div className="main-modal-content">
              <h4> Do you want to delete Function</h4>
              <div
                className="button"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button onClick={handleDeleteConfrom}> yes</Button>
                <Button onClick={handleCancel}>Cancel </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityAnt;




