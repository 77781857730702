import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import styled from 'styled-components';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import { Input, Select, Modal } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { Dropdown, Menu, } from 'antd';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PurePanel from 'antd/es/float-button/PurePanel';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TranslationContext } from '../../TranslationContext';
const { Option } = Select;

const TestListTable = () => {
    const { translations } = useContext(TranslationContext);

    const navigate = useNavigate()
    const { selectedUserType } = useUser();
    const config = useConfig();
    const [tests, setTests] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [totalItems, setTotalItems] = useState(0); // Total items
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState(); // State for search query
    const [searchCriteria, setSearchCriteria] = useState('trainingcode'); // State for selected search criteria
    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user.userdetails.agencyid;
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const getCurrentDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalMessage("");
    };

    const showModal = (message) => {
        setModalMessage(message);
        setModalOpen(true);
    };

    const handlePlayClick = async (test) => {
        console.log("Test data:", test);

        const payload = {
            skillSet: test?.skilltag,
            questionCount: test?.noofquestion,
            questionDifficutyID: test?.questionDifficultyID,
            trainingCategory: test?.trainingCategoryId,
        };

        // Define payloads for each API call

        const updateStatusPayload = {
            tptss_id: uuidv4(),
            tptss_testid: test?.testid,
            tptss_participantid: userid,
            tptss_createdon: getCurrentDate(),
            tptss_createdby: userid
        }; // Add any required fields here

        try {
            // Step 1: Check session restriction
            const checkSessionResponse = await axios.get(
                `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
                {
                    params: {
                        usertype: selectedUserType,
                        userid: userid,
                        sessionid: test?.sessionid,
                        trainingid: test?.trainingid,
                    },
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (checkSessionResponse.data === true) {
                showModal(translations["current_session_before"] || "You can not start current session before completing the previous sessions.");

                //showModal("You can not start current session before completing the previous sessions.");
                return;
            }

            // Step 2: Check test eligibility
            const eligibilityResponse = await axios.get(
                `${config?.REACT_APP_API_URL}/Check_Test_Eligibility`,
                {
                    params: {
                        userid: userid,
                        trainingid: test?.trainingid,
                        testid: test?.testid,
                    },
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (eligibilityResponse.data !== 1) {
                showModal(translations["Please_contactto_admin"] || "You are not eligible to do this test,Please contact to admin.");
                //showModal("You are not eligible to do this test,Please contact to admin.");
                return;
            }

            // Step 3: Check participant test status
            const statusResponse = await axios.get(
                `${config?.REACT_APP_EVAL_API_PATH}/tests/ParticipantTestStatus`,
                {
                    params: {


                        testid: test?.testid,
                        participantid: userid,
                    },
                    headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (statusResponse.data === false) {
                // Step 4: Update participant test status

                // Step 5: Run the test
                const runTestResponse = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
                    headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        'Content-Type': 'application/json'
                    }
                });

                if (runTestResponse.status === 200) {
                    const apiData = runTestResponse.data;
                    const confirmation = window.confirm('Once you start the test, you cannot exit without submitting it. Do you want to continue?');
                    if (confirmation == true) {
                        await axios.post(`${config.REACT_APP_EVAL_API_PATH}/Tests/Update_Participant_Test_Status`, updateStatusPayload, {
                            headers: {
                                "API-Key": config?.REACT_APP_EVAL_API_KEY,
                                'Content-Type': 'application/json'
                            }
                        });

                    }

                    // Navigate to the online test page
                    navigate('/online-test-page', { state: { test, apiData } });
                    console.log("apiData", apiData);
                } else {
                    console.error('Error running test:', runTestResponse.statusText);
                }
            } else {
                showModal(translations["already_beensubmitted"] || "This test has already been submitted by you.");
                //showModal("This test has already been submitted by you.");
            }
        } catch (error) {
            console.error('Error during API calls:', error);
            // Handle error (e.g., show a message to the user)
        }
    };


    useEffect(() => {
        const userid = user.userdetails.agencyid;
        const fetchData = async () => {
            //const url = `${config.REACT_APP_API_URL}/get_user_tests?usertype=${selectedUserType}&userid=${userid}`;
            const url = `${config.REACT_APP_API_URL}/get_user_tests?usertype=${selectedUserType}&userid=${userid}&testtype=1&PageNumber=${currentPage}&PageSize=${pageSize}`
            let payload = null;  // Initially set payload to null

            // If searchQuery has a value, include the searchCriteria in the payload
            if (searchQuery && searchQuery.trim() !== "") {
                payload = {
                    searchCriteria: [
                        {
                            column: searchCriteria,   // Search column
                            value: searchQuery,       // Search value
                            condition: '=',           // Search condition (can be 'like', '=', etc.)
                            nextoperator: 'Or',       // Next operator for multiple conditions
                        },
                    ],
                };
            }


            try {
                const response = await axios.post(url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'APIKey': config.REACT_APP_API_KEY,
                    },
                });

                console.log("response.data", response.data.items);
                setTests(response.data.items);
                setTotalItems(response.data.totalCount);
                setTotalPages(response.data.totalPages);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };


        fetchData();
    }, [currentPage, pageSize, searchQuery, searchCriteria, selectedUserType]); // Add searchQuery and searchCriteria to dependencies


    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handleSendButtonClick = async (tests, docStatus) => {
        const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
        const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from localStorage
        if (!user) {
            toast.error("User data not found");
            return;
        }
        console.log(tests, "test")

        const headers = {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
        };


        const payload = {
            tttds_info_desc: null,
            doc_id: tests,
            createdon: createdon,
            createdby: user.userdetails.agencyid,
            branchid: user.userdetails.branchid,
            docremark: "",
            docdate: createdon,
            actiondate: createdon,
            CreatedBy_empid: user.userdetails.agencyid,
            fwd_empid: user.userdetails.agencyid,
            tat_type_id: 115,
            doc_status: docStatus,
            attached_doc: null,
            attached_doc_name: null,
            doctype: 0,
            draftletter: null,
            tttds_is_final: 0,
            tttds_letter_type: 0,
            docremarkenc: null,
            draftletterenc: null,
        };


        try {
            const response = await axios.post(`${config.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
                headers: headers,
            });
            toast.success("Data sent successfully");
        } catch (error) {
            console.error("Error sending data:", error);
            toast.error("Error sending data");
        }
    };

    const StartTest = () => {
        console.log("Starting")
        navigate('/start-test')
    }

    const navigattestresult = async (test) => {
        navigate(`/test-result`, {
            state: {
                testparticipantid: test?.participantstatus,
                testquestionid: test?.testquestionid,
            }
        });
    };

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handleSearchCriteriaChange = (value) => {
        setSearchCriteria(value);
        setCurrentPage(1); // Reset to first page on criteria change
    };

    const renderActionButton = (test) => {
        const handleActionClick = (status) => {
            handleSendButtonClick(test.testID, status);
        };
        const menu = (
            <Menu>
                {test.ttttt_status == 0 || test.ttttt_status == 9 ? (
                    <Menu.Item key="1" onClick={() => handleActionClick(1)}>
                        <Button style={{ fontSize: "15px" }}>Approve</Button>
                    </Menu.Item>
                ) : (
                    <Menu.Item key="2" onClick={() => handleActionClick(9)}>

                        <Button style={{ fontSize: "15px" }}>Suspended</Button>
                    </Menu.Item>
                )}

            </Menu>
        );
        console.log("test-type", test.type);

        if (test.type == 2 && selectedUserType == 5) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View test result " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }}></span>
        }

        if (test.type !== 2 && selectedUserType == 5) {
            if (test.participantstatus == "") {
                return <span className="fa fa-play" title=" Start Test " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }} onClick={() => handlePlayClick(test)}></span>
            } else {
                return <span onClick={() => navigattestresult(test)} className="glyphicon  glyphicon-dashboard" title=" View test result " style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
            }
        }

        if (selectedUserType == 4) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }}></span>
        }

        if (selectedUserType == 1 || selectedUserType == 3) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                    <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="fa  fa-edit" title="Edit" style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="glyphicon  glyphicon-remove" title="Remove" style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }} onClick={() => handleActionClick(-1)}></span>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <span className="glyphicon  glyphicon-cog" title="Change Status" style={{ fontSize: "16px", margin: "6px", color: "gray", cursor: "pointer" }} onClick={e => e.preventDefault()}></span>
                    </Dropdown>
                </div>
            );
        }

        if (test.status == 0 || test.status == 9) {
            return <Button variant="contained" color="warning">Suspended</Button>;
        } else {
            return <Button variant="contained" color="warning"> Action</Button>;
        }
    };

    const paths = [
        { title: translations['Dashboard'] || 'Dashboard', url: '/dashboard' },
        { title: translations['My_test_list'] || 'Test List', url: '/Eval/TestList' }
    ];

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p>Error fetching data: {error.message}</p>
            </div>
        );
    }

    return (
        <>
            <Breadcrumb paths={paths} />
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>

                <Input
                    placeholder={translations["Search"] || "Search"}
                    style={{ width: '80%', padding: '8px' }}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
                <Select
                    placeholder="Select Search Criteria" className='selectsearch'
                    style={{ height: '40px', maxWidth: "400px", width: '30%' }}
                    bordered
                    value={searchCriteria}
                    onChange={handleSearchCriteriaChange}
                >
                    <Option value="trainingcode">{translations["Training_Code"] || "Training Code"} </Option>
                    <Option value="skilltag">{translations["Skill_tags"] || "Skill Tags"}</Option>
                    <Option value="training_category_name">{translations["Category"] || "Category"}</Option>
                    {/* Add more Option elements here */}
                </Select>
            </div>
            <div className="table-container">
                <TableContainer component={Paper} style={{ width: "96%", margin: "auto", marginTop: "10px" }}>
                    <Table>
                        <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                            <TableRow>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Training_Code"] || "Training Code"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Title"] || "Title"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Category"] || "Category"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Skill_tags"] || "Skill Tags"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Date/Day"] || "Date/Day"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Start_Time"] || "Start Time"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["No_of_questions"] || "No of Questions"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["marks_per_question"] || "Mark per Question"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Time_Period"]?.["In_minute"] || "Duration (min)"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Status"] || "Status"}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>{translations["Action"] || "Action"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tests.map((test, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.trainingcode}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.testname}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.training_category_name}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.skilltag}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.type == 2 ? test.ttttt_session_day : test.ttttt_session_time}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.test_time || 'N/A'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.noofquestion}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.mark_per_question}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.ttttt_session_duration}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.tdds_status == 1 ? 'Approved' : 'Suspended'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }}>
                                        <TableCell variant="contained" color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none" }}>
                                            {renderActionButton(test)}
                                        </TableCell>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
            <div className="card-container">
                {tests.map((test, index) => (
                    <div className="card" key={index}>
                        <div className="card-header">

                            {test.trainingcode}
                        </div>
                        <div className="card-body">
                            <p>{translations["Title"] || "Title"}:{test.testname}</p>
                            <p>{translations["Category"] || "Category"}: {test.training_category_name}</p>
                            <p>{translations["Skill_tags"] || "Skill Tags"}: {test.skilltag}</p>
                            <p>{translations["Date/Day"] || "Date/Day"}: {new Date(test.createdOn).toLocaleDateString()}</p>
                            <p>{translations["Start_Time"] || "Start Time"}: {test.test_time || 'N/A'}</p>
                            <p>{translations["No_of_questions"] || "No of Questions"}:{test.noofquestion}</p>
                            <p>{translations["No_of_questions"] || "Mark per Question"}:{test.mark_per_question}</p>
                            <p>{translations["Duration (min)"] || "Duration (min)"}: {test.ttttt_session_duration}</p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <p>{translations["Status"] || "Status"}: {test.tdds_status == 1 ? 'Approved' : 'Suspended'}</p>
                                <TableCell variant="contained" className='thismobile' color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none", textAlign: "center" }}>
                                    {renderActionButton(test)}
                                </TableCell>
                            </div>

                        </div>

                    </div>
                ))}
            </div>
            <div style={{ textAlign: 'end', margin: '20px 10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    {translations["Previous"] || "Previous"}
                </Button>
                <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    {translations["Next"] || "Next"}
                </Button>
            </div>
            <StyledModal
                open={modalOpen}
                onClose={handleModalClose}
                footer={null}
            >
                <DialogTitle style={{ paddingRight: '0px', fontSize: "20px" }}>Alert</DialogTitle>
                <DialogContent>
                    <p>{modalMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary" style={{ fontSize: "15px" }}>
                        OK
                    </Button>
                </DialogActions>
            </StyledModal>


        </>
    );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;  // Hide the close button
  }
  .ant-modal-footer {
    display: none;  // Hide the footer (OK/Cancel buttons if they appear)
  }
  .ant-modal {
    width: 30% !important;
  }
  .ant-collapse-header .ant-collapse-expand-icon {
    display: none !important;
  }
  @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;
    }
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export default TestListTable;
