// import React, { useState, useEffect, useRef } from 'react';
// import { Button, Box, Typography, TextField, Grid, IconButton, InputAdornment } from '@mui/material';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify'; // Assuming you have toast notifications configured
// import './modalcss.css'
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import { validatePassword } from '../../componentHome/utils/Validation';
// import { useConfig } from '../../ConfigContext';
// import hex_md5 from "md5";

// const ChangePassword = ({ passwordM, handleChangePassword }) => {
//     const config = useConfig();
//     const [dragging, setDragging] = useState(false);
//     const [position, setPosition] = useState({ x: 0, y: 0 });
//     const [initialOffset, setInitialOffset] = useState({ x: 0, y: 0 });
//     const [oldPassword, setOldPassword] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmNewPassword, setConfirmNewPassword] = useState('');
//     const [apiError, setApiError] = useState(null);
//     const [showOldPassword, setShowOldPassword] = useState(false);
//     const [showNewPassword, setShowNewPassword] = useState(false);
//     const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
//     const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
//     const [passwordsMatch, setPasswordsMatch] = useState(true);
//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         // Check if the screen is mobile-sized
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 600); // Define mobile threshold here
//         };

//         // Initial check
//         handleResize();

//         // Add resize event listener to update on screen size change
//         window.addEventListener('resize', handleResize);

//         // Cleanup event listener on unmount
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     const generateSalt = (length) => {
//         const characters = "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
//         let result = "";
//         for (let i = 0; i < length; i++) {
//             result += characters.charAt(Math.floor(Math.random() * characters.length));
//         }
//         return result;
//     };

//     useEffect(() => {
//         const centerX = window.innerWidth / 2;
//         const centerY = window.innerHeight / 2;
//         setPosition({ x: centerX, y: centerY });
//     }, []);

//     const handleMouseDown = (e) => {
//         setDragging(true);
//         setInitialOffset({
//             x: e.clientX - position.x,
//             y: e.clientY - position.y
//         });
//     };

//     const handleMouseMove = (e) => {
//         if (dragging) {
//             setPosition({
//                 x: e.clientX - initialOffset.x,
//                 y: e.clientY - initialOffset.y
//             });
//         }
//     };

//     const handleMouseUp = () => {
//         setDragging(false);
//     };

//     const handleInputChange = (event) => {
//         const { id, value } = event.target;
//         if (id === "TRG_MP_txtoldpwd") {
//             setOldPassword(value);
//         } else if (id === "TRG_MP_txtnewpwd") {
//             setPassword(value);
//         } else if (id === "TRG_MP_txtconfirmnewpwd") {
//             setConfirmNewPassword(value);
//         }
//     };

//     const handlePasswordChange = (event) => {
//         const newPassword = event.target.value;
//         setPassword(newPassword);
//         if (newPassword.length < 8 || newPassword !== confirmNewPassword) {
//             setPasswordsMatch(false);
//         } else {
//             setPasswordsMatch(true);
//         }
//     };

//     const handleConfirmPasswordChange = (event) => {
//         const newConfirmPassword = event.target.value;
//         setConfirmNewPassword(newConfirmPassword);
//         setPasswordsMatch(newConfirmPassword === password);
//     };

//     const toggleShowPassword = (type) => {
//         if (type === 'old') {
//             setShowOldPassword(!showOldPassword);
//         } else if (type === 'new') {
//             setShowNewPassword(!showNewPassword);
//         } else if (type === 'confirm') {
//             setShowConfirmNewPassword(!showConfirmNewPassword);
//         }
//     };

//     const user = JSON.parse(localStorage.getItem('user'));
//     const userid = user?.userdetails?.userid;

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setPasswordUpdateLoading(true);

//         try {
//             if (password.length < 8 || password !== confirmNewPassword) {
//                 throw new Error("Passwords do not match or are less than 8 characters.");
//             }

//             // Example validation function, replace with your own logic
//             if (!validatePassword(password)) {
//                 throw new Error("Password requirements not met. Password must be at least 8 characters long and contain at least one special character.");
//             }

//             const salt = generateSalt(8);

//             // Hash the password with salt
//             let hashedPassword = hex_md5(password).toString();

//             // Example API call using axios
//             const response = await axios.post(
//                 `${config.REACT_APP_API_URL}/UpdatePassword`,
//                 {
//                     userid: userid,
//                     password: hashedPassword,
//                     salt: salt, // Include salt in the request if necessary
//                 },
//                 {
//                     headers: {
//                         "Content-Type": "application/json",
//                         APIKey: config.REACT_APP_API_KEY,
//                     },
//                 }
//             );

//             console.log(response.data); // Log the response for verification

//             toast.success("Password Change successfully!");
//             handleChangePassword(); // Close modal after successful password update
//             // resetForm()
//         } catch (error) {
//             console.error("Error Change password:", error.message);
//             toast.error(error.message);
//         } finally {
//             setPasswordUpdateLoading(false);
//         }
//     };
//     const resetForm = () => {
//         setConfirmNewPassword()
//         setPassword()
//     };

//     const modalStyle = {
//         display: passwordM ? 'block' : 'none', // Toggle display based on open state
//         position: 'absolute',
//         top: position.y,
//         left: isMobile ? '50%' : position.x, // Set left to 206px on mobile, else use center X position
//         transform: 'translate(-50%, -50%)', // Center the modal properly
//         border: '2px solid #428bca',
//         backgroundColor: '#fff',
//         width: "80%", // Default width (for mobile responsiveness)
//         maxWidth: '500px', // Max width for larger screens
//         cursor: 'move',
//         zIndex: 1000,
//         boxSizing: 'border-box', // Ensure padding is included in width calculation
//         padding: '6px', // Padding for the modal content
//     };

//     return (
//         <>
//             <ToastContainer />
//             <div
//                 className="draggable-modal"
//                 style={modalStyle}

//             >

//                 <Box sx={{ p: 2 }}>
//                     <Grid container direction="column" spacing={2}>
//                         <div className="modal-header changehader" style={{ backgroundColor: '#f9f9f9 ' }} onMouseDown={handleMouseDown}
//                             onMouseMove={handleMouseMove}
//                             onMouseUp={handleMouseUp}>
//                             <div className="aboutmodalheader" style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
//                                 <h4 className="modal-title" style={{ padding: "5px" }}>
//                                     <span data-tag="About_littera">  Change password</span>
//                                 </h4>
//                                 <button
//                                     aria-hidden="true"
//                                     data-dismiss="modal"
//                                     className="close"
//                                     type="button"
//                                     onClick={handleChangePassword}
//                                 >
//                                     ×
//                                 </button>
//                             </div>
//                         </div>

//                         <TextField
//                             fullWidth
//                             label="Old password *"
//                             type={showOldPassword ? 'text' : 'password'}
//                             autoComplete="new-password"
//                             variant="outlined"
//                             id="TRG_MP_txtoldpwd"
//                             onChange={handleInputChange}
//                             value={oldPassword}
//                             style={{ margin: '10px', width: '97%', display: "none" }}
//                             className="my-custom-class"  // Add your custom class name here
//                             InputProps={{
//                                 endAdornment: (
//                                     <InputAdornment position="end">
//                                         <IconButton onClick={() => toggleShowPassword('old')} edge="end">
//                                             {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                                         </IconButton>
//                                     </InputAdornment>
//                                 ),
//                             }}
//                             InputLabelProps={{
//                                 style: { fontSize: "13px" } // Adjust the label font size here
//                             }}
//                             title="Please enter your old password"
//                         />


//                         <TextField
//                             fullWidth
//                             label="New password *"
//                             type={showNewPassword ? 'text' : 'password'}
//                             autoComplete="new-password"
//                             variant="outlined"
//                             id="TRG_MP_txtnewpwd"
//                             onChange={handlePasswordChange}
//                             value={password}
//                             error={password.length > 0 && !validatePassword(password)}
//                             helperText={
//                                 password.length > 0 && !validatePassword(password)
//                                     ? "Password must be at least 8 characters long and contain at least one special character"
//                                     : ""
//                             }
//                             className="my-textfield"  // Apply your custom class
//                             style={{ margin: '10px', width: '97%' }}
//                             InputProps={{
//                                 endAdornment: (
//                                     <InputAdornment position="end">
//                                         <IconButton onClick={() => toggleShowPassword('new')} edge="end">
//                                             {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                                         </IconButton>
//                                     </InputAdornment>
//                                 ),
//                             }}
//                             InputLabelProps={{
//                                 style: { fontSize: "13px" }
//                             }}
//                             title="Please enter your new password"
//                         />



//                         <TextField
//                             fullWidth
//                             label="Confirm New password *"
//                             type={showConfirmNewPassword ? 'text' : 'password'}
//                             autoComplete="new-password"
//                             variant="outlined"
//                             id="TRG_MP_txtconfirmnewpwd"
//                             onChange={handleConfirmPasswordChange}
//                             value={confirmNewPassword}
//                             error={!passwordsMatch}
//                             helperText={!passwordsMatch ? "Passwords do not match" : ""}
//                             style={{ margin: '10px', width: '97%' }}
//                             className="my-textfield"
//                             InputProps={{
//                                 endAdornment: (
//                                     <InputAdornment position="end">
//                                         <IconButton onClick={() => toggleShowPassword('confirm')} edge="end">
//                                             {showConfirmNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                                         </IconButton>
//                                     </InputAdornment>
//                                 ),
//                             }}
//                             InputLabelProps={{
//                                 style: { fontSize: "13px" } // Adjust the label font size here
//                             }}
//                             title="Please confirm your new password"
//                         />

//                         {apiError && (
//                             <Grid item>
//                                 <Typography variant="body2" color="error">
//                                     {apiError}
//                                 </Typography>
//                             </Grid>
//                         )}

//                         <Grid item>
//                             <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//                                 <Button
//                                     variant="contained"
//                                     onClick={handleSubmit}
//                                     color="primary"
//                                     disabled={!passwordsMatch || passwordUpdateLoading}
//                                 >
//                                     Change
//                                 </Button>
//                                 <Button
//                                     variant="outlined"
//                                     onClick={handleChangePassword}
//                                     style={{ marginLeft: '10px' }}
//                                     disabled={passwordUpdateLoading}
//                                 >
//                                     Close
//                                 </Button>
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </div>
//         </>
//     );
// };

// export default ChangePassword;


import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Grid, IconButton, InputAdornment } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import './modalcss.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { validatePassword } from '../../componentHome/utils/Validation';
import { useConfig } from '../../ConfigContext';
import hex_md5 from "md5";

const ChangePassword = ({ passwordM, handleChangePassword }) => {
    const config = useConfig();
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [oldPasswordVerified, setOldPasswordVerified] = useState(false); // State for old password verification
    const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [apiError, setApiError] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen is mobile-sized
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const generateSalt = (length) => {
        const characters = "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleOldPasswordChange = (e) => {
        setOldPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordsMatch(newPassword === confirmNewPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmNewPassword(newConfirmPassword);
        setPasswordsMatch(newConfirmPassword === password);
    };

    const toggleShowPassword = (type) => {
        if (type === 'old') {
            setShowOldPassword(!showOldPassword);
        } else if (type === 'new') {
            setShowNewPassword(!showNewPassword);
        } else if (type === 'confirm') {
            setShowConfirmNewPassword(!showConfirmNewPassword);
        }
    };

    const verifyOldPassword = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const salt = generateSalt(8);
            const emailid = user?.userdetails?.emailid;
            let hashedPassword = hex_md5(oldPassword).toString();
            hashedPassword = hex_md5(salt.toString() + hashedPassword.toString());
            const headers = {
                'Content-Type': 'application/json',
                APIKey: config.REACT_APP_API_KEY,
            };
            const response = await axios.post(
                `${config.REACT_APP_API_URL}/GetToken`,
                {
                    emailid: emailid,
                    salt,
                    password: hashedPassword, // Send the hashed old password
                }, { headers },
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    },
                }
            );
            console.log("response", response)
            if (response.status === 200) {
                setOldPasswordVerified(true); // Successfully verified old password
                toast.success("Old password verified successfully!");
            } else {
                setOldPasswordVerified(false);
                toast.error("Old password is incorrect.");
            }
        } catch (error) {
            console.error("Error verifying old password:", error.message);
            toast.error("Error verifying old password.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordUpdateLoading(true);

        try {
            if (password.length < 8 || password !== confirmNewPassword) {
                throw new Error("Passwords do not match or are less than 8 characters.");
            }

            if (!validatePassword(password)) {
                throw new Error("Password requirements not met.");
            }

            const salt = generateSalt(8);
            let hashedPassword = hex_md5(password).toString();

            const user = JSON.parse(localStorage.getItem('user'));
            const userid = user?.userdetails?.userid;

            const response = await axios.post(
                `${config.REACT_APP_API_URL}/UpdatePassword`,
                {
                    userid: userid,
                    password: hashedPassword,
                    salt: salt,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    },
                }
            );

            toast.success("Password changed successfully!");
            handleChangePassword(); // Close the modal after success
        } catch (error) {
            toast.error(error.message);
        } finally {
            setPasswordUpdateLoading(false);
        }
    };

    const modalStyle = {
        display: passwordM ? 'block' : 'none',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '50%' : '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #428bca',
        backgroundColor: '#fff',
        width: "80%",
        maxWidth: '500px',
        cursor: 'move',
        zIndex: 1000,
        boxSizing: 'border-box',
        padding: '6px',
    };

    return (
        <>
            <ToastContainer />
            <div className="draggable-modal" style={modalStyle}>
                <Box sx={{ p: 2 }}>
                    <Grid container direction="column" spacing={2}>
                        <div className="modal-header changehader" style={{ backgroundColor: '#f9f9f9' }}>
                            <div className="aboutmodalheader" style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                <h4 className="modal-title" style={{ padding: "5px" }}>
                                    <span>Change password</span>
                                </h4>
                                <button aria-hidden="true" data-dismiss="modal" className="close" type="button" onClick={handleChangePassword}>×</button>
                            </div>
                        </div>

                        {!oldPasswordVerified ? (
                            <>
                                <TextField
                                    fullWidth
                                    label="Old password *"
                                    type={showOldPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    id="TRG_MP_txtoldpwd"
                                    onChange={handleOldPasswordChange}
                                    value={oldPassword}
                                    style={{ margin: '10px', width: '97%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => toggleShowPassword('old')} edge="end">
                                                    {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Button variant="contained" color="primary" onClick={verifyOldPassword}>
                                        Verify Old Password
                                    </Button>
                                </div>

                            </>
                        ) : (
                            <>
                                <TextField
                                    fullWidth
                                    label="New password *"
                                    type={showNewPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    id="TRG_MP_txtnewpwd"
                                    onChange={handleNewPasswordChange}
                                    value={password}
                                    error={password.length > 0 && !validatePassword(password)}
                                    helperText={password.length > 0 && !validatePassword(password) ? "Password must be at least 8 characters long and contain at least one special character" : ""}
                                    style={{ margin: '10px', width: '97%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => toggleShowPassword('new')} edge="end">
                                                    {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Confirm New password *"
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    id="TRG_MP_txtconfirmnewpwd"
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmNewPassword}
                                    error={!passwordsMatch}
                                    helperText={!passwordsMatch ? "Passwords do not match" : ""}
                                    style={{ margin: '10px', width: '97%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => toggleShowPassword('confirm')} edge="end">
                                                    {showConfirmNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid item>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!passwordsMatch || passwordUpdateLoading}>Change</Button>
                                        <Button variant="outlined" onClick={handleChangePassword} style={{ marginLeft: '10px' }} disabled={passwordUpdateLoading}>Close</Button>
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </div>
        </>
    );
};

export default ChangePassword;


