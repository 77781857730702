import React from 'react'
import JobRollAnt from '../componentHome/fromtab/JobRollAnt'

const JobRollAntPage = () => {
  return (
    
   
        <JobRollAnt></JobRollAnt>

  )
}

export default JobRollAntPage