import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { useFeedback } from '../../../Context/FeedbackContext';

const TwoMonthCalendar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const datePickerRef = useRef(null); 
    const { 
        setSessionDates, 
        trainingDetails,
        startDate, 
        endDate, 
        initialAllowedStartDate, 
        initialAllowedEndDate, 
        setStartDate,
        setEndDate,
        setInitialAllowedStartDate,
        setInitialAllowedEndDate
    } = useFeedback();

    useEffect(() => {
        if (trainingDetails) {
            const start = new Date(trainingDetails.t_StartDate);
            const end = new Date(trainingDetails.t_EndDate);
            setSessionDates({ startDate: start, endDate: end });

            setInitialAllowedStartDate(start);
            setInitialAllowedEndDate(end);

            setStartDate(start);
            setEndDate(end);
        } else {
            console.log("trainingDetails is undefined");
        }
    }, [trainingDetails]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const isDateInRange = (date) => {
        return (
            initialAllowedStartDate && 
            initialAllowedEndDate &&
            date >= initialAllowedStartDate && 
            date <= initialAllowedEndDate
        );
    };

    const dayClassName = (date) => {
        if (date.toDateString() === new Date().toDateString()) {
            return 'today'; 
        }
        return isDateInRange(date) ? 'allowed-date' : 'disabled'; 
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="two-month-datepicker" ref={datePickerRef}>
            <input
                type="text"
                onClick={() => setIsOpen(!isOpen)}
                readOnly
                placeholder="Select date range"
                value={startDate && endDate ? 
                    `${new Intl.DateTimeFormat('en-GB').format(startDate)} - ${new Intl.DateTimeFormat('en-GB').format(endDate)}` : ''}
            />

            {/* <input
                type="text"
                onClick={() => setIsOpen(!isOpen)}
                readOnly
                placeholder="Select date range"
                value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
            /> */}
            {isOpen && (
                <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    monthsShown={2}
                    filterDate={isDateInRange} 
                    dayClassName={dayClassName}
                />
            )}
        </div>
    );
};

export default TwoMonthCalendar;