import { CircularProgress } from '@mui/material';
import React, { useContext, useRef } from 'react'
import { useFeedback } from '../Context/FeedbackContext.jsx';
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs.js';
import SessionDetailModal from '../components/Content/Session/SessionDetailModal.jsx';
import SessionList from '../components/Content/Session/SessionList.jsx';
import { useLocation, useParams } from 'react-router-dom';
import SingleTrainingDetailList from '../components/Content/TrainingDetails/SingleTrainingDetailList.jsx';
import { IoMdPrint } from 'react-icons/io';
import { TranslationContext } from '../TranslationContext.js';

const TrainingDetails = () => {
    const { loading } = useFeedback()
    const { trainingDetails } = useFeedback()
    const { translations } = useContext(TranslationContext)
    const location = useLocation();
    const { test } = location.state || {};  // Default to empty object if state is undefined

    console.log("Received test data:", test);
    // const paths = [
    //     { title: 'Dashboard', url: '/dashboard' },
    //     { title: 'Training List', url: '/training-list' },
    //     { title: 'Training Details', url: '/Training/Training_details/?trainingid' }
    // ];
    const paths = [];

    // Check if the user is coming from the SessionDetail page (with any id)
    // const isFromSessionDetail = location.pathname.startsWith('/Session_trg/SessionDetail/');

    if (test) {
        paths.push({ title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' });
        paths.push({ title: 'Training List', url: '/training-list' });
        paths.push({ title: 'Training Details', url: '/Training/Training_details/?trainingid' });
    } else {
        paths.push({ title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' });
        paths.push({ title: translations["Session Calendar"] || 'Session Calendar', url: '/Session_Calendar' });
        paths.push({ title: 'Training Details', url: '/Training/Training_details/?trainingid' });
    }

    const ScreenRef = useRef(null)

    const handlePrint = () => {
        const content = ScreenRef.current.innerHTML;  // Get the HTML content of the div
        const printWindow = window.open('', '', 'height=1000,width=1000');

        // Extract all <style> tags from the current document to capture dynamic styles
        const styles = Array.from(document.querySelectorAll('style')).map(style => style.innerHTML).join('');

        // Extract all external stylesheets
        const styleSheets = Array.from(document.styleSheets);
        let externalStyles = '';

        styleSheets.forEach(sheet => {
            try {
                const rules = sheet.cssRules || sheet.rules;
                if (rules) {
                    for (let rule of rules) {
                        externalStyles += rule.cssText;
                    }
                }
            } catch (e) {
                console.error("Error reading stylesheet: ", e);
            }
        });

        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>' + styles + '</style>');  // Inject styles from <style> tags
        printWindow.document.write('<style>' + externalStyles + '</style>');  // Inject external stylesheets

        // Additional styles for the print window
        printWindow.document.write('<style>');
        printWindow.document.write('body { width: 100%; margin: 0; padding: 0; font-family: Arial, sans-serif; padding: 6px }');
        printWindow.document.write('img { max-width: 100%; height: auto; display: block; margin: 0 auto; }');
        printWindow.document.write('@media print { body { width: 100%; font-size: 12pt; } }');
        printWindow.document.write('</style>');
        printWindow.document.write('</head><body>');

        printWindow.document.write('<h2>Training Details</h2>');  // Title
        printWindow.document.write(`<h2>${trainingDetails?.courseName || null} - ${trainingDetails?.trainingcode || null}</h2>`);  // Course name

        printWindow.document.write(content);  // Inject the content to be printed
        printWindow.document.write('</body></html>');
        printWindow.document.close();  // Close the document so it can render

        // Trigger the print dialog in the new window/tab
        printWindow.print();
    };

    // if (trainingDetails == null ) {
    //     return (
    //       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //           <CircularProgress />
    //       </div>
    //     );
    // }

    return <>
        <Breadcrumb paths={paths} />
        <div className='p-6 flex flex-col gap-6 h-full w-full'>
            <div className="flex justify-end pr-8">
                <IoMdPrint title={translations["Print"] || "Print"} onClick={handlePrint} fontSize={18} cursor={"pointer"} />
            </div>

            <div ref={ScreenRef}>
                <div className='flex flex-col gap-8' >
                    <SessionDetailModal pagetype={'training'} />
                    <SingleTrainingDetailList />
                </div>
            </div>
        </div>
    </>
}

export default TrainingDetails