import { CircularProgress, Switch, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useFeedback } from '../Context/FeedbackContext.jsx';
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs.js';
import SessionDetailModal from '../components/Content/Session/SessionDetailModal.jsx';
import SessionList from '../components/Content/Session/SessionList.jsx';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemText, ListItemButton, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TranslationContext } from '../TranslationContext.js';
import { useContext } from 'react';

const SessionDetails = () => {
    const { translations } = useContext(TranslationContext);
    
    // const paths = [
    //     { title: 'Dashboard', url: '/dashboard' },
    //     { title: 'Session Details', url: '/Session_trg/SessionDetail?trainingid' }
    // ];
       const location = useLocation();
    const { test } = location.state || {};  // Default to empty object if state is undefined

    console.log("Received test data:", test);
     const paths = [];

    // Check if the user is coming from the SessionDetail page (with any id)
    // const isFromSessionDetail = location.pathname.startsWith('/Session_trg/SessionDetail/');

    if (test) {
        paths.push({ title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' });
        paths.push({ title: 'Training List', url: '/training-list' });
        paths.push({ title: 'Session Details', url: '/Session_trg/SessionDetail?trainingid' });
    } else {
        paths.push({ title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' });
        paths.push({ title: 'Session Details', url: '/Session_trg/SessionDetail?trainingid' });   
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [open, setOpen] = useState(false); // State to track collapse

    const toggleCollapse = () => {
      setOpen(!open);
    };

    return <>
        <Breadcrumb paths={paths} />
        <div>
            <List className='bg-white pb-0'>
                <ListItemButton onClick={toggleCollapse}>
                <ListItemText 
                    primary={open 
                        ? `${translations["Hide"] || "Hide"} ${translations["Training_details"] || "Training Details"}` 
                        : `${translations["Show"] || "Show"} ${translations["Training_details"] || "Training Details"}`} 
                />
                {/* <ListItemText primary= { open ? "Hide Training Details" : "Show Training Details"  }  /> */}
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} {/* Icon changes based on open state */}
                </ListItemButton>
            </List>

            <Collapse in={open}>
                <List className='bg-white' component="div" disablePadding>
                <ListItem button>
                    <SessionDetailModal pagetype={'session'}/>
                </ListItem>
                </List>
            </Collapse>
        </div>

        <div className='px-6 bg-white pb-6 h-full w-full'>
            {/* <SessionDetailModal pagetype={'session'}/> */}
            <SessionList  />
        </div>
    </>
}

export default SessionDetails