// import React, { useState, useEffect } from 'react';
// import { useFeedback } from '../../Context/FeedbackContext';
// import Loader from '../../pages/Loader';

// const ContentFeedback = ({ onNext }) => {
//   const { ContentQuestions, selectedSessionContent, feedback, setFeedback } = useFeedback();
//   const [currentItemIndex, setCurrentItemIndex] = useState(0);
//   const [feedbackData, setFeedbackData] = useState([]);
//   const [completedFeedback, setCompletedFeedback] = useState([]);

//   useEffect(() => {
//     if (selectedSessionContent && selectedSessionContent.items) {
//       const initialFeedbackData = selectedSessionContent.items.map(() => ({
//         ratings: ContentQuestions.map(() => 0),
//         comments: ContentQuestions.map(() => ''),
//         showCommentBox: ContentQuestions.map(() => false),
//       }));
//       setFeedbackData(initialFeedbackData);
//       setCompletedFeedback(Array(selectedSessionContent.items.length).fill(false));
//     }
//   }, [selectedSessionContent, ContentQuestions]);

//   const handleRating = (index, questionIndex, rating) => {
//     const newFeedbackData = [...feedbackData];
//     newFeedbackData[index].ratings[questionIndex] = rating;
//     newFeedbackData[index].showCommentBox[questionIndex] = rating <= 2;
//     setFeedbackData(newFeedbackData);
//   };

//   const handleCommentChange = (index, questionIndex, event) => {
//     const newFeedbackData = [...feedbackData];
//     newFeedbackData[index].comments[questionIndex] = event.target.value;
//     setFeedbackData(newFeedbackData);
//   };

//   const canProceed = () => {
//     const currentItemFeedback = feedbackData[currentItemIndex];
//     if (!currentItemFeedback) return false;

//     for (let i = 0; i < ContentQuestions.length; i++) {
//       if (currentItemFeedback.ratings[i] <= 2 && currentItemFeedback.comments[i].trim() === '') {
//         return false;
//       }
//     }
//     return true;
//   };

//   const handleNextItem = () => {
//     const currentItemFeedback = feedbackData[currentItemIndex];
    
//     const newFeedbackItem = {
//       contentId: selectedSessionContent.items[currentItemIndex]?.ttsam_id || null,
//       facultyId: selectedSessionContent.items[currentItemIndex]?.facultyid || null,
//       questions: ContentQuestions.map((question, questionIndex) => ({
//         questionId: question.questionId,
//         questionType: question.questionType.toString(),
//         answerId: question.ratingAnswers[0]?.answerId || null,
//         answerDescription: currentItemFeedback.comments[questionIndex] || null,
//         rating: currentItemFeedback.ratings[questionIndex].toString(),
//         isFacultyQuestion: question.isFacultyQuestion || 0,
//       }))
//     };

//     const updatedFeedback = [...feedback];
//     const existingIndex = feedback.findIndex(item =>
//       item.contentId === newFeedbackItem.contentId &&
//       item.facultyId === newFeedbackItem.facultyId
//     );

//     if (existingIndex !== -1) {
//       updatedFeedback[existingIndex] = newFeedbackItem;
//     } else {
//       updatedFeedback.push(newFeedbackItem);  
//     }

//     setFeedback(updatedFeedback);

//     const updatedCompletedFeedback = [...completedFeedback];
//     updatedCompletedFeedback[currentItemIndex] = true;
//     setCompletedFeedback(updatedCompletedFeedback);

//     if (currentItemIndex < selectedSessionContent.items.length - 1) {
//       setCurrentItemIndex(currentItemIndex + 1);
//     } else {
//       console.log("All Feedback Data:", updatedFeedback); // Log updatedFeedback instead of feedback
//       onNext();
//     }
//   };

//   const handlePreviousItem = () => {
//     if (currentItemIndex > 0) {
//       setCurrentItemIndex(currentItemIndex - 1);
//     }
//   };

//   const stripHtmlTags = (html) => {
//     const tmp = document.createElement('DIV');
//     tmp.innerHTML = html;
//     return tmp.textContent || tmp.innerText || '';
//   };

//   const currentItem = selectedSessionContent.items && selectedSessionContent.items[currentItemIndex];

//   if (!currentItem) {
//     return <Loader />;
//   }

//   return (
//     <>
//       <div className="space-y-7 ">
//         <h4>{currentItemIndex + 1}. {currentItem.ttsad_title}</h4>
//         <div className='space-y-7 overflow-y-auto max-h-96'>
//           {ContentQuestions.map((question, questionIndex) => (
//             <div key={questionIndex} className='flex-shrink-0 '>
//               <label className="block text-sm font-medium mb-4 text-black">
//                 {stripHtmlTags(question.questionText)}
//               </label>
//               <div className="flex space-x-3">
//                 {[1, 2, 3, 4, 5].map((value) => (
//                   <button
//                     key={value}
//                     className={`focus:outline-none ${
//                       feedbackData[currentItemIndex]?.ratings[questionIndex] >= value ? 'text-yellow-500' : 'text-gray-400'
//                     }`}
//                     onClick={() => handleRating(currentItemIndex, questionIndex, value)}
//                   >
//                     ★
//                   </button>
//                 ))}
//               </div>
//               {feedbackData[currentItemIndex]?.showCommentBox[questionIndex] && (
//                 <input
//                   type="text"
//                   className="mt-2 w-full border rounded px-3 py-2"
//                   placeholder={`Please provide feedback for ${currentItem.ttsad_title}`}
//                   value={feedbackData[currentItemIndex]?.comments[questionIndex]}
//                   onChange={(event) => handleCommentChange(currentItemIndex, questionIndex, event)}
//                 />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className={`mt-6 flex ${currentItemIndex > 0 ? "justify-between" : "justify-end"}`}>
//         {
//           currentItemIndex > 0 ?
//             <button
//               className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
//                 currentItemIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
//               }`}
//               onClick={handlePreviousItem}
//               disabled={currentItemIndex === 0}
//             >
//               Previous
//             </button>
//             :
//             null
//         }
//         <button
//           className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
//             !canProceed() ? 'opacity-50 cursor-not-allowed' : ''
//           }`}
//           onClick={handleNextItem}
//           disabled={!canProceed()}
//         >
//           {currentItemIndex === selectedSessionContent.items.length - 1 ? 'Finish' : 'Next'}
//         </button>
//       </div>
//     </>
//   );
// };

// export default ContentFeedback;





// import React, { useState, useEffect } from 'react';
// import { useFeedback } from '../../Context/FeedbackContext';
// import Loader from '../../pages/Loader';

// const ContentFeedback = ({ onNext }) => {
//   const { 
//     ContentQuestions, 
//     selectedSessionContent, 
//     feedback, 
//     setFeedback , 
//     setContentFeedback , 
//     ContentFeedback ,
//     isReverse, 
//     setisReverse
//   } = useFeedback();
//   const [currentItemIndex, setCurrentItemIndex] = useState(0);
//   const [feedbackData, setFeedbackData] = useState([]);
//   const [completedFeedback, setCompletedFeedback] = useState([]);

//   useEffect(() => {
//     if (selectedSessionContent && selectedSessionContent.items) {
//       const initialFeedbackData = selectedSessionContent.items.map((item, index) => {
//         // Check if feedback exists for this item
//         const existingFeedback = feedback.find(
//           fb => fb.contentId === item.ttsam_id
//         );

//         if (existingFeedback) {
//           console.log("ha hai")
//           // Map existing feedback to match initial structure
//           return {
//             ratings: existingFeedback.questions.map(question => parseInt(question.rating)),
//             comments: existingFeedback.questions.map(question => question.answerDescription || ''),
//             showCommentBox: existingFeedback.questions.map(question => question.rating <= 2),
//           };
//         } else {
//           console.log("nahhi hai")

//           // Initialize with default values
//           return {
//             ratings: ContentQuestions.map(() => 0),
//             comments: ContentQuestions.map(() => ''),
//             showCommentBox: ContentQuestions.map(() => false),
//           };
//         }
//       });

//       setFeedbackData(initialFeedbackData);
//       setCompletedFeedback(Array(selectedSessionContent.items.length).fill(false));
//     }
//   }, [selectedSessionContent, ContentQuestions, feedback]);

//   const handleRating = (index, questionIndex, rating) => {
//     const newFeedbackData = [...feedbackData];
//     newFeedbackData[index].ratings[questionIndex] = rating;
//     newFeedbackData[index].showCommentBox[questionIndex] = rating <= 2;
//     setFeedbackData(newFeedbackData);
//   };

//   const handleCommentChange = (index, questionIndex, event) => {
//     const newFeedbackData = [...feedbackData];
//     newFeedbackData[index].comments[questionIndex] = event.target.value;
//     setFeedbackData(newFeedbackData);
//   };

//   const canProceed = () => {
//     const currentItemFeedback = feedbackData[currentItemIndex];
//     if (!currentItemFeedback) return false;

//     for (let i = 0; i < ContentQuestions.length; i++) {
//       if (currentItemFeedback.ratings[i] <= 2 && currentItemFeedback.comments[i].trim() === '') {
//         return false;
//       }
//     }
//     return true;
//   };

//   const handleNextItem = () => {
//     const currentItemFeedback = feedbackData[currentItemIndex];
    
//     const newFeedbackItem = {
//       contentId: selectedSessionContent.items[currentItemIndex]?.ttsam_id || null,
//       facultyId: selectedSessionContent.items[currentItemIndex]?.facultyid || null,
//       questions: ContentQuestions.map((question, questionIndex) => ({
//         questionId: question.questionId,
//         questionType: question.questionType.toString(),
//         answerId: question.ratingAnswers[0]?.answerId || null,
//         answerDescription: currentItemFeedback.comments[questionIndex] || null,
//         rating: currentItemFeedback.ratings[questionIndex].toString(),
//         isFacultyQuestion: question.isFacultyQuestion || 0,
//       }))
//     };

//     const updatedFeedback = [...feedback];
//     const existingIndex = feedback.findIndex(item =>
//       item.contentId === newFeedbackItem.contentId 
//     );

//     if (existingIndex !== -1) {
//       updatedFeedback[existingIndex] = newFeedbackItem;
//     } else {
//       updatedFeedback.push(newFeedbackItem);
//     }

//     setFeedback(updatedFeedback);
//     setContentFeedback(feedback)

//     const updatedCompletedFeedback = [...completedFeedback];
//     updatedCompletedFeedback[currentItemIndex] = true;
//     setCompletedFeedback(updatedCompletedFeedback);

//     if (currentItemIndex < selectedSessionContent.items.length - 1) {
//       setCurrentItemIndex(currentItemIndex + 1);
//     } else {
//       console.log("All Feedback Data:", ContentFeedback); // Log updatedFeedback instead of feedback
//       onNext();
//     }
//   };

//   const handlePreviousItem = () => {
//     if (currentItemIndex > 0) {
//       setCurrentItemIndex(currentItemIndex - 1);
//     }
//   };

//   const stripHtmlTags = (html) => {
//     const tmp = document.createElement('DIV');
//     tmp.innerHTML = html;
//     return tmp.textContent || tmp.innerText || '';
//   };

//   const currentItem = selectedSessionContent.items && selectedSessionContent.items[currentItemIndex];

//   if (!currentItem) {
//     return <Loader />;
//   }

//   return (
//     <>
//       <div className="space-y-7 ">
//         <h4>{currentItemIndex + 1}. {currentItem.ttsad_title}</h4>
//         <div className='space-y-7 overflow-y-auto max-h-96'>
//           {ContentQuestions.map((question, questionIndex) => (
//             <div key={questionIndex} className='flex-shrink-0 '>
//               <label className="block text-sm font-medium mb-4 text-black">
//                 {stripHtmlTags(question.questionText)}
//               </label>
//               <div className="flex space-x-3">
//                 {[1, 2, 3, 4, 5].map((value) => (
//                   <button
//                     key={value}
//                     className={`focus:outline-none ${
//                       feedbackData[currentItemIndex]?.ratings[questionIndex] >= value ? 'text-yellow-500' : 'text-gray-400'
//                     }`}
//                     onClick={() => handleRating(currentItemIndex, questionIndex, value)}
//                   >
//                     ★
//                   </button>
//                 ))}
//               </div>
//               {feedbackData[currentItemIndex]?.showCommentBox[questionIndex] && (
//                 <input
//                   type="text"
//                   className="mt-2 w-full border rounded px-3 py-2"
//                   placeholder={`Please provide feedback for ${currentItem.ttsad_title}`}
//                   value={feedbackData[currentItemIndex]?.comments[questionIndex]}
//                   onChange={(event) => handleCommentChange(currentItemIndex, questionIndex, event)}
//                 />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className={`mt-6 flex ${currentItemIndex > 0 ? "justify-between" : "justify-end"}`}>
//         {
//           currentItemIndex > 0 ?
//             <button
//               className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
//                 currentItemIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
//               }`}
//               onClick={handlePreviousItem}
//               disabled={currentItemIndex === 0}
//             >
//               Previous
//             </button>
//             :
//             null
//         }
//         <button
//           className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
//             !canProceed() ? 'opacity-50 cursor-not-allowed' : ''
//           }`}
//           onClick={handleNextItem}
//           disabled={!canProceed()}
//         >
//           {currentItemIndex === selectedSessionContent.items.length - 1 ? 'Finish' : 'Next'}
//         </button>
//       </div>
//     </>
//   );
// };

// export default ContentFeedback;


import React, { useState, useEffect } from 'react';
import { useFeedback } from '../../Context/FeedbackContext';
import Loader from '../../pages/Loader';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../ConfigContext';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const ContentFeedback = ({ onNext }) => {
  const { 
    ContentQuestions, 
    selectedSessionContent, 
    feedback, 
    setFeedback , 
    setContentFeedback , 
    ContentFeedback ,
    isReverse, 
    setisReverse,
    setCurrentForm,
    SingleSessionData,
    setLoading,
    selectedSessionId,
    setisVisible,
    setisFeedbackSubmitted
  } = useFeedback();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [feedbackData, setFeedbackData] = useState([]);
  const [completedFeedback, setCompletedFeedback] = useState([]);
  const { id } = useParams()
  const config = useConfig()
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (selectedSessionContent && selectedSessionContent.items) {
      const initialFeedbackData = selectedSessionContent.items.map((item, index) => {
        // Check if feedback exists for this item
        const existingFeedback = feedback.find(
          fb => fb.contentId === item.ttsam_id
        );

        if (existingFeedback) {
          // Map existing feedback to match initial structure
          return {
            ratings: existingFeedback.questions.map(question => parseInt(question.rating)),
            comments: existingFeedback.questions.map(question => question.answerDescription || ''),
            showCommentBox: existingFeedback.questions.map(question => question.rating <= 2),
          };
        } else {
          // Initialize with default values
          return {
            ratings: ContentQuestions.map(() => 0),
            comments: ContentQuestions.map(() => ''),
            showCommentBox: ContentQuestions.map(() => false),
          };
        }
      });

      setFeedbackData(initialFeedbackData);
      setCompletedFeedback(Array(selectedSessionContent.items.length).fill(false));
    }
  }, [selectedSessionContent, ContentQuestions, feedback]);

  const handleRating = (index, questionIndex, rating) => {
    const newFeedbackData = [...feedbackData];
    newFeedbackData[index].ratings[questionIndex] = rating;
    newFeedbackData[index].showCommentBox[questionIndex] = rating <= 2;
    setFeedbackData(newFeedbackData);
  };

  const handleCommentChange = (index, questionIndex, event) => {
    const newFeedbackData = [...feedbackData];
    newFeedbackData[index].comments[questionIndex] = event.target.value;
    setFeedbackData(newFeedbackData);
  };

  const canProceed = () => {
    const currentItemFeedback = feedbackData[currentItemIndex];
    if (!currentItemFeedback) return false;

    // for (let i = 0; i < ContentQuestions.length; i++) {
    //   if (currentItemFeedback.ratings[i] <= 2 && currentItemFeedback.comments[i].trim() === '') {
    //     return false;
    //   }
    // }
    for (let i = 0; i < ContentQuestions.length; i++) {
      if (currentItemFeedback.ratings[i] < 1 ) {
        return false;
      }
    }

    return true;
  };

  const CompleteSessionHandler = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
  
      const params = {
        trainingid: id,
        Sessionid: selectedSessionId,
        Participantid: user?.userdetails?.agencyid,
        timeonsession:null,
        branchid: config?.REACT_APP_ID,
        status: 1,
      };
  
      // Construct the URL with parameters
      const url = `${config?.REACT_APP_API_URL}/Update_Session_Status?` +
        `Participantid=${encodeURIComponent(params.Participantid)}&` +
        `trainingid=${encodeURIComponent(params.trainingid)}&` +
        `Sessionid=${encodeURIComponent(params.Sessionid)}&` +
        `timeonsession=${encodeURIComponent(params.timeonsession)}&` +
        `branchid=${encodeURIComponent(params.branchid)}&` +
        `status=${encodeURIComponent(params.status)}`;
  
      try {
        const { data } = await axios.post(
          url, // Use the constructed URL with parameters
          null, // Pass null as the second argument since data is passed in URL query string
          { headers: headers }
        );
  
        // console.log(data);
        setLoading(false);
      } catch (error) {
        console.error('Error Completing session', error);
        console.error('URL', url);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Please provide Training id');
    }
  };

  const checkFeedbackStatus = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {

        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CHECK_SESSION_FEEBDACK`, {
            headers: headers,
            params: {
                userid:user?.userdetails?.agencyid,
                sessionid:selectedSessionId, 
                trainingid:id 
            }
        });
        setisFeedbackSubmitted(data)
        console.log('CHECK_SESSION_FEEBDACK', data);

        setLoading(false);
      } catch (error) {
        console.log("error" , { sessionid:selectedSessionId  , trainingid:id })
        console.log('Error fetching sessions content', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);      
    }
  };

  const postFeedback = async (feedback)=>{

    if(id || feedback){

      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
      
      const params = {
        trainingid: id,
        sessionid: selectedSessionId,
        loginagencyid: user?.userdetails?.agencyid,
      };

      try {
        const { data } = await axios.post(`${config?.REACT_APP_API_URL}/SaveContentFeedback`, 
        { feedback : feedback }, 
        { headers: headers , params: params });
  
        console.log('Feedback Posted Succesfully', data);
        if(SingleSessionData?.completiontype?.id === 1){
          await CompleteSessionHandler()
          toast.success('Session Completed Succesfully');
        }
        else{
          toast.success('Feedback Posted Succesfully');
        }
        await checkFeedbackStatus()
        setLoading(false);
        setisVisible(false)
        setCurrentForm('content')
        setFeedback([])
      } catch (error) {
        console.error('Error posting session Comments:', error);
      } finally {
        setLoading(false);
      }      
    }
    else{
      console.error("Please provide Training id ")
    }
  }

  const handleNextItem = () => {
    const currentItemFeedback = feedbackData[currentItemIndex];
    
    const newFeedbackItem = {
      contentId: selectedSessionContent.items[currentItemIndex]?.ttsam_id || null,
      facultyId: selectedSessionContent.items[currentItemIndex]?.facultyid || null,
      questions: ContentQuestions.map((question, questionIndex) => ({
        questionId: question.questionId,
        questionType: question.questionType.toString(),
        answerId: question.ratingAnswers[0]?.answerId || null,
        answerDescription: currentItemFeedback.comments[questionIndex] || null,
        rating: currentItemFeedback.ratings[questionIndex].toString(),
        isFacultyQuestion: question.isFacultyQuestion || 0,
      }))
    };

    const updatedFeedback = [...feedback];
    const existingIndex = feedback.findIndex(item =>
      item.contentId === newFeedbackItem.contentId 
    );

    if (existingIndex !== -1) {
      updatedFeedback[existingIndex] = newFeedbackItem;
    } else {
      updatedFeedback.push(newFeedbackItem);
    }

    setFeedback(updatedFeedback);
    setContentFeedback(feedback);

    const updatedCompletedFeedback = [...completedFeedback];
    updatedCompletedFeedback[currentItemIndex] = true;
    setCompletedFeedback(updatedCompletedFeedback);

    if (currentItemIndex < selectedSessionContent.items.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else if (facultyarray?.length > 0) {
      onNext();
    }
    else {
      postFeedback(updatedFeedback)
      console.log("All Feedback Data:", ContentFeedback);
    }
  };

  const handlePreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const facultyarray = SingleSessionData?.sessionFaculties;

  const currentItem = selectedSessionContent?.items && selectedSessionContent?.items[currentItemIndex];

  // console.log('currentItem' , currentItem )

  useEffect(() => {
    if(currentItem === undefined || currentItem === null && facultyarray ){
      setCurrentForm("faculty")
    }
  }, [currentItem])

  console.log("faa" , currentItem)
  
  if (!currentItem) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    ) 
  }

  return (
    <>
      <div className="space-y-7 ">
        <h4>{currentItemIndex + 1}. {currentItem.ttsad_title}</h4>
        <div className='space-y-7 max-h-[35vh] overflow-y-auto'>
          {ContentQuestions.map((question, questionIndex) => (
            <div key={questionIndex} className='flex-shrink-0 '>
              <label className="block text-sm font-medium mb-4 text-black">
                {stripHtmlTags(question.questionText)}
              </label>
              <div className="flex space-x-3">
                {
                  config?.FEEDBACK_RATING_TYPE == 0 ?
                  <>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <button
                      key={value}
                      className={`focus:outline-none ${
                        feedbackData[currentItemIndex]?.ratings[questionIndex] >= value ? 'text-yellow-500' : 'text-gray-400'
                      }`}
                      onClick={() => handleRating(currentItemIndex, questionIndex, value)}
                    >
                      ★
                    </button>
                  ))}
                  </>
                  :
                  <div className='flex items-center gap-4'>
                     <button
                        className={`focus:outline-none ${
                          feedbackData[currentItemIndex]?.ratings[questionIndex] === 5
                            ? 'text-yellow-500'
                            : 'text-gray-400'
                        }`}
                        onClick={() => handleRating(currentItemIndex, questionIndex, 5)} // thumbs up
                    >
                        <FaThumbsUp />
                    </button>
                    <button
                        className={`focus:outline-none ${
                          feedbackData[currentItemIndex]?.ratings[questionIndex] === 1
                            ? 'text-yellow-500'
                            : 'text-gray-400'
                        }`}
                        onClick={() => handleRating(currentItemIndex, questionIndex, 1)} // thumbs down
                    >
                        <FaThumbsDown />
                    </button>
                   
                  </div>
                }
              </div>
                {
                  config?.FEEDBACK_RATING_TYPE == 0 ?
                  <>
                    {feedbackData[currentItemIndex]?.showCommentBox[questionIndex] && (
                      <input
                        type="text"
                        className="mt-2 w-full border rounded px-3 py-2"
                        placeholder={`Please provide feedback for ${currentItem.ttsad_title}`}
                        value={feedbackData[currentItemIndex]?.comments[questionIndex]}
                        onChange={(event) => handleCommentChange(currentItemIndex, questionIndex, event)}
                      />
                    )}
                  </>
                  :
                  null
                }
            </div>
          ))}
        </div>
      </div>
      <div className={`mt-6 flex ${currentItemIndex > 0 ? "justify-between" : "justify-end"}`}>
        {
          currentItemIndex > 0 ?
            <button
              className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
                currentItemIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousItem}
              disabled={currentItemIndex === 0}
            >
              Previous
            </button>
            :
            null
        }
        <button
          className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-7 rounded-md focus:outline-none ${
            !canProceed() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextItem}
          disabled={!canProceed()}
        >
        {currentItemIndex === selectedSessionContent?.items?.length - 1 && facultyarray?.length === 0   
          ? 'Submit'
          : 'Next'
        }
        </button>
      </div>
    </>
  );
};

export default ContentFeedback;