import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const printData = (formattedContent, config) => {
  if (window.XamarinApp && typeof window.XamarinApp.callXamarinFunction === 'function') {
    const newUUID = uuidv4(); // Generate a unique identifier
    const postData = {
      printData: formattedContent // Send the print data
    };

    // Post the data to the server
    axios.post(`${config?.REACT_APP_API_URL}/SET_PRINT_DATA?id=${newUUID}`, postData, {
      headers: {
        'Content-Type': 'application/json',
        APIKey: config?.REACT_APP_API_KEY,
      }
    })
      .then(response => {
        console.log('API response:', response.data);
        // Call Xamarin function with the new URL
        window.XamarinApp.callXamarinFunction(`${window.location.origin}/printdata?id=${newUUID}`);
      })
      .catch(error => {
        console.error('Error in sending POST request:', error);
      });
  } else {
    // If Xamarin is not available, open a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(formattedContent);
    printWindow.document.close();
    printWindow.onload = function () {
      printWindow.print();
    };
  }
};

export default printData;
