import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useConfig } from '../../ConfigContext';
import hex_md5 from "md5";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { TranslationContext } from '../../TranslationContext';
import { validateMobileNumber, validatePassword } from '../utils/Validation'; // Adjust the path as per your file structure
const UnlockPassword = ({ isOpen, onClose }) => {
    const { translations } = useContext(TranslationContext);
    const config = useConfig();
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpLoading, setOtpLoading] = useState(false);
    const [passwordFieldsVisible, setPasswordFieldsVisible] = useState(false);
    const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [userid, setUserid] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resendTimeout, setResendTimeout] = useState(0);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    useEffect(() => {
        if (resendTimeout > 0 && otpSent) {
            const timer = setTimeout(() => {
                setResendTimeout(resendTimeout - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [resendTimeout, otpSent]);

    const handleInputChange = (e) => {
        setUsername(e.target.value);
    };
    const generateSalt = (length) => {
        const characters =
            "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleSendOTP = async () => {
        setOtpLoading(true);

        try {
            const response = await axios.get(
                `${config.REACT_APP_API_URL}/GenerateOTP?username=${username}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    }
                }
            );
            setUserid(response.data.userid)
            console.log(response);
            toast.success(translations["OTP_sent_successfully"] || "OTP sent successfully!");

            setOtpSent(true);
            setResendTimeout(30); // Set the initial countdown time to 30 seconds

        } catch (error) {
            console.error("Error fetching OTP:", error);
            toast.error(translations["Failed_to_fetch_OTP"] || "Failed to fetch OTP. Please try again.");

        } finally {
            setOtpLoading(false);
        }
    };



    const handleOtpInputChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmitUpdatePassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(
                `${config.REACT_APP_API_URL}/VerifyOTP?username=${username}&otp=${otp}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    }
                }
            );

            console.log(response.data); // Log the response for verification

            toast.success(translations["OTP_verified_successfully"] || "OTP verified successfully!");
            setPasswordFieldsVisible(true);

        } catch (error) {
            console.error("Error verifying OTP:", error);
            toast.error(translations["Failed_to_verify_OTP"] || "Failed to verify OTP. Please try again.");
        }
    };


    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setPasswordUpdateLoading(true);

        try {


            const headers = {
                "Content-Type": "application/json",
                APIKey: config.REACT_APP_API_KEY,
            };
            const response = await axios.post(
                `${config.REACT_APP_API_URL}/UnblockPassword?userid=${userid}`,
                {
                    userid: userid,
                },
                {
                    headers
                }
            );

            console.log(response.data); // Log the response for verification

            toast.success("Password Unlock successfully!");
            onClose(); // Close modal after successful password update
            resetForm();
        } catch (error) {
            console.error("Error Unlock password:", error.message);
            toast.error(error.message);
        } finally {
            setPasswordUpdateLoading(false);
        }
    };
    const resetForm = () => {
        setUsername('');
        setOtp('');
        setOtpSent(false);
        setMobileError('');
        setPassword('');
        setConfirmPassword('');
        setPasswordFieldsVisible(false);
        setPasswordsMatch(true);
        setUserid('');
        setShowPassword(false);
        setShowConfirmPassword(false);
        setResendTimeout(0);
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    padding: "20px",
                    maxWidth: 400,
                    width: '60%',
                    maxHeight: 600,
                    overflowY: 'auto',
                    outline: 'none',
                    borderRadius: "12px",
                    '@media (max-width: 600px)': {
                        width: '90%',  // Adjust width for mobile screens
                        maxWidth: 'none', // Optional: remove maxWidth constraint on mobile
                    },
                }}
            >
                <Typography variant="h5" component="h2" id="modal-title" sx={{ mb: 2 }}>
                    {translations["Unlock_Password"] || ["Unlock Password"]}
                </Typography>
                <Button
                    onClick={onClose}
                    color="inherit"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </Button>
                <form onSubmit={!passwordFieldsVisible ? handleSubmitUpdatePassword : handleUpdatePassword}>

                    <Box sx={{ mt: 2 }}>
                        <TextField
                            id="username"
                            name="username"
                            label={translations["Enter your User Name/Emailid/Mobile No." || ["Enter your User Name/Emailid/Mobile No."]]}
                            variant="outlined"
                            fullWidth
                            value={username}
                            onChange={handleInputChange}
                            error={mobileError !== ''}
                            helperText={mobileError}
                            required
                            InputProps={{
                                style: { fontSize: "15px" }  // Adjust the input font size here
                            }}
                            InputLabelProps={{
                                style: { fontSize: "13px" }  // Adjust the label font size here
                            }}
                            title="Please enter your Username"
                        />
                        <p className="text-red-500  mt-1 ml-2 " style={{ fontSize: "12px" }}>{translations["Mobile No. Shoud be contains country"] || ["Mobile number should contain country code (Ex: 91-7995xxxx)"]}</p>
                    </Box>


                    {!otpSent ? (
                        <Box sx={{ mt: 2, textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={handleSendOTP} disabled={otpLoading}>
                                {otpLoading ? "Sending OTP..." : translations["Send_OTP"] || "Send OTP"}
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button variant="contained" color="primary" onClick={handleSendOTP} disabled={otpLoading || resendTimeout > 0}>
                                    {otpLoading ? "Sending OTP..." : (resendTimeout > 0 ? `Re-Send OTP (${resendTimeout})` : translations["ReSend_OTP"] || "ReSend OTP")}
                                </Button>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    id="otp"
                                    name="otp"
                                    label={translations["EnterOTP"] || ["Enter OTP"]}
                                    variant="outlined"
                                    fullWidth
                                    value={otp}
                                    onChange={handleOtpInputChange}
                                    required
                                    InputProps={{
                                        style: { fontSize: "15px" }  // Adjust the input font size here
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: "13px" }  // Adjust the label font size here
                                    }}
                                    title="Please enter your Username"
                                />
                            </Box>
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color="primary">
                                    Verify OTP
                                </Button>
                            </Box>
                        </>
                    )}
                    {passwordFieldsVisible && (
                        <>


                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color="primary" disabled={passwordUpdateLoading}>
                                    {passwordUpdateLoading ? "Unlocking Password..." : translations["Unlock_Password"] || "Unlock Password"}
                                </Button>
                            </Box>
                        </>
                    )}
                </form>
            </Box>
        </Modal>
    );
};

export default UnlockPassword;
