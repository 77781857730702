import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { PolarArea } from "react-chartjs-2";
import { FaFileExcel } from "react-icons/fa";
import { IoMdPrint } from "react-icons/io";
import { LuShoppingCart } from "react-icons/lu";
import { Chart, registerables } from 'chart.js';
import Breadcrumb from "../commonComponent/Breadcrumbs";
import axios from "axios";
import { useUser } from "../../UserContext";
import { useConfig } from "../../ConfigContext";
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // This generates a random UUID (v4)
import printData from '../../componentHome/commonComponent/Print'
import { TranslationContext } from "../../TranslationContext";

// Register the required components
Chart.register(...registerables);

function TestAnalytics() {
    const { translations } = useContext(TranslationContext);

    const config = useConfig();
    const { selectedUserType } = useUser();
    const [categoryData, setCategoryData] = useState([]);
    const [testData, setTestData] = useState([]);
    const [testData1, setTestData1] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [skillData1, setSkillData1] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState("category");
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const useId = user?.userdetails.agencyid;
    const [categoryId, setCategoryId] = useState(null);
    const [testId, setTestId] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const groupByMap = {
                    category: 1,
                    test: 2,
                    skill: 3,
                };

                // Start building the base URL
                let url = `${config?.REACT_APP_API_URL}/Selt_Test_Analytics?usertype=${selectedUserType}&userid=${useId}&GroupBy=${groupByMap[selectedOption]}`;

                // Conditionally add categoryId and testId to the URL if they exist
                if (categoryId) {
                    url += `&categoryid=${categoryId}`;
                }
                if (testId) {
                    url += `&testid=${testId}`;
                }

                // Log the constructed URL for debugging
                console.log("Fetching data from:", url);

                const response = await axios.get(url, {
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                    },
                });

                // Handle the response based on selectedOption
                switch (selectedOption) {
                    case "category":
                        setCategoryData(response.data);
                        break;
                    case "test":
                        setTestData(response.data);
                        break;
                    case "skill":
                        setSkillData(response.data);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                setError(error);
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedOption, categoryId, testId]); // Also add categoryId and testId as dependencies

    const handleCategoryOptionChange = () => {
        setSelectedOption("category");
        setCategoryId(null); // Clear categoryId
        setTestId(null); // Clear testId
    };
    const handleTestOptionChange = () => {
        setSelectedOption("test");
        setCategoryId(null); // Clear testId when switching to test view
        setTestId(null)
    };
    const handleCategoryClick = (id) => {
        setCategoryId(id); // Set the category ID
        setSelectedOption("test"); // Switch to test view
        console.log("categoryId", id);
    };

    const handleTestClick = (id) => {
        setTestId(id); // Set the test ID
        setSelectedOption("skill"); // Switch to skill view
        console.log("testId", id);
    };
    const renderTable = () => {
        let dataToRender = [];
        switch (selectedOption) {
            case "skill":
                dataToRender = skillData;
                break;
            case "test":
                dataToRender = testData;
                break;
            case "category":
                dataToRender = categoryData;
                break;
            default:
                break;
        }

        // Ensure dataToRender is an array
        if (!Array.isArray(dataToRender)) {
            console.error("Expected dataToRender to be an array, but got:", dataToRender);
            dataToRender = []; // Fallback to an empty array to avoid errors
        }

        // Don't show the table if there are no tests available in the "test" view
        if (selectedOption === "test" && dataToRender.length === 0) {
            return <p>No tests available for this category.</p>;
        }

        const headers = selectedOption === "skill" ? ["Skill", "Competency Level"] :
            (selectedOption === "test" ? ["Test Name", "Competency Level"] : ["Category", "Competency Level"]);

        return (
            <TableContainer component={Paper}>
                <Table striped>
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableCell key={header} style={{ backgroundColor: "rgb(66, 139, 202)", color: "white", fontSize: "1.5rem" }}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToRender.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={selectedOption === "category" ? () => handleCategoryClick(item.categoryid) : selectedOption === "test" ? () => handleTestClick(item.testid) : null}
                            >
                                {selectedOption === "test" ? (
                                    <>
                                        <TableCell style={{ fontSize: "1.4rem", textDecoration: "underline", cursor: "pointer" }}>{item.name}</TableCell>
                                        <TableCell style={{ fontSize: "1.4rem" }}>{item.percentage}</TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "1.4rem",
                                                textDecoration: selectedOption === "skill" ? "none" : "underline"
                                            }}
                                        >
                                            {item.name}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1.4rem" }}>{item.percentage}</TableCell>

                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const handlePrint = () => {
        const dataToRender = selectedOption === "skill" ? skillData : (selectedOption === "test" ? testData : categoryData);
        const headers = selectedOption === "skill" ? ["Skill", "Competency Level"] : (selectedOption === "test" ? ["Test", "Competency Level"] : ["Category", "Competency Level"]);

        const headerHTML = headers.map(header => `<th style="border: 1px solid #000; padding: 5px;">${header}</th>`).join('');
        const bodyHTML = dataToRender.map(item => `
      <tr>
          <td style="border: 1px solid #000; padding: 5px;">${item.name}</td>
          <td style="border: 1px solid #000; padding: 5px;">${item.percentage}</td>
      </tr>
    `).join('');

        const formattedContent = `<!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8" />
            <title>Print Document</title>
            <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                th { background-color: rgb(66, 139, 202); color: white; }
            </style>
        </head>
        <body>
            <table>
                <thead>
                    <tr>${headerHTML}</tr>
                </thead>
                <tbody>${bodyHTML}</tbody>
            </table>
        </body>
    </html>`;

        // Call the common print function
        printData(formattedContent, config);
    };
    useEffect(() => {
        const fetchData1 = async () => {
            setIsLoading(true);
            try {


                const response = await axios.get(`${config?.REACT_APP_API_URL}/Self_test_Report_Data?usertype=${selectedUserType}&userid=${useId}`, {
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                    },
                });

                setSkillData1(response.data);
                console.log("setskills", response.data);
            } catch (error) {
                setError(error);
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData1();
    }, []); // Re-fetch data when these dependencies change
    const generateColors = (numColors) => {
        const colors = [];
        for (let i = 0; i < numColors; i++) {
            // Create a unique color based on a formula
            const hue = (i * 360) / numColors; // Spread colors around the color wheel
            colors.push(`hsl(${hue}, 100%, 50%)`); // HSL color format
        }
        return colors;
    };

    const hslToRgba = (h, s, l, alpha) => {
        const c = (1 - Math.abs(2 * l - 1)) * (s / 100);
        const x = c * (1 - Math.abs((h / 60) % 2 - 1));
        const m = l / 100 - c / 2;
        let r = 0, g = 0, b = 0;


        if (0 <= h && h < 60) {
            r = c; g = x;
        } else if (60 <= h && h < 120) {
            r = x; g = c;
        } else if (120 <= h && h < 180) {
            g = c; b = x;
        } else if (180 <= h && h < 240) {
            g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; b = c;
        } else {
            r = c; b = x;
        }

        return `rgba(${Math.round((r + m) * 255)}, ${Math.round((g + m) * 255)}, ${Math.round((b + m) * 255)}, ${alpha})`;
    };

    const dataToUse = selectedOption === "skill" ? skillData : (selectedOption === "test" ? testData : categoryData);
    const numItems = dataToUse.length;

    const generateDistinctColors = (numItems) => {
        // Define a set of 40 distinct colors in HSL format
        const colorPalette = [
            'hsl(0, 100%, 50%)', 'hsl(30, 100%, 50%)', 'hsl(60, 100%, 50%)', 'hsl(90, 100%, 50%)',
            'hsl(120, 100%, 50%)', 'hsl(150, 100%, 50%)', 'hsl(180, 100%, 50%)', 'hsl(210, 100%, 50%)',
            'hsl(240, 100%, 50%)', 'hsl(270, 100%, 50%)', 'hsl(300, 100%, 50%)', 'hsl(330, 100%, 50%)',
            'hsl(15, 100%, 50%)', 'hsl(45, 100%, 50%)', 'hsl(75, 100%, 50%)', 'hsl(105, 100%, 50%)',
            'hsl(135, 100%, 50%)', 'hsl(165, 100%, 50%)', 'hsl(195, 100%, 50%)', 'hsl(225, 100%, 50%)',
            'hsl(255, 100%, 50%)', 'hsl(285, 100%, 50%)', 'hsl(315, 100%, 50%)', 'hsl(345, 100%, 50%)',
            'hsl(5, 100%, 50%)', 'hsl(35, 100%, 50%)', 'hsl(65, 100%, 50%)', 'hsl(95, 100%, 50%)',
            'hsl(125, 100%, 50%)', 'hsl(155, 100%, 50%)', 'hsl(185, 100%, 50%)', 'hsl(215, 100%, 50%)',
            'hsl(245, 100%, 50%)', 'hsl(275, 100%, 50%)', 'hsl(305, 100%, 50%)', 'hsl(335, 100%, 50%)',
            'hsl(10, 100%, 50%)', 'hsl(40, 100%, 50%)', 'hsl(70, 100%, 50%)', 'hsl(100, 100%, 50%)'
        ];

        // Repeat the colors if numItems exceeds the palette size
        const colors = [];
        for (let i = 0; i < numItems; i++) {
            colors.push(colorPalette[i % colorPalette.length]);
        }
        return colors;
    };

    const chartData = {
        labels: dataToUse?.map(item => item.name),
        datasets: [
            {
                label: selectedOption === "skill" ? "Skill Competency" : (selectedOption === "test" ? "Test Competency" : "Category Competency"),
                data: dataToUse.map(item => item.percentage),
                backgroundColor: generateDistinctColors(dataToUse.length).map(color => {
                    const hslMatch = color.match(/hsl\((\d+), (\d+)%?, (\d+)%?\)/);
                    return hslMatch ? hslToRgba(parseInt(hslMatch[1]), parseInt(hslMatch[2]), parseInt(hslMatch[3]), 0.2) : 'rgba(0, 0, 0, 0.2)';
                }), // Generate background colors
                borderColor: generateDistinctColors(dataToUse.length).map(color => {
                    const hslMatch = color.match(/hsl\((\d+), (\d+)%?, (\d+)%?\)/);
                    return hslMatch ? hslToRgba(parseInt(hslMatch[1]), parseInt(hslMatch[2]), parseInt(hslMatch[3]), 1) : 'rgba(0, 0, 0, 1)';
                }), // Generate border colors
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'start',
            },
            tooltip: {
                enabled: true,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    // Chart and download logic would go here...
    const handleDownloadExcel = () => {
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Prepare the headers for the Excel sheet
        const wsData = [
            ["Sl no.", "Category", "Test", "Question", "Skill Tags", "Max Marks", "Actual Marks", "Your Answer", "Correct Answer"]
        ];

        // Populate the rows with data from skillData1
        skillData1.forEach((item, index) => {
            wsData.push([
                index + 1,
                item.trainingCategoryName,
                item.testDescription,
                item.question,
                item.skilltag,
                item.maxMarks,
                item.actualMarks,
                item.youranswer,
                item.correctAnswerDescription || '0'
            ]);
        });

        // Convert the array of data into a worksheet
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Set the column widths
        ws['!cols'] = [
            { wpx: 50 }, // Sl no.
            { wpx: 150 }, // Category
            { wpx: 150 }, // Test
            { wpx: 250 }, // Question
            { wpx: 150 }, // Skill Tags
            { wpx: 100 }, // Max Marks
            { wpx: 100 }, // Actual Marks
            { wpx: 100 }, // Your Answer
            { wpx: 150 }  // Correct Answer
        ];

        // Set the row height (optional, apply to specific rows if needed)
        ws['!rows'] = Array(wsData.length).fill({ hpx: 20 }); // Set height to 30px for all rows

        // Hide gridlines in the Excel file (requires using the worksheet view)
        ws['!hidegridlines'] = true;

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Competency Report");

        // Write the workbook to a file
        XLSX.writeFile(wb, "Competency_Report.xlsx");
    };

    if (isLoading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </div>);
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>

            <Breadcrumb paths={[{ title: translations['Dashboard'] || 'Dashboard', url: '/dashboard' }, { title: translations['Self_assessment'] || 'Self Test', url: '' }]} />
            <Row style={{ border: "2px solid #ddd2d2", width: "99%", margin: "auto", marginTop: "0px", borderRadius: "5px" }}>
                <Col md={12} style={{ height: "65px", backgroundColor: "#e4e8ec", display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check type="radio" name="option" value="category" checked={selectedOption === "category"} onChange={handleCategoryOptionChange} style={{ marginRight: "8px" }} />
                        <span> {translations["Categorywise"] || "Category Wise"}</span>

                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="option"
                            value="test"
                            checked={selectedOption === "test"}
                            onChange={handleTestOptionChange}
                            style={{ marginRight: "8px" }}
                        />
                        <span> {translations["Testwise"] || "Test Wise"}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                        <Form.Check
                            type="radio"
                            name="option"
                            value="skill"
                            checked={selectedOption === "skill"}
                            onChange={() => setSelectedOption("skill")}
                            style={{ marginRight: "8px" }}
                        />
                        <span>{translations["Skillwise"] || "Skill Wise"}</span>
                    </div>

                </Col>
                <Col md={6} style={{ padding: "15px", marginBottom:"20px" }}>
                    <Col className="d-flex align-items-center" style={{ padding: "13px", justifyContent: "space-between", display: "flex" }}>
                        <p className="mb-0">{selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Test Analytics</p>
                        <div style={{ display: "flex" }}>
                            <FaFileExcel className="ms-2" style={{ fontSize: "1.3rem", cursor: "pointer" }} onClick={handleDownloadExcel} />
                            <IoMdPrint className="ms-2" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={handlePrint} />
                        </div>
                    </Col>
                    {renderTable()}
                </Col>
                <Col md={6} className="d-flex align-items-center graph-main" style={{ justifyContent: "center", height: "100%" }}>
                    <div className="chart-container">
                        <PolarArea data={chartData} options={options} />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default TestAnalytics;