import React, { useContext, useEffect, useRef, useState } from "react";
import { useConfig } from "../../../ConfigContext";
import axios from "axios";
import { useFeedback } from "../../../Context/FeedbackContext";
import { useTheme } from "../../../ThemeProvider";
import { IoFolder, IoRocketSharp, IoTrash } from "react-icons/io5";
import { FaEdit, FaHeadphones, FaRegPlayCircle, } from "react-icons/fa";
import { RiProgress1Line, RiWhatsappFill } from "react-icons/ri";
import { MdScreenShare, MdFeedback } from "react-icons/md";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useUser } from "../../../UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaCirclePlay, FaLock, FaLockOpen, FaRegCircleCheck, FaUnlock } from "react-icons/fa6";
import { TranslationContext } from "../../../TranslationContext";
import { v4 as uuidv4 } from "uuid";
import { Windowopen } from "../../../componentHome/utils/Validation";
import { HiLockClosed, HiLockOpen } from "react-icons/hi";

const HTMLParser = ({ iconname }) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return <span dangerouslySetInnerHTML={createMarkup(iconname)} />;
};

const CircularProgressBar = ({ session }) => {
  const { theme } = useTheme();

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "transparent", // Transparent background
        borderRadius: "50%", // Circular shape
        width: 40, // Adjusted size
        height: 40, // Adjusted size
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px", // Removed padding
      }}
    >
      {/* Empty Bar */}
      <CircularProgress
        variant="determinate"
        value={100} // Always full for the empty bar
        size={40} // Match the container size
        thickness={4}
        style={{
          position: "absolute",
          color:
            theme?.palette?.primary?.main === "#d32f2f" ? "#ffb3b3" : "#b3d7ff", // Light blue for the empty bar
        }}
      />
      {/* Filled Bar */}
      <CircularProgress
        variant="determinate"
        value={session} // Progress percentage
        size={40} // Match the container size
        thickness={4}
        style={{
          position: "absolute",
          color: theme?.palette?.primary?.main,
        }}
      />
      {/* Percentage Text */}
      <Typography
        variant="body2"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          // color: '#007bff' // Same color as filled bar
          color: theme?.palette?.primary?.main, // Same color as filled bar
        }}
      >
        {session === 0
          ? `${session}%`
          : Number.isInteger(session)
          ? `${session}%`
          : `${session.toFixed(1)}%`}
      </Typography>
    </div>
  );
};

const NestedList = () => {
  const config = useConfig();
  const { id } = useParams();
  const { theme } = useTheme();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(null);
  const [skillTags, setSkillTags] = useState([]);
  const { selectedUserType } = useUser();
  const {
    sessions,
    setsessions,
    uncheckedItems,
    excludedFacultyIds,
    noFacultySessionIds,
    allWithoutFacultySelected,
    trainingDetails,
    startDate,
    endDate,
    sessionid,
    setsessionid,
    initialAllowedStartDate,
    initialAllowedEndDate,
    selftestData,
    setselftestData,
  } = useFeedback();
  const { translations } = useContext(TranslationContext);
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const userid = user.userdetails.agencyid;

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getEvalIds = async () => {
    const headers = {
      Accept: "application/json, text/plain",
      "API-Key": config?.REACT_APP_EVAL_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${config?.REACT_APP_EVAL_API_PATH}/Tests/UserTests`,
        {
          headers,
          params: {
            usertype: selectedUserType,
            userid: user?.userdetails?.agencyid,
            trainingid: id,
          },
        }
      );
      return data;
    } catch (error) {
      console.log("Error fetching sessions", error);
    }
  };

  const navigattestresult = async () => {
    const data = await getEvalIds();
    navigate(`/test-result`, {
      state: {
        testparticipantid: data[0]?.participantstatus,
        testquestionid: data[0]?.testQuestionID,
      },
    });
  };

  const openLitteraRoom = async (session) => {
    if (selectedUserType == 5) {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config?.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/CHECK_SESSION_RESTRICTION`,
        {
          headers: headers,
          params: {
            userid: user?.userdetails?.agencyid,
            usertype: selectedUserType,
            sessionid: session?.ttttt_session_id,
            trainingid: trainingDetails?.trainingId,
          },
        }
      );
      console.log("restriction ", data);
      if (data === false) {
        navigate(`/Litteraroom/session/${trainingDetails?.trainingId}`);
        if(selectedUserType == 5 ){
          localStorage.setItem("restrictedsession", JSON.stringify(session));  
          setsessionid(session) 
        } else{
          setsessionid(null) 
          localStorage.setItem("restrictedsession", JSON.stringify(null));  
        }
      } else {
        showModal(
          translations["current_session_before"] ||
            "You can not start current session before completing the previous sessions."
        );
      }
    } else {
      navigate(`/Litteraroom/session/${trainingDetails?.trainingId}`);
      if(selectedUserType != 5 ){
        setsessionid(null);
        localStorage.setItem("restrictedsession", JSON.stringify(null));  
      }
    }
  };

  const navigatSelftest = async (sessionid, tag) => {
    console.log("testtag", tag);
    console.log("navigationdata", sessionid, tag);

    const fetchSkillTags = async () => {
      try {
        // Construct the API URL with the keyword, if present
        let apiUrl = `${config.REACT_APP_EVAL_API_PATH}/Tests/Category_Question_Tags?trainingcategoryid=${trainingDetails?.trainingCategoryId}`;

        // Fetch the skill tags from the API
        const skillTagsRes = await axios.get(apiUrl, {
          headers: { "API-Key": config?.REACT_APP_API_KEY },
        });

        // Filter the tags based on the keyword if provided

        console.log("filteredTags", skillTagsRes.data);

        // If filteredTags is empty, do not proceed with navigation
        if (skillTagsRes.data.length === 0) {
          console.log("No matching tags found, navigation will not happen.");
          return; // Prevent navigation if no matching tags
        }

        // Create an array of tags from filteredTags
        const tagsArray = skillTagsRes.data.map((item) => item.tags);
        console.log("tagsArray", tagsArray);
        const filteredTags = tagsArray.filter(
          (item) => item.toLowerCase() === tag.toLowerCase()
        );

        console.log("filteredTags", filteredTags);
        // Now navigate, including the tagsArray in the state
        navigate("/selftest-page", {
          state: {
            keyword: tag,
            trainingid: trainingDetails?.trainingId,
            sessionid: sessionid,
            trainingcategoryid: trainingDetails?.trainingCategoryId,
            tagsArray: tagsArray, // Pass tagsArray to the selftest page
            filteredTags: filteredTags,
          },
        });
      } catch (error) {
        console.error("Error fetching skill tags:", error);
        setSkillTags([]); // Reset the tags in case of an error
      }
    };

    // Call the fetchSkillTags function and wait for it to complete
    await fetchSkillTags(); // Use await to wait for fetchSkillTags to finish
  };

  const navigatJoinMeeting = (session) => {
    const url = session?.meeting[0]?.ttlm_z_join_link;
    console.log("join url", url);
    Windowopen(url);
  };

  const navigatStartMeeting = (session) => {
    const url = session?.meeting[0]?.ttlm_z_start_link;
    console.log("start url", url);
    Windowopen(url);
  };

  const getAssignmentId = async (sessionid) => {
      const headers = {
          Accept: "application/json, text/plain",
          APIKey: config.REACT_APP_API_KEY,
          "Content-Type": "application/json",
      };

      const payload = {
          searchCriteria: [
              {
                  column: 'ttttt_session_id',
                  value: sessionid,
                  condition: '=',
                  nextoperator: 'AND',
              },
          ],
      };

      const url = `${config?.REACT_APP_API_URL}/Assignment?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}`;

      try {
        const { data } = await axios.post(url, payload, {
            headers: headers,
        });
        return data
      } catch (error) {
          console.error(error); 
      }
  };

  const navigatAssignmentDetails = async(sessionid) => {
    const assignment = await getAssignmentId(sessionid)
    const assignmentId = assignment?.items[0]?.assignmentID
    if (selectedUserType == 5) {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config?.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/CHECK_SESSION_RESTRICTION`,
        {
          headers: headers,
          params: {
            userid: user?.userdetails?.agencyid,
            usertype: selectedUserType,
            sessionid: sessionid,
            trainingid: trainingDetails?.trainingId,
          },
        }
      );
      if (data === false) {
        navigate(`/assignment-detail/${assignmentId}`)
      } else {
        showModal(
          translations["current_session_before"] ||
            "You can not start current session before completing the previous sessions."
        );
      }
    } else {
      navigate(`/assignment-detail/${assignmentId}`)
    }
  };

  const getSessions = async () => {
    if (id) {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config?.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      try {
        const { data } = await axios.get(
          `${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`,
          {
            headers,
            params: {
              usertype: selectedUserType,
              userid: user?.userdetails?.agencyid,
            },
          }
        );
        return data;
      } catch (error) {
        console.log("Error fetching sessions", error);
      }
    } else {
      console.log("No id provided");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getSessions();
        setsessions(data);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  function formatDate(datetimeString) {
    // Extract the date part (first 10 characters)
    let dateString = datetimeString.substring(0, 10);

    // Split the date into year, month, and day
    let parts = dateString.split("-");
    let year = parts[0]; // Full year
    let month = parts[1];
    let day = parts[2];

    // Form the desired date format "dd-mm-yyyy"
    let formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const getDisplayString = (session) => {
    const displayParts = [];
    const displayOrderKeys = session?.displayOrder?.split(",") || [];
    const displayInfos = session?.displayInfos || [];

    const displayInfoMap = {};
    displayInfos.forEach((info) => {
      displayInfoMap[info.key] = info;
    });

    displayOrderKeys.forEach((key) => {
      const displayInfo = displayInfoMap[key];

      if (displayInfo?.value) {
        switch (key) {
          case "1":
            displayParts.push(
              `${translations["Module"] || "Module"} - ${session?.module || ""}`
            );
            break;
          case "2":
            displayParts.push(
              `${translations["Week"] || "Week"} - ${
                session?.ttttt_session_week || ""
              }`
            );
            break;
          case "3":
            displayParts.push(
              `📅 ${formatDate(session.ttttt_session_dt) || ""} 🕒 ${
                session.ttttt_session_time || ""
              }`
            );
            break;
          case "4":
            displayParts.push(
              `${translations["Days"] || "Days"} - ${
                session?.ttttt_session_day || ""
              }`
            );
            break;
          case "5":
            displayParts.push(
              `📝 ${translations["Srno"] || "SR"}  #${
                session?.ttttt_session_no || ""
              }`
            );
            break;
          default:
            break;
        }
      }
    });

    return displayParts.join(" ").trim();
  };

  const normalizeDate = (date) => new Date(date?.setHours(0, 0, 0, 0));

  const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

  const handleTooltipToggle = (index) => {
    if (isMobile) {
      setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
    }
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpenTooltipIndex(null); // Close the tooltip
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const disabletooltip = () => {
    if (openTooltipIndex != null) {
      setOpenTooltipIndex(null);
    }
  };

  const convertToHr = (duration) => {
    const time = duration / 60;
    return time;
  };

  const getCurrentDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalMessage("");
  };

  const showModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleStartTest = async (test, tag) => {
    console.log("Test data:", test);

    try {
      // Step 1: Check session restriction
      const checkSessionResponse = await axios.get(
        `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
        {
          params: {
            usertype: selectedUserType,
            userid: userid,
            sessionid: test?.ttttt_session_id,
            trainingid: test?.trainingid,
          },
          headers: {
            APIKey: config?.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (checkSessionResponse.data === true) {
        showModal(
          translations["current_session_before"] ||
            "You can not start current session before completing the previous sessions."
        );
        //showModal("You can not start current session before completing the previous sessions.");
        return;
      }

      // Step 2: Check test eligibility
      const evaltestdata = await getEvalIds();
      const evaltest = evaltestdata?.find(
        (item) => item.training_SessionID === test?.ttttt_session_id
      );
      console.log("evaltest", evaltest);

      // Step 3: Check test eligibility
      const eligibilityResponse = await axios.get(
        `${config?.REACT_APP_API_URL}/Check_Test_Eligibility`,
        {
          params: {
            userid: userid,
            trainingid: test?.trainingid,
            testid: evaltest?.testID,
          },
          headers: {
            APIKey: config?.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (eligibilityResponse.data !== 1) {
        showModal(
          translations["Please_contactto_admin"] ||
            "You are not eligible to do this test,Please contact to admin."
        );
        //showModal("You are not eligible to do this test,Please contact to admin.");
        return;
      }

      // Step 4: Check participant test status
      const statusResponse = await axios.get(
        `${config?.REACT_APP_EVAL_API_PATH}/tests/ParticipantTestStatus`,
        {
          params: {
            testid: evaltest?.testID,
            participantid: userid,
          },
          headers: {
            "API-Key": config?.REACT_APP_EVAL_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      // Define payloads for each API call
      const updateStatusPayload = {
        tptss_id: uuidv4(),
        tptss_testid: evaltest?.testID,
        tptss_participantid: userid,
        tptss_createdon: getCurrentDate(),
        tptss_createdby: userid,
      };

      if (statusResponse.data === false) {
        // Step 5: Update participant test status
        await axios.post(
          `${config.REACT_APP_EVAL_API_PATH}/Tests/Update_Participant_Test_Status`,
          updateStatusPayload,
          {
            headers: {
              "API-Key": config?.REACT_APP_EVAL_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        const payload1 = {
          searchCriteria: [
            {
              column: "testid",
              value: evaltest?.testID,
              condition: "=",
              nextoperator: "AND",
            },
          ],
        };
        // Step 6: Update participant test status
        const usertest = await axios.post(
          `${config.REACT_APP_API_URL}/get_user_tests?usertype=${selectedUserType}&userid=${userid}&testtype=1&PageNumber=1&PageSize=10`,
          payload1,
          {
            headers: {
              "Content-Type": "application/json",
              APIKey: config.REACT_APP_API_KEY,
            },
          }
        );

        const payload = {
          skillSet: evaltest?.skillTag,
          testID: evaltest?.testID,
          questionCount: evaltest?.noOfQuestion,
          questionDifficutyID: evaltest?.questionDifficultyID,
          trainingCategory: test?.trainingCategoryID,
        };
        console.log("beforesend", usertest);
        // Step 7: Run the test
        const runTestResponse = await axios.post(
          `${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`,
          payload,
          {
            headers: {
              "API-Key": config?.REACT_APP_EVAL_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        if (runTestResponse.status === 200) {
          const apiData = runTestResponse.data;
          // Navigate to the online test page
          navigate("/online-test-page", {
            state: { test: usertest?.data?.items[0], apiData },
          });
          console.log("apiData", apiData);
        } else {
          console.error("Error running test:", runTestResponse.statusText);
        }
      } else {
        showModal(
          translations["already_beensubmitted"] ||
            "This test has already been submitted by you."
        );
        //showModal("This test has already been submitted by you.");
        // juyjuyjyuj
      }
    } catch (error) {
      console.error("Error during API calls:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="min-h-[35vh]">
      <List className="flex flex-col gap-4" component="nav">
        {sessions
          ?.filter((session) => {
            const isUncheckedItem = uncheckedItems?.some(
              (item) => item?.id == session?.ttttt_type
            );
            const hasExcludedFaculty = session?.sessionFaculties?.some(
              (faculty) => excludedFacultyIds?.includes(faculty?.facultyid)
            );

            // Check if startDate and endDate are null
            if (startDate == null && endDate == null) {
              return false; // Hide all sessions
            }

            const sessionStartDate = new Date(session?.ttttt_session_dt);
            const sessionEndDate = new Date(session?.ttttt_session_end_time);

            // Normalize dates for comparison
            const normalizedStartDate = normalizeDate(startDate).getTime();
            const normalizedEndDate = normalizeDate(endDate).getTime();
            const normalizedInitialStartDate = normalizeDate(
              initialAllowedStartDate
            ).getTime();
            const normalizedInitialEndDate = normalizeDate(
              initialAllowedEndDate
            ).getTime();

            // Check if both startDate and endDate are the same as the allowed dates
            const isExactAllowedDateRange =
              normalizedStartDate === normalizedInitialStartDate &&
              normalizedEndDate === normalizedInitialEndDate;

            // Check if session is within the date range unless it's the exact allowed range
            const isInDateRange =
              isExactAllowedDateRange ||
              (sessionStartDate > startDate && sessionEndDate <= endDate) ||
              (startDate?.getTime() === endDate?.getTime() &&
                sessionStartDate >= startDate &&
                sessionEndDate >= startDate);
            return (
              !isUncheckedItem &&
              !hasExcludedFaculty &&
              (allWithoutFacultySelected ||
                session?.sessionFaculties?.length > 0) &&
              isInDateRange
            );
          })
          .filter(
            (ses, index, self) =>
              self.findIndex(
                (s) => s.ttttt_session_id === ses.ttttt_session_id
              ) === index
          )
          .map((session, index) => (
            <div key={index}>
              <ListItem
                className={`${session.is_Session_Restricted ? 'bg-[#e5e5e5]' : 'bg-[#d8e4f0]'} h-fit rounded-lg`}
                button
                onClick={() => handleClick(index)}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ width: "100%" }}
                >
                  <ListItemText
                    onClick={disabletooltip}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: isMobile ? "1.3rem" : "1.4rem",
                        lineHeight: isMobile ? "2" : null,
                      },
                    }}
                    secondaryTypographyProps={{ sx: { fontSize: "1.2rem" } }}
                    // primary={`${getDisplayString(session)} ⏱️ ${session.ttttt_session_duration} ${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`}
                    primary={`${getDisplayString(session)} ${
                      session.ttttt_session_duration == 0
                        ? ""
                        : `⏱️ ${
                            session.ttttt_session_duration_type == 2
                              ? convertToHr(session.ttttt_session_duration)
                              : session.ttttt_session_duration
                          } ${session.ttttt_session_duration_type_name}`
                    }`}
                    secondary={
                      <span
                        title={
                          !isMobile
                            ? `${session.ttttt_content_desc} - ${session.ttttt_subject}`
                            : null
                        }
                        className="flex capitalize items-center"
                      >
                        <Tooltip
                          title={
                            isMobile
                              ? `${session.ttttt_content_desc} - ${session.ttttt_subject}`
                              : null
                          }
                          open={
                            isMobile ? openTooltipIndex === index : undefined
                          }
                          disableInteractive
                        >
                          <span
                            onClick={() => handleTooltipToggle(index)}
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "normal",
                              cursor: "pointer",
                            }}
                          >
                            {(() => {
                              const combinedText = `${session.ttttt_content_desc} - ${session.ttttt_subject}`;
                              const maxWords = 25;
                              const words = combinedText.split(" ");

                              const truncatedHtml =
                                words.length > maxWords
                                  ? words.slice(0, maxWords).join(" ") + "..."
                                  : combinedText;

                              return (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncatedHtml,
                                  }}
                                />
                              );
                            })()}
                          </span>
                        </Tooltip>
                        <IconButton edge="end" aria-label="expand">
                          {openTooltipIndex === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </IconButton>
                      </span>
                    }
                  />
                  {isMobile && (
                    <Typography
                      onClick={disabletooltip}
                      variant="body2"
                      color="textSecondary"
                      className="flex-wrap"
                      sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                    >
                      {session.is_Session_Restricted ? <FaLock title="Locked" className="text-red-500 text-lg mb-1"/> : <FaUnlock title="Un-Locked" className="text-green-500 text-lg mb-1"/> }
                      {session?.actionInfos
                        ?.filter(
                          (action) =>
                            action.value &&
                            ![
                              "EDIT_SESSION",
                              "DELETE_SESSION",
                              "CONTENT_LIBRARY",
                              "Complete_Session",
                            ].includes(action.name)
                        ) // Filter for actions where value is true and not in the exclusion list
                        .map((action) => (
                          <IconButton
                            key={action.key}
                            className="flex uppercase items-start text-[1.1rem] font-semibold gap-2"
                            edge="end"
                            aria-label={action.name
                              .toLowerCase()
                              .replace("_", " ")}
                            onClick={
                              action.name === "LITTERA_ROOM"
                                ? () => openLitteraRoom(session)
                                : action.name === "View_TEST_RESULT"
                                ? navigattestresult
                                : action.name === "CHECK_COMPETENCY"
                                ? () =>
                                    navigatSelftest(
                                      session.ttttt_session_id,
                                      session.ttttt_tag
                                    )
                                : action.name === "JOIN_MEETING"
                                ? () => navigatJoinMeeting(session)
                                : action.name === "RUN_TEST"
                                ? () =>
                                    handleStartTest(session, session.ttttt_tag)
                                : action.name === "CREATE_MEETING" ||
                                  action.name === "START_MEETING"
                                ? () => navigatStartMeeting(session)
                                : null
                            }
                          >
                            {action.name === "CREATE_MEETING" ||
                            action.name === "START_MEETING" ? (
                              <FaCirclePlay fontSize={14} color="red" />
                            ) : action.name === "JOIN_MEETING" ? (
                              <FaHeadphones fontSize={14} color="#8fbc8f" />
                            ) : action.name === "SHARE_MEETING" ? (
                              <RiWhatsappFill fontSize={14} color="#25D366" />
                            ) : action.name === "LITTERA_ROOM" ? (
                              <IoRocketSharp />
                            ) : action.name === "SHARE_FEEDBACK" ? (
                              <MdScreenShare />
                            ) : action.name === "CHECK_COMPETENCY" ? (
                              <FaRegCircleCheck />
                            ) : action.name === "GIVE_FEEDBACK" ? (
                              <MdFeedback />
                            ) : action.name === "RUN_TEST" ? (
                              <FaRegPlayCircle />
                            ) : action.name === "View_TEST_RESULT" ? (
                              <i
                                style={{ color: "red" }}
                                data-title="View_test_Result"
                                class="glyphicon glyphicon-dashboard"
                                title="View Test Result"
                              ></i>
                            ) : action.name === "Complete_Session" ? (
                              <RiProgress1Line />
                            ) : null}
                            {action.name === "CREATE_MEETING"
                              ? translations["Create_Meeting_button"] ||
                                "CREATE MEETING"
                              : action.name === "START_MEETING"
                              ? translations["Start_meeting"] || "START MEETING"
                              : action.name === "JOIN_MEETING"
                              ? translations["Join_meeting"] || "JOIN MEETING"
                              : action.name === "SHARE_MEETING"
                              ? `${translations["Share"]} ${translations["Meeting"]}` ||
                                "SHARE MEETING"
                              : action.name === "Littera_room"
                              ? translations["Littera_room"] || "LITTERA ROOM"
                              : action.name === "SHARE_FEEDBACK"
                              ? translations["Share_survey"] || "SHARE FEEDBACK"
                              : action.name === "CHECK_COMPETENCY"
                              ? translations["Self_assessment"] || "Self Test"
                              : // action.name === 'GIVE_FEEDBACK' ? (
                              //   translations["GIVE_FEEDBACK"] || "GIVE FEEDBACK"
                              // ) :
                              action.name === "SHARE_MEETING"
                              ? `${translations["Share"]} ${translations["Meeting"]}` ||
                                "SHARE MEETING"
                              : action.name === "RUN_TEST"
                              ? "Start Test"
                              : action.name === "View_TEST_RESULT"
                              ? translations["View_test_Result"] ||
                                "View test Result"
                              : action.name.replace("_", " ")}
                          </IconButton>
                      ))}

                      <IconButton
                        className="flex uppercase items-center text-[1.1rem] font-semibold gap-2"
                        edge="end"
                        aria-label="edit"
                        onClick={
                          session?.session_type_name === 'Assignment' ? ()=> navigatAssignmentDetails(session?.ttttt_session_id)  : null
                        }
                      >
                        <HTMLParser iconname={session?.session_type_icon} />
                        {session?.session_type_name === "Academic"
                          ? translations["Personled"] || "Person led"
                          : session?.session_type_name === "Break"
                          ? translations["Breaks"] || "Break"
                          : session?.session_type_name === "Tour"
                          ? translations["Tour  "] || "Tour"
                          : session?.session_type_name === "GroupDiscussion"
                          ? translations["Group_discussion"] ||
                            "Group Disscussion"
                          : session?.session_type_name === "Assignment"
                          ? translations["Assignment"] || "Assignment"
                          : session?.session_type_name === "PT"
                          ? translations["PT"] || "PT"
                          : session?.session_type_name === "Sports"
                          ? translations["Sports"] || "Sport"
                          : session?.session_type_name === "Practical"
                          ? translations["Practical"] || "Practical"
                          : session?.session_type_name === "SelfPaced"
                          ? translations["Self_paced"] || "Self paced"
                          : session?.session_type_name}
                      </IconButton>

                      <IconButton
                        className="flex items-center text-[1.1rem] font-semibold gap-2"
                        edge="end"
                        aria-label="edit"
                        disabled={session?.completionpercentage === undefined}
                      >
                        <CircularProgressBar
                          session={session?.completionpercentage}
                        />
                      </IconButton>
                    </Typography>
                  )}
                </Box>

                {!isMobile && (
                  <ListItemSecondaryAction
                    className="flex-wrap"
                    sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                  > 
                    {session.is_Session_Restricted ? <FaLock title="Locked" className="text-red-500"/> : <FaUnlock title="Un-Locked" className="text-green-500"/> }
                    {session?.actionInfos
                      ?.filter(
                        (action) =>
                          action.value &&
                          ![
                            "EDIT_SESSION",
                            "DELETE_SESSION",
                            "CONTENT_LIBRARY",
                            "Complete_Session",
                          ].includes(action.name)
                      ) // Filter for actions where value is true and not in the exclusion list
                      .map((action) => (
                        <IconButton
                          key={action.key}
                          className="flex uppercase items-center text-[1.1rem] font-semibold gap-2"
                          edge="end"
                          aria-label={action.name
                            .toLowerCase()
                            .replace("_", " ")}
                          onClick={
                            action.name === "LITTERA_ROOM"
                              ? () => openLitteraRoom(session)
                              : action.name === "View_TEST_RESULT"
                              ? navigattestresult
                              : action.name === "CHECK_COMPETENCY"
                              ? () =>
                                  navigatSelftest(
                                    session.ttttt_session_id,
                                    session.ttttt_tag
                                  )
                              : action.name === "JOIN_MEETING"
                              ? () => navigatJoinMeeting(session)
                              : action.name === "RUN_TEST"
                              ? () =>
                                  handleStartTest(session, session.ttttt_tag)
                              : action.name === "CREATE_MEETING" ||
                                action.name === "START_MEETING"
                              ? () => navigatStartMeeting(session)
                              : null
                          }
                        >
                          {action.name === "CREATE_MEETING" ||
                          action.name === "START_MEETING" ? (
                            <FaCirclePlay fontSize={14} color="red" />
                          ) : action.name === "JOIN_MEETING" ? (
                            <FaHeadphones fontSize={14} color="#8fbc8f" />
                          ) : action.name === "SHARE_MEETING" ? (
                            <RiWhatsappFill fontSize={14} color="#25D366" />
                          ) : action.name === "LITTERA_ROOM" ? (
                            <IoRocketSharp />
                          ) : action.name === "SHARE_FEEDBACK" ? (
                            <MdScreenShare />
                          ) : action.name === "CHECK_COMPETENCY" ? (
                            <FaRegCircleCheck />
                          ) : action.name === "GIVE_FEEDBACK" ? (
                            <MdFeedback />
                          ) : action.name === "RUN_TEST" ? (
                            <FaRegPlayCircle />
                          ) : action.name === "View_TEST_RESULT" ? (
                            <i
                              style={{ color: "red" }}
                              data-title="View_test_Result"
                              class="glyphicon glyphicon-dashboard"
                              title="View Test Result"
                            ></i>
                          ) : action.name === "Complete_Session" ? (
                            <RiProgress1Line />
                          ) : null}
                          {action.name === "CREATE_MEETING"
                            ? translations["Create_Meeting_button"] ||
                              "CREATE MEETING"
                            : action.name === "START_MEETING"
                            ? translations["Start_meeting"] || "START MEETING"
                            : action.name === "JOIN_MEETING"
                            ? translations["Join_meeting"] || "JOIN MEETING"
                            : action.name === "SHARE_MEETING"
                            ? `${translations["Share"]} ${translations["Meeting"]}` ||
                              "SHARE MEETING"
                            : action.name === "Littera_room"
                            ? translations["Littera_room"] || "LITTERA ROOM"
                            : action.name === "SHARE_FEEDBACK"
                            ? translations["Share_survey"] || "SHARE FEEDBACK"
                            : action.name === "CHECK_COMPETENCY"
                            ? translations["Self_assessment"] || "Self Test"
                            : // action.name === 'GIVE_FEEDBACK' ? (
                            //   translations["GIVE_FEEDBACK"] || "GIVE FEEDBACK"
                            // ) :
                            action.name === "SHARE_MEETING"
                            ? `${translations["Share"]} ${translations["Meeting"]}` ||
                              "SHARE MEETING"
                            : action.name === "RUN_TEST"
                            ? "Start Test"
                            : action.name === "View_TEST_RESULT"
                            ? translations["View_test_Result"] ||
                              "View test Result"
                            : action.name.replace("_", " ")}
                        </IconButton>
                      ))}

                    <IconButton
                      className="flex uppercase items-center text-[1.1rem] font-semibold gap-2"
                      edge="end"
                      aria-label="edit"
                      onClick={
                        session?.session_type_name === 'Assignment' ? ()=> navigatAssignmentDetails(session?.ttttt_session_id)  : null
                      }
                    >
                      <HTMLParser iconname={session?.session_type_icon} />
                      {session?.session_type_name === "Academic"
                        ? translations["Personled"] || "Person led"
                        : session?.session_type_name === "Break"
                        ? translations["Breaks"] || "Break"
                        : session?.session_type_name === "Tour"
                        ? translations["Tour  "] || "Tour"
                        : session?.session_type_name === "GroupDiscussion"
                        ? translations["Group_discussion"] ||
                          "Group Disscussion"
                        : session?.session_type_name === "Assignment"
                        ? translations["Assignment"] || "Assignment"
                        : session?.session_type_name === "PT"
                        ? translations["PT"] || "PT"
                        : session?.session_type_name === "Sports"
                        ? translations["Sports"] || "Sport"
                        : session?.session_type_name === "Practical"
                        ? translations["Practical"] || "Practical"
                        : session?.session_type_name === "SelfPaced"
                        ? translations["Self_paced"] || "Self paced"
                        : session?.session_type_name}
                    </IconButton>

                    <IconButton
                      className="flex items-center text-[1.1rem] font-semibold gap-2"
                      edge="end"
                      aria-label="edit"
                      disabled={session?.completionpercentage === undefined}
                    >
                      <CircularProgressBar
                        session={session?.completionpercentage}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>

              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem className="flex flex-col items-start" button>
                    <Box
                      className="max-phone:flex-col max-phone:gap-4"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {session?.sessionFaculties.length > 0
                          ? session.sessionFaculties.map((faculty, index) => (
                              <ListItemAvatar
                                key={index}
                                title={faculty.facultyname}
                              >
                                <Avatar
                                  alt={faculty.facultyname}
                                  src={
                                    faculty.facultuimg
                                      ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${faculty.facultuimg}`
                                      : `${config?.LITTERA_CDN_BASE_URL}/theme/images/faculty.jpg`
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevents looping
                                    e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/faculty.jpg`; // Fallback image URL
                                  }}
                                  sx={{
                                    border: `2px solid ${theme?.palette?.primary?.main}`,
                                    marginRight: "8px", // Spacing between images
                                  }}
                                />
                              </ListItemAvatar>
                            ))
                          : null}
                      </Box>

                      {session?.sessionFaculties.length > 0 && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {session.sessionFaculties.map((faculty, index) => (
                            <React.Fragment key={index}>
                              <span title={faculty.facultyname}>
                                {faculty.facultyname}
                              </span>
                              {index < session.sessionFaculties.length - 1 &&
                                ", "}
                            </React.Fragment>
                          ))}
                        </Box>
                      )}
                    </Box>

                    {selectedUserType == 5 ? null : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: 2,
                          gap: "14px",
                          mt: 1,
                        }}
                      >
                        {session?.actionInfos
                          .filter(
                            (action) =>
                              action.value &&
                              [
                                "EDIT_SESSION",
                                "DELETE_SESSION",
                                "CONTENT_LIBRARY",
                              ].includes(action.name)
                          )
                          .map((action) => {
                            let iconButton = null;
                            if (action.name === "EDIT_SESSION") {
                              iconButton = (
                                <IconButton
                                  title="Edit Session Details"
                                  className="p-0"
                                >
                                  <FaEdit />
                                </IconButton>
                              );
                            } else if (action.name === "DELETE_SESSION") {
                              iconButton = (
                                <IconButton
                                  title="Delete Session"
                                  className="p-0"
                                >
                                  <IoTrash />
                                </IconButton>
                              );
                            } else if (action.name === "CONTENT_LIBRARY") {
                              iconButton = (
                                <IconButton
                                  title="Content Library"
                                  className="p-0"
                                >
                                  <IoFolder color="#FFA500" />
                                </IconButton>
                              );
                            }
                            return iconButton;
                          })}
                      </Box>
                    )}
                  </ListItem>
                </List>
              </Collapse>
            </div>
          ))}
      </List>

      <Dialog
        className="z-[9999999999999999999999999999999]"
        open={modalOpen}
        onClose={handleModalClose}
        PaperProps={{
          style: { width: "340px" },
        }}
      >
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <p>{modalMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NestedList;
