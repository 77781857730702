import React, { useEffect } from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import SessionCalender from '../components/Content/Calender/SessionCalender'

const TestSessionCalendar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   
        <SessionCalender/>
    
  )
}

export default TestSessionCalendar