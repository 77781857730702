import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineCompress, AiOutlineLike } from 'react-icons/ai'
import AllContent from './AllContent'
import Tabs from './Tabs'
import Comments from './Comments'
import { PiKeyboardThin }from "react-icons/pi"
import { FaFolder , FaPen }from "react-icons/fa"
import { IoIosList }from "react-icons/io"
import { FaFile }from "react-icons/fa"
import { IoIosLink }from "react-icons/io"
import { MdAudiotrack, MdOutlineDoneAll, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight }from "react-icons/md"
import { PiImageSquareFill , PiVideoFill }from "react-icons/pi"
import { CgClose, CgMenu}from "react-icons/cg"
import { SlSizeFullscreen }from "react-icons/sl"
import Universal from './Universal'
import HTML from './HTML'
import Feedback from '../Feedback/Feedback'
import { useFeedback } from '../../Context/FeedbackContext'
import axios from 'axios'
import { useConfig } from "../../ConfigContext";
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../pages/Loader'
import Notes1 from './Notes1'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import Meetings from './Meetings'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
// import Breadcrumb from '../../componentHome/commonComponent/Breadcrumbs'
import { useUser } from '../../UserContext'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Switch, useMediaQuery } from '@mui/material'
import { RotateView } from '../../componentHome/utils/Validation'
import { useTheme } from '../../ThemeProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointDown, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { IoClose } from 'react-icons/io5'

const LitteraRoom1 = () => {
    const { theme } = useTheme()
    const { selectedUserType } = useUser(); 
    const config = useConfig()
    const {
        roomSessions, 
        setroomSessions , 
        selectedSessionId, 
        setSelectedSessionId , 
        selectedSessionContent, 
        setSelectedSessionContent ,
        PageSize, 
        setPageSize,
        PageNumber, 
        setPageNumber,
        setContentType,
        setNotes,
        setVideo,
        setAudio,
        setpdf,
        setWYISIWYG,
        setImage,
        setinteractive,
        setdocuments,
        setotherdocuments,
        setCdn,
        Cdn,
        Video,
        Audio,
        pdf,
        WYISIWYG,
        Image,
        interactive,
        otherdocuments,
        documents,
        SingleSessionData, 
        setSingleSessionData,
        completion, 
        isFeedbackSubmitted, 
        setcompletion,
        setisFeedbackSubmitted,
        setLink,
        setTabs,
        whiteboard, 
        setwhiteboard,
        filtercontent, 
        setfiltercontent,
        sessionid, 
        setsessionid,
        tabs
    } = useFeedback()

    const SelectOption = useRef()
    const user = JSON.parse(localStorage.getItem("user"))
    const restrictedsession = JSON.parse(localStorage.getItem("restrictedsession"))
    const { isVisible, setisVisible } = useFeedback()
    const [activeComponent, setactiveComponent] = useState("AllContent")
    const [tabFile, settabFile] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedHtmlFile, setSelectedHtmlFile] = useState(null);
    const [complete, setcomplete] = useState(false)
    const [isActive, setisActive] = useState(false)
    const [htmlfile, sethtmlfile] = useState("")
    const [comments, setcomments] = useState("")
    const [notes, setnotes] = useState("")
    const { id } = useParams();
    const [loading, setLoading] = useState(true);    
    const [sideMenu, setsideMenu] = useState(true);    
    const [showDiv, setShowDiv] = useState(false)
    const [sessiontitle, setsessiontitle] = useState(null)
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));


    const navigate = useNavigate()

    const ContentTypeArray = [
        {
            id:"1",
            title : "All Content",
            value : "All Content",
            Logo : <MdOutlineDoneAll color="#FFA809" fontSize={20}/>,
        },
        {
            id:"2",
            title : "Videos",
            value : "Video",
            Logo : <PiVideoFill color="#6060E6" fontSize={20}/>,
        },
        {
            id:"3",
            title : "Images",
            value : "Image",
            Logo : <PiImageSquareFill color="#A52A2A"  fontSize={20}/>,
        },
        {
            id:"4",
            title : "Documents",
            value : "Pdf",
            Logo : <FaFile color="#6060E6" fontSize={20}/>,
        },
        {
            id:"5",
            title : "Wysiywyg",
            value : "WYISIWYG",
            Logo : <FaPen color="#A52A2A" fontSize={18}/>,
        },
        {
            id:"6",
            title : "Audio",
            value : "Audio",
            Logo : <MdAudiotrack color="#129812" fontSize={20}/>,
        },
        {
            id:"7",
            title : "CDN",
            value : "Cdn",
            Logo : <IoIosLink color="#6060E6" fontSize={20}/>,
        },
    ] 

    const filteredItems = user?.userdetails?.usertype?.filter(item => item.usertypeid === "5");
            
    const WhiteboardHandler = () =>{
        const whiteboard = {
            sessionAttachmentType: "whiteboard" , 
            Link : config?.REACT_APP_WHITEBOARD_URL,
            ttsad_title: "Whiteboard"
        }
        setSelectedFile(whiteboard);
        setSelectedHtmlFile(null);
        setisActive(false)
        setwhiteboard(true)
        setShowDiv(!showDiv)
    }

    const showHandler = (componentName) => {
        setactiveComponent(componentName)
        scrollToNotes()
    }

    const handleFileSelect = (docs) => {
        setSelectedFile(docs);
        setSelectedHtmlFile(null);
        setisActive(false)
    }

    const handleHtmlFileSelect = (docs) => {
        setSelectedHtmlFile(docs);
        sethtmlfile(null)
        setSelectedFile(null);
        setisActive(true)
    }

    const removeFileFromTab = (data) => {
        setSelectedFile(null)
        setSelectedHtmlFile(null)
    }

    const tabFileHandler = (data) => {
        if(data === null){
            setSelectedFile(null)
            setSelectedHtmlFile(null)
            settabFile(null);
            sethtmlfile(null)
            setisActive(false)

        }
        else if (data.sessionAttachmentType == 4) {
            setSelectedFile(null)
            settabFile(data);
            setisActive(false)
        }
        else if (data.sessionAttachmentType == 5) {
            setSelectedFile(null)
            settabFile(data);
            setisActive(false)

        }
        else if (data.sessionAttachmentType == 1) {
            setSelectedFile(null)
            settabFile(data);
            setisActive(false)

        }
        else {
            sethtmlfile(data)
            setisActive(true)

        }
    };

    const showCommentsHandler = (data) => {
        setcomments(data)
        setactiveComponent("Comments")
    }

    const showNotesHandler = (data) => {
        setnotes(data)
        setactiveComponent("Notes")
    }

    const showMeetingHandler = () => {
        setactiveComponent("Meetings")
    }

    const getSessionContent = async (sessionId) => {
        if (id) {
          const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {

            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Trg_Content`, {
                headers: headers,
                params: {
                    // sessionid:sessionId , trainingid:id , PageSize:10 , PageNumber:1 ,
                    sessionid:sessionId , trainingid:id 
                }
            });

            console.log('sessionid' , data)
            setVideo([])
            setAudio([])
            setpdf([])
            setWYISIWYG([])
            setImage([])
            setinteractive([])
            setdocuments([])
            setotherdocuments([])
            setCdn([])
            setSelectedSessionContent(data);
            setContentHandler(data)
            setLoading(false);

          } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);      
        }
    };

    const getContentType = async () => {
        if (id) {
          const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {

            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/ContentType`, {
                headers: headers,
            });
            
            setContentType(data);
            setLoading(false);

          } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);      
        }
    };

    const handleSessionSelect = (sessionId) => {
        setSelectedSessionId(sessionid?.ttttt_session_id || sessionId);
        checkNextSessionStatus(sessionId)
        getSessionContent(sessionid?.ttttt_session_id || sessionId);
        setLoading(false)
    };
    
    const handleSelectChange = (event) => {
        const sessionId = event.target.value;
        getSessionContent(sessionId);
        setSelectedSessionId(sessionId);
        const session = roomSessions?.find(session => session.ttttt_session_id === sessionId);
        setSingleSessionData(session)
        setactiveComponent("AllContent")
        setfiltercontent("All Content")
        setLink([])
        setTabs([])
        setVideo([])
        setAudio([])
        setpdf([])
        setWYISIWYG([])
        setImage([])
        setinteractive([])
        setdocuments([])
        setotherdocuments([])
        setCdn([])
        setLoading(true)
    };

    const setContentHandler = (sessiondata) => {
        try {
            setLoading(true);    
            sessiondata.items.forEach(item => {
                const { sessionAttachmentType } = item;
                if (item.sessionAttachmentType == 1) {
                    setpdf(prevPdf => [...prevPdf, item]);
                } else if (item.sessionAttachmentType == 2) {
                    setdocuments(prevDocument => [...prevDocument, item]);
                } else if (item.sessionAttachmentType == 4) {
                    setVideo(prevVideo => [...prevVideo, item]);
                } else if (item.sessionAttachmentType == 5) {
                    setImage(prevImages => [...prevImages, item]);
                } else if (item.sessionAttachmentType == 7) {
                    setWYISIWYG(prevWYISIWYG => [...prevWYISIWYG, item]);
                } else if (item.sessionAttachmentType == 8) {
                    setAudio(prevAudio => [...prevAudio, item]);
                } else if (item.sessionAttachmentType == 9 ) {
                    setCdn(prevCdn => [...prevCdn, item]);
                } else if (item.sessionAttachmentType == 10) {
                    setinteractive(prevInteractive => [...prevInteractive, item]);
                } else if (item.sessionAttachmentType == 11 ) {
                    setCdn(prevCdn => [...prevCdn, item]);
                } else {
                    setotherdocuments(preOther => [...preOther, item]);
                }
            });
            setLoading(false); 
        } catch (error) {
            setLoading(false); 
        }
    };
    
    const checkFeedbackStatus = async () => {
        if (id && selectedUserType == 5) {
          const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {

            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CHECK_SESSION_FEEBDACK`, {
                headers: headers,
                params: {
                    userid:user?.userdetails?.agencyid,
                    sessionid:selectedSessionId, 
                    trainingid:id 
                }
            });
            setisFeedbackSubmitted(data)
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);      
        }
    };

    const checkCompletionStatus = async () => {
        if (id && selectedUserType == 5 ) {
          const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {

            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CHECK_SESSION_COMPLETION_STATUS`, {
                headers: headers,
                params: {
                    userid:user?.userdetails?.agencyid,
                    sessionid:selectedSessionId, 
                    trainingid:id 
                }
            });
            setcompletion(data)
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);      
        }
    };

    const checkNextSessionStatus = async (sessionId) => {
        if (id && selectedUserType == 5 ) {
            const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {

            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CHECK_SESSION_RESTRICTION`, {
                headers: headers,
                params: {
                    userid:user?.userdetails?.agencyid,
                    usertype:selectedUserType,
                    sessionid:sessionId, 
                    trainingid:id 
                }
            });
            setLoading(false);
            return data
        } catch (error) {
            setLoading(false);
          }
        } else {
          setLoading(false);      
        }
    };

    useEffect(()=>{
        if(id){
            checkFeedbackStatus()
            checkCompletionStatus()
            setTabs([])
        }
    } ,[selectedSessionId])

    useEffect(() => {
        if (roomSessions?.length > 0) {
            setLoading(true)
            getContentType()
            const defaultSession = roomSessions[0];
            // setSelectedSessionId(sessionid || defaultSession || defaultSession?.ttttt_session_id)
            // setSelectedSessionId(sessionid || defaultSession?.ttttt_session_id)
            // handleSessionSelect(sessionid || defaultSession?.ttttt_session_id);
            setSelectedSessionId(sessionid?.ttttt_session_id || defaultSession?.ttttt_session_id)
            handleSessionSelect(sessionid?.ttttt_session_id || defaultSession?.ttttt_session_id);
            setSingleSessionData(sessionid || defaultSession)
        }
    }, [roomSessions]);

    const parseHTML = (htmlString) => {
        return (
            <span dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };

    const [isOpen, setIsOpen] = useState(false);
    
    const isSessionDisabled = (currentSessionId) => {
        return sessionid !== null && sessionid !== currentSessionId;
    };

    const handleMenuItemClick = async (sessionId) => {
        if(selectedUserType == "5"){
            setLoading(true);
            const isAllowed = await checkNextSessionStatus(sessionId);
            if ( isAllowed === true || isAllowed === null ) {            
                toast.warning('Please Complete Previous Session.');
            } else {            
                const syntheticEvent = {
                    target: { value: sessionId }
                };
                handleSelectChange(syntheticEvent);
            }
            setLoading(false);            
        }
        else{
            const syntheticEvent = {
                target: { value: sessionId }
            };
            handleSelectChange(syntheticEvent);
        }
    };

    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Littera Room', url: '/Litteraroom' }
    ];

    const handleFilterSelect = (filterdata)=>{
        setfiltercontent(filterdata.value)
        toggleDropdown()
    }
    
    useEffect(() => {
        setfiltercontent('All Content')
    }, [])

    const allcontentlength = (
        (pdf?.length || 0) +
        (Video?.length || 0) +
        (Image?.length || 0) +
        (Cdn?.length || 0) +
        (WYISIWYG?.length || 0) +
        (Audio?.length || 0)
    )

    const hidesideMenu = ()=>{
        setsideMenu(false)
        setShowDiv(!showDiv)
    }

    const showsideMenu = ()=>{
        setsideMenu(true)
        setShowDiv(!showDiv)
    }

    const sessionListPage = ()=>{
        document.body.style.width = "auto";
        document.body.style.transform = "rotate(0deg)"; 
        setfullscreen(false)
        navigate(`/Session_trg/SessionDetail/${id}`)
        setShowDiv(!showDiv)
    }

    const contentRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { fullscreen , setfullscreen } = useFeedback()

    const openFullScreen = () => {
        const content = contentRef.current;
    
        // Check if XamarinApp is available
        if (window.XamarinApp && typeof window.XamarinApp.callXamarinFunction === "function") {
        // if (1==1) {
            // Landscape mode - adjust for portrait orientation
            document.body.style.width = "100vh";
            document.body.style.transform = "rotate(270deg)";
            setfullscreen(true)
            window.XamarinApp.RotateView('1');
            // Portrait mode - adjust for landscape orientation
        } else {
          // Browser fullscreen logic
          if (content.requestFullscreen) {
            content.requestFullscreen();
          } else if (content.mozRequestFullScreen) {
            content.mozRequestFullScreen(); // Firefox
          } else if (content.webkitRequestFullscreen) {
            content.webkitRequestFullscreen(); // Chrome, Safari, Opera
          } else if (content.msRequestFullscreen) {
            content.msRequestFullscreen(); // IE/Edge
          }
        }
        setIsFullScreen(true); // Set fullscreen state to true
    };
    
    const exitFullScreen = () => {
        if (window.XamarinApp && typeof window.XamarinApp.callXamarinFunction === "function") {
        // if (1==1) {
            document.body.style.width = "auto";
            document.body.style.transform = "rotate(0deg)";
            setfullscreen(false)
            window.XamarinApp.RotateView('2');
        } else {
          // Browser fullscreen exit logic
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
          }
        }
    
        setIsFullScreen(false); // Set fullscreen state to false
    };
    
    const handleDropdownToggle = () => {
        // Only toggle if sessionid is not null or undefined
        if (sessionid === null) {
          setIsOpen((prev) => !prev); // Toggle dropdown open/close
        }
    };
    
    const scrollToNotes = () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
    };

    const [iisOpen, setiisOpen] = useState(false);
    const menuRef = useRef(null);
    
    const toggleDropdown = () => {
      setiisOpen(prevState => !prevState);
      scrollToNotes()
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            setiisOpen(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const session = roomSessions?.find(
        (session) =>
            session.ttttt_session_id === sessionid || session.ttttt_session_id === selectedSessionId
        );
        // setsessiontitle(`${session?.ttttt_subject} - ${session?.ttttt_content_desc}`);
        setsessiontitle(`${session?.ttttt_subject} - ${session?.ttttt_content_desc}`);
    }, [selectedSessionId])
    
    const fullsize = () => {
        document.body.style.width = "auto";
        document.body.style.transform = "rotate(0deg)"; 
        setfullscreen(false)
    }

    if(loading){
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }
    
    return (
        <div className='w-full border-b-2 h-full bg-[#FFFFFF] overflow-x-hidden  border-t-0 '>
            
            <div className="pageheader h-0 px-4 py-8 flex items-center justify-between">
                <div className="media ">
                    <div className="media-body flex justify-between">
                    <ul className="breadcrumb ">
                        <li>
                        <NavLink exact to="">
                            <i className="glyphicon glyphicon-home" />
                        </NavLink>
                        </li>
                        {paths.map((path, index) => (
                        <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`} aria-current={index === paths.length - 1 ? 'page' : ''}>
                            {index === paths.length - 1 ? (
                            path.title
                            ) : (
                            <NavLink onClick={fullsize} to={path.url}>{path.title}</NavLink>
                            )}
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>

                {
                    !isFullScreen &&
                    <div className='flex items-center justify-center gap-4'>
                                        
                        <button onClick={isFullScreen ? exitFullScreen : openFullScreen} style={{ fontSize: '12px' }}>
                            {isFullScreen ? (
                            <AiOutlineCompress title="Exit Full Screen" fontSize={16} />
                            ) : (
                            <SlSizeFullscreen title="Go To Full Screen" fontSize={12} />
                            )}
                        </button>
                                            
                        <button 
                            onClick={() => setShowDiv(!showDiv)} 
                            style={{ cursor:'pointer'  }}
                        >
                            {
                                showDiv ?
                                <CgClose color='black' fontSize={16} />
                                :
                                <CgMenu color='black' fontSize={16} />
                            }
                        </button>

                        {showDiv && (
                            <div className='flex gap-4 z-[99999999] absolute flex-col top-full right-0' style={{ padding: '15px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', transition: 'all 0.3s ease' }}>
                                                    <button 
                                                        data-tooltip-id="my-tool"
                                                        data-tooltip-content={!whiteboard ? 'Whiteboard' : "Whiteboard Already Opened"}
                                                        data-tooltip-place='top'
                                                        onClick={!whiteboard ? WhiteboardHandler : null} className='pointer flex items-center gap-2'>
                                                        <PiKeyboardThin
                                                            fontSize={18}                                                                        
                                                            style={{ cursor: !whiteboard ? 'pointer' : "not-allowed", opacity: !whiteboard ? '1' : ".5" }} 
                                                            color={whiteboard ? "#4ade80" : "black"}
                                                        />
                                                        Whiteboard
                                                    </button>
                                                    {
                                                        selectedUserType != 5 ?
                                                        <button 
                                                            data-tooltip-id="my-tool"
                                                            data-tooltip-content='Content Library'
                                                            data-tooltip-place='top'
                                                            onClick={() => setShowDiv(!showDiv)} className='pointer flex items-center gap-2'>
                                                            <FaFolder
                                                                fontSize={17}                                                                        
                                                                
                                                                cursor={"pointer"} 
                                                            />
                                                            Content Library
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                    <button 
                                                        data-tooltip-id="my-tool"
                                                        data-tooltip-content='Session List'
                                                        data-tooltip-place='top'
                                                        onClick={sessionListPage} className='pointer flex items-center gap-2'>
                                                        <IoIosList
                                                            fontSize={18}                                                                        
                                                            cursor={"pointer"} 
                                                        />
                                                        Session List
                                                    </button>
                                                    
                                                    {sideMenu ?
                                                        // <button onClick={() => setsideMenu(false)} className='pointer flex items-center gap-2'>
                                                        <button 
                                                            data-tooltip-id="my-tool"
                                                            data-tooltip-content='Hide Side Menu'
                                                            data-tooltip-place='top'
                                                            onClick={hidesideMenu} 
                                                            className='pointer flex items-center gap-2'>
                                                            <MdOutlineKeyboardDoubleArrowRight 
                                                                fontSize={18}                                                                        
                                                                cursor={"pointer"} 
                                                            />
                                                            Hide Side Menu
                                                        </button>
                                                        :
                                                        // <button onClick={() => setsideMenu(true)} className='pointer flex items-center gap-2'>
                                                        <button
                                                            data-tooltip-id="my-tool"
                                                            data-tooltip-content='Enable Side Menu'
                                                            data-tooltip-place='top'
                                                            onClick={showsideMenu} className='pointer flex items-center gap-2'>
                                                            <MdOutlineKeyboardDoubleArrowLeft 
                                                                fontSize={18}                                                                        
                                                                cursor={"pointer"} 
                                                            />
                                                            Show Side Menu
                                                        </button>
                                                    }
                            </div>
                        )}

                        <Tooltip className='z-[999999999999999999999999999]' id='my-tool'  />  
                    </div>  
                }
            </div>
            
            <div ref={contentRef} className='wrapper h-fit bg-white w-full flex'>
                <div className='h-full  w-full '>
                    <div className='h-full w-full  max-md:block flex '>

                        <div className='max-md:h-fit h-full max-md:w-full w-full'>
                            <div className='h-fit relative w-full border-b-2 flex items-center justify-between py-1 px-2 gap-1 '>
                                
                                <div className='flex gap-2'>

                                    <div className="relative inline-block text-left">
                                        <div>
                                            <Tooltip 
                                                id='res' 
                                                style={{ zIndex:'999' , maxWidth: '300px',   wordWrap: 'break-word', whiteSpace: 'normal' }}
                                            />                               
                                            <button
                                                data-tooltip-id="res"
                                                data-tooltip-content={
                                                    restrictedsession != null || sessionid != null ?
                                                    sessiontitle
                                                    : 
                                                    null
                                                } 
                                                data-tooltip-place='left' 
                                                // title={sessionid != null ? 'You can not change session.' : null }
                                                onClick={handleDropdownToggle} // Conditional toggle based on sessionid
                                                disabled={restrictedsession != null || sessionid != null}
                                                // onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown
                                                className={
                                                    `
                                                    inline-flex 
                                                    w-full 
                                                    items-center 
                                                    relative 
                                                    gap-x-1.5 
                                                    overflow-hidden 
                                                    rounded-md 
                                                    bg-white
                                                    max-w-[47vw]
                                                    max-lg:max-w-[80vw]
                                                    max-md:max-w-[67vw]
                                                    max-phone:max-w-[60vw]
                                                    px-2.5 
                                                    pt-3.5 
                                                    pb-3 
                                                    text-sm 
                                                    font-medium 
                                                    capitalize 
                                                    text-gray-900 
                                                    shadow-sm
                                                    ${
                                                        selectedUserType == 5 ? 
                                                        null 
                                                        : 
                                                        'ring-1'
                                                    } 
                                                     
                                                    ring-inset 
                                                    ring-gray-300 
                                                    hover:bg-gray-50 
                                                    whitespace-nowrap
                                                    `    
                                                }
                                            >
                                            {(() => {
                                                const session = roomSessions?.find(
                                                (session) =>
                                                    session.ttttt_session_id === sessionid || session.ttttt_session_id === selectedSessionId
                                                );

                                                if (session) {
                                                const parsedHTML = parseHTML(session?.session_type_icon); // Assuming parseHTML is available
                                                const displaysubject = session?.ttttt_subject;
                                                const displaydescription = session?.ttttt_content_desc;
                                                
                                                const combinedText = displaysubject + " - " + displaydescription;

                                                // Truncate combined text if it's longer than 50 characters
                                                const truncatedText = combinedText.length > 70 ? combinedText.slice(0, 70) + "..." : combinedText;
                                                const truncatedTextInPhone = combinedText.length > 25 ? combinedText.slice(0, 25) + "..." : combinedText;

                                                return (
                                                    <>
                                                    {parsedHTML}
                                                    {isMobile ? truncatedTextInPhone : truncatedText }
                                                    {/* {displaysubject} - {displaydescription} */}
                                                    </>
                                                );
                                                } else {
                                                return 'Select Session';
                                                }
                                            })()}

                                            {
                                                restrictedsession != null || sessionid != null ?
                                                null
                                                :
                                                <ChevronDownIcon
                                                    aria-hidden="true"
                                                    className="max-md:absolute top-1.5 right-[.6px] max-md:h-[70%] bg-white flex items-center w-7 text-black"
                                                />
                                            }
                                            </button>
                                        </div>

                                        {isOpen && (
                                            <div
                                            className="absolute bg-white max-w-[600px] max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                                            >
                                            <div className="py-1 w-full">
                                                {/* Render Menu Items */}
                                                {roomSessions
                                                ?.filter(
                                                    (ses, index, self) =>
                                                    self?.findIndex((s) => s?.ttttt_session_id === ses?.ttttt_session_id) === index
                                                )
                                                ?.map((session) => (
                                                    <div key={session.ttttt_session_id}>
                                                    <button
                                                        title={session.display_txt}
                                                        style={{
                                                        cursor: isSessionDisabled(session.ttttt_session_id) ? 'not-allowed' : 'pointer',
                                                        }}
                                                        disabled={isSessionDisabled(session.ttttt_session_id)}
                                                        onClick={() => handleMenuItemClick(session.ttttt_session_id)} // Handle click
                                                        className={`block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full ${
                                                        selectedSessionId === session.ttttt_session_id ? 'bg-[#1976d2] text-white' : ''
                                                        }`}
                                                    >
                                                        <span className="flex py-2 items-center gap-2">
                                                            {parseHTML(session.session_type_icon)}
                                                            <span>{session?.ttttt_subject} - {session?.ttttt_content_desc}</span>
                                                        </span>
                                                    </button>
                                                    </div>
                                                ))}
                                            </div>
                                            </div>
                                        )}
                                    </div>
                                 
                                    {
                                        selectedUserType == 5 ?
                                            <React.Fragment>
                                                {
                                                    SingleSessionData?.completiontype?.id === 1 ?
                                                    <button
                                                        data-tooltip-id="buttontooltip"
                                                        data-tooltip-content={isFeedbackSubmitted ? 'Completed' : 'Complete Now'} 
                                                        data-tooltip-place='top'
                                                        onClick={() => setisVisible(true)}
                                                        disabled={isFeedbackSubmitted}
                                                        // style={{ 
                                                        //     backgroundColor: complete !== false ? 
                                                        //     "rgb(86, 190, 86)" 
                                                        //     :
                                                        //     "rgb(74 222 128", 
                                                        //     color: "white"
                                                        // }}
                                                        
                                                        className={
                                                        `text-xm capitalize min-w-fit border-2 py-[3px] px-4 flex items-center gap-2 
                                                        ${
                                                            isFeedbackSubmitted ? 
                                                            'bg-[#428bca] text-white opacity-70 cursor-not-allowed' 
                                                            : 
                                                            'bg-[#428bca] text-white opacity-100'
                                                        }`
                                                    }
                                                    >
                                                        {
                                                            isFeedbackSubmitted ?
                                                            'Completed'
                                                            :
                                                            'Complete'
                                                        }
                                                        <AiOutlineLike />
                                                    </button>
                                                    :
                                                    <button
                                                        data-tooltip-id="buttontooltip"
                                                        data-tooltip-content='Completed'
                                                        data-tooltip-place='top'
                                                        onClick={() => setisVisible(true)}
                                                        disabled={isFeedbackSubmitted}                                                        
                                                        // style={{ backgroundColor: complete !== false ? "rgb(86, 190, 86)" : "rgb(74 222 128", color: "white" }}
                                                        className={`text-xm capitalize min-w-fit border-2 py-[3px] px-4 flex items-center gap-2 
                                                         ${
                                                            isFeedbackSubmitted ? 
                                                            'bg-[#428bca] text-white opacity-70 cursor-not-allowed' 
                                                            : 
                                                            'bg-[#428bca] text-white opacity-100'
                                                        }`
                                                    }
                                                    >
                                                        Feedback
                                                        ★
                                                    </button>

                                                }
                                                <Tooltip id='buttontooltip'  />                               


                                            </React.Fragment>
                                            :
                                            null
                                    }
                                
                                    {
                                        isVisible ?
                                            <Feedback isVisible={isVisible} />
                                            :
                                            null
                                    }

                                </div>
                                
                                {
                                    isFullScreen ? 
                                    <div className='flex items-center justify-center pr-2 gap-4'>
                                        
                                        <button onClick={isFullScreen ? exitFullScreen : openFullScreen} style={{ fontSize: '12px' }}>
                                            {isFullScreen ? (
                                            <AiOutlineCompress title="Exit Full Screen" fontSize={16} />
                                            ) : (
                                            <SlSizeFullscreen title="Go To Full Screen" fontSize={12} />
                                            )}
                                        </button>
                                        
                                        <button 
                                            onClick={() => setShowDiv(!showDiv)} 
                                            style={{ cursor:'pointer'  }}
                                        >
                                            {
                                                showDiv ?
                                                <CgClose color='black' fontSize={16} />
                                                :
                                                <CgMenu color='black' fontSize={16} />
                                            }
                                        </button>

                                        {showDiv && (
                                            <div className='flex gap-4 z-[99999999] absolute flex-col top-full right-0' style={{ padding: '15px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', transition: 'all 0.3s ease' }}>
                                                <button 
                                                    data-tooltip-id="my-tool"
                                                    data-tooltip-content={!whiteboard ? 'Whiteboard' : "Whiteboard Already Opened"}
                                                    data-tooltip-place='top'
                                                    onClick={!whiteboard ? WhiteboardHandler : null} className='pointer flex items-center gap-2'>
                                                    <PiKeyboardThin
                                                        fontSize={18}                                                                        
                                                        style={{ cursor: !whiteboard ? 'pointer' : "not-allowed", opacity: !whiteboard ? '1' : ".5" }} 
                                                        color={whiteboard ? "#4ade80" : "black"}
                                                    />
                                                    Whiteboard
                                                </button>
                                                {
                                                    selectedUserType != 5 ?
                                                    <button 
                                                        data-tooltip-id="my-tool"
                                                        data-tooltip-content='Content Library'
                                                        data-tooltip-place='top'
                                                        onClick={() => setShowDiv(!showDiv)} className='pointer flex items-center gap-2'>
                                                        <FaFolder
                                                            fontSize={17}                                                                        
                                                            
                                                            cursor={"pointer"} 
                                                        />
                                                        Content Library
                                                    </button>
                                                    :
                                                    null
                                                }
                                                <button 
                                                    data-tooltip-id="my-tool"
                                                    data-tooltip-content='Session List'
                                                    data-tooltip-place='top'
                                                    onClick={sessionListPage} className='pointer flex items-center gap-2'>
                                                    <IoIosList
                                                        fontSize={18}                                                                        
                                                        cursor={"pointer"} 
                                                    />
                                                    Session List
                                                </button>
                                                
                                                {sideMenu ?
                                                    // <button onClick={() => setsideMenu(false)} className='pointer flex items-center gap-2'>
                                                    <button 
                                                        data-tooltip-id="my-tool"
                                                        data-tooltip-content='Hide Side Menu'
                                                        data-tooltip-place='top'
                                                        onClick={hidesideMenu} 
                                                        className='pointer flex items-center gap-2'>
                                                        <MdOutlineKeyboardDoubleArrowRight 
                                                            fontSize={18}                                                                        
                                                            cursor={"pointer"} 
                                                        />
                                                        Hide Side Menu
                                                    </button>
                                                    :
                                                    // <button onClick={() => setsideMenu(true)} className='pointer flex items-center gap-2'>
                                                    <button
                                                        data-tooltip-id="my-tool"
                                                        data-tooltip-content='Enable Side Menu'
                                                        data-tooltip-place='top'
                                                        onClick={showsideMenu} className='pointer flex items-center gap-2'>
                                                        <MdOutlineKeyboardDoubleArrowLeft 
                                                            fontSize={18}                                                                        
                                                            cursor={"pointer"} 
                                                        />
                                                        Show Side Menu
                                                    </button>
                                                }
                                            </div>
                                        )}
                                    </div>  
                                    :
                                    null
                                }

                                <Tooltip className='z-[999999999999999999999999999]' id='my-tool'  />                               

                            </div>

                            <Tabs
                                sendfile={tabFileHandler} 
                                closefile={removeFileFromTab} 
                                filename={selectedFile || selectedHtmlFile} 
                            />

                            {
                                tabs?.length > 0 ?
                                null
                                :
                                <>
                                <div className="flex items-center justify-center h-full max-md:my-[20vh] mt-[20vh]">
                                    <div className="flex flex-col items-center p-6 bg-gray-100 text-gray-800 rounded-lg shadow-lg shadow-gray-300 max-w-md mx-auto animate-pulse">
                                        {
                                            isMobile &&
                                            <span className="text-center text-lg font-medium text-gray-700">
                                                To view the content, please click on the play button.
                                            </span>
                                        }
                                        <FontAwesomeIcon 
                                            fontSize={50} 
                                            icon={isMobile ? faHandPointDown : faHandPointRight} 
                                            style={{color:theme?.palette?.primary?.main}}
                                            className="my-4 animate-pulse animate-wiggle "  
                                        />
                                        {
                                            !isMobile &&
                                            <span className="text-center text-lg font-medium text-gray-700">
                                                To view the content, please click on the play button.
                                            </span>
                                        }
                                    </div>
                                </div>
                                </>
                            }

                            {
                                isActive === false ? (
                                    selectedFile ? (
                                        <Universal docs={selectedFile} />
                                    ) : (
                                        tabFile ? (
                                            <Universal docs={tabFile} />
                                        ) : null
                                    )
                                ) : (
                                    htmlfile ? (
                                        <HTML htmlContent={htmlfile} />
                                    ) : (
                                        selectedHtmlFile ? (
                                            <HTML htmlContent={selectedHtmlFile} />
                                        ) : null
                                    )
                                )
                            }
                        </div>

                        {
                            sideMenu ? 
                            // <div className='max-md:mt-2 absolute left-[50vw] transition-all ease-in-out duration-300 h-fit '>
                            <div className='max-md:mt-2 border-l-2 transition-all ease-in-out duration-300 h-fit max-md:w-full w-[23.5vw]'>

                                <div className='flex max-md:border-t-2 items-center justify-evenly  border-b-2'>

                                    <div
                                        onClick={() => showHandler('AllContent', setcomments(null), setnotes(null))}
                                        style={{
                                            backgroundColor : activeComponent === "AllContent" && theme?.palette?.primary?.main,
                                            color: activeComponent === "AllContent" && 'white'
                                        }}
                                        className={`cursor-pointer 
                                                capitalize 
                                                flex 
                                                justify-center
                                                items-center
                                                pt-[8px] 
                                                py-[7px] 
                                                max-md:w-44
                                                w-28
                                                border-r-2
                                                `}
                                    >
                                        Contents
                                    </div>

                                    <div
                                        onClick={() => showHandler('Comments', setnotes(null))}
                                        style={{
                                            backgroundColor : activeComponent === "Comments" && theme?.palette?.primary?.main,
                                            color: activeComponent === "Comments" && 'white'
                                        }}
                                        className={ `cursor-pointer 
                                                capitalize 
                                                flex 
                                                justify-center
                                                items-center
                                                pt-[8px] 
                                                py-[7px] 
                                                max-md:w-44
                                                w-32
                                                border-r-2  
                                                `}
                                    >
                                        Comments
                                    </div>

                                    <div
                                        onClick={() => showHandler('Notes', setcomments(null))}
                                        style={{
                                            backgroundColor : activeComponent === "Notes" && theme?.palette?.primary?.main,
                                            color: activeComponent === "Notes" && 'white'
                                        }}
                                        className={` cursor-pointer 
                                                capitalize 
                                                flex 
                                                justify-center
                                                items-center
                                                py-[7px] 
                                                max-md:w-40
                                                w-24
                                                pt-[8px] 
                                                border-r-2 
                                                `}
                                    >
                                        Notes
                                    </div>

                                    <div
                                        onClick={() => showHandler('Meetings', setcomments(null) , setnotes(null))}
                                        style={{
                                            backgroundColor : activeComponent === "Meetings" && theme?.palette?.primary?.main,
                                            color: activeComponent === "Meetings" && 'white'
                                        }}
                                        className={`sssscursor-pointer 
                                                capitalize 
                                                flex 
                                                justify-center
                                                items-center
                                                py-[7px] 
                                                max-md:w-44
                                                w-28
                                                pt-[8px] 
                                                `}
                                    >
                                        Meetings
                                    </div>
                                </div>

                                <div className='flex items-center justify-center px-1 max-md:pt-1 h-14  border-b-2 '>
                                    {
                                        activeComponent === "AllContent" ?
                                        <div className="relative w-full inline-block text-left" ref={menuRef}>
                                                <div className="w-full">
                                                    <button
                                                    onClick={toggleDropdown} // Toggle dropdown on click
                                                    className="inline-flex h-11 w-full items-center justify-between px-6 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-2 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                                                    aria-expanded={isOpen} // This will indicate whether the dropdown is open
                                                    aria-haspopup="true"
                                                    >
                                                    {(() => {
                                                        const session = ContentTypeArray?.find(item => item.value === filtercontent);

                                                        if (session) {
                                                        return (
                                                            <>
                                                            <span className="flex items-center justify-start gap-4">
                                                                {session.Logo}
                                                                <span>{session.title}</span>
                                                            </span>
                                                            </>
                                                        );
                                                        } else {
                                                        return 'Select Content';
                                                        }
                                                    })()}
                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                                                    />
                                                    </button>
                                                </div>

                                                {iisOpen && (
                                                    <div
                                                    className="absolute bg-white w-full max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                                                    role="menu"
                                                    >
                                                    <div className="py-1 w-full">
                                                        {ContentTypeArray?.map(session => (
                                                        <div key={session.id} role="none">
                                                            <button
                                                            value={session.value}
                                                            onClick={() => handleFilterSelect(session)}
                                                            className={`block px-6 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full ${
                                                                filtercontent === session.value ? 'bg-[#1976d2] text-white' : ''
                                                            }`}
                                                            role="menuitem"
                                                            >
                                                            <span className="flex py-2 items-center justify-start gap-4">
                                                                {session.Logo}
                                                                <span>{session.title}</span>
                                                                {
                                                                session.value === "All Content" ? allcontentlength
                                                                : session.value === "Pdf" ? pdf?.length
                                                                : session.value === "Video" ? Video?.length
                                                                : session.value === "Image" ? Image?.length
                                                                : session.value === "Cdn" ? Cdn?.length
                                                                : session.value === "WYISIWYG" ? WYISIWYG?.length
                                                                : session.value === "Audio" ? Audio?.length
                                                                : null
                                                                }
                                                            </span>
                                                            </button>
                                                        </div>
                                                        ))}
                                                    </div>
                                                    </div>
                                                )}
                                        </div>
                                        :
                                        null
                                    }
                                </div>

                                {
                                    comments ? (
                                        <>
                                            {activeComponent === 'Comments' && <Comments showComments={comments} />}
                                        </>
                                    ) : notes ? (
                                        <>
                                            {activeComponent === 'Notes' && <Notes1 showNotes={notes} />}
                                        </>
                                    ) :
                                        activeComponent === "Meetings" ? (
                                        <>
                                            {activeComponent === 'Meetings' && <Meetings />}
                                        </>
                                    ) :
                                        activeComponent === "AllContent" ? (
                                            <>
                                                {activeComponent === 'AllContent' && (
                                                    <AllContent
                                                        showNotes={showNotesHandler}
                                                        showComments={showCommentsHandler}
                                                        onFileSelect={handleFileSelect}
                                                        onHtmlSelect={handleHtmlFileSelect}
                                                    />
                                                )}
                                            </>
                                        )
                                            : (
                                                <>
                                                    {activeComponent === 'Comments' && <Comments showComments={comments} />}
                                                    {activeComponent === 'AllContent' && (
                                                        <AllContent
                                                            showNotes={showNotesHandler}
                                                            showComments={showCommentsHandler}
                                                            onFileSelect={handleFileSelect}
                                                            onHtmlSelect={handleHtmlFileSelect}
                                                        />
                                                    )}
                                                    {activeComponent === 'Notes' && <Notes1 showNotes={notes} />}
                                                    {activeComponent === 'Meetings' && <Meetings />}
                                                </>
                                            )
                                }

                            </div>
                            :
                            null
                        }                
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LitteraRoom1