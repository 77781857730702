import React, { useState, usee } from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import JobPositionAnt from '../componentHome/fromtab/JobPositionAnt'

const JobPositionAntPage = () => {
  

  return (
    

      <JobPositionAnt />

   
  )
}

export default JobPositionAntPage