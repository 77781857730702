import React, { useState } from "react";
import { Table, Button, Form, Row, Col, } from "react-bootstrap";

import { Modal, Checkbox, DatePicker, Select, TimePicker, } from 'antd';


// import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import image1 from '../../assest/user_img.png';
const { Option } = Select;
const facultyOptions = [
    { value: 'anand', label: 'Anand Bugde', image: image1 },
    { value: 'john', label: 'John Doe', image: image1 },
    { value: 'jane', label: 'Jane Smith', image: image1 },
];

const TimeTable = () => {
    const [dates, setDates] = useState([null, null]);
    const [isOpenModal, setOpenModal] = useState(false)
    const [validationMessage, setValidationMessage] = useState('');
    const [nextModalVisible, setNextModalVisible] = useState(false);


    const [allDaysChecked1, setAllDaysChecked1] = useState(true); // Default to false
    const handleSelectAll1 = (checked) => {
        setAllDaysChecked1(checked);
        setSelectedDays(checked ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] : []);
    };
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    
    const getWeeks = () => {
        const weeks = [];
        const startDate = new Date(); // Current date
    
        for (let i = 0; i < 5; i++) {
            const weekStart = new Date(startDate);
            weekStart.setDate(weekStart.getDate() + i * 7);
            const weekEnd = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);
    
            weeks.push({
                label: `Week ${i + 1} (${formatDate(weekStart)} - ${formatDate(weekEnd)})`,
                value: `week_${i + 1}`
            });
        }
    
        return weeks;
    };
    
    const weekOptions = getWeeks();
    
    const handleDayChange = (day) => {
        setSelectedDays(prevSelectedDays => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(d => d !== day);
            } else {
                return [...prevSelectedDays, day];
            }
        });
    };

    const handleIndividualCheckboxChange = (day) => {
        handleDayChange(day);
        setAllDaysChecked1(selectedDays.length + (selectedDays.includes(day) ? -1 : 1) === 6); // Check if all are selected
    };

    const handleNext = () => {
        let allRowsValid = true; // Track if all rows are valid

        for (const row of rows) {
            const daysFilled = Object.values(row.days).some(day => day); // Check if any day is filled
            const timeRangeFilled = row.startTime && row.endTime; // Check if time range is filled

            // Check if either the time range or any day is not filled
            if (!daysFilled) {
                allRowsValid = false; // Mark as invalid if at least one row is empty
                break; // Exit loop as soon as we find an invalid row
            }
        }

        if (!allRowsValid) {
            setValidationMessage(`Please fill in the time range and at least one day in each row.`);
            setShowModal(true); // Show validation modal
        } else {
            setNextModalVisible(true); // Show next steps modal
        }
    };


    const [rowCount, setRowCount] = useState();
    const [rows, setRows] = useState([{
        startTime: [null, null], // Initialize with null values
        endTime: [null, null], sessionType: "", faculty: [], days: {}, lastInputValue: "", applyToAll: false
    }]);

    const [showModal, setShowModal] = useState(false);
    const [selectedWeeks, setSelectedWeeks] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [lastInputValue, setLastInputValue] = useState('');

    const addRow = () => {
        const newRows = Array.from({ length: rowCount }, () => ({ startTime: "", endTime: "", sessionType: "", faculty: [], days: {}, lastInputValue: "", applyToAll: false }));
        setRows(prevRows => [...prevRows, ...newRows]);
    };

    const handleFacultyChange = (index, selectedOptions) => {
        const updatedRows = [...rows];
        updatedRows[index].faculty = selectedOptions;
        setRows(updatedRows);
    };

    const removeRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const daysOfWeek = ["Mon", "Tues", "Wed", "Thurs", "Friday", "Sat", "Sun"];



    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        if (field.startsWith('days.')) {
            const day = field.split('.')[1];
            updatedRows[index].days[day] = value;
        }

        // Update the last input value for the current row
        updatedRows[index].lastInputValue = value;

        setRows(updatedRows);
    };

    const handleApplyToAll = (index) => {
        if (index < 0 || index >= rows.length) return;
        const updatedRows = [...rows];

        updatedRows[index].applyToAll = !updatedRows[index].applyToAll;

        if (updatedRows[index].applyToAll) {
            setSelectedRowIndex(index);
            setOpenModal(true);
        } else {
            setRows(updatedRows);
        }
    };

    const handleConfirmApplyToAll = () => {
        const updatedRows = [...rows];
        const valueToApply = updatedRows[selectedRowIndex].lastInputValue;

        // Apply the last input value to all days for the selected row
        updatedRows[selectedRowIndex].days = daysOfWeek.reduce((acc, day) => {
            acc[day] = valueToApply;
            return acc;
        }, {});

        setRows(updatedRows);
        setOpenModal(false);
    };



    // const handleNext = () => {
    //     setShowModal(true);
    // };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [selectedDays, setSelectedDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);

    const handleWeekChange = (selected) => {
        setSelectedWeeks(selected);
        setDates([null, null]); // Clear dates when weeks are selected
    };
    const handleDateChange = (dates) => {
        setDates(dates);
        setSelectedWeeks([]); // Clear weeks when dates are selected
    };
    const handleConCloseModal = () => {
        setOpenModal(false);
    };
    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Time Table', url: '/Eval/TestList' }
    ];

    return (
        <>
            <Breadcrumb paths={paths} />
            <div className="timetable-container" style={{ border: "1px solid #99999978", borderRadius: "5px", marginBottom: "10px", width: "100%" }}>
                <div className="my-3" style={{ borderBottom: "1px solid #99999978" }}>
                    <h3>Training - 24250026 - व्यक्तिगत विकास</h3>
                    <p>From 01-05-2024 to 23-01-2025</p>
                </div>
                <Row className="mb-3" style={{ display: "flex", justifyContent: "space-between", margin: "2px" }}>
                    <Col style={{ width: "100%", display: "flex" }}>
                        <Form.Control type="number" placeholder="#Rows" value={rowCount}
                            onChange={(e) => setRowCount(Math.max(1, e.target.value))} style={{ width: "200px" }} />
                        <Button onClick={addRow} variant="primary" style={{ marginLeft: "5px" }}>ADD</Button>
                    </Col>
                    <Col className="text-end" xs={7}>
                        <Button variant="success" onClick={handleNext}>Next</Button>
                    </Col>
                </Row>

                <div style={{ overflowX: 'auto', height: "45rem" }}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Time Range</th>
                                <th>Session Type</th>
                                <th>Faculty</th>
                                <th>APPLY ON ALL DAYS</th>
                                {daysOfWeek.map((day, index) => (
                                    <th key={index}>{day}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td><Button variant="danger" className="button-icon-boost" onClick={() => removeRow(index)}>X</Button></td>
                                    <td>

                                        <TimePicker.RangePicker
                                            style={{ width: "153px", padding: "10px" }}
                                            format="HH:mm" // Format for hours and minutes
                                            showNow={false} // Optionally disable showing the current time
                                            onChange={(value) => handleChange(index, 'startTime', value)} // Update state on change
                                            value={row.startTime} // Bind the value to the state
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            as="select"
                                            value={row.sessionType}
                                            onChange={(e) => handleChange(index, "sessionType", e.target.value)}
                                            style={{ width: "120px", padding: "13px" }}
                                        >
                                            <option>Person Led</option>
                                            <option>Virtual Led</option>
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <Select

                                            mode="multiple" // Set the mode to multiple for multi-select
                                            options={facultyOptions}
                                            value={row.faculty}
                                            onChange={(selectedOptions) => handleFacultyChange(index, selectedOptions)}
                                            placeholder="Select Faculty"
                                            style={{
                                                minWidth: '150px',
                                                maxWidth: '400px',
                                                margin: "5px"
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check style={{ textAlign: "center" }}
                                            type="checkbox"
                                            checked={row.applyToAll}
                                            onChange={() => handleApplyToAll(index)}
                                        />
                                    </td>
                                    {daysOfWeek.map((day, i) => (
                                        <td key={i}>
                                            <Form.Control
                                                type="text"
                                                placeholder={day}
                                                value={row.days[day] || ''} // Default to empty string if undefined
                                                onChange={(e) => handleChange(index, `days.${day}`, e.target.value)}
                                                style={{ width: "120px" }}
                                            />
                                        </td>
                                    ))}
                                </tr>

                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* Confirmation Modal */}
            <Modal
                title="Confirm Apply to All"
                visible={isOpenModal}
                onOk={handleConfirmApplyToAll}
                onCancel={handleConCloseModal}
            >
                <p>Are you sure you want to apply the last input value to all days?</p>
            </Modal>
            {/* Modal for applying the timetable */}

            <Modal
                title="Apply Time Table Settings"
                visible={nextModalVisible}
                onCancel={() => setNextModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setNextModalVisible(false)}>
                        Close
                    </Button>,
                    <Button key="apply" type="primary" onClick={() => setNextModalVisible(false)}>
                        Apply
                    </Button>,
                ]}
                style={{ padding: "20px" }} // Optional: customize modal padding
            >
                <Select
                    mode="multiple"
                    placeholder="Select Week/Weeks to Apply:"
                    value={selectedWeeks}
                    onChange={handleWeekChange}
                    style={{ width: '100%', marginBottom: '10px', backgroundColor: "#f9f9f9", marginTop: '5px' }}
                >
                    {weekOptions.map(week => (
                        <Option key={week.value} value={week.value}>{week.label}</Option>
                    ))}
                </Select>

                <h5 className="text-center"> OR</h5>
                <DatePicker.RangePicker
                    onChange={handleDateChange}
                    value={dates}
                    format="DD-MM-YYYY"
                    style={{ marginBottom: '20px', width: '100%', marginTop: '5px' }}
                />

                <div style={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                    <Checkbox
                        checked={allDaysChecked1}
                        onChange={(event) => handleSelectAll1(event.target.checked)}
                    />
                    <span style={{ marginLeft: "8px", fontWeight: "bold" }}>All Days</span>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center", width: "30%" }}>
                            <Checkbox
                                checked={selectedDays.includes(day)}
                                onChange={() => handleIndividualCheckboxChange(day)}
                            />
                            <span>{day}</span>
                        </div>
                    ))}
                </div>
            </Modal>
            <Modal
                title="Validation Error"
                visible={showModal}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="close" onClick={handleCloseModal}>
                        Close
                    </Button>,
                ]}
            >
                <p>{validationMessage}</p>
            </Modal>

        </>
    );
};

export default TimeTable;
