import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LitteraRoom1 from './NewLitteraRoom';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';
import { CircularProgress } from '@mui/material';

const LitteraRoomLink = () => {
  const config = useConfig();
  const { roomSessions } = useFeedback();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [setroomSessions] = useState([
    {
        "trainingid": "5f6b76ea-5770-98fa-e58d-43f541c2dfcc",
        "trainingcode": null,
        "training_title": null,
        "ttttt_session_row_no": "1",
        "ttttt_session_id": "797c4d0e-cd69-45b3-a96d-839cf838203c",
        "ttttt_timetableid": "23fa97b0-4828-43c8-b9a2-745f2a60817f",
        "ttttt_facultyid": "75f1cbb7-7a31-4c56-ad00-8ad5fb5b9fb7",
        "ttttt_content_desc": "Tesy",
        "ttttt_session_dt": "2024-11-13",
        "ttttt_session_time": "10:00",
        "ttttt_session_duration": "10",
        "ttttt_session_day": 4,
        "ttttt_is_joint_session": "0",
        "ttttt_session_end_time": "2024-11-13 10:10:00",
        "ttttt_session_no": 0,
        "ttttt_status": "0",
        "tttttf_status": "0",
        "ttttt_remark": "",
        "ttttt_session_week": 1,
        "ttttt_type": 1,
        "ttttt_session_duration_type": 1,
        "ttttt_session_duration_type_name": "Min",
        "ttttt_tag": "Test",
        "ttttt_subject": "Desc",
        "participant_seession_required": "0",
        "facultyname": "9990909099  ",
        "hfacultyname": "9990909099  ",
        "facultyimgpath": null,
        "attendance": "1",
        "session_type_name": "Academic",
        "session_type_icon": "<i class='fa fa-graduation-cap'></i>",
        "meeting": null,
        "noofcompletion": 0,
        "completionpercentage": 0,
        "completiondetail": null,
        "sessionFaculties": [
            {
                "facultyid": "75f1cbb7-7a31-4c56-ad00-8ad5fb5b9fb7",
                "facultyname": "9990909099  ",
                "hfacultyname": "9990909099  ",
                "facultuimg": null
            }
        ],
        "ttttt_complimentory": 1,
        "recurringid": null,
        "sessiondate_time": "0001-01-01 00:00:00",
        "module": 1,
        "modulename": "Module I",
        "completiontype": {
            "id": 1,
            "activities": null
        },
        "displayInfos": [
            {
                "key": "1",
                "name": "Module",
                "value": true
            },
            {
                "key": "2",
                "name": "Week",
                "value": true
            },
            {
                "key": "3",
                "name": "Date",
                "value": true
            },
            {
                "key": "4",
                "name": "Day",
                "value": true
            },
            {
                "key": "5",
                "name": "srno",
                "value": true
            }
        ],
        "actionInfos": null,
        "displayOrder": "1,2,3,4,5",
        "display_txt": "Module I  week - 1 Day - 4 Tesy-Desc"
    },
    {
        "trainingid": "5f6b76ea-5770-98fa-e58d-43f541c2dfcc",
        "trainingcode": null,
        "training_title": null,
        "ttttt_session_row_no": "2",
        "ttttt_session_id": "3440509c-86aa-4859-9890-d68f68ac0746",
        "ttttt_timetableid": "23fa97b0-4828-43c8-b9a2-745f2a60817f",
        "ttttt_facultyid": "75f1cbb7-7a31-4c56-ad00-8ad5fb5b9fb7",
        "ttttt_content_desc": "test",
        "ttttt_session_dt": "2024-11-14",
        "ttttt_session_time": "10:00",
        "ttttt_session_duration": "10",
        "ttttt_session_day": 1,
        "ttttt_is_joint_session": "0",
        "ttttt_session_end_time": "2024-11-14 10:10:00",
        "ttttt_session_no": 0,
        "ttttt_status": "0",
        "tttttf_status": "0",
        "ttttt_remark": "",
        "ttttt_session_week": 1,
        "ttttt_type": 1,
        "ttttt_session_duration_type": 1,
        "ttttt_session_duration_type_name": "Min",
        "ttttt_tag": "Test",
        "ttttt_subject": "desc",
        "participant_seession_required": "0",
        "facultyname": "9990909099  ",
        "hfacultyname": "9990909099  ",
        "facultyimgpath": null,
        "attendance": "",
        "session_type_name": "Academic",
        "session_type_icon": "<i class='fa fa-graduation-cap'></i>",
        "meeting": null,
        "noofcompletion": 0,
        "completionpercentage": 0,
        "completiondetail": null,
        "sessionFaculties": [
            {
                "facultyid": "75f1cbb7-7a31-4c56-ad00-8ad5fb5b9fb7",
                "facultyname": "9990909099  ",
                "hfacultyname": "9990909099  ",
                "facultuimg": null
            }
        ],
        "ttttt_complimentory": 0,
        "recurringid": null,
        "sessiondate_time": "0001-01-01 00:00:00",
        "module": 1,
        "modulename": "Module I",
        "completiontype": {
            "id": 1,
            "activities": null
        },
        "displayInfos": [
            {
                "key": "1",
                "name": "Module",
                "value": true
            },
            {
                "key": "2",
                "name": "Week",
                "value": true
            },
            {
                "key": "3",
                "name": "Date",
                "value": true
            },
            {
                "key": "4",
                "name": "Day",
                "value": true
            },
            {
                "key": "5",
                "name": "srno",
                "value": true
            }
        ],
        "actionInfos": null,
        "displayOrder": "1,2,3,4,5",
        "display_txt": "Module I  week - 1 Day - 1 test-desc"
    }
  ]);
  useEffect(() => {
    const getSessions = async () => {
      if (id) {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };

        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`,
            {
              headers,
              params: {
                pagetype: 1
              }
            });
          setroomSessions(data);
          console.log("setroomSessions",data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getSessions();

  }, [id, config, setroomSessions]);


  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
      </div>
  );
  }

  return (
    <LitteraRoom1 />
  );
};

export default LitteraRoomLink;
