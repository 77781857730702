import React, { useState, useEffect, useContext, useRef } from "react";
import loadConfig from "../../config"; // Assuming your config file is in ../../config
import "./main.css";
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
  Button,
  Divider,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  InputAdornment,
  useTheme,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import RightSidePanel from "./RightSidePanel";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUpload from "../fromscomponent/FileUpload";
import SecondSidePanel from "./SecondSidePanel";
import { NavLink, useNavigate } from "react-router-dom";
import PaginationExample from "./MobileTab";

import { useUser } from "../../UserContext";
import Breadcrumb from "../commonComponent/Breadcrumbs";
import { TranslationContext } from "../../TranslationContext";

const drawerWidth = 240;
var hf_edit_id = "";
const FuncationAnt = () => {
  const { translations } = useContext(TranslationContext);
  const [formData, setFormData] = useState({
    ttcf_name: "",
    ttcf_description: "",
    ttcf_hod_designation_id: "",
    ttcf_uploads: "",
    ttcf_objective: "",
  });
  const user = JSON.parse(localStorage.getItem('user'));
  const fileUploadRef = useRef(null);
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4); // Number of items per page
  const [selectedOption, setSelectedOption] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeListItem, setActiveListItem] = useState(null);
  const [selectitem, Setselectitem] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // State for total records
  const [nameError, setNameError] = useState(false);
  const [hfEditId, setHfEditId] = useState("");
  const [error, setError] = useState('');
  const [filterText, setFilterText] = useState(""); // State for filter text
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [config, setConfig] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedFile, setSelectedFile] = useState(null); // State to hold selected file
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImagePath, setSelectedImagePath] = useState(null);
  const [myData, setMyData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [isVisibleArray, setIsVisibleArray] = useState([]);
  const [descError, setDescError] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (selectedFile) => {
    setFile(selectedFile);
    console.log('Selected file name:', selectedFile.name);
  };

  const handleFileDelete = () => {
    setFile(null);
  };
  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Fetch user and client data
    const fetchUserAndClientData = async () => {
      try {
        // Fetch user data
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);

        // Fetch client data
        const clientData = JSON.parse(localStorage.getItem('clientData'));
        console.log(clientData);

        // Check if both user and clientData exist
        if (user && clientData) {
          // Set loading to false if user and clientData are available
          setLoading(false);
        } else {
          // Simulate loading for 5 seconds
          const timer = setTimeout(() => {
            setLoading(false); // After 5 seconds, set loading to false
            navigate('/login-page'); // Redirect to login page
          }, 2000);

          return () => clearTimeout(timer); // Clean up timer on component unmount
        }
      } catch (error) {
        console.error('Error fetching user and clientData:', error);
      }
    };

    fetchUserAndClientData();
  }, [navigate]);
  // Function to handle file selection from FileUpload component
  // const handleFileChange = (file) => {
  //   setSelectedFile(file);
  // };

  const fetchConfig = async () => {
    try {
      console.log("Loading config data...");
      const configData = await loadConfig();
      console.log("Config data loaded:", configData);
      setConfig(configData);
      console.log("ccc", configData);
      fetchAdditionalData(configData);
      return configData;


    } catch (error) {
      console.error("Error loading config:", error);
    }
  };


  const createdby = "7AB3FE43-ECCC-7C2A-24FA-FFF9EEEE5DD6"
  useEffect(() => {
    const initializeConfig = async () => {
      const configData = await fetchConfig();
      if (configData) {
        setLoading(false);
        fetchData(configData); // Fetch initial data once config is loaded
        fetchAdditionalData(configData);
        // fetchAdditionalDataDMS(configData)
        clearInputs()
        // Fetch additional data once config is loaded
      }
    };
    initializeConfig();
  }, []);


  // Function to handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'ttcf_name') {

    }
  };
  const handleBlur = (event) => {
    const { name } = event.target;
    if (name === 'ttcf_name') {
      const isValidName = validateInputsName();
      setNameError(!isValidName);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: isValidName ? '' : 'Name already exists!',
      }));
    }
  };
  const validateInputsName = () => {
    // Check if ttca_name or ttca_description already exists in sidebar
    const existingName = sidebar.some(
      (item) =>
        item.sidebaritem &&
        typeof item.sidebaritem === 'string' &&
        item.sidebaritem.toLowerCase().trim() === formData.ttcf_name.toLowerCase().trim() &&
        item.itemId !== hfEditId // Exclude the current item being edited
    );

    if (existingName) {
      toast.error("Name already exists!");
      setNameError(true);
      return false; // Return false to indicate validation failed
    } else {
      setNameError(false);
    }

    return true; // Return true if validation passes
  };

  const { selectedYear } = useUser();

  const handleDelete = (itemId) => {
    setUserIdToDelete(itemId);
    setShowModal(true);
  };


  const handleDeleteConfrom = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const employeeid = user.userdetails.agencyid;
      const branchid = user.userdetails.branchid;




      const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
      const docdate = createdon;
      const actiondate = createdon;

      const headers = {
        "Content-Type": "application/json",
        APIKey: config.REACT_APP_API_KEY,
      };

      const payload = {
        tttds_info_desc: null,
        doc_id: userIdToDelete, // Use item.applicationid from myData
        createdon: createdon,
        createdby: employeeid,
        branchid: branchid,
        docremark: "",
        docdate: docdate,
        actiondate: actiondate,
        CreatedBy_empid: employeeid,
        fwd_empid: employeeid,
        tat_type_id: 129,
        doc_status: -1,
        attached_doc: null,
        attached_doc_name: null,
        doctype: 0,
        draftletter: null,
        tttds_is_final: 0,
        tttds_letter_type: 0,
        docremarkenc: null,
        draftletterenc: null,
      };

      const response = await axios.post(`${config.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
        headers: headers,
      });

      console.log("API Response:", response.data);
      toast.success("Deleted successfully!");
      setShowModal(false);
      fetchAdditionalData(config);
      // fetchAdditionalDataDMS(config);
      // Update myData array to remove the sent item


    } catch (error) {
      console.error("Error sending data:", error);
      toast.error(`Error sending data: ${error.message}`);
    }
  };

  const handelClick = (option) => {
    console.log("option", option);

    // Set ID and form data
    setHfEditId(option.itemId);
    setFormData({
      ttcf_name: option.sidebaritem,
      ttcf_description: option.sidebarsubitem,
      ttcf_objective: option.sidebarobject,
      ttcf_code: option.funccode,
      ttcf_hod_designation_id: option.hodid,
      ttcf_uploads: option.upldeImage[0] || "", // Handle the case where upldeImage might be empty
    });

    // Set selected image path and option
    setSelectedImagePath(option.upldeImage[0] || ""); // Handle the case where upldeImage might be empty
    setSelectedOption(option.hodid);

    // Call handleCancelUpload if upldeImage is empty or doesn't contain valid paths
    if (!option.upldeImage || option.upldeImage.length === 0 || !option.upldeImage[0]) {
      handleCancelupload();
    }
  };


  // Function to fetch additional data with pagination
  const fetchAdditionalData = async (config) => {
    console.log("AAA in fun", config)
    // console.log("AAA", config.REACT_APP_API_KEY)
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetFunction`, {
        headers,
      });

      console.log(config.REACT_APP_API_URL)

      // Handle the response data as needed
      if (response && response.data) {
        const { items, totalRecords } = response.data; // Assuming your API response has totalRecords
        const options = items.map((item) => ({
          sidebaritem: item.ttcf_name,
          sidebarsubitem: item.ttcf_description,
          sidebarobject: item.ttcf_objective,
          itemId: item.ttcf_id,
          hodid: item.ttcf_hod_designation_id,
          funccode: item.ttcf_code,
          upldeImage: item.ttcf_uploads
        }));
        console.log("data", options);

        setSidebar(options);
        setTotalRecords(totalRecords); // Update totalRecords state
      }
      console.log("All data", response);
    } catch (error) {
      console.error(null);
    }
  };
  //========fatch dms data =====//
  // const fetchAdditionalDataDMS = async (config) => {

  //   console.log("selectedYear in function",selectedYear)
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const employeeid = user.userdetails.agencyid;
  //   const applicationtypeid = "129";
  //   console.log("before spilt",selectedYear)
  //   const startDate = `${selectedYear.split('-')[0]}-04-01`;
  //   const endDate = `${selectedYear.split('-')[1]}-03-31`;
  //   console.log("after spilt",selectedYear)
  //    console.log("startDate",startDate)
  //   try {
  //     const response = await axios.get(
  //       `${config.REACT_APP_API_URL}/DMSData?fromdate=${startDate}&todate=${endDate}&applicationtypeid=${applicationtypeid}&employeeid=${employeeid}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           APIKey: config.REACT_APP_API_KEY,
  //         },
  //       }
  //     );
  //     const formattedData = response.data.map((item) => ({
  //       ...item,
  //       action: "", // Initialize action field
  //     }));
  //     setMyData(formattedData);
  //     console.log(myData);
  //     console.log("fromdatfun", response)
  //     // handleDeleteConfrom()
  //     setIsVisibleArray(Array(formattedData.length).fill(false)); // Initialize isVisibleArray
  //   } catch (error) {
  //     console.error("Error fetching additional data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchAdditionalDataDMS(config);
  // }, [formattedData]); // Ensure this runs only once on component mount






  // Function to fetch initial data
  const fetchData = async (config) => {
    console.log("bbb")
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetDesignations`, {
        headers,
      });

      if (response && response.data && response.data.items) {
        const options = response.data.items.map((item) => ({
          value: item.agencyid,
          label: item.agencyname,
        }));

        Setselectitem(options);

      }
      console.log("222", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to validate form inputs


  const generateGUID = () => {
    return uuidv4();
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    let isValid = true;
    const errors = {
      name: '',
      description: '',
    };

    if (!formData.ttcf_name.trim()) {
      errors.name = 'Name is required';
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (!formData.ttcf_description.trim()) {
      errors.description = 'Description is required';
      setDescError(true);
      isValid = false;
    } else {
      setDescError(false);
    }

    if (!isValid) {
      setFormErrors(errors);
      return; // Stop submission if validation fails
    }

    const isValidName = validateInputsName(); // Assuming this is a synchronous function
    if (!isValidName) {
      return; // Stop submission if validation fails
    }

    let uniqueFileName = '';
    if (file) {
      const uniqueGUID1 = generateGUID();
      const originalFileName = file.name ? file.name : ''; // Ensure file name is not undefined
      uniqueFileName = `${uniqueGUID1}_${originalFileName}`;

      // Prepare file for upload
      const fileFormData = new FormData();
      fileFormData.append('files', file, uniqueFileName);

      console.log('Unique file name:', uniqueFileName);

      try {
        const uploadUrl = `${config?.LITTERA_CDN_BASE_URL}/Upload/UploadFile?Foldername=training_upload`;
        const response = await fetch(uploadUrl, {
          method: 'POST',
          body: fileFormData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Upload successful:', responseData);

        // Proceed with saving the data, including the file name
        await saveData(uniqueFileName);

      } catch (error) {
        console.error(null);
        saveData(uniqueFileName)
        //alert(`Error: ${error.message}`);
        // Proceed with saving the data even if file upload fails
      }
    } else {
      // No file selected, proceed with saving data
      await saveData(null);
    }
  };

  const saveData = async (fileName) => {
    const ttcf_id = hfEditId || generateGUID();
    const createdon = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const postData = {
      ttcf_id,
      ttcf_name: formData.ttcf_name,
      ttcf_description: formData.ttcf_description,
      ttcf_objective: formData.ttcf_objective,
      ttcf_uploads: fileName ? [fileName] : [''], // Handle null case
      ttcf_hod_designation_id: selectedOption,
      ttcf_branchid: user.userdetails.branchid,
      dms: {
        doc_id: ttcf_id,
        createdon,
        createdby: user.userdetails.agencyid,
        branchid: user.userdetails.branchid,
        docdate: createdon,
        actiondate: createdon,
        createdBy_empid: user.userdetails.agencyid,
        fwd_empid: user.userdetails.agencyid,
        tat_type_id: 129,
        doc_status: 0,
        attached_doc: null,
        attached_doc_name: null,
        doctype: 0,
        draftletter: null,
        tttds_is_final: 0,
        tttds_letter_type: 0,
        docremarkenc: '',
        draftletterenc: null
      },
    };

    const headers = {
      "Content-Type": "application/json",
      APIKey: config.REACT_APP_API_KEY,
    };

    try {
      const saveResponse = await axios.post(
        `${config.REACT_APP_API_URL}/SaveFunction?createdby=${user.userdetails.agencyid}`,
        postData,
        { headers }
      );
      if (!hfEditId) {
        setHfEditId(ttcf_id);
        setFormData(prevData => ({
          ...prevData,
          ttcf_code: saveResponse.data // Update formData with the new ID
        }));
      }
      console.log("Save response:", saveResponse);

      toast.success(
        hfEditId
          ? `Function updated successfully. Your Function code: ${saveResponse.data}`
          : `Function saved successfully. Your Function code: ${saveResponse.data}`
      );

      // Reset form data and state as needed
      // setFormData({
      //   ttcf_name: "",
      //   ttcf_description: "",
      //   ttcf_objective: "",
      // });
      // setSelectedFiles([]);
      // setSelectedOption(null);
      // setHfEditId(null);

      // Fetch updated data
      fetchAdditionalData(config);
      // fetchAdditionalDataDMS(config);
      toggleSidePanel();
    } catch (saveError) {
      toast.error(
        hfEditId
          ? `Error updating item: ${saveError.message}`
          : `Error inserting item: ${saveError.message}`
      );
      console.error('Error saving data:', saveError);
    }
  };




  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  const clearInputs = () => {
    setFormData({
      ttcf_name: "",
      ttcf_description: "",
      ttcf_objective: "",
      ttcf_uploads: "", // You might want to use an array or a different state for uploads
      ttcf_code: "",
    });

    setSelectedOption(null);
    handleCancelupload()
    setHfEditId("");
  };

  const handleCancelimage = () => {
    setSelectedFiles([]); // Clear selected files array
    setSelectedImagePath([]); // Clear selected image paths array
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const applyFilter = () => {
    if (!filterText) {
      return sidebar; // If no filter text, return original sidebar items
    }

    return sidebar.filter((item) =>
      item.sidebaritem.toLowerCase().includes(filterText.toLowerCase()) ||
      item.sidebarsubitem.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const paginatedData = () => {
    // Step 1: Apply the filter to the entire dataset
    const filteredData = applyFilter();

    // Step 2: Calculate pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Step 3: Return paginated data from filtered results
    return filteredData.slice(startIndex, endIndex);
  };

  const handleSearchChange = (event) => {
    setFilterText(event.target.value);
    setCurrentPage(1); // Reset page number when search text changes
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const toggleSidePanel = () => {
    // fetchAdditionalData()
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  useEffect(() => {
    if (hfEditId) {
      setIsEditMode(true); // Editing mode
    } else {
      setIsEditMode(false); // Create mode
    }
  }, [hfEditId]);
  const handleCancelupload = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.cancelUpload(); // Call cancelUpload method on FileUpload component
    }
    console.log('File upload canceled');
  };


  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    ); // Display loading message while waiting for user data
  }

  const paths = [
    { title: 'Home', url: '/' },
    { title: 'Dashboard', url: '/dashboard' },
    {
      title: translations["Organizational Function"] || "Organizational Function",
      url: '/function-panel'
    }
  ];


  return (
    <>
      <div className="box"
        style={{
          width: isMobile ? "100%" : "98%",
          marginLeft: isMobile ? "0" : "0",
        }}
      >
        <Breadcrumb paths={paths} />

        {/* <PaginationExample /> */}
        <Box
          sx={{
            display: "none",
            "@media (max-width: 667px)": { display: "block" },
          }}
        >
          {/* Search Bar and Add Button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "60px",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              style={{ margin: "auto", width: "80%" }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <FilterListIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={filterText}
              onChange={handleSearchChange}
            />

            <Button variant="contained" color="primary" style={{ margin: "auto", width: "80%", marginTop: "15px", marginBottom: "15px" }}>
              Add
            </Button>
          </Box>

          {/* Items List */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto", // Enable vertical scrolling

              px: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                overflowX: "auto", // Enable horizontal scrolling
                pb: 2,
              }}
            >
              {paginatedData().map((option, index) => (
                <Box
                  key={index}
                  onClick={() => handelClick(option)}
                  sx={{
                    minWidth: "250px", // Adjust the card width as needed
                    border: "1px solid #ccc",
                    p: 2,
                    mr: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: option.selected ? "0px 0px 5px 2px rgba(0,0,0,0.2)" : "none",
                    borderColor: option.selected ? "blue" : "#ccc",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">{option.sidebaritem}</Typography>
                    <IconButton >
                      <DeleteIcon onClick={() => handleDelete(option.itemId)} style={{ fontSize: "2.5rem" }} />
                    </IconButton>
                  </Box>
                  <Typography variant="body1">{option.sidebarsubitem}</Typography>
                </Box>
              ))}
            </Box>
            <Pagination
              count={Math.ceil(totalRecords / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ToastContainer />
          <Drawer
            variant="permanent"
            sx={{
              width: isMobile ? "100%" : drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: isMobile ? "100%" : drawerWidth,
                boxSizing: "border-box",
              },
              "@media (max-width:600px)": {
                position: "fixed",
                zIndex: "1100",
                width: "100vw",
              },
            }}
          >
            <Box sx={{ overflow: "auto" }}>
              <List sx={{ display: "flex", flexDirection: "column" }}>
                <ListItem>
                  <TextField
                    label="Search Name/Description"
                    variant="outlined"
                    fullWidth
                    value={filterText}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <FilterListIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </ListItem>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px", width: "92%", fontSize: "15px", backgroundColor: theme.palette.primary.main, }}
                  onClick={() => clearInputs("")}
                >
                  {translations["create_new"] || "Create New"}
                </Button>
                {paginatedData().map((option, index) => (
                  <div
                    key={index}
                    className={`custom-button ${activeListItem === option ? "active-button" : ""
                      }`}
                    onClick={() => handelClick(option)}
                  >
                    <div className="custom-button-content">
                      <div className="custom-button-title">
                        {option.sidebaritem}
                      </div>
                      <div className="custom-button-ttcf_description">
                        {option.sidebarsubitem}
                      </div>
                    </div>
                    <IconButton
                      className="delete-button"
                      onClick={() => handleDelete(option.itemId)}
                    >
                      <DeleteIcon style={{ fontSize: "2.5rem" }} />
                    </IconButton>
                  </div>
                ))}
              </List>
            </Box>
            <Pagination
              count={Math.ceil(totalRecords / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >

            <Box>

              <form onSubmit={handleSubmit}>
                {isEditMode && (
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <TextField
                      label={translations["Function Code"] || "Function Code"}
                      variant="outlined"
                      value={formData.ttcf_code}
                      name="ttcf_code"
                      fullWidth
                      inputProps={{ readOnly: true, maxLength: 500 }}
                      InputLabelProps={{
                        shrink: formData.ttcf_code ? true : false, // Shrink label if value exists
                        style: {
                          fontSize: "15px",
                          color: "#808080", // Optional: Adjust label color
                        },
                      }}
                      InputProps={{
                        style: {
                          fontSize: "15px",
                          color: "black", // Optional: Change text color if needed
                          backgroundColor: "#f0f0f0", // Optional: Change background color
                          cursor: "not-allowed", // Optional: Change cursor style
                          textAlign: 'center', // Center text within the input
                        },
                        readOnly: true,
                      }}
                    />
                  </Box>
                )}
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {formData.ttcf_name.length}/500
                  </Typography>
                  {/* <TextField
                    label={translations["name"] || "Name"}
                    variant="outlined"
                    value={formData.ttcf_name}
                    name="ttcf_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    sx={{ marginBottom: "8px" }}
                    InputLabelProps={{
                      style: { fontSize: "15px" },
                    }}
                    error={nameError}
                    helperText={formErrors.name}
                  /> */}
                  <TextField
                    label={translations["name"] || "Name"}
                    variant="outlined"
                    value={formData.ttcf_name}
                    name="ttcf_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    InputLabelProps={{
                      style: {
                        fontSize: "15px",
                        color: "#808080",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                      },

                    }}
                    error={nameError}
                    helperText={formErrors.name}
                  />
                </Box>

                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {formData.ttcf_description.length}/2000
                  </Typography>
                  <TextField
                    label={translations["description"] || "Description"}
                    variant="outlined"
                    name="ttcf_description"
                    value={formData.ttcf_description}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    InputLabelProps={{
                      style: {
                        fontSize: "15px",
                        color: "#808080",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                      },

                    }}
                    error={descError}
                    helperText={formErrors.description}
                  />
                </Box>

                <Box sx={{ marginTop: "15px" }}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="input"
                      sx={{ fontSize: "14px", color: "#808080" }}
                    >
                      Select Designation
                    </InputLabel>
                    <Select
                      fullWidth
                      defaultValue="Select Designation"
                      name="ttcf_hod_designation_id"
                      label="Select Designation"
                      value={selectedOption || ""}
                      onChange={(e) => setSelectedOption(e.target.value || "")}
                      sx={{ fontSize: "15px" }}
                      MenuProps={{ PaperProps: { sx: { fontSize: "15px" } } }}
                    >
                      <MenuItem value="">Select Designation</MenuItem>
                      {selectitem.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value.toString()}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>

                  <textarea
                    id="ttcf_objective"
                    value={formData.ttcf_objective}
                    name="ttcf_objective"
                    onChange={handleChange}
                    placeholder={translations["objective"] || "Objective"}
                    inputProps={{ maxLength: 2000 }}
                    style={{
                      height: "55px",
                      marginTop: "35px",
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      resize: "vertical",
                    }}
                  />
                  <Typography variant="caption" color="textSecondary">
                    {formData.ttcf_objective.length}/4000
                  </Typography>
                </Box>
                <FileUpload
                  onFileChange={handleFileChange}
                  fileName={file ? file.name : ''}
                  onFileDelete={handleFileDelete}
                  ref={fileUploadRef} // Pass the ref to FileUpload
                  initialFileUrl={formData.ttcf_uploads} // Pass the initial file URL
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mr: 2, fontSize: "16px", backgroundColor: theme.palette.primary.main, }}
                  >
                    {translations["Save"] || "Save"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ fontSize: "16px" }}
                    onClick={() => clearInputs("")}
                  >
                    {translations["cancel"] || "Cancel"}
                  </Button>
                </Box>
              </form>
            </Box>
            <Typography
              variant="h6"
              noWrap
              style={{
                fontSize: "22px",
                marginTop: "20px",
              }}
            >
              {/* Job Position */}
              {translations["Job_Position"] || "Job Position"}
            </Typography>
            <hr
              style={{
                width: "120px",
                height: "3px",
                backgroundColor: "blue",
                border: "2px solid blue",
              }}
            />
            <NavLink to="/job-position">
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "15px", margin: "10px", backgroundColor: theme.palette.primary.main, }}
              >
                {translations["Add_Job_Position"] || "Add Job Position"}
                {/* Add Job Position */}
              </Button>
            </NavLink>
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "15px", margin: "10px" }}
            >
              Accountant
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "15px", margin: "10px" }}
            >
              Sr.Accountant
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "15px", margin: "10px" }}
            >
              Jr.Accountant
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "15px", margin: "10px" }}
            >
              CA
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: "15px", margin: "10px" }}
            >
              HR
            </Button> */}
          </Box>
          <RightSidePanel hfEditId={hfEditId} />


          <SecondSidePanel
            key={myData.length}
            isSidePanelOpen={isSidePanelOpen}
            toggleSidePanel={toggleSidePanel}
            myData={myData}
          // fetchAdditionalDataDMS={fetchAdditionalDataDMS}
          />


        </Box>

        {showModal && (
          <div className="main-modal">
            <div className="main-modal-content">
              <h4> Do you want to delete Function</h4>
              <div
                className="button"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button onClick={handleDeleteConfrom}> yes</Button>
                <Button onClick={handleCancel}>Cancel </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FuncationAnt;
