import React , { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useFeedback } from '../../Context/FeedbackContext';
import axios from 'axios';
import { useConfig } from '../../ConfigContext';
import { Windowopen } from '../../componentHome/utils/Validation';

const Meetings = () => {
  const { selectedSessionId , setLoading , meeting, setmeeting } = useFeedback()
  const { id } = useParams()
  const config = useConfig()
  const user = JSON.parse(localStorage.getItem('user'))

  const getMeetings = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SESSION_MEETINGS`, {
          headers: headers,
          params: {
            sessionid: selectedSessionId,
          }
        });
        setmeeting(data)
        console.log('meetings', data);
        setLoading(false);

      } catch (error) {
        console.log('Error fetching session Comments', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);
    }
  };

  const startCheck = meeting?.ttlm_host_id === user?.userdetails?.agencyid;
  // console.log("startCheck", startCheck); 
  // console.log("startids", meeting[0]?.ttlm_host_id, user?.userdetails?.agencyid);
  const transformedMeetings = meeting?.map(meeting => ({
    ...meeting, // Spread existing meeting properties
    isHostedByUser: meeting.ttlm_host_id === user.userdetails.agencyid 
  }));
  console.log("transformedMeetings" , transformedMeetings)

  useEffect(() => {
    getMeetings()
  }, [selectedSessionId])
  
  function formatDate(datetimeString) {
    // Extract the date part (first 10 characters)
    let dateString = datetimeString.substring(0, 10);

    // Split the date into year, month, and day
    let parts = dateString.split("-");
    let year = parts[0]; // Full year
    let month = parts[1];
    let day = parts[2];

    // Form the desired date format "dd-mm-yyyy"
    let formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  
  const startMeeting = (url)=>{
    Windowopen(url)
  }

  const joinMeeting = (url)=>{
    Windowopen(url)
  }

  return (
    <div className='p-2 max-md:h-fit datadiv flex flex-col items-center w-full border-b-2 gap-2 '>
      <h4 className='uppercase my-4 text-2xl'>Meetings</h4>

      <div className='w-full max-md:h-fit items-center overflow-y-auto flex flex-col gap-2 commentswrapper border-2 p-2'>

      {
        meeting?.length > 0 ?
          <div className='w-full flex justify-center'>
              {
                  meeting?.map((item, index) => (
                    <p
                      className='w-full text-2xl px-2 pt-1 pb-2 border-2 flex-shrink-0 flex flex-col justify-center ' 
                      key={index}
                    >
                    <p 
                      className='notesfont text-3xl '
                    >
                        {item.ttlm_title}
                    </p>
                    
                    <p 
                      className='notesfont text-2xl '
                    >
                        {item.ttlm_agenda}
                    </p>
                    
                    <p 
                      className='notesfont text-xl '
                    >
                        {formatDate(item.ttlm_date)} {item.ttlm_time}
                    </p>

                    {
                      item?.ttlm_host_id === user?.userdetails?.agencyid ?
                      <span 
                        onClick={()=>joinMeeting(item.ttlm_z_join_link)} 
                        className='bg-[#007bff] w-fit px-6 py-2 text-white rounded-md'
                      >
                        Start
                      </span>
                      :
                      <span 
                        onClick={()=>startMeeting(item.ttlm_z_start_link)} 
                        className='bg-[#007bff] w-fit px-6 py-2 text-white rounded-md'>
                        Join
                      </span>
                    }

                    
                    </p>
                  ))
              }

          </div>
        :
        <h1 className='capitalize text-xl'>No Meetings...</h1>          
      }
      </div>
  </div>
  )
}

export default Meetings