// App.js

import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import logo from "./logo.svg";
import 'animate.css'
import "./App.css";
import "./css/morris.css";
import "./css/select2.css";
import "./css/style.default.css";
import "./css/newstyle.css";
// import "./css/bootstrap.min.css";
//import "./css/bootstrap-override.css";
import "./css/weather-icons.min.css";
import "./css/jquery-ui-1.10.3.css";
import "./css/font-awesome.min.css";
import "./css/animate.min.css";
import "./css/animate.delay.css";
import "./css/toggles.css";
import "./css/pace.css";
import "../src/componentHome/header/header.css";
import "../src/componentHome/home/about.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import FuncationAntPage from "./pages/FuncationAntPage"
import DashboardPage from "./pages/DasboardPage";
import HomePage from "./pages/HomePage";
import AddPositionSidebarPage from "./pages/AddPositionSideberPage";
import LoginPage from "./componentHome/login&ragister/LoginPage";
import DasboardPage from "./pages/DasboardPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./ThemeProvider";
import Litteraroom from "./pages/Litteraroom";
import JobPositionAntPage from "./pages/JobPositionAntPage";
import JobRollAntPage from "./pages/JobRollAntPage";
import AddActivitesPage from "./pages/AddActivitesPage";
import LoginPost from "./componentHome/login&ragister/LoginPost";
import ImageUpload from "./components/navbar/Test";

import FeedbackPage from "./pages/FeedbackPage";
import TestListPage from "./pages/TestListPage";
import AutoLogout from "./AutoLogout";
import SessionCalender from "./components/Content/Calender/SessionCalender";
import TestSessionCalendar from "./pages/TestSessionCalendar";
import MeetingListPage from "./pages/MeetingListPage";
import RedirectPage from "./componentHome/meeting/UrlCopyLink";
import SelfTestPage from "./pages/SelfTestPage";
import OnlineTest from "./componentHome/self-test/ChatUi";
import StartTest from "./componentHome/self-test/StartTest";
import TestSubmit from "./componentHome/self-test/TestSubmit";
import TestNavbar from "./components/teastpage/TestNavbar";
import Layout from "./Layout";
import LoginPage2 from "./componentHome/login&ragister/LoginPage2";

import TimeTable from "./componentHome/utils/TimeTable";
import SessionDetails from "./pages/SessionDetails";
import TrainingDetails from "./pages/TrainingDetails";
//import TrainingList from "./componentHome/tranning/TrainingList";
// import TestAnalytics from "./componentHome/test-analytics/TestAnalytics";
import TestAnaliticMain from "./componentHome/test-analytics/TestAnaliticMain";
import TestAnalytics from "./componentHome/test-analytics/TestAnalytics";



// import TestAnalytics from "./componentHome/test-analytics/TestAnalytics";
// import TestAnaliticMain from "./componentHome/test-analytics/TestAnaliticMain";
import TrainingList from "./componentHome/tranning/TrainingList";
import TestResult from "./pages/TestResult";
import AssignmentList from "./componentHome/assignment/AssignmentList";
import AssignmentDetails from "./componentHome/assignment/AssignmentDetails";
import TrainingProgress from "./pages/TrainingProgress";
import OpenLink from "./componentHome/assignment/OpenLink";
import LitteraRoomLink from "./componentHome/assignment/LitteraRoomLink";
import UserProfile from "./pages/UserProfile";
import PrintData from "./pages/PrintData";
//import TrainingList from "./componentHome/tranning/TrainingList";
import { initGA, logPageView } from './Analytics';

const App = () => {

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user) {
        user == [] ? console.log(user) : console.log("Empty user")
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            setProfile(res.data);
            console.log("data assigned");
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  const logOut = () => {
    googleLogout();
    setProfile([]);
  };
  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(); // Log the first page view on app load

    // Use React Router to track page views on route change
    const handleRouteChange = () => {
      logPageView(); // Log each page view on route change
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);
  return (
    <Router>
      <AutoLogout>
        <ThemeProvider>
          {/* <Breadcrumb /> */}


          
          <ToastContainer />
          <Layout>
            <Routes>
              <Route path="/" element={<LoginPage2 profileDetails={profile} login={login} logout={logOut} />} />
              {/* <Route path="/" element={<HomePage />} /> */}
              <Route path="/home" element={<DashboardPage />} />
              <Route path="/login" element={<LoginPost />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/assignment-detail/:id" element={<AssignmentDetails />} />
              <Route
                path="/funcation-panel/add-position-sidebar"
                element={<AddPositionSidebarPage />}
              />

              {/* <Route path="/login-page" element={<LoginPage profileDetails={profile} login={login} logout={logOut} />} /> */}
              <Route path="/link-online/:id" element={<LitteraRoomLink />} />
              <Route path="/function-panel" element={<FuncationAntPage />} />
              <Route path="/training-list" element={<TrainingList />} />
              <Route path="/training-progress-report/:id" element={<TrainingProgress />} />
              <Route path="/job-position" element={<JobPositionAntPage />} />
              <Route path="/job-role" element={<JobRollAntPage />} />
              <Route path="/dashboard" element={<DasboardPage />} />
              <Route path="/job-activity" element={<AddActivitesPage />} />
              <Route path="/test-list" element={<TestListPage />} />
              <Route path="/test-result" element={<TestResult />} />
              <Route path="/meeting-list" element={<MeetingListPage />} />
              <Route path="/Litteraroom" element={<Litteraroom />} />
              <Route path="/Feedback/Feedback" element={<FeedbackPage />} />
              <Route path="/upload" element={<ImageUpload />} />
              <Route path="/redarict/:shortUrl" element={<RedirectPage />} />
              <Route path="/redarict" element={<RedirectPage />} />
              {/* <Route path="/LMS_Transaction/frm_Session_Calendar.aspx" element={<TestSessionCalendar />} /> */}
              <Route path="/Session_Calendar" element={<TestSessionCalendar />} />
              <Route path="/Litteraroom/session/:id" element={<Litteraroom />} />
              <Route path="/Session_trg/SessionDetail/:id" element={<SessionDetails />} />
              <Route path="/Training/Training_details/:id" element={<TrainingDetails />} />
              <Route path="/selftest-page" element={<SelfTestPage />} />
              <Route path="/online-test-page" element={<OnlineTest />} />
              <Route path="/start-test" element={<StartTest />} />
              <Route path="/submit-test" element={<TestSubmit />} />
              <Route path="/time-table" element={<TimeTable />} />
              <Route path="/Eval/TestAnalytics" element={<TestAnalytics />} />
              <Route path="/Eval/Trg_TestAnalytics" element={<TestAnaliticMain />} />
              {/* <Route path="/Eval/TestAnalytics" element={<TestAnalytics/>} /> */}

              <Route path="/Eval/TestAnalytics" element={<TestAnalytics />} />
              <Route path="/Eval/Trg_TestAnalytics" element={<TestAnaliticMain />} />
              <Route path="/Assignment/AssignmentView" element={<AssignmentList />} />
              <Route path="/printdata" element={<PrintData />} />
              {/* <Route path="/Eval/TestAnalytics" element={<TestAnalytics/>} /> */}
            </Routes>
          </Layout>
        </ThemeProvider>
      </AutoLogout>
    </Router>

  );
};

export default App;
