// import moment from 'moment'
// import React from 'react'

// const CustomDayCell = ({ value , children}) => {
//   const dateStr = moment(value).format('YYYY-MM-DD')
//   return (
//     <div className='rbc-day-bg' date-date={dateStr}>
//         {children}
//     </div>
//   )
// }

// export default CustomDayCell

import moment from 'moment'
import React from 'react'

const CustomDayCell = ({ value, children }) => {
  const dateStr = moment(value).format('YYYY-MM-DD');
  const todayStr = moment().format('YYYY-MM-DD');
  const isToday = dateStr === todayStr;

  return (
    <div className={`rbc-day-bg ${isToday ? 'rbc-today' : ''}`} date-date={dateStr}>
      {children}
    </div>
  )
}

export default CustomDayCell;
