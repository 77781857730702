import { Mail, Phone, PhotoCamera } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { GiCalendar, GiRotaryPhone, GiSmartphone } from 'react-icons/gi'
import { FaCalendar, FaCity, FaCreditCard, FaLocationArrow, FaLocationDot, FaPhone, FaUser } from 'react-icons/fa6'
import { BsCalendar3 } from 'react-icons/bs'
import { LiaUniversitySolid } from 'react-icons/lia'
import { FaAdjust } from 'react-icons/fa'
import { useTheme } from '../../../ThemeProvider'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaEye, FaTimes } from 'react-icons/fa'; // Eye and Close icons
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import SidePanel from './SidePanel'
import EditPage from './EditPage'
import StaticPage from './StaticPage'

const Userprofile = () => {
    const { theme } = useTheme()
    const { selectedUserType } = useUser()
    const [uploading, setUploading] = useState(false);
    const { userProfile, setuserProfile ,  showeditpage, setshoweditpage , uploadingProfile , imageFormData  } = useFeedback()
    const config = useConfig()
    const user = JSON.parse(localStorage.getItem('user'));
    const [salutation, setsalutation] = useState(null)

    const getsalutation = async () => {

        const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        }
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/salutation`, {
              headers: headers,
          });
          
          return data
        } catch (error) {
          console.log('Error session type', error);
        }
    }

    useEffect(() => {
        const fetchdata = async ()=>{
            const data = await getsalutation()
            setsalutation(data)
        }
        fetchdata()
    }, [])

    const getUserProfileData = async () => {

        const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        }
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Agency`, {
              headers: headers,
              params: {
                agencyid:user?.userdetails?.agencyid
              }
          });
          
          return data
        } catch (error) {
          console.log('Error session type', error);
        }
    }

    useEffect(() => {
        const fetchdata = async ()=>{
            const data = await getUserProfileData()
            setuserProfile(data)
        }
        fetchdata()
    }, [selectedUserType])

    useEffect(() => {
        setshoweditpage(false)
    }, [])
    
    return (
    <div className='w-full max-lg:flex-col flex gap-6'>
        <SidePanel/>
        <div className='h-fit w-full  border-2 rounded-xl '>
            <h4 className='px-10 py-10 border-b-2 max-sm:px-8 m-0 w-full'> 
                {   
                    showeditpage ? 
                    "Edit Details"
                    :
                    'Personal Detail'
                }
            </h4>
            <div className='bg-white'>
                {   
                    showeditpage ?
                    <EditPage salutation={salutation}/>
                    :
                    <StaticPage/>
                }
            </div>
        </div>
    </div>
    )
}

export default Userprofile