import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import styled from 'styled-components';
import { useConfig } from '../../ConfigContext';
import { Input, Select, Modal } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useUser } from '../../UserContext';
import { Visibility } from '@mui/icons-material';
import { UploadFile } from '@mui/icons-material'; // MUI File Upload Icon
import { IconButton, Tooltip } from '@mui/material';
import {
  AllOut as AllIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Pause as PauseIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import UploadModal from './DownloadModal';
import CommentsModal from './CommentsModal';
import UploadAssignment from './UploadAssignment';
import { TranslationContext } from '../../TranslationContext';
const { Option } = Select;

// StyledTableCell for table
const StyledTableCell = styled(TableCell)`
    border-left: 1px solid #ccc;
    text-align: center;
`;

// Card styles
const cardStyle = {
  marginBottom: '16px',
};

// Main component
const AssignmentList = () => {
  const { translations } = useContext(TranslationContext);

  const [modalType, setModalType] = useState(null);
  const { selectedUserType } = useUser();
  const { selectedYear } = useUser();
  const config = useConfig();
  const [feedbackData, setFeedbackData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [totalItems, setTotalItems] = useState(0); // Total items
  const [searchQuery, setSearchQuery] = useState(); // State for search query
  const [valuationData, setValuationData] = useState({}); // Store valuation data for each assignment
  const [searchCriteria, setSearchCriteria] = useState('trainingcode'); // State for selected search criteria
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState(null); // New state for participantId
  const [updatedId, setUpdatedId] = useState(null); // New state for participantId
  const [trainingId, setTrainingId] = useState(null); // New state
  const [activeAssignment, setActiveAssignment] = useState([]); // Initialize as an empty array
  const [selectedStatus, setSelectedStatus] = useState(null); // Status filter state
  const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust as needed
  const [sessionId, setSessionId] = useState(null); //
  const navigate = useNavigate()
  const [modalContent, setModalContent] = useState('');
  const paths = [
    { title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' },
    { title: translations["Assessment_list"] || 'Assignment List', url: '/Feedback/Feedback' }
  ];
  const modalRef = useRef(null);
  useEffect(() => {
    if (isModalVisible1 && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isModalVisible1]); // This will run whenever the modal visibility changes

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'));
  const startDate = `${selectedYear.split('-')[0]}-04-01`;
  const endDate = `${selectedYear.split('-')[1]}-03-31`;
  useEffect(() => {
    const fetchData = async () => {
      const url = `${config.REACT_APP_API_URL}/Assignment?usertype=${selectedUserType}&userid=${user.userdetails.agencyid}&startdate=${startDate}&enddate=${endDate}&PageNumber=${currentPage}&PageSize=${pageSize}`;

      let payload = null;

      if (searchQuery && searchQuery.trim() !== "") {
        payload = {
          searchCriteria: [
            {
              column: searchCriteria,
              value: searchQuery,
              condition: '=',
              nextoperator: 'Or',
            },
          ],
        };
      }

      if (!searchQuery && searchCriteria?.length > 0) {
        payload = {
          ...payload,
          searchCriteria: [...searchCriteria],
        };
      }

      try {
        const response = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'APIKey': config.REACT_APP_API_KEY,
          },
        });



        // Update the state with the sorted data
        setFeedbackData(response.data.items);
        setTotalItems(response.data.totalRecords);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };


    fetchData();
  }, [selectedYear, currentPage, pageSize, searchQuery, searchCriteria, selectedUserType, itemsPerPage, isModalVisible]);
  const fetchDataDirectly = async (status) => {
    setSelectedStatus(status); // Update the selected status

    // Reset searchCriteria for 'All'
    if (status === '') {
      setSearchCriteria(null);  // Reset to default or no criteria
    } else {
      setSearchCriteria([
        {
          column: 'status',  // Column name for filtering (you can modify this based on your API)
          value: status.toString(),
          condition: '=',  // Equality condition
          nextoperator: 'or',  // Or operator (if you want to chain conditions)
        }
      ]);
    }

    setCurrentPage(1); // Reset to the first page on filter change

    const url = `${config.REACT_APP_API_URL}/Assignment?usertype=${selectedUserType}&userid=${user.userdetails.agencyid}&startdate=${startDate}&enddate=${endDate}&PageNumber=${currentPage}&PageSize=${pageSize}`;

    let payload = { searchCriteria: searchCriteria || [] };

    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'APIKey': config.REACT_APP_API_KEY,
        },
      });

      // Set the data based on the API response
      setFeedbackData(response.data.items);
      setTotalItems(response.data.totalRecords);
      setTotalPages(response.data.totalPages);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  // Ensure these dependencies are tracked
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  console.log('Loading')

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleModalOk = () => {
    // Close the modal
    setIsModalVisible1(false);

  };
  const handleModalCancel = () => {
    setIsModalVisible1(false);
  };
  const handleClick = async (assignmentID, status) => {
    // Initialize activeAssignments if null or undefined
    setActiveAssignment(prevState => {
      if (!Array.isArray(prevState)) {
        return [assignmentID]; // Initialize it as an array if it's not already
      }

      if (!prevState.includes(assignmentID)) {
        return [...prevState, assignmentID]; // Add to the active list
      }
      return prevState; // Do nothing if the assignment is already active
    });

    // If status is '0', set valuation to 0 immediately
    if (status == '0') {
      setValuationData(prevState => ({
        ...prevState,
        [assignmentID]: 0, // Directly set valuation to 0 if the status is 0
      }));
    } else {
      const url = `${config.REACT_APP_API_URL}/Get_Assignment_Valuation?assignmentid=${assignmentID}&participantid=${user.userdetails.agencyid}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'APIKey': config.REACT_APP_API_KEY,
          },
        });

        setValuationData(prevState => ({
          ...prevState,
          [assignmentID]: response.data.status == 1 ? response.data.marks[0]?.valuation : 0,
        }));
      } catch (error) {
        console.error('Error fetching valuation data:', error);
      }
    }
  };


  const openModal = async (assignmentID, uploadID, trainingID, sessionID, modalType) => {
    // Step 1: Check session restriction
    const userid = user.userdetails.agencyid;
    try {
      const checkSessionResponse = await axios.get(
        `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
        {
          params: {
            usertype: selectedUserType,
            userid: userid,
            sessionid: sessionID,
            trainingid: trainingID,
          },
          headers: {
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );

      // If session restriction is true, show the modal with the restriction message
      if (checkSessionResponse.data === true) {
        // Display the restriction message modal
        showModal1(translations["current_session_before"] || "You cannot start the current session before completing the previous sessions.");
        return; // Prevent opening the upload modal
      }

      // If no session restriction, proceed with the normal modal opening logic
      if (modalType === 'download') {
        setSelectedParticipantId(assignmentID);
        setModalType('download');
        setIsModalVisible(true);
      } else if (modalType === 'comment') {
        setSelectedParticipantId(assignmentID);
        setTrainingId(trainingID);
        setModalType('comment');
        setIsModalVisible(true);
      } else if (modalType === 'upload') {
        setSelectedParticipantId(assignmentID);
        setUpdatedId(uploadID);
        setTrainingId(trainingID);
        setSessionId(sessionID);
        setModalType('upload');
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error("Error checking session restriction:", error);
      showModal1(translations["session_check_error"] || "Error checking session restriction.");
    }
  };

  const showModal1 = (message) => {
    setModalContent(message);  // Set the content of the modal
    setIsModalVisible1(true);   // Show the modal
  };

  // Close the modal
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedParticipantId(null); // Clear the participantId when modal closes
    setUpdatedId(null); // Clear the
    setTrainingId(null)
    setSessionId(null)
  };

  const FeedbackCard = ({ feedback, index }) => {
    const rowStyle = feedback.taau_status === "0" ? "draftclass" :
      feedback.taau_status === "1" ? "redclass" :
        ""; // Default to empty string if taau_status is undefined

    return (
      <Card style={cardStyle} key={index} className={rowStyle}>
        <CardContent>
          <Typography variant="h6">{translations["Code"] || "Code"}: {feedback.trainingCode}</Typography>
          <Typography variant="body1">{translations["Assignment_name"] || "Assignment Name"}: {feedback.assignmentName}</Typography>
          <Typography variant="body1">{translations["Faculty"] || "Faculty"}: {feedback.facultyname}</Typography>
          <Typography variant="body1">{translations["Type"] || "Type"}: {feedback.assignmentTypeName}</Typography>
          <Typography variant="body1">{translations["Tag"] || "Tag"}: {feedback.tag}</Typography>
          <Typography variant="body1">Start Date(Duration):{moment(feedback.ttttt_session_dt).format('DD/MM/YYYY HH:mm')}</Typography>
          <Typography variant="body1">View : <Visibility style={{ cursor: 'pointer', fontSize: '24px' }} onClick={() => handelDetailsView(feedback.assignmentID)} /></Typography>
          <Typography variant="body1">
            {translations["Marks"] || "Marks"}: {feedback.status == '4' ? (
              activeAssignment.includes(feedback.assignmentID) ? (
                // If this assignment is active, show the valuation
                <span>{valuationData[feedback.assignmentID] !== undefined ? valuationData[feedback.assignmentID] : 0}</span>
              ) : (
                // If this assignment is not clicked, show the icon
                <i
                  className="glyphicon glyphicon-saved" style={{ fontSize: "16px" }}
                  onClick={() => handleClick(feedback.assignmentID, feedback.status)} // Pass the status here as well
                />
              )
            ) : (
              <span>-</span>
            )}
          </Typography>

          <div style={{ marginTop: '10px' }}>
            <Typography variant="body1">
              <strong>{translations["Status"] || "Status"}: </strong>
              {feedback.status == '0' && <i className="fa fa-lock" style={{ color: 'orange' }} title="Not started" />}
              {feedback.status == '1' && <i className="fa fa-unlock-alt" style={{ color: 'green' }} title="Open" />}
              {feedback.status == '2' && <i className="fa fa-pause" style={{ color: 'orange' }} title="Pause" />}
              {feedback.status == '3' && <i className="fa fa-lock" style={{ color: 'green' }} title="Close" />}
              {feedback.status == '-1' && <CancelIcon className="redcancel" style={{ color: "red" }} />}
              {feedback.status == '4' && <i className="fa fa-check" style={{ color: 'green' }} title="Approved" />}
            </Typography>
            <div style={{ marginTop: '10px' }}>
              {feedback.status == '1' || feedback.status == '4' || feedback.isOpenended == 1 ? (
                <>
                  {/* Download icon */}
                  <span
                    className="fa fa-download"
                    title="Download Attachment"
                    style={{ margin: "3px", fontSize: "14px", cursor: "pointer" }}
                    onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'download')}
                  ></span>

                  {/* Comment icon */}
                  <span
                    className="fa fa-comments-o"
                    title="Comment"
                    style={{ margin: "8px", color: 'chocolate', fontSize: "14px", cursor: "pointer" }}
                    onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'comment')}
                  ></span>

                  {/* Conditionally render the upload icon based on taau_status */}
                  {feedback.taau_status !== "1" && (
                    <span
                      className="fa fa-upload"
                      title="Upload Attachment"
                      style={{ margin: "3px", fontSize: "14px", cursor: "pointer" }}
                      onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'upload')}
                    ></span>
                  )}
                </>
              ) : (
                <span>-</span>
              )}
            </div>


          </div>
        </CardContent>
      </Card>
    )
  };
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSearchCriteriaChange = (value) => {
    setSearchCriteria(value);
    setCurrentPage(1); // Reset to first page on criteria change
  };

  const handelDetailsView = (assignmentID) => {

    navigate(`/assignment-detail/${assignmentID}`)

  }

  if (isLoading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
  );;
  if (error) return <p>Error: {error.message}</p>;
  const handleFilter = (status) => {
    // Set the selected status to filter assignments
    setSelectedStatus(status);

    if (status === ' ') {
      // Reset search criteria when "All" filter is clicked
      setSearchCriteria(null);  // Reset to default search criteria (or any other default state)
    } else {
      // Modify the search criteria to reflect the status filter
      const updatedSearchCriteria = [
        {
          column: 'status',   // Column name for filtering (assuming 'trainingStatus' is used in your API)
          value: status.toString(),   // Ensure the value is a string
          condition: '=',             // Equal condition for filtering
          nextoperator: 'or',         // Use "or" to combine multiple conditions
        }
      ];

      // Apply the updated filter
      setSearchCriteria(updatedSearchCriteria);
    }

    setCurrentPage(1);  // Reset to the first page when a new filter is applied
  };



  return (
    <>
      <Breadcrumb paths={paths} />
      <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>
        <Input
          const placeholder={translations["Search"] || "Search"}
          style={{ width: '80%', padding: '8px' }}
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
        <Select
          placeholder="Select Search Criteria" className='selectsearch'
          style={{ height: '40px', maxWidth: "400px", width: '30%' }}
          bordered
          value={searchCriteria}
          onChange={handleSearchCriteriaChange}
        >
          <Option value="trainingcode">
            {translations["Training_code"] || "Training Code"}
          </Option>
          <Option value="assignmentName">

            {translations["Assignment_name"] || "Assignment Name"}
          </Option>
          {/* <Option value="status_txt">
            Feedback Title
          </Option> */}
          {/* Add more Option elements here */}
        </Select>

      </div>
      <div style={{ width: "100%", textAlign: "end" }}>
        <Tooltip title="All">
          <IconButton
            onClick={() => fetchDataDirectly('')}  // Directly trigger data fetch for 'All'
            style={{ color: selectedStatus === '' ? '#428bca' : '#428bca' }}
          >
            <span className="fa fa-certificate" title="All" style={{ margin: "3px", fontSize: "2rem" }}></span>
          </IconButton>
        </Tooltip>

        <Tooltip title="Not started">
          <IconButton
            onClick={() => handleFilter('0')}
            style={{ color: selectedStatus === '0' ? 'orange' : 'orange' }}
          >
            <LockIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Close">
          <IconButton
            onClick={() => handleFilter('3')}
            style={{ color: selectedStatus === '3' ? 'green' : 'green' }}
          >
            <LockIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Open">
          <IconButton
            onClick={() => handleFilter('1')}
            style={{ color: selectedStatus === '1' ? 'green' : 'green' }}
          >
            <LockOpenIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Pause">
          <IconButton
            onClick={() => handleFilter('2')}
            style={{ color: selectedStatus === '2' ? 'orange' : 'orange' }}
          >
            <PauseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Cancel">
          <IconButton
            onClick={() => handleFilter('-1')}
            style={{ color: selectedStatus === '-1' ? 'red' : 'red' }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Mark allotted">
          <IconButton
            onClick={() => handleFilter('4')}
            style={{ color: selectedStatus === '4' ? 'green' : 'green' }}
          >
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>

      </div>
      {isMobileView ? (
        <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
          {feedbackData.map((feedback, index) => (
            <FeedbackCard key={feedback.trainingid} feedback={feedback} index={index} />
          ))}
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
              <TableRow>
                <StyledTableCell className='feedbck-titel'>{translations["Code"] || "Code"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>{translations["Assignment_name"] || "Assignment Name"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>{translations["Faculty"] || "Faculty"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'> {translations["Type"] || "Type"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>{translations["Tag"] || "Tag"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>Start Date (Duration)</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>
                  <Visibility style={{ cursor: 'pointer', fontSize: '24px' }} />
                </StyledTableCell>
                <StyledTableCell className='feedbck-titel'>{translations["Marks"] || "Marks"}</StyledTableCell>
                <StyledTableCell className='feedbck-titel'>
                  <UploadFile style={{ cursor: 'pointer', fontSize: '24px' }} />
                </StyledTableCell>
                <StyledTableCell className='feedbck-titel'> {translations["Status"] || "Status"}</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {feedbackData && feedbackData.map((feedback, index) => {

                // Determine the class name based on taau_status
                // If taau_status is blank or not defined, don't apply any specific class
                const rowStyle = feedback.taau_status === "0" ? "draftclass" :
                  feedback.taau_status === "1" ? "redclass" :
                    ""; // Empty string if taau_status is blank or undefined

                return (
                  <TableRow key={index} className={`feedbck-titel ${rowStyle}`}>
                    <StyledTableCell className="feedback-row">{feedback.trainingCode}</StyledTableCell>
                    <StyledTableCell className="feedback-row">{feedback.assignmentName}</StyledTableCell>
                    <StyledTableCell className="feedback-row">{feedback.facultyname}</StyledTableCell>
                    <StyledTableCell className="feedback-row">{feedback.assignmentTypeName}</StyledTableCell>
                    <StyledTableCell className="feedback-row">{feedback.tag}</StyledTableCell>
                    <StyledTableCell className="feedback-row">{moment(feedback.ttttt_session_dt).format('DD/MM/YYYY HH:mm')}</StyledTableCell>
                    <StyledTableCell className="feedback-row">
                      <Visibility
                        className="feedbckicin"
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        onClick={() => handelDetailsView(feedback.assignmentID)}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      {feedback.status == '4' ? (
                        activeAssignment.includes(feedback.assignmentID) ? (
                          <span style={{ fontSize: "15px" }}>
                            {valuationData[feedback.assignmentID] !== undefined ? valuationData[feedback.assignmentID] : 0}
                          </span>
                        ) : (
                          <i
                            className="glyphicon glyphicon-saved" style={{ fontSize: "16px" }}
                            onClick={() => handleClick(feedback.assignmentID, feedback.status)} // Pass the status here as well
                          />
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      {feedback.status === 1 || feedback.status === 4 || feedback.isOpenended === 1 ? (
                        <>
                          <span className="fa fa-download" title="Download Attachment" style={{ margin: "3px", fontSize: "14px" }} onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'download')}></span>
                          <span className="fa fa-comments-o" title="Comment" style={{ color: 'chocolate', fontSize: "14px" }} onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'comment')}></span>
                          {feedback.taau_status !== "1" && (
                            <span className="fa fa-upload" title="Upload Attachment" style={{ margin: "3px", fontSize: "14px" }} onClick={() => openModal(feedback.assignmentID, feedback.taau_uploadid, feedback.trainingid, feedback.ttttt_session_id, 'upload')}></span>
                          )}
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </StyledTableCell>

                    <StyledTableCell className="feedback-row">
                      {feedback.status === 0 ? (
                        <i className="fa fa-lock" style={{ color: 'orange' }} title="Not started"></i>
                      ) : feedback.status === 1 ? (
                        <i className="fa fa-unlock-alt" style={{ color: 'green' }} title="Open"></i>
                      ) : feedback.status === 2 ? (
                        <i className="fa fa-pause" style={{ color: 'orange' }} title="Pause"></i>
                      ) : feedback.status === 3 ? (
                        <i className="fa fa-lock" style={{ color: 'green' }} title="Close"></i>
                      ) : feedback.status === -1 ? (
                        <CancelIcon className='redcancel' style={{ color: "red" }} />
                      ) : feedback.status === 4 ? (
                        <i className="fa fa-check" style={{ color: 'green' }} title="Approved"></i>
                      ) : (
                        <span>-</span>
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>

          </Table>
        </TableContainer>

      )}
      {isModalVisible && modalType === 'download' && (
        <UploadModal
          participantId={selectedParticipantId} // Pass the participantId to the modal
          thistrainings={trainingId}
          closeModal={closeModal} // Pass closeModal function to modal
        />
      )}

      {isModalVisible && modalType === 'comment' && (
        <CommentsModal
          participantId={selectedParticipantId} // Pass the participantId to the modal
          thistrainings={trainingId} // Pass the trainingId to the modal
          closeModal={closeModal} // Pass closeModal function to modal
        />
      )}
      {isModalVisible && modalType === 'upload' && (
        <UploadAssignment
          participantId={selectedParticipantId} // Pass the participantId to the modal
          uploaded={updatedId}
          thistrainings={trainingId} // Pass the trainingId to the modal
          sessionid={sessionId}
          closeModal={closeModal} // Pass closeModal function to modal
        />
      )}
      <div style={{ textAlign: 'end', margin: '20px 10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          {translations["Previous"] || "Previous"}
        </Button>
        <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages || totalItems <= 10}
        >
          {translations["Next"] || "Next"}
        </Button>
      </div>
      <Modal
        visible={isModalVisible1}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="OK"
        ref={modalRef} // Modal reference
       
      >
        <p>{modalContent}</p>
        {/* Input field with its own ref */}
        <input
          type="text"
          ref={modalRef} // Reference for the input field
          placeholder="Type something..."
          style={{ display: "none", marginTop: "20px", width: "100%" }}
        />
      </Modal>

    </>
  );
};

export default AssignmentList;


