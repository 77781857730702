import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTheme } from '../../../ThemeProvider'
import axios from 'axios';
import { useConfig } from '../../../ConfigContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TranslationContext } from '../../../TranslationContext';
import { LuActivity } from 'react-icons/lu';
import { useUser } from '../../../UserContext';
import { useFeedback } from '../../../Context/FeedbackContext';
import * as XLSX from 'xlsx';
import printData from '../../../componentHome/commonComponent/Print'
import { IoFlask, IoFolder, IoRocketSharp, IoTrash } from 'react-icons/io5';
import { FaBook , FaEdit, FaHeadphones, FaPrint, FaRegPlayCircle, FaSuitcase, FaUser } from 'react-icons/fa';
import { RiFileExcel2Fill, RiProgress1Line, RiWhatsappFill } from 'react-icons/ri';
import { MdScreenShare, MdFeedback } from 'react-icons/md';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { List, ListItem, ListItemText, Collapse, ListItemSecondaryAction, IconButton, ListItemAvatar, Avatar, Box, CircularProgress, Typography, useMediaQuery, Tooltip} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FaCirclePlay, FaRegCircleCheck } from 'react-icons/fa6';
import TwoMonthCalendar from '../Session/TwoMonthCalendar';
import CreateSessionFromCalendar from '../Calender/CreateSessionFromCalendar';
import { toast } from 'react-toastify';

const HTMLParser = ({ iconname }) => {
    console.log("iconname" , iconname )
    const createMarkup = (html) => {
      return { __html: html };
    };
  
    return (
        <span style={{ paddingLeft: '8px', paddingRight: '8px' }} dangerouslySetInnerHTML={createMarkup(iconname)} />
    );
};

const NestedList = () => {
    const config = useConfig()
    const { id } = useParams()
    const { theme } = useTheme()
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading , setLoading] = useState(null)
    const { selectedUserType } = useUser()
    const { 
      sessions, 
      setsessions
    } = useFeedback()
    const { translations } = useContext( TranslationContext )
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    const getSessions = async () => {
      if (id) {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
  
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`,
            {
              headers,
              params: {
                usertype:selectedUserType ,
                userid: user?.userdetails?.agencyid
              }
            });
          return data;
        } catch (error) {
          console.log('Error fetching sessions', error);
        }
      } else {
        console.log('No id provided');
      }
    };
  
    function formatDate(datetimeString) {
      // Extract the date part (first 10 characters)
      let dateString = datetimeString.substring(0, 10);
  
      // Split the date into year, month, and day
      let parts = dateString.split("-");
      let year = parts[0]; // Full year
      let month = parts[1];
      let day = parts[2];
  
      // Form the desired date format "dd-mm-yyyy"
      let formattedDate = `${day}-${month}-${year}`;
  
      return formattedDate;
    }
  
    const getDisplayString = (session) => {
      const displayParts = [];
      const displayOrderKeys = session?.displayOrder?.split(',') || [];
      const displayInfos = session?.displayInfos || [];
    
      const displayInfoMap = {};
      displayInfos.forEach(info => {
        displayInfoMap[info.key] = info; 
      });
    
      displayOrderKeys.forEach((key) => {
        const displayInfo = displayInfoMap[key];
    
        if (displayInfo?.value) { 
          switch (key) {
            case '1':
              displayParts.push(`${translations["Module"] || "Module"} - ${session?.module || ''}`);
              break;
            case '2':
              displayParts.push(`${translations["Week"] || "Week"} - ${session?.ttttt_session_week || ''}`);
              break;
            case '3':
              displayParts.push(`📅 ${formatDate(session.ttttt_session_dt) || ''} 🕒 ${session.ttttt_session_time || ''}`);
              break;
            case '4':
              displayParts.push(`${translations["Days"] || "Days"} - ${session?.ttttt_session_day || ''}`);
              break;
            case '5':
              displayParts.push(`📝 ${translations["Srno"] || "SR"}  #${session?.ttttt_session_no || ''}`);
              break;
            default:
              break;
          }
        }
      });
    
      return displayParts.join(' ').trim();
    };
  
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await getSessions();
          setsessions(data)
        } catch (error) {
          console.error('Error fetching sessions:', error);
        } finally {
          setLoading(false); 
        }
      };
  
      fetchData();
    }, [selectedUserType,id]);
    
    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
          .MuiBox-root.css-1ofqig9 {
            width: 100% !important;
          }
        `;
        
        // Append the style element to the document head
        document.head.appendChild(style);
    
        // Cleanup on unmount
        return () => {
          document.head.removeChild(style);
        };
    }, []);
    
    const convertToHr = (duration) =>{
      const time = duration/60
      return time
    }

    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
      );
    }
  
    return (
      <div className=''>
        <List className='flex flex-col gap-4' component="nav">
            {
              sessions
              ?.filter((ses, index, self) => 
                self.findIndex(s => s.ttttt_session_id === ses.ttttt_session_id) === index
              ) 
              .map((session, index) => (
              <div key={index}>
                <ListItem className='bg-[#e5e5e5] h-fit rounded-lg'  >
                  <Box display="flex" flexDirection="column" sx={{ width: '100%'  }}>
                    <ListItemText
                      primaryTypographyProps={{ sx: { fontSize: '1.4rem', lineHeight: isMobile ? '2' : null } }}
                      secondaryTypographyProps={{ sx: { fontSize: '1.2rem'  } }}
                      primary={
                        <>
                          {getDisplayString(session)}
                          <HTMLParser iconname={session?.session_type_icon} />
                          <span style={{ paddingRight: '8px' }}>
                            {
                                session?.session_type_name === 'Academic' ? (
                                translations["Personled"] || "Person led"
                                ) :
                                session?.session_type_name === 'Break' ? (
                                translations["Breaks"] || "Break"
                                ) :
                                session?.session_type_name === 'Tour' ? (
                                translations["Tour"] || "Tour"
                                ) :
                                session?.session_type_name === 'GroupDiscussion' ? (
                                translations["Group_discussion"] || "Group Discussion"
                                ) :
                                session?.session_type_name === 'Assignment' ? (
                                translations["Assignment"] || "Assignment"
                                ) :
                                session?.session_type_name === 'PT' ? (
                                translations["PT"] || "PT"
                                ) :
                                session?.session_type_name === 'Sports' ? (
                                translations["Sports"] || "Sport"
                                ) :
                                session?.session_type_name === 'Practical' ? (
                                translations["Practical"] || "Practical"
                                ) :
                                session?.session_type_name === 'SelfPaced' ? (
                                translations["Self_paced"] || "Self paced"
                                ) :
                                session?.session_type_name
                            }
                          </span>
                          <span>
                            {session.ttttt_session_duration === 0 
                              ? '' 
                              : `⏱️ ${session.ttttt_session_duration_type === 2 ? convertToHr(session.ttttt_session_duration) : session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`
                            }
                          </span>
                          
                        </>
                      }
                      secondary={
                        <span 
                            className="flex flex-col mt-2 capitalize items-start gap-2"
                        >
                            <span
                                title={`${session.ttttt_content_desc} - ${session.ttttt_subject}`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'normal',
                                    cursor: 'pointer', 
                                }}
                            >
                              {
                                (() => {
                                    const combinedText = `${session.ttttt_content_desc} - ${session.ttttt_subject}`;
                                    const maxWords = 50;
                                    const words = combinedText.split(' ');
                                  
                                    // Check if it's mobile, if so, show full text
                                    if (isMobile) {
                                      return combinedText;  // Show the full text on mobile
                                    }
                                  
                                    // Otherwise, truncate the text if it exceeds the word limit
                                    const truncatedHtml =  words.length > maxWords
                                      ? words.slice(0, maxWords).join(' ') + '...'
                                      : combinedText;
                                      
                                  return <span dangerouslySetInnerHTML={{ __html: truncatedHtml }} />;

                                })()
                              }
                            </span>

                            <Box className='' sx={{ display: 'flex' , alignItems: 'center'}}>
                                {
                                    session?.sessionFaculties?.length > 0 ?
                                    <Typography className='text-xl mt-0 flex items-start' variant="body2" sx={{ marginRight: 1 }}>
                                        Faculty - 
                                    </Typography>
                                    : null
                                }
                                {session?.sessionFaculties.length > 0 && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {session.sessionFaculties.map((faculty, index) => (
                                        <React.Fragment key={index}>
                                            <span 
                                            title={faculty.facultyname}
                                            >
                                            {faculty.facultyname}
                                            </span>
                                            {index < session.sessionFaculties.length - 1 && ', '}
                                        </React.Fragment>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </span>
                      }
                    />
                  </Box>
                </ListItem>
              </div>
            ))
          }
        </List>
      </div>
    );
};

const SingleTrainingDetailList = () => {
    const { theme } = useTheme()
    const [durationtype, setdurationtype] = useState(null);
    const config = useConfig()
    const { id } = useParams()
    const { translations } = useContext(TranslationContext);
    const { sessions , trainingDetails } = useFeedback()

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(2);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return `${day}-${month}-${year} `;
    };

    const formatTime = (inputString) => {
      let date;
    
      // Check if the input is in "YYYY-MM-DD HH:mm:ss" format (no 'T' separator)
      if (inputString.includes(' ')) {
        // For "YYYY-MM-DD HH:mm:ss", replace the space with 'T' to make it ISO format
        inputString = inputString.replace(' ', 'T');
        date = new Date(inputString); // Parse as an ISO string
      } else {
        // If it's just a time string (HH:mm or HH:mm AM/PM), handle it
        const timeRegex = /^(\d{2}):(\d{2})(\s?[APMapm]{2})?$/;
    
        const match = inputString.match(timeRegex);
    
        if (!match) {
          return 'Invalid time format'; // If input doesn't match expected time format
        }
    
        let [_, hours, minutes, ampm] = match;
        hours = parseInt(hours);
        minutes = parseInt(minutes);
    
        // If the time is in 12-hour format (AM/PM), adjust hours accordingly
        if (ampm) {
          ampm = ampm.toUpperCase().trim();
          if (ampm === 'PM' && hours !== 12) {
            hours += 12; // Convert PM hours to 24-hour format
          } else if (ampm === 'AM' && hours === 12) {
            hours = 0; // Convert 12 AM to 0 (midnight)
          }
        }
    
        // Ensure hours and minutes are valid
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          return 'Invalid time format';
        }
    
        // Create a valid date object from parsed time
        date = new Date(`1970-01-01T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);
      }
    
      // If date parsing failed, return an error message
      if (isNaN(date.getTime())) {
        return 'Invalid time format';
      }
    
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
    
      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? String(hours).padStart(2, '0') : '12'; // '0' becomes '12'
    
      return `${hours}:${minutes} ${ampm}`;
    };
    
    const printTable = () => {
      //const printWindow = window.open('', '_blank');
    
      // Define session type mapping
      const sessionTypeMapping = {
        'Academic': translations["Personled"] || "Person led",
        'Break': translations["Breaks"] || "Break",
        'Tour': translations["Tour"] || "Tour",
        'GroupDiscussion': translations["Group_discussion"] || "Group Discussion",
        'Assignment': translations["Assignment"] || "Assignment",
        'PT': translations["PT"] || "PT",
        'Sports': translations["Sports"] || "Sport",
        'Practical': translations["Practical"] || "Practical",
        'SelfPaced': translations["Self_paced"] || "Self paced",
      };
    
      // Initialize visibility flags for each column
      let columnVisibility = {
        "Day": false,
        "Week": false,
        "Module": false,
        "srno": false,
        // Fixed columns: these are always visible, so no condition is applied to them
        "Duration": true,
        "Subject": true,
        "Type": true,
        "Faculty": true,
        "Date": false,
      };
    
      // Loop through all sessions to determine which columns should be shown
      sessions.forEach(session => {
        const displayInfo = session.displayInfos || [];
    
        displayInfo.forEach(info => {
          if (info.value) {
            columnVisibility[info.name] = true;
          }
        });
      });
    
      // Remove duplicate sessions based on session ID (or any unique identifier)
      const uniqueSessions = sessions.filter((session, index, self) => 
        index === self.findIndex((t) => (
          t.ttttt_session_id === session.ttttt_session_id
        ))
      );
    
      // Prepare session rows
      const sessionRows = uniqueSessions?.map((session, index) => {
        const displayInfo = session.displayInfos || {};
    
        const faculties = session?.sessionFaculties?.map((faculty, facultyIndex) => {
          return `<span key="${facultyIndex}">${faculty.facultyname}</span>`;
        }).join(", "); // Join faculty names with commas
    
        // Use sessionTypeMapping for the session type
        const sessionType = sessionTypeMapping[session.session_type_name] || session.session_type_name || "Unknown";
    
        // Check if session.ttttt_type is 6 or 7 to conditionally add the start and end date
        let sessionContentDesc = `${session.ttttt_subject} - ${session.ttttt_content_desc}`;
        if (session.ttttt_type === 6 || session.ttttt_type === 7) {
          const startDate = formatDate(session.ttttt_session_dt);
          const endDate = formatDate(session.ttttt_session_end_time);
          const startTime = formatTime(session.ttttt_session_time);
          const endTime = formatTime(session.ttttt_session_end_time);
          sessionContentDesc += `<br>Start Date: ${startDate} ${startTime} - Last Date: ${endDate} ${endTime}`;
        }
    
        return `
          <tr key="${session.ttttt_session_id || index}">
            ${columnVisibility["Day"] ? `<td>${session.ttttt_session_day}</td>` : ''}
            ${columnVisibility["Week"] ? `<td>${session.ttttt_session_week}</td>` : ''}
            ${columnVisibility["Module"] ? `<td>${session.module}</td>` : ''}
            ${columnVisibility["srno"] ? `<td>${session.ttttt_session_no}</td>` : ''}
            ${columnVisibility["Date"] ? `<td>${formatDate(session.ttttt_session_dt)}</td>` : ''}
            ${columnVisibility["Duration"] ? `<td>${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}</td>` : ''}
            ${columnVisibility["Duration"] ? `<td>${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}</td>` : ''}
            ${columnVisibility["Subject"] ? `<td>${sessionContentDesc}</td>` : ''}
            ${columnVisibility["Type"] ? `<td>${sessionType}</td>` : ''}
            ${columnVisibility["Faculty"] ? `<td>${faculties}</td>` : ''}
          </tr>
        `;
      }).join("");
    
      // Print HTML document
     const formattedContent = `
        <html>
          <head>
            <title>Session List Table</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;
              }
              th {
                background-color: #f2f2f2;
              }
              h2 {
                text-align: center;
                margin-bottom: 20px;
              }
              h3 {
                text-align: center;
                margin-bottom: 30px;
              }
            </style>
          </head>
          <body>
            <h2>${trainingDetails?.courseName || 'Course Name Not Available'} - ${trainingDetails?.trainingcode || 'Training Code Not Available'}</h2>
            <h3>Time Table Duration: ${formatDate(trainingDetails?.t_StartDate) || 'Start Date Not Available'} - ${formatDate(trainingDetails?.t_EndDate) || 'End Date Not Available'}</h3>
    
            <table>
              <thead>
                <tr>
                  ${columnVisibility["Day"] ? `<th>${translations["Days"] || "Days"}</th>` : ''}
                  ${columnVisibility["Week"] ? `<th>${translations["Week"] || "Week"}</th>` : ''}
                  ${columnVisibility["Module"] ? `<th>${translations["Module"] || "Module"}</th>` : ''}
                  ${columnVisibility["srno"] ? `<th>${translations["Srno"] || "Sr No"}</th>` : ''}
                  ${columnVisibility["Date"] ? `<th>${translations["Date"] || "Date"}</th>` : ''}
                  ${columnVisibility["Duration"] ? `<th>${translations["Duration"] || "Duration"}</th>` : ''}
                  ${columnVisibility["Subject"] ? `<th>${translations["Subject"] || "Subject"}</th>` : ''}
                  ${columnVisibility["Type"] ? `<th>${translations["Type"] || "Type"}</th>` : ''}
                  ${columnVisibility["Faculty"] ? `<th>${translations["Faculty"] || "Faculty"}</th>` : ''}
                </tr>
              </thead>
              <tbody>
                ${sessionRows}
              </tbody>
            </table>
          </body>
        </html>
      `;
     printData(formattedContent, config);
      // Wait for the window to load and trigger the print dialog
      // printWindow.document.close(); // Important to close the document for proper rendering
      // printWindow.print(); // Open the print dialog
    };

    const downloadExcel = () => {
      // Define session type mapping
      const sessionTypeMapping = {
        'Academic': translations["Personled"] || "Person led",
        'Break': translations["Breaks"] || "Break",
        'Tour': translations["Tour"] || "Tour",
        'GroupDiscussion': translations["Group_discussion"] || "Group Discussion",
        'Assignment': translations["Assignment"] || "Assignment",
        'PT': translations["PT"] || "PT",
        'Sports': translations["Sports"] || "Sport",
        'Practical': translations["Practical"] || "Practical",
        'SelfPaced': translations["Self_paced"] || "Self paced",
      };
    
      // Initialize visibility flags for each column
      let columnVisibility = {
        "Day": false,
        "Week": false,
        "Module": false,
        "srno": false,
        // Fixed columns: these are always visible, so no condition is applied to them
        "Duration": true,
        "Subject": true,
        "Type": true,
        "Faculty": true,
        "Date": false,
      };
    
      // Loop through all sessions to determine which columns should be shown
      sessions.forEach(session => {
        const displayInfo = session.displayInfos || [];
    
        displayInfo.forEach(info => {
          if (info.value) {
            columnVisibility[info.name] = true;
          }
        });
      });
    
      // Remove duplicate sessions based on session ID (or any unique identifier)
      const uniqueSessions = sessions.filter((session, index, self) =>
        index === self.findIndex((t) => (
          t.ttttt_session_id === session.ttttt_session_id
        ))
      );
    
      // Prepare the rows for the Excel file, including headers
      const rows = [
        // Header row for Excel (same as the table headers)
        [
          columnVisibility["Day"] ? (translations["Days"] || "Days") : null,
          columnVisibility["Week"] ? (translations["Week"] || "Week") : null,
          columnVisibility["Module"] ? (translations["Module"] || "Module") : null,
          columnVisibility["srno"] ? (translations["Srno"] || "Sr No") : null,
          columnVisibility["Date"] ? (translations["Date"] || "Date") : null,
          columnVisibility["Duration"] ? (translations["Duration"] || "Duration") : null,
          columnVisibility["Subject"] ? (translations["Subject"] || "Subject") : null,
          columnVisibility["Type"] ? (translations["Type"] || "Type") : null,
          columnVisibility["Faculty"] ? (translations["Faculty"] || "Faculty") : null,
        ].filter(Boolean),  // Filter out nulls for columns that are not visible
    
        // Data rows (loop through unique sessions)
        ...uniqueSessions.map(session => {
          const sessionType = sessionTypeMapping[session.session_type_name] || session.session_type_name || "Unknown";
          const faculties = session?.sessionFaculties?.map(faculty => faculty.facultyname).join(", ") || "";
    
          // Prepare session row based on column visibility
          const row = [];
          if (columnVisibility["Day"]) row.push(session.ttttt_session_day);
          if (columnVisibility["Week"]) row.push(session.ttttt_session_week);
          if (columnVisibility["Module"]) row.push(session.module);
          if (columnVisibility["srno"]) row.push(session.ttttt_session_no);
          if (columnVisibility["Date"]) row.push(formatDate(session.ttttt_session_dt));
          if (columnVisibility["Duration"]) row.push(`${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`);
          if (columnVisibility["Subject"]) row.push(`${session.ttttt_subject} - ${session.ttttt_content_desc}`);
          if (columnVisibility["Type"]) row.push(sessionType);  // Mapped session type here
          if (columnVisibility["Faculty"]) row.push(faculties);
    
          return row;
        }) || []
      ];
    
      // Course details row (No need to span across columns in Excel)
      const courseDetails = [
        [
          `${trainingDetails?.courseName} - ${trainingDetails?.trainingcode}`
        ],
        [`Time Table Duration :  ${formatDate(trainingDetails?.t_StartDate) || 'Start Date Not Available'} - ${formatDate(trainingDetails?.t_EndDate) || 'End Date Not Available'}`]  // 1 column
      ];
    
      // Combine course details and session data
      const finalRows = [
        ...courseDetails,
        [],
        // Add a blank row for separation
        ...rows
      ];
    
      // Create a worksheet and workbook
      const ws = XLSX.utils.aoa_to_sheet(finalRows); // Array of arrays (rows)
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Session List");
    
      // Download the file as Excel
      XLSX.writeFile(wb, "session_list.xlsx");
    };

    const getTrgSessionDuration = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TRG_SESSIONS_DURATION`, {
            headers: headers,
            params:{
              trainingid:id,
              durationtype:2
            }
          });
          setdurationtype(data);
  
        } catch (error) {
          console.log('Error session type', error);
        }
    }; 

    const handleError = ()=>{
      toast.warning("No sessions Found")
    }

    useEffect(() => {
        getTrgSessionDuration()
    }, [])

    return (
    <div 
        style={{ borderColor: theme?.palette?.primary?.main , borderWidth:'1px' , borderStyle: 'solid' }} 
        className={`h-fit max-sm:shrink-0 w-full rounded-xl p-4 relative`}
    >
        <div 
            style={{ borderWidth:'1px' , borderStyle: 'solid' }} 
            className={`h-fit max-sm:shrink-0 w-full  relative border-[#80808057]`}
        >   
            <div 
                className='px-6 py-3 text-white'
                style={{ backgroundColor: theme?.palette?.primary?.main }} 
            >
                <span className='font-semibold text-2xl'>{translations["Session_details"] || "Session Details"}</span>
            </div>

            <div 
                className='px-6 py-4 flex flex-col justify-center gap-4'
            >
                <div className='flex flex-wrap gap-2 '>
                    
                    {/* <div className='flex max-sm:grid max-phone:grid-cols-1 max-sm:grid-cols-2 gap-2'>
                        {durationtype?.activity > 0 && ( 
                        <button
                            title={`Activity ${durationtype?.activity} - ${durationtype?.durationtype}`}
                            className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                        >
                            <LuActivity /> {translations["Activity"] || "Activity"} {durationtype?.activity} - {durationtype?.durationtype}
                        </button>
                        )}
                            
                        {durationtype?.practical > 0 && (
                        <button
                            title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                            className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                        >
                            <IoFlask /> {translations["Practical"] || "Practical"} {durationtype?.practical} - {durationtype?.durationtype}
                        </button>
                        )}
                            
                        {durationtype?.theory > 0 && (
                        <button
                            title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                            className='text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg'
                        >
                            <FaBook /> 
                            {translations["Theory"] || "Theory"} {durationtype?.theory} - {durationtype?.durationtype}
                        </button>
                        )}
                    </div> */}

                    <div
                      className={`phone:hidden gap-2
                        ${
                          durationtype?.theory > 0 &&
                          durationtype?.activity > 0 &&
                          durationtype?.practical > 0
                            ? "flex flex-col"
                            : "flex"
                        }`}
                    >
                      <div className="flex gap-2">
                        {/* {durationtype?.activity <= 0 && ( */}
                        {durationtype?.activity > 0 && (
                          <button
                            title={`Activity ${durationtype?.activity > 1 ? durationtype?.activity : (durationtype?.activity * 60).toFixed(0)} - ${durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            {/* <LuActivity /> {durationtype?.activity} -{" "} */}
                            <LuActivity /> {durationtype?.activity > 1 ? durationtype?.activity :  (durationtype?.activity * 60).toFixed(0)} -{" "}
                            {/* {durationtype?.durationtype} */}
                            {durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}
                          </button>
                        )}

                        {/* {durationtype?.practical <= 0 && ( */}
                        {durationtype?.practical > 0 && (
                          <button
                            title={`Practical ${durationtype?.practical > 1 ? durationtype?.practical : (durationtype?.practical * 60).toFixed(0)} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}

                            // title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            <IoFlask /> {durationtype?.practical > 1 ? durationtype?.practical :  (durationtype?.practical * 60).toFixed(0)} -{" "}
                            {/* {durationtype?.durationtype} */}
                            {durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}
                          </button>
                        )}

                        {/* {durationtype?.theory <= 0 && ( */}
                        {durationtype?.theory > 0 && (
                          <button
                            title={`Practical ${durationtype?.theory > 1 ? durationtype?.theory : (durationtype?.theory * 60).toFixed(0)} - ${durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}`}
                          // title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            <FaBook />
                            {/* {durationtype?.theory} - {durationtype?.durationtype} */}
                            {durationtype?.theory > 1 ? durationtype?.theory : (durationtype?.theory * 60).toFixed(0)} - {" "}
                            {durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}

                          </button>
                        )}
                      </div>

                      <div className="flex gap-2">
                        <button
                          onClick={sessions?.length > 0 ? printTable : handleError}
                          title="Print"
                          className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
                        >
                          <FaPrint />
                        </button>

                        <button
                          onClick={sessions?.length > 0 ? downloadExcel : handleError}
                          title="Save as excel"
                          className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
                        >
                          <RiFileExcel2Fill />
                        </button>
                      </div>

                    </div>

                    <div className="flex max-phone:hidden max-sm:grid max-sm:grid-cols-1 gap-2">
                      <div className="flex max-phone:grid max-phone:grid-cols-1 gap-2">
                        {/* {durationtype?.activity <= 0 && ( */}
                        {durationtype?.activity > 0 && (
                          <button
                            title={`Activity ${durationtype?.activity > 1 ? durationtype?.activity : (durationtype?.activity * 60).toFixed(0)} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            <LuActivity /> {translations["Activity"] || "Activity"}{" "}
                            {durationtype?.activity > 1 ? durationtype?.activity : (durationtype?.activity * 60).toFixed(0)} -{" "}
                            {durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}
                          </button>
                        )}

                        {/* {durationtype?.practical <= 0 && ( */}
                        {durationtype?.practical > 0 && (
                          <button
                            title={`Practical ${durationtype?.practical > 1 ? durationtype?.practical : (durationtype?.practical * 60).toFixed(0)} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}
                          // title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            <IoFlask /> {translations["Practical"] || "Practical"}{" "}
                            {durationtype?.practical > 1 ? durationtype?.practical : (durationtype?.practical * 60).toFixed(0)} -{" "}
                            {durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}
                            {/* {durationtype?.practical} - {durationtype?.durationtype} */}
                          </button>
                        )}

                        {/* {durationtype?.theory <= 0 && ( */}
                        {durationtype?.theory > 0 && (
                          <button
                            title={`Theory ${durationtype?.theory > 1 ? durationtype?.theory : (durationtype?.theory * 60).toFixed(0)} - ${durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}`}
                            // title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                            className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                          >
                            <FaBook />
                            {translations["Theory"] || "Theory"}{" "}
                            {durationtype?.theory > 1 ? durationtype?.theory : (durationtype?.theory * 60).toFixed(0)} -{" "}
                            {durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}

                            {/* {durationtype?.theory} -{" "}
                            {durationtype?.durationtype} */}
                          </button>
                        )}
                      </div>

                      <div className="flex gap-2">
                        <button
                          onClick={sessions?.length > 0 ? printTable : handleError}
                          title="Print"
                          className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
                        >
                          <FaPrint />
                        </button>

                        <button
                          onClick={sessions?.length > 0 ? downloadExcel : handleError}
                          title="Save as excel"
                          className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
                        >
                          <RiFileExcel2Fill />
                        </button>
                      </div>
                      
                    </div>
                              
                    {/* <div className='flex max-sm:h-fit gap-2'>
                      <button
                          onClick={sessions?.length > 0 ? printTable : handleError}
                          title='Print'
                          className='text-white  bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                          >
                          <FaPrint/> 
                      </button>

                      <button
                          onClick={sessions?.length > 0 ? downloadExcel : handleError }
                          title='Save as excel'
                          className='text-white  bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                          >
                          <RiFileExcel2Fill/> 
                      </button>
                    </div> */}

                </div>
                
                <NestedList />

            </div>

        </div>
    </div>
  )
}

export default SingleTrainingDetailList