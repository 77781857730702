import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import loadConfig from "../../config";
import axios from "axios";
import { useUser } from "../../UserContext";
import { message } from "antd";

const LoginPost = () => {
  const navigate = useNavigate();
  const location = useLocation(); // useLocation hook to get the current URL
  const queryParams = new URLSearchParams(location.search); // Extract query parameters from URL
  const username = queryParams.get("username"); // Get the 'username' query parameter
  const [data, setData] = useState(null);
  const { setUser, setClient, setSelectedUserType } = useUser();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
 const[isOpen,setIsOpen]= useState(true);
  useEffect(() => {
    if (username) {
      console.log("Extracted username from query parameter:", username);
      setLoading(true);
      fetchConfig(); // Fetch configuration and data when username is available
    }
  }, [username]); // Trigger when username changes

  const fetchConfig = async () => {
    try {
      console.log("Loading config data...");
      const configData = await loadConfig(); // Assuming loadConfig fetches your config object
      console.log("Config data loaded:", configData);
      setConfig(configData);
      fetchClientData(configData);
      checkOAuthToken(configData);
    } catch (error) {
      console.error("Error loading config:", error);
    }
  };

  // Check OAuth token before calling UserInfo
  const checkOAuthToken = async (config) => {
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/CheckOAuthToken?username=${username}`, { headers });

      if (response.data.result) {
        console.log("OAuth token is valid, fetching user info...");
        fetchAdditionalData(config); // Call UserInfo API if OAuth token is valid
      } else {
        console.log("OAuth token is invalid, redirecting to home page...");
        navigate("/"); // Redirect to home page if OAuth token is invalid
      }
    } catch (error) {
      console.error("Error checking OAuth token:", error);
    }
  };

  // Fetch additional user data after OAuth check
  const fetchAdditionalData = async (config) => {
    console.log("Fetching data for user:", username);
  
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };
  
      const response = await axios.get(`${config.REACT_APP_API_URL}/UserInfo?username=${username}`, { headers });
  
      // Set user data
      setUser(response.data.result);
      localStorage.setItem('user', JSON.stringify(response.data.result));
      navigate("/dashboard");
  
      const userTypes = response.data.result.userdetails.usertype;
  
      // Check if there's more than 1 user type and whether '5' is present
      const shouldOpenModal = userTypes.length > 1;
      if (!shouldOpenModal) {
        setSelectedUserType(userTypes[0].usertypeid); // If only one user type, set it as selected
        // message.success("Login successful!");
      } else {
        // Set default user type to 5 if it's available in the list of user types
        const defaultUserType = userTypes.find(type => type.usertypeid == 5);
        if (defaultUserType) {
          setSelectedUserType(5);  // Set 5 as the default user type
        } else {
          setSelectedUserType(userTypes[0].usertypeid); // Default to the first user type
        }
  
        // Open the modal
        setIsOpen(true);
        // message.success("Login successful!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  

  const fetchClientData = async (config) => {
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetClientData`, {
        headers,
      });

      setClient(response.data);
      localStorage.setItem('clientData', JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  return (
    <div>
      
    </div>
  );
};

export default LoginPost;
