import React, { useEffect, useContext, useState } from 'react'
import { FaCertificate } from "react-icons/fa"
import { BsTable } from "react-icons/bs"
import { TbCheckbox } from "react-icons/tb"
import { MdPendingActions , MdOutlineAssessment } from "react-icons/md"
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import { useMediaQuery } from '@mui/material'
import { TranslationContext } from '../../../TranslationContext';

const DashBoardAnalytics = () => {
    const config = useConfig()
    const isMobile = useMediaQuery("(max-width: 640px)");
    const { translations } = useContext(TranslationContext);

    const { 
        setLoading , 
        dashboard_analytics, 
        setdashboard_analytics,
        isActive,
    } = useFeedback()
    const [pendingTests, setPendingTests] = useState([]);
    const { 
        selectedYear , selectedUserType, 
    } = useUser()
    const user = JSON.parse(localStorage.getItem('user'));

    const getDashboardAnalytics = async () => {
        if (user) {
            setLoading(true);
            const headers = {
                Accept: 'application/json, text/plain',
                APIKey: config?.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
            };
    
            try {
                const startDate = `${selectedYear.split('-')[0]}-04-01`;
                const endDate = `${selectedYear.split('-')[1]}-03-31`;

                const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Dashboard_analytics`, {
                    headers: headers,
                    params: {
                        usertype: selectedUserType,
                        userid: user?.userdetails?.agencyid,
                        startdate: startDate,
                        enddate: endDate,
                    }
                });
                setdashboard_analytics(data);
                console.log('Dashboard data', data);
                setLoading(false);
    
            } catch (error) {
                console.log('Error Dashboard data', error);
                setLoading(false);
            }
        } else {
            console.log('No user provided');
            setLoading(false);
        }
    };

    const getPendingTests = async () => {

        const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_EVAL_API_KEY,
        'Content-Type': 'application/json',
        };

        try {
        const { data } = await axios.get(`${config?.REACT_APP_EVAL_API_PATH}/Tests/UserTests`,
            {
            headers,
            params: {
                usertype:selectedUserType ,
                userid: user?.userdetails?.agencyid,
                PageNumber:1,
                PageSize:100000000
            }
        });
        return data;
        } catch (error) {
        console.log('Error fetching sessions', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedYear) {
                await getDashboardAnalytics();
            }
        };
    
        fetchData();
    }, [selectedUserType,selectedYear]);

    useEffect(() => {
        const fetchData = async () => {
          const data = await getPendingTests();  // Assuming this function returns the data
    
          // Filter data based on the conditions
          const filteredData = data?.filter(item => 
            item?.tdds_status === 1 && item?.participantstatus === null
          );
    
          console.log('filteredData' , filteredData?.length)
          // Set the filtered data to the state
          setPendingTests(filteredData?.length);
        };
    
        fetchData();
    }, []);

    const cardsdata = [
        {
            id:"1",
            title : translations["Completed" || 'Completed'],
            Logo : <TbCheckbox fontSize={30}/>,
            background: "bg-[#5CB85C]",
            numbers: dashboard_analytics?.trg_completed
        },
        {
            id:"2",
            title : translations["Certificate" || 'Certificate'],
            // title : "Certificate",
            Logo : <FaCertificate fontSize={30}/>,
            background: "bg-[#337AB7]",
            numbers: dashboard_analytics?.no_of_certificates
        },
        {
            id:"3",
            title : translations["UpComing_Training" || 'Upcoming Training'],
            Logo : <BsTable fontSize={30}/>,
            background: "bg-[#4E5154]",
            numbers:dashboard_analytics?.upcoming_trg
        },
        {
            id:"4",
            title : translations["Pending_test" || 'Pending Test'],
            Logo : <MdPendingActions fontSize={30}/>,
            background: "bg-[#5BC0DE]",
            // numbers:dashboard_analytics?.no_of_pending_tests
            numbers:pendingTests
        },
        {
            id:"5",
            title : translations["Pending_assignment" || 'Pending Assignment'],
            Logo : <MdOutlineAssessment fontSize={30}/>,
            background: "bg-[#F0AD4E]",
            numbers:dashboard_analytics?.no_of_pending_assignment
        },
    ]

    return (
    <div className='h-full w-full'>
        {
            isActive && isMobile ? (
                <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
                {
                    cardsdata?.map((item, index) => (
                    <div key={item.id} className="panel panel-success-alt noborder">
                        <div className={`panel-heading noborder ${item.background} h-full`}>
                        <div className="panel-icon text-black flex items-center justify-center ">
                            {item.Logo}
                        </div>
                        <div className="media-body">
                            <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                            <h2 className="mt5">{item.numbers}</h2>
                        </div>
                        </div>
                    </div>
                    ))
                }
                </div>
            ) : null
        }

        {
            !isMobile ? (
            <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
                {
                    cardsdata?.map((item, index) => (
                    <div key={item.id} className="panel panel-success-alt noborder">
                        <div className={`panel-heading noborder ${item.background} h-full`}>
                        <div className="panel-icon text-black flex items-center justify-center ">
                            {item.Logo}
                        </div>
                        <div className="media-body">
                            <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                            <h2 className="mt5">{item.numbers}</h2>
                        </div>
                        </div>
                    </div>
                    ))
                }
            </div>
            ) : null
        }
    </div>
  )
}

export default DashBoardAnalytics