import React from 'react';
import styled from 'styled-components';

// Styled Components

// Page container that ensures full height and flex layout
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height */
`;

// Content area that will scroll
const Content = styled.div`
  flex: 1; /* Take all available space above the footer */
  padding: 20px;
  overflow-y: auto; /* Make content scrollable */
`;

// Footer styles with fixed position at the bottom
const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #80808042;
  border-top: 1px solid #80808069;
  position: fixed; /* Fix the footer to the bottom */
  bottom: 0;
  left: 0;
  padding: 15px 100px;
  z-index: 9999;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    padding: 2px 10px; /* Adjust padding for smaller screens */
  }
`;

// Footer item container (for each status item)
const FooterItem = styled.div`
  display: flex;
  align-items: center;
`;

// Status circle icon for each footer item
const StatusIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || 'gray'}; /* Dynamic background color */
`;

// Span for text next to each icon
const FooterText = styled.span`
  margin-left: 5px;
`;

const Footer = () => {
  return (
    <PageContainer>
      {/* Main content */}
      <Content>
        <h4>Scrollable Content Area</h4>
        {/* Add content to test scrolling */}
        {[...Array(50)].map((_, index) => (
          <p key={index}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget porttitor odio.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget porttitor odio.
          </p>
        ))}
      </Content>

      {/* Footer */}
      <FooterContainer>
        {/* Current Status */}
        <FooterItem>
          <StatusIcon bgColor="blue" />
          <FooterText>Current</FooterText>
        </FooterItem>

        {/* Answered Status */}
        <FooterItem>
          <StatusIcon bgColor="green" />
          <FooterText>Answered</FooterText>
        </FooterItem>

        {/* Marked for Review Status */}
        <FooterItem>
          <StatusIcon bgColor="red" />
          <FooterText>Marked for Review</FooterText>
        </FooterItem>

        {/* Not Answered Status */}
        <FooterItem>
          <StatusIcon bgColor="white" />
          <FooterText>Not Answered</FooterText>
        </FooterItem>
      </FooterContainer>
    </PageContainer>
  );
};

export default Footer;
