import React, { useEffect, useState, useRef } from 'react';
import moment, { duration } from 'moment';
import { HiPhone } from 'react-icons/hi';
import { MdEmail } from 'react-icons/md';
import { useFeedback } from '../../../Context/FeedbackContext';
import { useConfig } from '../../../ConfigContext';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../pages/Loader';
import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import { Input, Tag } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '../../../UserContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Modal.setAppElement('#root');

const CreateSessionFromCalendar = () => {
  const config = useConfig()
  const { 
    selectedDate, 
    setSelectedDate , 
    showModal, 
    setShowModal, 
    sessionType , 
    loading , 
    setLoading ,
    allfaculty, 
    setallfaculty,
    sessionModules ,
    setsessionModules,
    setsessionType
  } = useFeedback()
  const { selectedYear , selectedUserType } = useUser()
  const [eventTitle, setEventTitle] = useState('');
  const [selectedTraining, setSelectedTraining] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [day, setDay] = useState(null);
  const [week, setWeek] = useState(null);
  const [durationTime, setDurationTime] = useState('');
  const [durationUnit, setDurationUnit] = useState(null);
  const [subject, setSubject] = useState(null);
  const [description, setDescription] = useState(null);
  const [module, setModule] = useState(null);
  const [completion, setcompletion] = useState(null);
  const [faculty, setFaculty] = useState('');
  const [filtercontent, setfiltercontent] = useState(false);
  const [SessionNo, setSessionNo] = useState(null);
  // const [allfaculty, setallfaculty] = useState(null);
  const [tag, setTag] = useState(''); // Current input value
  const [tags, setTags] = useState([]); // List of tags
  const inputRef = useRef(null);
  const [Trainings, setTrainings] = useState(null);
  const [trainingCondition, settrainingCondition] = useState(null);
  const [Complimentory, setComplimentory] = useState(null);
  const [DayWeek, setDayWeek] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  // Handle input change
  const handleInputChange = (e) => {
    setTag(e.target.value);
  };

  // Handle key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && tag.trim() !== '') {
      e.preventDefault();
      addTag(tag.trim());
    }
  };

  // Add a tag
  const addTag = (newTag) => {
    if (!tags.some(tag => tag.text === newTag)) {
      setTags((prevTags) => [...prevTags, { id: uuidv4(), text: newTag }]);
    }
    setTag(''); // Clear the input field
  };

  // Remove a tag
  const removeTag = (tagId) => {
    setTags((prevTags) => prevTags.filter((t) => t.id !== tagId));
  };

  // Handle click outside
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      if (tag.trim() !== '') {
        addTag(tag.trim());
      }
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tag]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Handle item selection
  const handleSelectItem = (item) => {
    setSelectedItems((prevSelected) => {
      if (!prevSelected.some(selected => selected.agencyid === item.agencyid)) {
        return [...prevSelected, item];
      }
      return prevSelected;
    });
    setIsOpen(false); // Close dropdown after selection
  };

  // Remove selected tag
  const removeTags = (id) => {
    setSelectedItems((prevSelected) => prevSelected.filter(item => item.agencyid !== id));
  };

  // Toggle dropdown visibility
  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown if clicked outside
  const handleClickOutsidee = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidee);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidee);
    };
  }, []);

  const [time, setTime] = useState(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  });

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setDurationTime(value);
    }
  };

  const handleSessionNo = (e) => {
    const value = e.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setSessionNo(value);
    }
  };

  const handleFilter = (filterdata) => {
    setfiltercontent(filterdata)
  }

  const getFaculty = async () => {
    // setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Agency`, {
        headers: headers,
        params: {
          agencytype: "00054",
        }
      }
      );
      setallfaculty(data);
      console.log("faculties",data)
      // setLoading(false);
    } catch (error) {
      console.log('Error session type', error);
      // setLoading(false);
    }
  };

  const getTrainings = async () => {
    // setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const startDate = `${selectedYear.split('-')[0]}-04-01`;
      const endDate = `${selectedYear.split('-')[1]}-03-31`;

      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Trainings`, {
        headers: headers,
        params: {
          fromdate: startDate,
          todate:endDate
        }
      }
      );
      setTrainings(data);
      console.log("Trainings",data)
      // setLoading(false);
    } catch (error) {
      console.log('Error Trainings', error);
      // setLoading(false);
    }
  };

  const getSessionModules = async () => {
    // setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionModules`, {
        headers: headers
      }
      );
      setsessionModules(data);
      // setLoading(false);
    } catch (error) {
      console.log('moduels', error);
      // setLoading(false);
    }
  };

  const getSessionType = async () => {
    // setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionType`, {
        headers: headers,
      });
      setsessionType(data);
      // setLoading(false);
      console.log("sessionType", sessionType)
    } catch (error) {
      console.log('Error session type', error);
      // setLoading(false);
    }
  };

  const getDayWeek = async () => {
    // setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const startDate = `${selectedYear.split('-')[0]}-04-01`;
      const endDate = `${selectedYear.split('-')[1]}-03-31`;

      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Training_Day_Week`, {
        headers: headers,
        params: {
          fromdate: startDate,
          todate:endDate
        }
      }
      );
      setDayWeek(data);
      console.log("setDayWeek",data)
      // setLoading(false);
    } catch (error) {
      console.log('Error Trainings', error);
      // setLoading(false);
    }
  };

  const GET_SESSION_ENTRY_CONTROLS = async (training) => {

    setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/GET_SESSION_ENTRY_CONTROLS`, {
        headers: headers,
        params:{
          trainingtype:training.trg_type
        }
      });
      settrainingCondition(data);
      setLoading(false);
      console.log("sessionType", data)
    } catch (error) {
      console.log('Error session type', error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await Promise.all([
  //         getFaculty(),
  //         getDayWeek(),
  //         getTrainings(),
  //         getSessionModules(),
  //         getSessionType(),
  //       ]);
  //     } catch (error) {
  //       console.error("Error fetching data", error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); 

      try {
        await Promise.all([
          getFaculty(),
          getDayWeek(),
          getTrainings(),
          getSessionModules(),
          // getSessionType(),
        ]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  function getCurrentDateTime() {
    const now = new Date();
    
    const pad = (num) => num.toString().padStart(2, '0');
  
    // Extract date and time components
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1); // Months are zero-indexed
    const day = pad(now.getDate());
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());
  
    // Format date and time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const postSession = async (e) => {
    e.preventDefault();

    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    const postData = {
      trainingid:selectedTraining,
      createdby: user?.userdetails?.agencyid,
      createdon: getCurrentDateTime(),
      branchid: user?.userdetails?.branchid,
      sessionid: uuidv4(),
      faculties : selectedItems?.map(item => item.agencyid),
      subject: subject,
      description : description ,
      sessiontype : filtercontent?.id || null ,
      date: trainingCondition?.date === true ? moment(selectedDate).format('YYYY-MM-DD HH-MM-SS') : null , 
      day:  trainingCondition?.day === true ? day : null , 
      week: trainingCondition?.week === true ? week : null , 
      duration : durationTime,
      duration_type : durationUnit,
      isjointSession : selectedItems?.length > 1 ? 1 : (selectedItems?.length === 0 ? null : 0),
      sessionno : trainingCondition?.srno === true ? SessionNo : null ,
      sessionTime : time ,
      status : 0 ,
      tags : tags?.map(item => item.text),
      iscomplementory: Complimentory,
      module: trainingCondition?.module === true ?  module : null,
      completiontype:completion
    };

    try {
      const { data } = await axios.post(`${config?.REACT_APP_API_URL}/comment`, postData, { headers: headers });

      setLoading(false);
      console.log("posted" , data)
    } catch (error) {
      console.error('Error posting session Comments:', error);
    } finally {
      setLoading(false);
    }
  };

  const CreateSession = async(e)=>{
    e.preventDefault()
    const trainingData = {
      trainingid:selectedTraining,
      createdby: user?.userdetails?.agencyid,
      // createdon: "YYYY-MM-DD HH:MM:SS",
      createdon: getCurrentDateTime(),
      branchid: user?.userdetails?.branchid,
      sessionid: uuidv4(),
      faculties : selectedItems?.map(item => item.agencyid),
      subject: subject,
      description : description ,
      sessiontype : filtercontent?.id || null ,
      date: trainingCondition?.date === true ? moment(selectedDate).format('YYYY-MM-DD') : null , 
      day:  trainingCondition?.day === true ? day : null , 
      week: trainingCondition?.week === true ? week : null , 
      duration : durationTime,
      duration_type : durationUnit,
      isjointSession : selectedItems?.length > 1 ? 1 : (selectedItems?.length === 0 ? null : 0),
      sessionno : trainingCondition?.srno === true ? SessionNo : null ,
      sessionTime : time ,
      status : 0 ,
      tags : tags?.map(item => item.text),
      iscomplementory: Complimentory,
      module: trainingCondition?.module === true ?  module : null,
      completiontype:completion
    }
    console.log("trainingData" , trainingData  )
    setShowModal(false)
  }

  const handleTrainingChange =  async  (event) => {
    const selectedId = event.target.value;
    setSelectedTraining(selectedId);

    const selectedTraining = Trainings?.find(training => training.trainingId === selectedId);
    console.log("selectedId" , selectedId)
    console.log("selectedTraining" , selectedTraining)
    await GET_SESSION_ENTRY_CONTROLS(selectedTraining);
  };

  useEffect(() => {
    if (trainingCondition?.complementory === true) {
      // Default to 0 if Complimentory is null when trainingCondition is true
      setComplimentory(Complimentory === null ? 0 : Complimentory);
    } else {
      // If complementory is false, set Complimentory to null
      setComplimentory(null);
    }
  }, [trainingCondition]);

  const handleCheckboxChange = (event) => {
    setComplimentory(event.target.checked ? 1 : 0);
  };

  const uniqueWeekcounts = Array.from(new Set(DayWeek?.map(item => item.weekcount)));

  if (loading) {
    <Loader />
  }

  return (
    <div className='fixed z-99 top-0 left-0 right-0 bottom-0  bg-gray-700 bg-opacity-50 flex justify-center items-center'>
      <Draggable handle=".modal-header">
        <div className='bg-white py-6 border-[#428BCA] rounded shadow-lg'>
          <div className="modal-header px-6  cursor-move pb-4 border-b-2 flex items-center justify-between ">
            <span className='text-3xl'>Create Session</span>
            <IoClose
              className='z-[999999]'
              cursor={"pointer"}
              onClick={() => setShowModal(false)}
            />
          </div>
          <form onSubmit={CreateSession}>
          
          <div className='flex  flex-col max-h-[55vh] overflow-x-hidden overflow-y-auto  px-6 py-4 gap-4'>
            <div className='w-[45vw]  max-mid:w-[65vw] '>
            </div>
            

            <div className='flex  max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
              <span className='w-40 '>Training <span className='text-red-600'>*</span></span>
              <select
                className='w-full rounded-md px-1 py-2 border-2'
                value={selectedTraining}
                onChange={handleTrainingChange}
                required
                // onChange={(e) => setSelectedTraining(e.target.value)}
                // onChange={(e) => setSelectedTraining(e.target.value)}
              >
                <option value=''>Select Training</option>
                {
                  Trainings?.map((training)=>(
                    <option key={training.trainingId} 
                      value={training.trainingId}
                    >
                      {training.t_Name} - {training.trainingcode}
                    </option>
                  ))
                }
              </select>
            </div>

            <div className='flex  max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
              <span className='w-40 '>Type <span className='text-red-600'>*</span></span>
              <Menu as="div" className="relative w-full inline-block text-left">
                <div className="w-full ">
                  <MenuButton
                    className="inline-flex h-15 w-full  items-center justify-between pl-4 pr-1 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                  >
                    {(() => {
                      const session = sessionType?.find(item => item.name === filtercontent.name);
                      if (session) {
                        return (
                          <>
                            <span className="flex items-center justify-start gap-2">
                                <i className={session.displayClass}></i>
                                <span>{session.name}</span>
                            </span>
                          </>
                        );
                      } else {
                        return 'Select Content';
                      }
                    })()}
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                    />
                  </MenuButton>
                </div>
                <MenuItems
                  //  className="absolute bg-white w-full max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                  className="absolute bg-white w-full  overflow-y-auto left-0  origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                >
                  <div className="py-1 w-full flex flex-col h-52 overflow-y-auto  ">
                    {sessionType?.map(session => (
                      <MenuItem key={session.id}>
                        <button
                          value={session.value}
                          onClick={() => handleFilter(session)}
                          className={`block typebtn px-4 flex-shrink-0  whitespace-nowrap text-sm text-gray-700 w-full ${
                              filtercontent.name === session.name ? 'bg-[#428BCA] text-white' : ''
                          }`}
                        >
                          <span className="flex typebtn py-2 items-center justify-start gap-4">
                            <i className={session.displayClass}></i>
                            <span>{session.name}</span>
                          </span>
                        </button>
                      </MenuItem>
                    ))}
                  </div>
                </MenuItems>
              </Menu>
            </div>

            <div 
              className={`flex flex-col 
              ${trainingCondition?.date === true || trainingCondition?.day === true ? 'gap-4' : 'gap-0'  } 
              max-mid:justify-between max-mid:flex-row`
            }>
              
              {
                trainingCondition?.date === true ?
                  <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>                    
                    <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
                      <span className='w-32 '>Date</span>
                      {/* <DatePicker startDate={moment(selectedDate).format('YYYY-MM-DD')} onChange={(date)=>setSelectedDate(date)} /> */}
                        
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          dateFormat="yyyy/MM/dd"
                        />

                      {/* <input
                        required
                        type='text'
                        value={moment(selectedDate).format('YYYY-MM-DD')}
                        readOnly
                        className='rounded-md max-mid:ml-0 ml-2 px-1 py-3 max-mid:mt-1.5 max-mid:py-1.5 border-2'
                      /> */}
                    </div>

                    <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
                      <span className='ml-4 max-mid:ml-0 mr-8 '>Time</span>
                      <input
                        required
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        className='rounded-md px-1  border-2'
                      />
                    </div>
                  </div>
                :
                null
              }



              <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
              {
                trainingCondition?.day === true ?
                <>
                  <span className='w-28 mr-[5px]'>Day</span>
                  <select
                    className='w-fit rounded-md px-1 py-2 border-2'
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                    required
                    
                  >
                    <option value=''>Select Day</option>

                    {
                      DayWeek?.map((item,index)=>(
                        <option key={index} value={item.daycount}>{item.daycount}</option>
                      )) 
                    }
                  </select>
                </>
                :
                  null
                }

                {
                  trainingCondition?.week === true ?
                  <>
                    <span className='ml-6 max-mid:mx-0 mr-8'>Week</span>
                    <select
                      className='w-fit rounded-md px-1 py-2 border-2'
                      value={week}
                      onChange={(e) => setWeek(e.target.value)}
                      required

                    > 
                      <option value=''>Select Week</option>

                      {
                        uniqueWeekcounts?.map((weekcount, index) => (
                          <option key={index} value={weekcount}>{weekcount}</option>
                        ))
                      }
                    </select>
                </>
                :
                  null
                }
                
                {
                  trainingCondition?.srno === true ?
                  <>
                    <span className='ml-6 max-mid:mx-0 mr-8'>Session No</span>
                    <input
                      required
                      type='number'
                      value={SessionNo}
                      onChange={handleSessionNo}
                      className='w-20 rounded-md px-1 py-2 border-2'
                      min='0'
                      step='1'
                    />
                </>
                :
                  null
                }
              </div>

              <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
                <span className='w-24  mr-[15px]'>Duration<span className='text-red-600'>*</span></span>
                <input
                  required
                  type='number'
                  value={durationTime}
                  onChange={handleChange}
                  className='min-w-24 rounded-md px-1 py-2 border-2'
                  placeholder='Time'
                  min='0'
                  step='1'
                />
                <select
                  className=' rounded-md px-1 py-2 border-2'
                  value={durationUnit}
                  required
                  onChange={(e) => setDurationUnit(e.target.value)}
                >
                  <option value=''>Select Format</option>
                  <option value='1'>Min</option>
                  <option value='2'>Hr</option>
                </select>

                {
                  trainingCondition?.complementory === true ?
                  <span className=''>
                    <span className='w-24 mx-4'>Complimentory</span>
                    <input
                      type='checkbox'
                      checked={Complimentory === 1}
                      onChange={handleCheckboxChange}
                    />
                    {/* <input
                      type='checkbox'
                      checked={Complimentory === 1}
                      onChange={(event) => setComplimentory(event.target.checked ? 1 : 0)}
                    />                   */}
                  </span> 
                  :
                  null
                }

              </div>

            </div>

            <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
              <span className='w-36  mr-[9px]'>Subject<span className='text-red-600'>*</span></span>
              <input
                required
                type='text'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className='w-full rounded-md px-1 py-2 border-2'
              />
            </div>

            <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
              <span className='w-40 '>Description<span className='text-red-600'>*</span></span>
              <input
                required
                type='text'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='w-full rounded-md px-1 py-2 border-2'
              />
            </div>

            <div ref={containerRef} className='flex max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
              <span className='w-40 mb-2'>Faculty</span>
              <Menu as="div" className="relative w-full inline-block text-left z-[9999]">
                <div className="w-full">
                  <MenuButton
                    onClick={handleButtonClick}
                    className="inline-flex h-15 w-full items-center flex-1 pl-4 pr-1 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                  >
                    {selectedItems?.length > 0 ? (
                      <div className="flex flex-wrap gap-2 max-w-full overflow-auto">
                        {selectedItems?.map(item => (
                          <span key={item.agencyid} className="flex items-center gap-2 p-2 text-[12px] bg-[#428BCA] text-white rounded-md">
                            {item.agencyname}
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent closing dropdown when clicking on the close button
                                removeTags(item.agencyid);
                              }}
                              className="text-white"
                            >
                              &times;
                            </button>
                          </span>
                        ))}
                      </div>
                    ) : (
                      'Select Faculty'
                    )}
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="absolute top-1.5 right-1 flex items-center w-7 text-black"
                    />
                  </MenuButton>
                </div>
                {isOpen && (
                  <MenuItems className="absolute bg-white w-full max-h-52 overflow-y-auto left-0 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                    <div className="py-1 w-full flex flex-col">
                    {allfaculty?.items?.filter(session => session.tyaam_status === 1).length > 0 ? (
                      allfaculty?.items
                        ?.filter(session => session.tyaam_status === 1)
                        .map(session => (
                          <MenuItem key={session.agencyid}>
                            {({ active }) => (
                              <button
                                onClick={() => handleSelectItem(session)}
                                className={`px-6 py-2 text-sm w-full whitespace-nowrap block items-center gap-4 ${
                                  selectedItems?.some(selected => selected.agencyid === session.agencyid)
                                  // ? 'bg-blue-100 text-blue-800'
                                  ? 'bg-[#428BCA] text-white'
                                  : active
                                  ? 'bg-blue-100 text-blue-800'
                                  : 'text-gray-700 hover:bg-gray-100'
                                }`}
                              > 
                                <span className='flex items-center gap-4'>
                                  <img
                                    className="h-14 w-14 object-cover rounded-full"
                                    src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${session.upload_photo_name}`}
                                    alt=""
                                  />
                                  <span>{session.agencyname}</span>
                                </span>
                                <span className="flex flex-col items-start gap-2 text-xs mt-2">
                                  <span className="flex items-center gap-1">
                                    <HiPhone />
                                    {session.ag_mobileno}
                                  </span>
                                  <span className="flex items-center gap-1">
                                    <MdEmail />
                                    {session.ag_email}
                                  </span>
                                </span>
                              </button>
                            )}
                          </MenuItem>
                        ))
                    ) : (
                      <div className="px-6 py-2 text-sm text-gray-700">
                        No faculties available.
                      </div>
                    )}
                    </div>
                  </MenuItems>
                )}
              </Menu>
            </div>

            {
              trainingCondition?.module === true ?
                <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                  <span className='w-36  mr-[9px]'>Module<span className='text-red-600'>*</span></span>
                  <select
                    required
                    className='w-full rounded-md px-1  py-2 border-2'
                    value={module}
                    onChange={(e) => setModule(e.target.value)}
                  >
                    <option value=''>Select Module</option>
                    {
                      sessionModules?.map((module)=>(
                        <option key={module.id} value={module.id}>{module.displayName}</option>
                      ))
                    }
                  </select>
                </div>
              :
              null
            }
        

            
            {
              filtercontent === false || filtercontent?.id == "2" || filtercontent?.id == "6" || filtercontent?.id == "7" ?
              null 
              :
              <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                <span className='w-36  mr-[9px]'>Completion Type<span className='text-red-600'>*</span></span>
                <select
                  className='w-full rounded-md px-1  py-2 border-2'
                  value={completion}
                  onChange={(e) => setcompletion(e.target.value)}
                  required
                >
                  <option value=''>Select Module</option>
                  <option value='1'>Self</option>
                  <option value='2'>Select Test</option>
                  <option value='3'>By Faculty</option>
                </select>
              </div>                  

            }    
                


            <div className='flex max-mid:items-start max-mid:gap-4 max-mid:flex-col items-center relative'>
              <span className='w-40 mb-2'>Tags</span>
              <div className='relative w-full' ref={inputRef}>
                <div className='flex flex-wrap items-center border-2 rounded-md p-2'>
                  {tags.map((t) => (
                    <Tag
                      key={t.id} 
                      closable
                      onClose={() => removeTag(t.id)}
                      className='m-1 bg-[#1CAF9A] custom-tag text-white flex items-center'
                    >
                      {t.text}
                    </Tag>
                  ))}
                   <Input
                    required={tags?.length === 0} 
                    type='text'
                    value={tag}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    className='flex-1 border-none outline-none'
                    style={{ border: 'none', boxShadow: 'none', outline: 'none' }} // Remove blue border
                  />
                </div>
              </div>
            </div>

          </div>
                  
          <div className='w-full  flex items-center px-6 justify-between mt-4 border-t-2 pt-6'>
            
            <button
              className='bg-[#CCCCCC] text-black px-4 py-2 rounded'
              onClick={() => setShowModal(false)}
            >
              Close
            </button>

            <button
              type='submit'
              // onClick={CreateSession}
              className='bg-[#428BCA] text-white px-4 py-2 rounded '
            >
              Create Session
            </button>

          </div>

          </form>
        
        </div>
      </Draggable>
    </div>
  )
}

export default CreateSessionFromCalendar