import React, { useEffect } from 'react'
import TestResultModal from './TestResultModal'
import TestResultList from './TestResultList'
import { useConfig } from '../../../ConfigContext'
import axios from 'axios'
import { useFeedback } from '../../../Context/FeedbackContext'
import { CircularProgress } from '@mui/material'

const ViewTestResult = ({TestQuestionID,TestParticipantId}) => {
  const config = useConfig()
  const user = JSON.parse(localStorage.getItem('user'));
  
  const {
    summarydata,
    testresultdata, 
    setsummarydata,
    settestresultdata
  } = useFeedback()


  const getParticipantTestResultsSummary = async () => {

    const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_EVAL_API_KEY,
        'Content-Type': 'application/json',
    }

    try {
      const { data } = await axios.get(`${config?.REACT_APP_EVAL_API_PATH}/ParticipantTestResults/ParticipantResultSummary`, {
          headers: headers,
          params: {
            Participantid: user?.userdetails?.agencyid,
            TestParticipantId: TestParticipantId
          }
      });

      return data
      // settrainingDetails(data)
    } catch (error) {
      console.log('Error session type', error);
    }
  }

  const getParticipantTestResult = async () => {

    const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_EVAL_API_KEY,
        'Content-Type': 'application/json',
    }

    try {
      const { data } = await axios.get(`${config?.REACT_APP_EVAL_API_PATH}/ParticipantTestResults/${user?.userdetails?.agencyid}/${TestQuestionID}`, {
          headers: headers,
      });
      return data
      // settrainingDetails(data)
  } catch (error) {
    console.log('Error session type', error);
  }
  }

  // useEffect(() => {
  //   const fetchdata = async() =>{
  //     const summarydata = await getParticipantTestResultsSummary()
  //     const testresultdata = await getParticipantTestResult()

  //     setsummarydata(summarydata)
  //     settestresultdata(testresultdata)
  
  //   }
  
  //   fetchdata()
  // }, [])

  useEffect(() => {
    const fetchData = () => {
      // Start the first API call immediately
      getParticipantTestResultsSummary()
        .then(summaryData => {
          // Set the summary data from the first API
          setsummarydata(summaryData);
  
          // Add a timeout for the second API call
          setTimeout(() => {
            getParticipantTestResult()
              .then(testResultData => {
                // Set the test result data after the delay
                settestresultdata(testResultData);
              })
              .catch(error => {
                console.error("Error fetching test result data:", error);
              });
          }, 1000); // Delay of 2000ms (2 seconds) for the second API call
        })
        .catch(error => {
          console.error("Error fetching summary data:", error);
        });
    };
  
    fetchData();
  }, []);
  
  // useEffect(() => {
  //   const fetchData = () => {
  //     // Use Promise.all to execute both API calls simultaneously
  //     Promise.all([
  //       getParticipantTestResultsSummary(),
  //       getParticipantTestResult()
  //     ])
  //     .then(([summaryData, testResultData]) => {
  //       // Destructure the results and set them in state
  //       setsummarydata(summaryData);
  //       settestresultdata(testResultData);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching data:", error);
  //     });
  //   };
  
  //   fetchData();
  // }, []);
  
  if ( summarydata === null && testresultdata === null ) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
      </div>
    );
  }
  
  return (
    <div className=''>
        <TestResultModal/>
        <TestResultList/>
    </div>
  )
}

export default ViewTestResult