import React, { useState } from 'react';
import styled from 'styled-components';
import { useUser } from '../../UserContext';
import { useConfig } from '../../ConfigContext';

// Styled components
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const ModalContent = styled.div`
  background-color: #fff;
  width: 269px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin-bottom: 20px;
  }

  & > select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${({ primary }) =>
    primary &&
    `
    background-color: #428bca;
    color: #fff;
  `}

  ${({ primary }) =>
    primary &&
    `
    &:hover {
      background-color: #0056b3;
    }
  `}

  ${({ secondary }) =>
    secondary &&
    `
    background-color: #6c757d;
    color: #fff;
  `}

  ${({ secondary }) =>
    secondary &&
    `
    &:hover {
      background-color: #5a6268;
    }
  `}
`;

// Modal component
const UserTypeModal = ({ isOpen, closeModal, onYesClick }) => {
  const config = useConfig();
  const user = JSON.parse(localStorage.getItem('user'));
  const { selectedUserType, setSelectedUserType } = useUser(); // Use the context hook

  // Check if user and user details are defined
  if (!user || !user.userdetails) {
    return null; // or handle loading state/error state
  }

  // Check if usertype length is greater than 1 to determine modal visibility
  const shouldDisplayModal = user.userdetails.usertype.length > 1;

  const handleUserTypeChange = (event) => {
    setSelectedUserType(event.target.value);
  };

  return (
    <>
      {isOpen && shouldDisplayModal && (
        <ModalBackdrop>
          <ModalContent>
            <ModalImage
              src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${user.userdetails.photopath}`}
              alt="User Avatar"
            />
            <p>{user.userdetails.username}</p> 

            {shouldDisplayModal && (
              <select value={selectedUserType} onChange={handleUserTypeChange}>
                {user.userdetails.usertype.map((type, index) => (
                  <option key={type.usertypeid} value={type.usertypeid} selected={index === 0}>
                    {type.usertypename}
                  </option>
                ))}
              </select>
            )}

            <ButtonContainer>
              <Button primary onClick={() => onYesClick(selectedUserType)}>
                Confirm
              </Button>
            </ButtonContainer>
          </ModalContent>
        </ModalBackdrop>
      )}
    </>
  );
};

export default UserTypeModal;
