import React, { useState, useEffect,useContext } from "react";
import { Box, Button, MenuItem, Select, Stack, TextField, FormControl, InputLabel, useTheme } from "@mui/material";
import axios from "axios";
import { useConfig } from "../../ConfigContext";
import { useUser } from "../../UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TranslationContext } from "../../TranslationContext";

const DmsActivity = ({ isSidePanelOpen, toggleSidePanel }) => {
  const theme= useTheme()
  const { translations } = useContext(TranslationContext);
  const config1 = useConfig();
  const { selectedYear } = useUser();
  const [isVisibleArray, setIsVisibleArray] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [designationOptions, setDesignationOptions] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [data, setData] = useState([]);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sidebar, setSidebar] = useState([]);
  const [activeButton, setActiveButton] = useState(null);

  // Fetch designation options
  const fetchDesignationOptions = async () => {
    try {
      const response = await axios.get(`${config1.REACT_APP_API_URL}/Charges`, {
        headers: {
          "Content-Type": "application/json",
          APIKey: config1.REACT_APP_API_KEY,
        },
      });
      setDesignationOptions(response.data);
    } catch (error) {
      console.error("Error fetching designation options:", error);
    }
  };

  // Fetch employee details based on selected employee ID
  const fetchEmployeeDetails = async (selectedEmployeeId) => {
    try {
      const response = await axios.get(
        `${config1.REACT_APP_API_URL}/agency_by_charge?chargeid=${selectedEmployeeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            APIKey: config1.REACT_APP_API_KEY,
          },
        }
      );
      setEmployeeDetails(response.data);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      setEmployeeDetails([]);
    }
  };

  useEffect(() => {
    if (config1) {
      fetchDesignationOptions();
    }
  }, [config1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSidePanelOpen) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay for side panel open
          await fetchAdditionalData(config1);
        } else {
          await fetchAdditionalData(config1);
        }

        // Fetch additional data for DMS
        if (selectedYear) {
          await fetchAdditionalDataDMS();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedYear, isSidePanelOpen, config1]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const fetchAdditionalData = async (config) => {
    console.log("Fetching additional data with config", config);
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetActivity`, { headers });

      console.log(config.REACT_APP_API_URL);

      if (response && response.data) {
        const { items, totalRecords } = response.data;
        const options = items.map((item) => ({
          sidebaritem: item.ttca_name,
          sidebarsubitem: item.ttca_description,
          itemId: item.ttca_id,
          funccode: item.ttca_code,
        }));
        console.log(options);

        setSidebar(options);
        setTotalRecords(totalRecords);
      }
      console.log("All data DMS DATA ", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAdditionalDataDMS = async () => {
    if (!selectedYear) {
      console.error("selectedYear is not available");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const employeeid = user.userdetails.agencyid;
    const applicationtypeid = "129";
    const startDate = `${selectedYear.split('-')[0]}-04-01`;
    const endDate = `${selectedYear.split('-')[1]}-03-31`;

    try {
      const response = await axios.get(
        `${config1.REACT_APP_API_URL}/DMSData?fromdate=${startDate}&todate=${endDate}&applicationtypeid=${applicationtypeid}&employeeid=${employeeid}`,
        {
          headers: {
            "Content-Type": "application/json",
            APIKey: config1.REACT_APP_API_KEY,
          },
        }
      );
      const formattedData = response.data.map((item) => ({
        ...item,
        action: "",
      }));
      setData(formattedData);
      setIsVisibleArray(Array(formattedData.length).fill(false));
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  const handleSelect1Change = (event) => {
    setSelectedOption1(event.target.value);
  };

  const handleSelect2Change = (event) => {
    setSelectedOption2(event.target.value);
    setSelectedEmployeeId(event.target.value);
    fetchEmployeeDetails(event.target.value);
  };

  const handleSendButtonClick = async (index) => {
    if (!selectedOption2 || !selectedOption1 || !remark) {
      toast.error("Please fill all fields");
      return;
    }

    const item = data[index];
    const action = item.action;

    const newVisibilityArray = [...isVisibleArray];
    newVisibilityArray[index] = !newVisibilityArray[index];
    setIsVisibleArray(newVisibilityArray);

    const applicationid = item.applicationid;
    let docStatus;

    switch (action) {
      case "Send For Approve":
        docStatus = 3;
        break;
      case "Forward":
        docStatus = 2;
        break;
      case "Approve":
        docStatus = 1;
        break;
      default:
        console.warn("Unknown action:", action);
        return;
    }

    const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
    const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from localStorage
    if (!user) {
      toast.error("User data not found");
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      APIKey: config1.REACT_APP_API_KEY,
    };

    const payload = {
      tttds_info_desc: null,
      doc_id: applicationid,
      createdon: createdon,
      createdby: user.userdetails.agencyid,
      branchid: user.userdetails.branchid,
      docremark: remark,
      docdate: createdon,
      actiondate: createdon,
      CreatedBy_empid: user.userdetails.agencyid,
      fwd_empid: selectedOption1 || null,
      tat_type_id: 129,
      doc_status: docStatus,
      attached_doc: null,
      attached_doc_name: null,
      doctype: 0,
      draftletter: null,
      tttds_is_final: 0,
      tttds_letter_type: 0,
      docremarkenc: null,
      draftletterenc: null,
    };

    try {
      const response = await axios.post(`${config1.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
        headers: headers,
      });
      toast.success("Data sent successfully");
      setSelectedOption1("");
      setSelectedOption2("");
      setSelectedEmployeeId("");
      setRemark("");
      fetchAdditionalData()
      fetchAdditionalDataDMS();
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Error sending data");
    }
  };

  const toggleVisibility = (index, action) => {
    const newVisibilityArray = [...isVisibleArray];
    newVisibilityArray[index] = !newVisibilityArray[index];
    setIsVisibleArray(newVisibilityArray);
    const newData = [...data];
    newData[index].action = action;
    setData(newData);
  };

  const renderContent = () => {
    const filteredData = data?.filter(item => {
      if (activeTab === "preview") {
        return item.appCurrentstatusBit === 1;
      }
      if (activeTab === "approve") {
        return item.appCurrentstatusBit === 2;
      }
      return true;
    });

    if (filteredData.length === 0) {
      return <p style={{ marginLeft: "30px" }}>No data available</p>;
    }

    return (
      <div className="activity-log" style={{ maxHeight: "400px", overflowY: "auto" }}>
        <ul className="activity-log-list">
          {filteredData.map((item, index) => {
            const isCancelled = item.status_txt === "Cancelled";
            return (
              <li key={item.id}>
                <p>{item.applicationno}</p>
                <p>{formatDate(item.receiveddate)}</p>
                <h3 className="dbs-craated-funcation" style={{ fontSize: "15px", fontWeight: "500", marginTop: "-9px" }}>
                  {translations[item.status_txt]}
                </h3>
                <p>Remark: {item.previousRemark}</p>
                <p>By: {item.receivedFromEmp}</p>
                <p>Send To: {item.receivedhEmpname}</p>

                {!isCancelled && (
                  <div className="div-buttons" style={{ display: "flex" }}>
                    <button
                      className="btn dns-button"
                      onClick={() => { toggleVisibility(index, "Forward"); setActiveButton("Forward"); }}
                      style={{
                        backgroundColor: activeButton === "Forward" ? "blue" : "transparent",
                        color: activeButton === "Forward" ? "white" : "black",
                      }}
                    >
                      Forward
                    </button>
                    <button
                      className="btn dns-button"
                      onClick={() => { toggleVisibility(index, "Send For Approve"); setActiveButton("Send For Approve"); }}
                      style={{
                        backgroundColor: activeButton === "Send For Approve" ? "blue" : "transparent",
                        color: activeButton === "Send For Approve" ? "white" : "black",
                        marginLeft: "2px", marginRight: "2px"
                      }}
                    >
                      Send For Approve
                    </button>
                    <button
                      className="btn dns-button"
                      onClick={() => { toggleVisibility(index, "Approve"); setActiveButton("Approve"); }}
                      style={{
                        backgroundColor: activeButton === "Approve" ? "blue" : "transparent",
                        color: activeButton === "Approve" ? "white" : "black",
                      }}
                    >
                      Approve
                    </button>
                  </div>
                )}

                {isVisibleArray[index] && (
                  <Box style={{ marginTop: "3px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-designation-label">Select Designation</InputLabel>
                      <Select
                        fullWidth
                        value={selectedOption2}
                        onChange={handleSelect2Change}
                        label="Select Designation"
                      >
                        <MenuItem value="">Select Designation</MenuItem>
                        {designationOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id.toString()}>
                            {option.hname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "4px" }}>
                      <InputLabel id="select-employee-label">Select Employee Name</InputLabel>
                      <Select
                        fullWidth
                        value={selectedOption1}
                        onChange={handleSelect1Change}
                        label="Select Employee Name"
                      >
                        <MenuItem value="">Select Employee Name</MenuItem>
                        {employeeDetails.map((emp) => (
                          <MenuItem key={emp.id} value={emp.agencyid.toString()}>
                            {emp.agencyname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      style={{ marginTop: "8px" }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "8px" }}
                      onClick={() => handleSendButtonClick(index)}
                    >
                      Send
                    </Button>
                  </Box>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={`app ${isSidePanelOpen ? "side-panel-open-left" : ""}`}>
      <div className="second-rightside-toggle side-panel-toggle" style={{backgroundColor:theme.palette.primary.main}}>
        <button type="button" onClick={toggleSidePanel}>
          <span className="toggle-text">DMS</span>
        </button>
        <div className="side-panel sidepanel-rightside" role="show activity log">
          <div className="flex-button" style={{ justifyContent: "space-around", paddingTop: "5px" }}>
            <Stack
              direction="row"
              spacing={1}
              mb={2}
              style={{ width: "97%", marginLeft: "14px", borderBottom: "1px solid blue" }}
            >
              <Button
                style={{ border: "none", fontSize: "small" }}
                variant={activeTab === "all" ? "contained" : "outlined"}
                onClick={() => setActiveTab("all")}
                sx={{
                  bgcolor: activeTab === "all" ? "blue" : "transparent",
                  color: activeTab === "all" ? "white" : "black",
                }}
              >
                All
              </Button>
              <Button
                style={{ border: "none", fontSize: "small" }}
                variant={activeTab === "approve" ? "contained" : "outlined"}
                onClick={() => setActiveTab("approve")}
                sx={{
                  bgcolor: activeTab === "approve" ? "blue" : "transparent",
                  color: activeTab === "approve" ? "white" : "black",
                }}
              >
                Approve
              </Button>
              <Button
                style={{ border: "none", fontSize: "small" }}
                variant={activeTab === "preview" ? "contained" : "outlined"}
                onClick={() => setActiveTab("preview")}
                sx={{
                  bgcolor: activeTab === "preview" ? "blue" : "transparent",
                  color: activeTab === "preview" ? "white" : "black",
                }}
              >
                Review
              </Button>
            </Stack>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmsActivity;
