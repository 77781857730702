import { useTheme } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
const Breadcrumb = ({ paths }) => {

  const theme = useTheme()
  return (
    <div className="pageheader">
      <div className="media">
        <div className="pageicon pull-left" style={{ backgroundColor: theme.palette.primary.main }}>
          <i className="fa fa-home" />
        </div>
        <div className="media-body">
          <ul className="breadcrumb">
            <li>
              <NavLink exact to="/dashboard">
                <i className="glyphicon glyphicon-home" />
              </NavLink>
            </li>
            {paths.map((path, index) => (
              <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`} aria-current={index === paths.length - 1 ? 'page' : ''}>
                {index === paths.length - 1 ? (
                  path.title
                ) : (
                  <NavLink to={path.url}>{path.title}</NavLink>
                )}
              </li>
            ))}
          </ul>
          <h4>{paths[paths.length - 1].title}</h4>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
