import React, { useEffect } from 'react'
import ViewTestResult from '../components/Content/TestResult/ViewTestResult'
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs.js';
import { useLocation } from 'react-router-dom';

const TestResult = () => {
    const location = useLocation()
    const { testparticipantid , testquestionid } = location.state || {}; 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Test List', url: '/test-list' },
        { title: 'Test Result', url: '' }
    ];
 
    return <>
        <Breadcrumb paths={paths} />
        <div className='p-6 h-full w-full'>
            <ViewTestResult  TestQuestionID={testquestionid} TestParticipantId={testparticipantid} />
        </div>
    </>
  
}

export default TestResult