import React, { useState, useEffect,useContext } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom"; // Import useNavigate for redirection in React Router v6
// import Breadcrumb from "../../componentHome/commonComponent/Breadcrumbs";
import TrainingsDashboard from "../Content/TrainingDashboard/TrainingsDashboard";
// import { useTheme } from "../../ThemeProvider";
import { useFeedback } from "../../Context/FeedbackContext";
// import Breadcrumb from "../../componentHome/commonComponent/Breadcrumbs";
import { Switch } from 'antd';
import { useMediaQuery, useTheme } from "@mui/material";
import Toggle from "react-toggle";
import DashBoardAnalytics from "../Content/TrainingDashboard/DashBoardAnalytics";
import { TranslationContext } from "../../TranslationContext";

const Breadcrumb = ({ paths }) => {
  const { isActive, setIsActive } = useFeedback(); 
  const theme = useTheme()
  const isMobile = useMediaQuery(("768px"));
  
  const handleToggle = () => {
    setIsActive(!isActive); 
  };

  return (
    <div className="pageheader pr-8 flex justify-between">
      <div className="media ">
        <div className="pageicon pull-left" style={{backgroundColor:theme?.palette?.primary?.main}}>
          <i className="fa fa-home" />
        </div>
        <div className="media-body">
          <ul className="breadcrumb">
            <li>
              <NavLink exact to="/dashboard">
                <i className="glyphicon glyphicon-home" />
              </NavLink>
            </li>
            {paths.map((path, index) => (
              <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`} aria-current={index === paths.length - 1 ? 'page' : ''}>
                {index === paths.length - 1 ? (
                  path.title
                ) : (
                  <NavLink to={path.url}>{path.title}</NavLink>
                )}
              </li>
            ))}
          </ul>
          <h4>{paths[paths.length - 1].title}</h4> 
        </div>
      </div>

      <div className="sm:hidden flex items-end ">
        <div className="flex  max-phone:flex-col items-center gap-4">
          <span className="text-sm ">{isActive ? 'Hide Analytics' : 'Show Analytics' }</span>
          <Switch onChange={handleToggle} checked={isActive}/>
        </div>
      </div>

    </div>
  );
};

const MainpainalPage = () => {
  const { translations } = useContext(TranslationContext);

  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    // Check if user data exists
    if (user) {
      setLoading(false); // Set loading to false if user data is already available
    } else {

// Simulate loading for 5 seconds
      const timer = setTimeout(() => {
        setLoading(false); // After 5 seconds, set loading to false
        // Redirect to login page
        navigate("/login-page"); // Replace '/login' with your actual login route
      },500);

      return () => clearTimeout(timer); // Clean up timer on component unmount
    }
  }, [user, navigate]);

  if (loading) {
    return <p> <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-gray-900"></div>
    </div></p>; 
  }

  const paths = [
    { title: translations['Dashboard']||'Dashboard', url: '/dashboard' }
  ];

  return (
    <>
      <div className="mainwrapperreact">
         <Breadcrumb paths={paths}/>
          <div className="p-6">
            <DashBoardAnalytics/>
            <TrainingsDashboard/>
        </div>
      </div>
    </>
  );
};

export default MainpainalPage;
