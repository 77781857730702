import React, { useContext, useEffect, useState } from 'react'
import { TranslationContext } from '../../../TranslationContext'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTheme } from '../../../ThemeProvider'
import { IoBan, IoCloseOutline } from 'react-icons/io5'
import { MdOutlineDone } from 'react-icons/md'
import { useFeedback } from '../../../Context/FeedbackContext'


const TestListTable = ()=>{
    const { translations } = useContext(TranslationContext)
    const { theme } = useTheme()
    const { testresultdata , settestresultdata } = useFeedback()
    const [sortConfig, setSortConfig] = useState({
        key: '',
        direction: 'asc', 
    });

    const sortData = (key) => {
        let direction = 'asc';
        
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
    
        setSortConfig({ key, direction });
    
        const sortedData = [...testresultdata].sort((a, b) => {
            // Handle undefined/null values for nested properties
            let valueA = a[key];
            let valueB = b[key];
    
            // Handle nested properties (access deep nested values)
            if (key === 'question') {
                valueA = a.question?.question1 || '';  // Access the question1 property
                valueB = b.question?.question1 || '';
            } else if (key === 'skill_tag') {
                valueA = a.testQuestion?.skillTag || '';  // Access the skillTag property
                valueB = b.testQuestion?.skillTag || '';
            } else if (key === 'isAnsweredCorrect') {
                valueA = a.isAnsweredCorrect;
                valueB = b.isAnsweredCorrect;
            } else if (key === 'result') {
                // For result, you may want to use the boolean flag isAnsweredCorrect, for example
                valueA = a.isAnsweredCorrect === 1 ? 'Correct' : 'Incorrect';
                valueB = b.isAnsweredCorrect === 1 ? 'Correct' : 'Incorrect';
            } else if (key === 'marks') {
                valueA = a.test?.mark_per_question || 0;  
                valueB = b.test?.mark_per_question || 0;
            }
    
            // Ensure the values are strings for comparison
            const strA = valueA.toString().toLowerCase();
            const strB = valueB.toString().toLowerCase();
    
            if (direction === 'asc') {
                return strA < strB ? -1 : strA > strB ? 1 : 0;
            } else {
                return strA > strB ? -1 : strA < strB ? 1 : 0;
            }
        });
    
        settestresultdata(sortedData);
    };
    
    return (
        <>
        
        <div className="table-container">
            <TableContainer component={Paper}>
                <Table>

                    <TableHead style={{ backgroundColor: theme?.palette?.primary?.main }}>
                        <TableRow>

                            <TableCell
                                style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                                className='feedbck-titel'
                                onClick={() => sortData('question')}
                            >
                                {translations["Questions"] || "Questions"}
                                {sortConfig.key === 'question' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                            </TableCell>

                            <TableCell
                                style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                                className='feedbck-titel'
                                onClick={() => sortData('skill_tag')}
                            >
                                {translations["Skill_tags"] || "Skill Tags"}
                                {sortConfig.key === 'skill_tag' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                            </TableCell>
                            
                            <TableCell
                                style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                                className='feedbck-titel'
                                onClick={() => sortData('isAnsweredCorrect')}
                            >
                                {translations["Marks"] || "Marks"}
                                {sortConfig.key === 'isAnsweredCorrect' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                            </TableCell>
                            
                            <TableCell
                                style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                                className='feedbck-titel'
                                onClick={() => sortData('result')}
                            >
                                {translations["Result"] || "Result"}
                                {sortConfig.key === 'result' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                            </TableCell>
                        
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {testresultdata?.map((meeting, index) => (
                            <TableRow key={index}>
                            
                                <TableCell 
                                    style={{ borderLeft: '2px solid #ddd' }} 
                                    className="feedback-row"
                                >
                                    <span dangerouslySetInnerHTML={{ __html: meeting?.question?.question1 }} />
                                </TableCell>

                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                    <span dangerouslySetInnerHTML={{ __html: meeting?.testQuestion?.skillTag }} />
                                </TableCell>
                                
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                    {meeting.isAnsweredCorrect === 1 ? meeting?.test?.mark_per_question : 0 }
                                </TableCell>

                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                    {
                                        meeting.isAnsweredCorrect === 0 ? 
                                        <IoCloseOutline className='text-red-500'  /> 
                                        :
                                        meeting.isAnsweredCorrect === 3 ? 
                                        <IoBan className='text-red-500'/>
                                        :
                                        <MdOutlineDone className='text-green-500' /> 
                                    }
                                </TableCell>
                            
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </div>

        <div className="card-container p-0">
            {testresultdata?.map((meeting, index) => (
            <div className="card mb-0" key={index}>

                <div className="card-body mb-0">
                    <p><strong>Question : </strong> <span dangerouslySetInnerHTML={{ __html: meeting?.question?.question1}} /></p>
                    <p><strong>Skill Tags :</strong> <span dangerouslySetInnerHTML={{ __html: meeting?.testQuestion?.skillTag}} /></p>
                    <p className='flex gap-2 items-center'><strong>Marks :</strong> {meeting?.isAnsweredCorrect === 1 ? meeting?.test?.mark_per_question : 0 }
                    </p>
                    <p className='flex gap-2 items-center mb-0'><strong>Result :</strong> 
                        {
                            meeting.isAnsweredCorrect === 0 ? 
                            <IoCloseOutline className='text-red-500'  /> 
                            : 
                            <MdOutlineDone className='text-green-500' /> 
                        }
                    </p>
                </div>
                
            </div>
            ))}
        </div>

    </>
    )
}


const TestResultList = () => {
    const { translations } = useContext(TranslationContext)
    const { testresultdata } = useFeedback()
    const { theme } = useTheme()

    useEffect(() => {
        console.log(testresultdata)
    }, [])
    

    const totalMarks = Object.values(testresultdata ?? {})?.reduce((sum, question) => {
        return sum + (question?.test?.mark_per_question || 0);
      }, 0);
      
      const marksObtained = Object.values(testresultdata ?? {})?.reduce((sum, question) => {
        if (question?.isAnsweredCorrect !== 0 && question?.isAnsweredCorrect !== 3) {
          return sum + (question?.test?.mark_per_question || 0);
        }
        return sum;
      }, 0);
      

    return (
    <div className='py-6 flex flex-col gap-10 h-full w-full'>
        <div className="flex max-sm:justify-center justify-end pr-8">
            <div className='flex gap-10'>
                <span className='text-red-500 font-semibold'>
                    {translations["Total"] || "Total"} {translations["Marks"] || "Marks"} :  {totalMarks}
                </span>
                <span className='text-green-500 font-semibold'>
                    {translations["Marks"] || "Marks"} {translations["Obtained"] || "Obtained"} : {marksObtained}
                </span>
            </div>
        </div>
        <TestListTable />
    </div>
  )
}

export default TestResultList