import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useFeedback } from '../../../Context/FeedbackContext';
import { useConfig } from '../../../ConfigContext';
import { IoIosBook, IoIosClose, IoIosTimer, IoIosVideocam, IoMdPlayCircle } from 'react-icons/io';
import { LuCalendarDays } from 'react-icons/lu';
import Progressbar from './Progressbar';
import { TranslationContext } from '../../../TranslationContext';
import { useUser } from '../../../UserContext';
import axios from 'axios';
import { useTheme } from '../../../ThemeProvider';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { Windowopen } from '../../../componentHome/utils/Validation';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { v4 as uuidv4 } from "uuid";

const VideoComponent = ({url  , onclose})=>{

    const config = useConfig()
    function cleanVideoUrl(url) {
        return url.replace(/(\.(mp4|avi|mov|wmv|flv))(.+)$/, "$1");
    }
    return (
        <div className='locked flex items-center justify-center'>
            <div className='h-2/3 max-sm:h-1/3 max-lg:w-5/6 w-4/6 relative bg-white shadow-lg rounded-xl pt-14 p-8'>
                <IoIosClose onClick={onclose} fontSize={24} className='absolute cursor-pointer right-5 top-3'/>
                <video 
                    controls 
                    autoPlay
                    className='h-full w-full object-cover object-center'  
                    src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${cleanVideoUrl(url)}`}                                 
                ></video>
            </div>
        </div>
    )
}

const Modal = ({ training , status , isOpen, onClose }) => {
    const navigate = useNavigate()
    const { theme } = useTheme() 
    const config = useConfig()
    const { selectedUserType , selectedYear  } = useUser()
    const { dashboardpagenumber , setdashboard_Data , searchQuery , setLoading , dashboardpagesize , activeButton } = useFeedback()
    const [sponsors, setsponsors] = useState(null)
    const [selectedsponsor, setselectedsponsor] = useState(null)
    const [restriction, setrestriction] = useState(null)
    const user = JSON.parse(localStorage.getItem('user'));

    const getSponsors = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TRG_SPONSOR?trainingid=${training?.trainingId}`, {
            headers: headers,
          });
          console.log("Get_Certificate_Signatory", data)
          return data 
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    const Check_Payment_Gateway_Available = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Check_Payment_Gateway_Available`, {
            headers: headers,
          });
          return data 
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    useEffect(() => {
        const fetchData = async () =>{
            if(training != undefined){
                const data = await getSponsors()
                setsponsors(data)
                const condition= await Check_Payment_Gateway_Available()
                setrestriction(condition)
            } return
        }
        fetchData()
    }, [])

    const registerParticipantInTraining = async (sponsorid) => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
        
        const postData = {
            trainingid: training?.trainingId,
            sponsorid: selectedsponsor || sponsorid,
            participantid: user?.userdetails?.agencyid,
            branchid: user?.userdetails?.branchid,
            createdby: user?.userdetails?.agencyid,
            userid: user?.userdetails?.agencyid,
        }

        try {
            const { data } = await axios.post(`${config?.REACT_APP_API_URL}/TRG_PARTICIPANT_MAP`, postData, { headers: headers });
            console.log('postData' , postData)
            console.log('selectedsponsor' , postData?.sponsorid)
            return data 
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    const registerparticipant = async(data)=>{
        const check = await registerParticipantInTraining(data)
        if(check){
            await getDashboardData()
        }
        if(training?.training_SponsorType == 4 ){
            console.log( "paid" , training?.training_SponsorType == 4)
            if (restriction) {
                console.log("Restriction true hai ji")
                Windowopen(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_payment.aspx?id=${training?.trainingId}*${user?.userdetails?.agencyid}*100`)
            } else if (!restriction){
                console.log("Restriction false hai ji")
                Windowopen(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_Payoffline.aspx?id=${training?.trainingId}*${user?.userdetails?.agencyid}*100`)
            } else {
                console.log("Restriction nahi hai ji" , training?.training_SponsorType == 4  , restriction)
                return
            }
        } else {
            navigate(`/Session_trg/SessionDetail/${training?.trainingId}`)
        }
        onClose()
    }

    const getDashboardData = async (input,criteria) => {
        console.log("inputdata" , input)
        if (user) {
            setLoading(true);
            const headers = {
                Accept: "application/json, text/plain",
                APIKey: config.REACT_APP_API_KEY,
                "Content-Type": "application/json",
            };

            const startdate = `${selectedYear.split('-')[0]}-04-01`
            const enddate = `${selectedYear.split('-')[1]}-03-31`

            const payload = {

                searchCriteria: [
                    {
                        column: 'trainingcode',
                        value: input,
                        condition: '=',
                        nextoperator: 'AND',
                    },
                ],
            };

            try {
                const dashboardataurl = `${config?.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`
                const allDashboardDataurl = `${config?.REACT_APP_API_URL}/Dashboard_All_Trg_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`
                const  { data }  = await axios.post( 
                    activeButton === 'myTrainings' ?
                    dashboardataurl 
                    :
                    allDashboardDataurl ,
                searchQuery === '' || input === undefined ? null : payload,                
                // null ,                
                { headers: headers });
                setdashboard_Data(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () =>{
            if(training != undefined){
                if(sponsors?.length === 1){
                    await registerparticipant(sponsors[0]?.agencyid)
                } else{
                    console.log('Sponsors are still loading or not available');
                } 
            } return
        }
        fetchData()
    }, [training , sponsors])

    if(sponsors?.length === 1){
        return 
    }

    return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300"
          onClick={onClose}
        >
            <div
                className="bg-white p-8 rounded-2xl shadow-xl w-full max-w-md mx-auto animate-modal"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-between items-center mb-8">
                    <h2 className="text-2xl font-semibold text-gray-800">Participant Registration</h2>
                    <IoClose 
                        className="text-gray-500 cursor-pointer hover:text-gray-700 transition-colors duration-200"
                        onClick={onClose} 
                    />
                </div>

                <div className="mb-8">
                    <label 
                        className="block text-xl font-medium text-gray-700 mb-2" 
                        htmlFor="sponsor"
                    >
                        Select Sponsor
                    </label>
                    <select
                        id="sponsor"
                        value={selectedsponsor}
                        onChange={(e) => setselectedsponsor(e.target.value)}
                        className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-lg text-gray-800"
                    >
                        <option value="">Select Sponsor</option>
                        {sponsors?.map((sponsor, index) => (
                            <option key={index} value={sponsor?.agencyid}>
                                {sponsor?.agencyname}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mt-8">
                    <button
                        title={!selectedsponsor && 'Please Select Sponsor'}
                        disabled={!selectedsponsor}
                        onClick={registerparticipant}
                        style={{
                            backgroundColor: selectedsponsor ? theme?.palette?.primary?.main : '#d1d5db',
                            color: selectedsponsor ? 'white' : '#6b7280'
                        }}
                        className={`w-full py-4 rounded-lg text-lg font-semibold focus:outline-none transition-all duration-200 
                            ${selectedsponsor ? 'cursor-pointer hover:bg-indigo-600' : 'cursor-not-allowed'}`}
                    >
                        Register Now
                    </button>
                </div>
            </div>
        </div>
      )}
    </>
    );
    
};

const DashboardTrainings = ({ uploadRef, saveRef, actionsRef }) => {
    const { theme } = useTheme()
    const { translations } = useContext(TranslationContext);
    const [isVisible, setisVisible] = useState(false)
    const [videoUrl, setvideoUrl] = useState(null)
    const [dataToMap, setdataToMap] = useState(null)
    const [training, settraining] = useState(null)
    const [status, setstatus] = useState(null)
    const { selectedUserType } = useUser()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [startTest, setstartTest] = useState(false);
    
    const openModal = (status , training) =>{
        setIsModalOpen(true);
        setstatus(status)
        settraining(training)
        console.log(training)
    }

    const closeModal = () => setIsModalOpen(false);
    
    const config = useConfig()
    const { 
        dashboard_Data,
        activeButton,
        searchQuery,
        dashboardpagesize,
        setsessionid,
        trainingDetails,

    } = useFeedback()
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const ShowVideoHandler = (url)=> {
        setisVisible(true)
        setvideoUrl(url)
    }   
    
    const closeHandler = ()=> {
        setisVisible(false)
        setvideoUrl(null)
    }

    const getResumeSession = async(trainingid) => {
          const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          };
  
          try {
            const { data } = await axios.get(`${config?.REACT_APP_API_URL}/GET_PARTICIPANT_NEXT_SESSION?trainingid=${trainingid}&participantid=${user?.userdetails?.agencyid}`, {
                headers: headers,
            });
            return data
          } catch (error) {
          }
    };

    const getAssignmentId = async (sessionid) => {
        const headers = {
            Accept: "application/json, text/plain",
            APIKey: config.REACT_APP_API_KEY,
            "Content-Type": "application/json",
        };
  
        const payload = {
            searchCriteria: [
                {
                    column: 'ttttt_session_id',
                    value: sessionid,
                    condition: '=',
                    nextoperator: 'AND',
                },
            ],
        };
  
        const url = `${config?.REACT_APP_API_URL}/Assignment?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}`;
  
        try {
          const { data } = await axios.post(url, payload, {
              headers: headers,
          });
          return data
        } catch (error) {
            console.error(error); 
        }
    };

    const navigatAssignmentDetails = async(sessionid) => {
        const assignment = await getAssignmentId(sessionid)
        const assignmentId = assignment?.items[0]?.assignmentID
        if (selectedUserType == 5) {
          const headers = {
            Accept: "application/json, text/plain",
            APIKey: config?.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          };
    
          const { data } = await axios.get(
            `${config?.REACT_APP_API_URL}/CHECK_SESSION_RESTRICTION`,
            {
              headers: headers,
              params: {
                userid: user?.userdetails?.agencyid,
                usertype: selectedUserType,
                sessionid: sessionid,
                trainingid: trainingDetails?.trainingId,
              },
            }
          );
          if (data === false) {
            navigate(`/assignment-detail/${assignmentId}`)
          } else {
            showModal(
              translations["current_session_before"] ||
                "You can not start current session before completing the previous sessions."
            );
          }
        } else {
          navigate(`/assignment-detail/${assignmentId}`)
        }
    };

    const getCurrentDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const getEvalIds = async (id) => {
        const headers = {
          Accept: "application/json, text/plain",
          "API-Key": config?.REACT_APP_EVAL_API_KEY,
          "Content-Type": "application/json",
        };
    
        try {
          const { data } = await axios.get(
            `${config?.REACT_APP_EVAL_API_PATH}/Tests/UserTests`,
            {
              headers,
              params: {
                usertype: selectedUserType,
                userid: user?.userdetails?.agencyid,
                trainingid: id,
              },
            }
          );
          return data;
        } catch (error) {
          console.log("Error fetching sessions", error);
        }
    };

    const handleStartTest = async (test, tag) => {
        console.log("Test data:", test);
        const userid = user?.userdetails?.agencyid
        try {
          // Step 1: Check session restriction
          const checkSessionResponse = await axios.get(
            `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
            {
              params: {
                usertype: selectedUserType,
                userid: userid,
                sessionid: test?.ttttt_session_id,
                trainingid: test?.trainingid,
              },
              headers: {
                APIKey: config?.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );
    
          if (checkSessionResponse.data === true) {
            showModal(
              translations["current_session_before"] ||
                "You can not start current session before completing the previous sessions."
            );
            //showModal("You can not start current session before completing the previous sessions.");
            return;
          }
    
          // Step 2: Check test eligibility
          const evaltestdata = await getEvalIds(test?.trainingid);
          const evaltest = evaltestdata?.find(
            (item) => item.training_SessionID === test?.ttttt_session_id
          );
          console.log("evaltest", evaltest);
    
          // Step 3: Check test eligibility
          const eligibilityResponse = await axios.get(
            `${config?.REACT_APP_API_URL}/Check_Test_Eligibility`,
            {
              params: {
                userid: userid,
                trainingid: test?.trainingid,
                testid: evaltest?.testID,
              },
              headers: {
                APIKey: config?.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );
    
          if (eligibilityResponse.data !== 1) {
            showModal(
              translations["Please_contactto_admin"] ||
                "You are not eligible to do this test,Please contact to admin."
            );
            //showModal("You are not eligible to do this test,Please contact to admin.");
            return;
          }
    
          // Step 4: Check participant test status
          const statusResponse = await axios.get(
            `${config?.REACT_APP_EVAL_API_PATH}/tests/ParticipantTestStatus`,
            {
              params: {
                testid: evaltest?.testID,
                participantid: userid,
              },
              headers: {
                "API-Key": config?.REACT_APP_EVAL_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );
    
          // Define payloads for each API call
          const updateStatusPayload = {
            tptss_id: uuidv4(),
            tptss_testid: evaltest?.testID,
            tptss_participantid: userid,
            tptss_createdon: getCurrentDate(),
            tptss_createdby: userid,
          };
    
          if (statusResponse.data === false) {
            const confirmation = window.confirm("This is your test session. Once you start, you cannot exit without submitting it. Do you want to continue ?");
            if(confirmation == true){
                
                // Step 5: Update participant test status
                await axios.post(
                    `${config.REACT_APP_EVAL_API_PATH}/Tests/Update_Participant_Test_Status`,
                    updateStatusPayload,
                    {
                        headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        "Content-Type": "application/json",
                        },
                    }
                );
                const payload1 = {
                    searchCriteria: [
                        {
                        column: "testid",
                        value: evaltest?.testID,
                        condition: "=",
                        nextoperator: "AND",
                        },
                    ],
                };
                // Step 6: Update participant test status
                const usertest = await axios.post(
                    `${config.REACT_APP_API_URL}/get_user_tests?usertype=${selectedUserType}&userid=${userid}&testtype=1&PageNumber=1&PageSize=10`,
                    payload1,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                        },
                    }
                );
                        
                const payload = {
                    skillSet: evaltest?.skillTag,
                    testID: evaltest?.testID,
                    questionCount: evaltest?.noOfQuestion,
                    questionDifficutyID: evaltest?.questionDifficultyID,
                    trainingCategory: test?.trainingCategoryID,
                };

                console.log("beforesend", usertest);
                // Step 7: Run the test
                const runTestResponse = await axios.post(
                    `${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`,
                    payload,
                    {
                        headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        "Content-Type": "application/json",
                        },
                    }
                );
                        
                if (runTestResponse.status === 200) {
                                const apiData = runTestResponse.data;
                                // Navigate to the online test page
                
                                navigate("/online-test-page", {
                                    state: { test: usertest?.data?.items[0], apiData },
                                });
                
                                console.log("apiData", apiData);
                } else {
                console.error("Error running test:", runTestResponse.statusText);
                }
                
            }

          } 
          else {
            showModal(
              translations["already_beensubmitted"] ||
                "This test has already been submitted by you."
            );
            //showModal("This test has already been submitted by you.");
            // juyjuyjyuj
          }
        } catch (error) {
          console.error("Error during API calls:", error);
          // Handle error (e.g., show a message to the user)
        }
    };

    const handleResumeSession = async(trainingid)=>{
        const resumingSession = await getResumeSession(trainingid)
        const sessionid = resumingSession?.ttttt_session_id

        // assignment redirection condition
        if(resumingSession?.ttttt_type == 6){
            navigatAssignmentDetails(sessionid)
        }
        
        // test redirection condition
        else if (resumingSession?.ttttt_type == 7){
            handleStartTest(resumingSession , resumingSession?.ttttt_tag)
        }
        else {
            navigate(`/Litteraroom/session/${trainingid}`);
            if(selectedUserType == 5 ){
                localStorage.setItem("restrictedsession", JSON.stringify(resumingSession));  
                setsessionid(resumingSession) 
            } else {
                localStorage.setItem("restrictedsession", JSON.stringify(null));  
                setsessionid(null) 
            }
        }
    }

    const upcoming_training_old = dashboard_Data?.items?.filter(item => 
        item.trainingStatus == 0 || item.trainingStatus == 1 || item.trainingStatus == 5
    );

    const [currentItems, setcurrentItems] = useState(null)
    
    const upcomingTraining = useMemo(() => {
        return dashboard_Data?.items?.filter(item => item.trainingStatus != 0);
    }, [dashboard_Data]);

    useEffect(() => {
        // const newDataToMap = activeButton === "alltrainings" ? upcomingTraining : dashboard_Data?.items;
        const newDataToMap = dashboard_Data?.items;
        setdataToMap(newDataToMap);
    }, [activeButton, dashboard_Data, upcomingTraining]);

    useEffect(() => {
        if (dataToMap) {
            setcurrentItems(dataToMap.slice(0, dashboardpagesize));
        }
    }, [dataToMap, dashboardpagesize]);

    const showModal = (message) => {
        setModalMessage(message);
        setModalOpen(true);
    };
    
    const handleModalClose = () => {
        setModalOpen(false);
        setModalMessage("");
    };

    const handleStartTestCondition = () => {
        setstartTest(false)
        return true
    }

    return (
    <>  
        {isModalOpen && (
            <Modal isOpen={isModalOpen} status={status} training={training}  onClose={closeModal} />
        )}
        {
            isVisible === true ?
            <VideoComponent url={videoUrl} onclose={closeHandler}/>
            :
            null
        }

        <div className='h-full w-full grid grid-cols-3 max-lg:grid-cols-2 max-mid:grid-cols-1 gap-4'>
            {
                    // currentItems?.filter(item => 
                    //     activeButton === "alltrainings" ? item.trainingStatus != 0 : true
                    // ).                    
                    // map((item, index) => (
                    currentItems
                    ?.filter((item, index, self) =>
                      self.findIndex(i => i.trainingId === item.trainingId) === index
                    ) // Filter duplicates based on trainingId
                    .map((item, index) => (                    
                    // currentItems?.map((item, index) => (
                    <div
                        key={index}
                        // className='min-w-xl  max-mid:min-w-full cursor-pointer rounded overflow-hidden shadow-lg bg-white transition-transform transform duration-300 ease-in-out hover:scale-105 hover:shadow-3xl hover:bg-gray-100'
                        className='carddiv relative min-w-xl max-mid:min-w-full cursor-pointer rounded shadow-lg bg-white transition-transform transform duration-300 ease-in-out hover:scale-105 hover:shadow-3xl '
                    >
                        <div className='h-64  max-mid:h-80 max-sm:h-64 overflow-hidden relative'>
                            <img
                                className='h-full w-full'
                                src={
                                    item.img_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : 
                                    `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${item.img_path}`
                                }
                                onError={(e) => {
                                    e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg`; // Fallback image
                                }}
                                alt={item.img_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : item.img_path}
                            />
                                
                            <div className='h-full opacity-0 videobtn z-[999999] w-full flex  items-center justify-center absolute top-0 left-0'>
                                {(() => {
                                    const trainingVideo = item?.trg_Setting?.displaycontrols?.find(control => control.id === 6 && control.isdisplay === 1)?.displaytext;                                        
                                    return <>
                                        {
                                            trainingVideo === undefined ?
                                            null 
                                            :
                                            <IoMdPlayCircle onClick={()=>ShowVideoHandler(trainingVideo)} fontSize={60}/>
                                        }
                                    </>
                                })()}
                            </div>
                        </div>    
                        {
                            item.trg_Setting === null ? (
                                <div className='p-2 flex flex-col gap-2'>
                                <div className='p-4 flex flex-col justify-between gap-3'>
                                        <div className='w-full flex items-start justify-between'>
                                            {/* <span className='font-bold w-2/3 text-2xl text-gray-900'>{item.t_Name} - ({item.trainingcode})</span> */}
                                            <span className='font-bold w-full text-2xl text-gray-900'>{item.t_Name} - ({item.trainingcode})</span>
                                                <span
                                                    style={{ color: theme?.palette?.primary?.main }} 
                                                    className='font-semibold text-lg'
                                                >
                                                    
                                                {/* { 
                                                    item.status_txt === "Proposed" ? translations["Proposed"] :
                                                    item.status_txt === "Started_Reg_Open" ? translations["Started_Reg_Open"] :
                                                    item.status_txt === "Started" ? translations["Started"] :
                                                    item.status_txt === "Pending" ? translations["Pending"] :
                                                    item.status_txt === "Postponed" ? translations["Postponed"] :
                                                    item.status_txt === "Canceled" ? translations["Canceled"] :
                                                    item.status_txt === "Completed" ? translations["Completed"] :
                                                    null
                                                } */}
                                                {/* <span 
                                                    title={
                                                        item.status_txt === "Proposed" ? translations["Proposed"] :
                                                        item.status_txt === "Started" ? translations["Started"] :
                                                        item.status_txt === "Started_Reg_Open" ? translations["Started_Reg_Open"] :
                                                        item.status_txt === "Pending" ? translations["Pending"] :
                                                        item.status_txt === "Postponed" ? translations["Postponed"] :
                                                        item.status_txt === "Canceled" ? translations["Cancelled"] :
                                                        item.status_txt === "Completed" ? translations["Completed"] :
                                                        item.status_txt
                                                    } 
                                                    className={`
                                                        px-4 
                                                        uppercase 
                                                        py-1 
                                                        w-fit
                                                        flex 
                                                        items-center 
                                                        justify-center 
                                                        rounded-2xl '
                                                        text-white
                                                        ${
                                                            item.status_txt === "Proposed" ? 'bg-gray-500' :
                                                            item.status_txt === "Started_Reg_Open" ? 'bg-[#9bd839]' :
                                                            item.status_txt === "Started" ? 'bg-teal-400' :
                                                            item.status_txt === "Pending" ? 'bg-orange-400' :
                                                            item.status_txt === "Postponed" ? 'bg-yellow-300' :
                                                            item.status_txt === "Canceled" ? 'bg-red-500' :
                                                            item.status_txt === "Completed" ? 'bg-green-500' :
                                                            item.status_txt
                                                        }
                                                        
                                                    `}
                                                >
                                                    <span>
                                                            {
                                                                item.status_txt === "Started" ?
                                                                's'
                                                                :
                                                                item.status_txt === "Proposed" ?
                                                                'p'
                                                                :
                                                                item.status_txt === "Started_Reg_Open" ?
                                                                'Ro'
                                                                :
                                                                item.status_txt === "Pending" ?
                                                                'p'
                                                                :
                                                                item.status_txt === "Canceled" ?                                                            
                                                                'cn'
                                                                :
                                                                item.status_txt === "Completed" ?
                                                                'c'
                                                                :
                                                                item.status_txt
                                                            }
                                                    </span>
                                                </span> */}
                                            </span>
                                        </div>
                                        <span className='text-gray-700 flex items-center mt-2 text-xl'>{item.tttt_name}</span>
                                        <span className="w-full flex flex-col gap-1 text-gray-600">
                                        <Progressbar completionPercentage={item.trg_completionpercentage} traininigid={item.trainingId} />
                                        {
                                            item.isSelfPaced === 0 ?
                                            <div className='flex gap-2'>
                                                <span className='flex items-center gap-3'>
                                                    <LuCalendarDays/>
                                                    {formatDate(item.t_StartDate)} to {formatDate(item.t_EndDate)}
                                                </span>
                                            </div>
                                            : null
                                        }
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-4">
                                                <div className='flex gap-2 items-center'>
                                                    <span className='pt-.5 flex items-center capitalize gap-2'>
                                                        <IoIosTimer fontSize={16} />
                                                        {item.duration} {item.durationType}
                                                    </span>
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <IoIosVideocam fontSize={18}/>
                                                    <span className='flex items-end'>
                                                        {item.no_of_sessions}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="text-blue-500 z-[999] absolute bottom-0 w-full left-0 flex justify-center">
                                                {
                                                    selectedUserType == 5 && activeButton === 'alltrainings' && item?.participantstatus === null ? (                                                    
                                                    // Show "Register To View" button if the conditions are met
                                                    <NavLink style={{ textDecoration: "none" }} className="w-full flex justify-center">
                                                        <button ref={uploadRef}
                                                            title={item?.trainingStatus == 0 ? 'Training is proposed try after sometimes' : 'Register To View'}
                                                            onClick={()=>openModal(item?.trainingStatus , item)}
                                                            disabled={item?.trainingStatus == 0}
                                                            style={{
                                                            backgroundColor: item?.trainingStatus == 0 ? 'orange' : theme?.palette?.primary?.main,
                                                            color: 'white',
                                                            }}
                                                            className="text-sm rounded-full h-12 w-2/3 my-4 mb-8 px-3 py-2 hover:text-white transition"
                                                        >
                                                            Register To View
                                                        </button>
                                                    </NavLink>
                                                ) : (
                                                    // If conditions are not met, show the "Overview" button
                                                    <div className='w-full flex'>
                                                        <NavLink 
                                                            style={{ textDecoration: "none" }} 
                                                            className="w-full flex justify-center" 
                                                            to={`/Session_trg/SessionDetail/${item.trainingId}`}
                                                        >
                                                            <button 
                                                                onMouseEnter={(e) => {
                                                                e.currentTarget.style.backgroundColor = theme?.palette?.primary?.main; // Reset to original
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                e.currentTarget.style.backgroundColor = 'white'; // Replace with desired hover color
                                                                }}
                                                                style={{
                                                                borderColor: theme?.palette?.primary?.main,
                                                                color: theme?.palette?.primary?.main,
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                }}
                                                                className="text-sm rounded-full h-12 w-2/3 my-4 mb-8 px-3 py-2 hover:text-white transition"
                                                            >
                                                                Overview
                                                            </button>
                                                        </NavLink>
                                                        {
                                                            item.no_of_sessions > 0 &&
                                                            <NavLink 
                                                                style={{ textDecoration: "none" }} 
                                                                className="w-full flex justify-center" 
                                                                onClick={()=>handleResumeSession(item.trainingId)}
                                                            >
                                                                <button ref={saveRef}
                                                                    onMouseEnter={(e) => {
                                                                    e.currentTarget.style.backgroundColor = theme?.palette?.primary?.main; // Reset to original
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                    e.currentTarget.style.backgroundColor = 'white'; // Replace with desired hover color
                                                                    }}
                                                                    style={{
                                                                    borderColor: theme?.palette?.primary?.main,
                                                                    color: theme?.palette?.primary?.main,
                                                                    borderWidth: '1px',
                                                                    borderStyle: 'solid',
                                                                    }}
                                                                    className="text-sm whitespace-nowrap  rounded-full h-12 w-fit my-4 mb-8 px-6 py-2 hover:text-white transition"
                                                                >
                                                                    Resume Session
                                                                </button>
                                                            </NavLink>
                                                        }
                                                    </div>
                                                )}
                                            </div>

                                            {/* disabled         */}

                                            <div className="text-blue-500 flex justify-center">
                                                <button className='text-sm opacity-0 border-blue-500 rounded-full h-12 w-2/3 my-4 border px-3 py-2 hover:bg-blue-500 hover:text-white transition' ref={actionsRef}>
                                                    Overview
                                                </button>
                                            </div>

                                            {/* disabled         */}

                                        </div>
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className='p-4 h-fit flex flex-col gap-3'>
                                    <div className='w-full flex items-start justify-between'>
                                        {(() => {
                                            const trainingTitle = item.trg_Setting.displaycontrols.find(control => control.id === 2 && control.isdisplay === 1)?.displaytext;                                        
                                            return <>
                                                {
                                                    trainingTitle === undefined ?
                                                    null 
                                                    :
                                                    // <span  className='font-bold text-2xl w-2/3 text-gray-900'>{trainingTitle} - ({item.trainingcode})</span>
                                                    <span  className='font-bold text-2xl w-full text-gray-900'>{trainingTitle} - ({item.trainingcode})</span>
                                                }
                                            </>
                                        })()}
                                        <span
                                            // className='text-[#428bca] text-lg font-semibold'
                                            className='text-lg font-semibold'
                                        >
                                            {/* { 
                                                item.status_txt === "Proposed" ? translations["Proposed"] :
                                                item.status_txt === "Started_Reg_Open" ? translations["Started_Reg_Open"] :
                                                item.status_txt === "Pending" ? translations["Pending"] :
                                                item.status_txt === "Postponed" ? translations["Postponed"] :
                                                item.status_txt === "Canceled" ? translations["Cancelled"] :
                                                item.status_txt === "Completed" ? translations["Completed"] :
                                                item.status_txt
                                            } */}
                                                 
                                            {/* <span 
                                                    title={
                                                        item.status_txt === "Proposed" ? translations["Proposed"] :
                                                        item.status_txt === "Started" ? translations["Started"] :
                                                        item.status_txt === "Started_Reg_Open" ? translations["Started_Reg_Open"] :
                                                        item.status_txt === "Pending" ? translations["Pending"] :
                                                        item.status_txt === "Postponed" ? translations["Postponed"] :
                                                        item.status_txt === "Canceled" ? translations["Cancelled"] :
                                                        item.status_txt === "Completed" ? translations["Completed"] :
                                                        item.status_txt
                                                    } 
                                                    className={`
                                                        px-4 
                                                        uppercase 
                                                        py-1 
                                                        w-fit
                                                        flex 
                                                        items-center 
                                                        justify-center 
                                                        text-white
                                                        rounded-2xl 
                                                        ${
                                                            item.status_txt === "Proposed" ? 'bg-gray-500' :
                                                            item.status_txt === "Started_Reg_Open" ? 'bg-[#9bd839]' :
                                                            item.status_txt === "Started" ? 'bg-teal-400' :
                                                            item.status_txt === "Pending" ? 'bg-orange-400' :
                                                            item.status_txt === "Postponed" ? 'bg-yellow-300' :
                                                            item.status_txt === "Canceled" ? 'bg-red-500' :
                                                            item.status_txt === "Completed" ? 'bg-green-500' :
                                                            item.status_txt
                                                        }
                                                    `}
                                                >
                                                    <span>
                                                        {
                                                            item.status_txt === "Started" ?
                                                            's'
                                                            :
                                                            item.status_txt === "Proposed" ?
                                                            'p'
                                                            :
                                                            item.status_txt === "Started_Reg_Open" ?
                                                            'Ro'
                                                            :
                                                            item.status_txt === "Pending" ?
                                                            'p'
                                                            :
                                                            item.status_txt === "Canceled" ?                                                            
                                                            'cn'
                                                            :
                                                            item.status_txt === "Completed" ?
                                                            'c'
                                                            :
                                                            item.status_txt
                                                        }
                                                    </span>
                                            </span> */}
                                        </span>
                                    </div>
                                    {(() => {
                                        const trainingType = item.trg_Setting.displaycontrols.find(control => control.id === 1 && control.isdisplay === 1)?.displaytext;                                        
                                        return <>
                                            {
                                                trainingType === undefined ?
                                                null 
                                                :
                                                <span className='text-gray-700 flex items-center mt-2 text-xl'>
                                                    {trainingType}
                                                </span>
                                            }
                                        </>
                                    })()}    
                                    <Progressbar completionPercentage={item.trg_completionpercentage} traininigid={item.trainingId}/>
                                    <span className="w-full flex flex-col gap-1 text-gray-600">
                                        {
                                            item.isSelfPaced === 0 && (
                                                <div className='flex gap-2'>
                                                    <span className='flex items-center gap-3'>
                                                        {(() => {
                                                            const trainingDate = item.trg_Setting.displaycontrols.find(control => control.id === 3 && control.isdisplay === 1)?.displaytext;                                        
                                                            return (
                                                                <>
                                                                    {
                                                                        trainingDate === undefined ?
                                                                        null 
                                                                        :
                                                                        <>
                                                                        <LuCalendarDays />
                                                                        {trainingDate}
                                                                        </>
                                                                    }
                                                                </>
                                                            );
                                                        })()}    
                                                    </span>
                                                </div>
                                            )
                                        }
                                        <div className="flex flex-col">
                                            <div className="flex  items-center gap-4">
                                                {(() => {
                                                    const trainingDuration = item.trg_Setting.displaycontrols.find(control => control.id === 5 && control.isdisplay === 1)?.displaytext;                                        
                                                    return (
                                                        <>  
                                                            {
                                                                trainingDuration === undefined ?
                                                                null 
                                                                :
                                                                <>
                                                                <div className='flex gap-2 items-center'>
                                                                    <span className='pt-.5 flex items-center capitalize gap-2'>
                                                                        <IoIosTimer fontSize={16} />
                                                                        {trainingDuration}
                                                                    </span>
                                                                </div>
                                                                </>
                                                            }
                                                        </>
                                                    );
                                                })()}    
                                                <div className='flex gap-2 items-center'>
                                                    <IoIosVideocam fontSize={18} />
                                                    <span className='flex items-end'>
                                                        {item.no_of_sessions}
                                                    </span>
                                                </div>
                                                {(() => {
                                                    const trainingLearningTime = item.trg_Setting.displaycontrols.find(control => control.id === 7 && control.isdisplay === 1)?.displaytext;                                        
                                                    return (
                                                        <>
                                                            {
                                                                trainingLearningTime === undefined ?
                                                                null 
                                                                :
                                                                <>
                                                                <div className='flex gap-2 items-center'>
                                                                    <span className='flex items-center gap-2'>
                                                                        <IoIosBook fontSize={16} />
                                                                        {trainingLearningTime}
                                                                    </span>
                                                                </div>
                                                                </>
                                                            }
                                                        </>
                                                    );
                                                })()}  
                                            </div>
                                            
                                            <div className="text-blue-500 z-[999] absolute bottom-0 w-full left-0 flex justify-center">
                                                {
                                                    selectedUserType == 5 && activeButton === 'alltrainings' && item?.participantstatus === null ? (                                                    // Show "Register To View" button if the conditions are met
                                                    <NavLink style={{ textDecoration: "none" }} className="w-full flex justify-center">
                                                        <button ref={uploadRef}
                                                            title={item?.trainingStatus == 0 ? 'Training is proposed try after sometimes' : 'Register To View'}
                                                            onClick={()=>openModal(item?.trainingStatus , item)}
                                                            disabled={item?.trainingStatus == 0}
                                                            style={{
                                                            backgroundColor: item?.trainingStatus == 0 ? 'orange' : theme?.palette?.primary?.main,
                                                            color: 'white',
                                                            }}
                                                            className="text-sm rounded-full h-12 w-2/3 my-4 mb-8 px-3 py-2 hover:text-white transition"
                                                        >
                                                            Register To View
                                                        </button>
                                                    </NavLink>
                                                ) : (
                                                    // If conditions are not met, show the "Overview" button
                                                    <div className='flex w-full'>
                                                        <NavLink 
                                                            style={{ textDecoration: "none" }} 
                                                            className="w-full flex justify-center" 
                                                            to={`/Session_trg/SessionDetail/${item.trainingId}`}
                                                        >
                                                            <button ref={actionsRef}
                                                                onMouseEnter={(e) => {
                                                                e.currentTarget.style.backgroundColor = theme?.palette?.primary?.main; // Reset to original
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                e.currentTarget.style.backgroundColor = 'white'; // Replace with desired hover color
                                                                }}
                                                                style={{
                                                                borderColor: theme?.palette?.primary?.main,
                                                                color: theme?.palette?.primary?.main,
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                }}
                                                                className="text-sm rounded-full h-12 w-2/3 my-4 mb-8 px-3 py-2 hover:text-white transition"
                                                            >
                                                                Overview
                                                            </button>
                                                        </NavLink>
                                                        {
                                                            item.no_of_sessions > 0 &&
                                                            <NavLink 
                                                                style={{ textDecoration: "none" }} 
                                                                className="w-full flex justify-center" 
                                                                onClick={()=>handleResumeSession(item.trainingId)}
                                                            >
                                                                <button ref={saveRef}
                                                                    onMouseEnter={(e) => {
                                                                    e.currentTarget.style.backgroundColor = theme?.palette?.primary?.main; // Reset to original
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                    e.currentTarget.style.backgroundColor = 'white'; // Replace with desired hover color
                                                                    }}
                                                                    style={{
                                                                    borderColor: theme?.palette?.primary?.main,
                                                                    color: theme?.palette?.primary?.main,
                                                                    borderWidth: '1px',
                                                                    borderStyle: 'solid',
                                                                    }}
                                                                    className="text-sm whitespace-nowrap rounded-full h-12 w-fit my-4 mb-8 px-6 py-2 hover:text-white transition"
                                                                >
                                                                    Resume Session
                                                                </button>
                                                            </NavLink>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            
                                            {/* disabled         */}

                                            <div className="text-blue-500 flex justify-center">
                                                <button className='text-sm opacity-0 border-blue-500 rounded-full h-12 w-2/3 my-4 border px-3 py-2 hover:bg-blue-500 hover:text-white transition' ref={actionsRef}>
                                                    Overview
                                                </button>
                                            </div>

                                            {/* disabled         */}

                                        </div>
                                    </span>
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </div>

        <Dialog
            className="z-[9999999999999999999999999999999]"
            open={modalOpen}
            onClose={handleModalClose}
            PaperProps={{
            style: { width: "340px" },
            }}
        >
            <DialogTitle>Alert</DialogTitle>
            <DialogContent className='pb-0 mb-0'>
            <p>{modalMessage}</p>
            </DialogContent>
            <DialogActions className='flex justify-center'>
                {
                    startTest ?
                    <>
                    <Button onClick={()=>handleStartTestCondition()}  color="primary">
                        Yes
                    </Button>

                    <Button onClick={handleModalClose} color="primary">
                        No
                    </Button>
                    </>
                    :
                    <Button onClick={handleModalClose} color="primary">
                        OK
                    </Button>
                }
            </DialogActions>
        </Dialog>
    </>
    )
}

export default DashboardTrainings