
import React, { useState, useEffect, useContext } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import {
    Button,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Typography,
    CircularProgress,
    Snackbar,
    Box
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import loadConfig from "../../config";
import axios from "axios";
import hex_md5 from "md5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTypeModal from "../modals/UserTypeModal";
import { useUser } from "../../UserContext"; // Adjust the path as needed

import { useConfig } from '../../ConfigContext';
import ForgetPassword from "./ForgetPssword";
import { TranslationContext } from "../../TranslationContext";
import { useMediaQuery } from '@mui/material';


const generateSalt = (length) => {
    const characters =
        "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const LoginPage = ({ profileDetails, login }) => {
    const isMobile = useMediaQuery('(max-width:640px)');
    console.log("LoginPage", profileDetails)
    const navigate = useNavigate();
    const { setUser, setClient, setSelectedUserType } = useUser(); // Access setUser from UserContext
    const { translations } = useContext(TranslationContext);
    const config1 = useConfig();
    // console.log(config1)
    const [loginType, setLoginType] = useState("emailid"); // Default to mobileno, change as needed
    const [username, setUsername] = useState('');
    const [emailid, setEmailid] = useState("");
    const [emailError, setEmailError] = useState('');
    const [mobileno, setMobilno] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState('');
    const [mode, setMode] = useState("password");
    const [error, setError] = useState(null);
    const [config, setConfig] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [countres, setCountres] = useState([]); // State to hold countries data
    const [clientData, setClientData] = useState('')
    const [loading, setLoading] = useState(false); // Loading state for form submissions
    const [otpLoading, setOtpLoading] = useState(false); // Loading state for OTP request
    const [showOTPLogin, setShowOTPLogin] = useState(false); // State to determine if OTP login should be shown

    const [timer, setTimer] = useState(30); // Set initial countdown time
    const [isTimerActive, setIsTimerActive] = useState(false); // Timer active state
    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        setIsOpen(false); // Open the modal when component mounts
    }, []); // Empty dependency array ensures this effect runs only once on mount
    useEffect(() => {
        // Fetch countries data and set default selectedCountry to '91' (India)
        if (countres.length > 0) {
            const defaultCountry = countres.find(country => country.countryCode === '91');
            setSelectedCountry(defaultCountry);
        }
    }, [countres]);

    const fetchConfig = async () => {
        try {
            console.log("Loading config data...");
            const configData = await loadConfig(); // Assuming loadConfig fetches your config object
            console.log("Config data loaded:", configData);
            setConfig(configData);
            fetchClientData(configData);
            console.log("ccc", configData);
            if (configData) {
                localStorage.setItem('configData', JSON.stringify(configData));
            }
            return configData;


        } catch (error) {
            console.error("Error loading config:", error);
        }
    };

    //==============Show to OTP Login Start ======================


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!config) return; // Ensure config is loaded before making API call

                const headers = {
                    APIKey: config.REACT_APP_API_KEY,
                };

                const response = await axios.get(
                    `${config.REACT_APP_API_URL}/Get_Application_Setting`,
                    {
                        params: {
                            settingtype: 6,
                        },
                        headers: headers,
                    }
                );

                if (
                    response.data &&
                    response.data.otP_LOGIN_REQUIRED !== undefined &&
                    response.data.otP_LOGIN_REQUIRED === 1
                ) {
                    setShowOTPLogin(true);
                } else {
                    setShowOTPLogin(false);
                }
                console.log("OTP required:", response);
            } catch (error) {
                console.error("Error fetching application setting:", error);
                // Handle error if needed
            }
        };

        fetchData();
    }, [config]); // Include config as a dependency


    // Countdown timer effect
    useEffect(() => {
        let countdown;

        if (isTimerActive) {
            countdown = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setIsTimerActive(false); // Stop the timer
                        return 0; // Reset timer
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(countdown); // Cleanup interval on component unmount or timer stop
    }, [isTimerActive]);


    const handleChange = (e) => {
        const value = e.target.value;
        setUsername(value);

        // Regex for email and mobile number validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobilePattern = /^[0-9]{10,15}$/; // Adjust as needed

        if (emailPattern.test(value)) {
            setLoginType('emailid');
        } else if (mobilePattern.test(value)) {
            setLoginType('mobileno');
        } else {
            setLoginType('');
        }
    };


    const handleBlur = () => {
        // Email validation regex
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const mobileRegex = /^[0-9]{10}$/; // Regex to validate mobile number format starting with "91-"

        if (username && !emailRegex.test(username) && !mobileRegex.test(username)) {
            setEmailError('Please enter a valid email address.<br>or mobile number (91********)');

        } else {
            setEmailError('');
        }
    };



    const handleYesClick = () => {
        setIsOpen(false);
        navigate("/dashboard");
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        navigate("/dashboard");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading state to true

        try {
            if (mode === 'password') {
                // Password login logic
                const salt = generateSalt(8);
                let hashedPassword = hex_md5(password).toString();
                hashedPassword = hex_md5(salt.toString() + hashedPassword.toString());

                const loginData = {
                    password: hashedPassword,
                    salt,
                    ...(loginType === 'emailid' ? { emailid: username } : { mobileno: `91-${username}` }) // Hardcode country code
                };

                const headers = {
                    'Content-Type': 'application/json',
                    APIKey: config.REACT_APP_API_KEY,
                };

                const response = await axios.post(
                    `${config.REACT_APP_API_URL}/GetToken`,
                    loginData,
                    { headers }
                );

                // Store user data in context and localStorage
                setUser(response.data.result);
                localStorage.setItem('user', JSON.stringify(response.data.result));

                // Check if modal should be opened based on usertype
                const shouldOpenModal = response.data.result.userdetails.usertype.length > 1;

                if (!shouldOpenModal) {
                    setSelectedUserType(response.data.result.userdetails.usertype[0].usertypeid);
                    navigate('/dashboard');
                    toast.success('Login successful!');
                } else {
                    setIsOpen(true); // Open modal if necessary
                    toast.success('Login successful!');
                }
            } else if (mode === 'otp') {
                // OTP login logic
                const loginData = {
                    otp,
                    ...(loginType === 'emailid' ? { emailid: username } : { mobileno: `91-${username}` }), // Hardcode country code
                };

                const headers = {
                    'Content-Type': 'application/json',
                    APIKey: config.REACT_APP_API_KEY,
                };

                const response = await axios.post(
                    `${config.REACT_APP_API_URL}/GetToken`,
                    loginData,
                    { headers }
                );

                // Store user data in context and localStorage
                setUser(response.data.result);
                localStorage.setItem('user', JSON.stringify(response.data.result));

                const shouldOpenModal = response.data.result.userdetails.usertype.length > 1;

                if (!shouldOpenModal) {
                    navigate('/dashboard');
                    toast.success('Login successful!');
                } else {
                    setIsOpen(true); // Open modal if necessary
                    toast.success('Login successful!');
                }
            }
        } catch (error) {
            console.error('Error in logging in:', error);
            toast.error(error.response?.data || 'Error in Login');
            setError('Failed to login. Please check your credentials.');
        } finally {
            setLoading(false); // Set loading state back to false
        }
    };


    const handleGetOTP = async () => {
        setOtpLoading(true); // Set OTP loading state to true

        try {
            const headers = {
                "Content-Type": "application/json",
                APIKey: config.REACT_APP_API_KEY,
            };

            // Check if the username is a valid mobile number
            const mobilePattern = /^[0-9]{10}$/; // Adjust as per your mobile number validation
            const formattedUsername = mobilePattern.test(username) ? `91-${username}` : username;

            const response = await axios.get(
                `${config.REACT_APP_API_URL}/GenerateOTP?username=${formattedUsername}`,
                {
                    headers,
                }
            );

            console.log(response);
            toast.success("OTP sent successfully!");

            // Start the timer only after OTP is successfully sent
            setTimer(30); // Reset timer to 30 seconds
            setIsTimerActive(true); // Start the timer

            // Only change mode to "otp" if OTP is successfully sent
            setMode("otp");
        } catch (error) {
            console.error("Error fetching OTP:", error);
            toast.error("Failed to fetch OTP. Please try again.");
            // Optionally, reset timer if OTP sending fails
            setTimer(0);
            setMode("password");
        } finally {
            setOtpLoading(false); // Set OTP loading state back to false
        }
    };


    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    // Function to validate mobile number format (example)
    const validateMobileNumber = (mobileNumber) => {
        // Example pattern for validation, adjust as per your requirements
        const regex = /^[0-9]{10}$/;
        return regex.test(mobileNumber);
    };

    const switchToOTPMode = () => {
        if (loginType === "emailid" && (!emailid || !validateEmail(emailid))) {
            toast.error("Please enter a valid Email Address.");
            return;
        }

        if (loginType === "mobileno" && (!mobileno || !validateMobileNumber(mobileno))) {
            toast.error("Please enter a valid Mobile Number.");
            return;
        }

        // Proceed to send OTP if validations pass
        handleGetOTP();
    };
    // Function to start OTP timer
    const startOTPTimer = () => {
        setTimeout(() => {
            setTimer(0); // Reset timer after 30 seconds
        }, 30000);
    }


    useEffect(() => {
        const initializeConfig = async () => {
            const configData = await fetchConfig();
            if (configData) {
                setLoading(false);

                fetchClientData(configData); // Fetch initial data once config is loaded

            }
        };
        initializeConfig();
    }, []);

    const fetchClientData = async (config) => {
        console.log(config)
        try {
            const headers = {
                Accept: "application/json, text/plain",
                APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
                "Content-Type": "application/json",
            };

            const response = await axios.get(`${config.REACT_APP_API_URL}/GetClientData`, {
                headers,
            });
            setClientData(response.data)
            setClient(response.data)
            localStorage.setItem('clientData', JSON.stringify(response.data));
            console.log(clientData)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const checkEmailAndNavigate = async () => {
            if (profileDetails.verified_email) {
                try {
                    // Construct the API URL with the dynamic email from profileDetails
                    const apiUrl = `${config.REACT_APP_API_URL}/userinfo?username=${encodeURIComponent(profileDetails.email)}`;

                    try {
                        const response = await fetch(apiUrl, {
                            headers: {
                                Accept: 'application/json, text/plain',
                                APIKey: config.REACT_APP_API_KEY,
                            },
                        });

                        if (!response.ok) {
                            // Handle non-2xx HTTP responses
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        const data = await response.json();
                        setUser(data.result);
                        localStorage.setItem('user', JSON.stringify(data.result));
                        // Check if modal should be opened (in your case, based on usertype condition)
                        const shouldOpenModal = data.result.userdetails.usertype.length > 1;

                        if (!shouldOpenModal) {
                            // Navigate to dashboard if modal should not open
                            navigate("/dashboard");
                            toast.success("Login successful!");
                        } else {
                            // Open modal if necessary
                            setIsOpen(true);
                            toast.success("Login successful!");
                        }

                    } catch (error) {
                        // Handle errors here (network errors, JSON parsing errors, etc.)
                        console.error('Error fetching user info:', error);
                    }

                } catch (error) {
                    console.error("Error in Login:", error);
                    toast.error("Error in Login");
                    setError("Failed to  Login. Please check your Email.");
                } finally {
                    setLoading(false); // Set loading state back to false
                }
            }
        };

        checkEmailAndNavigate();
    }, [profileDetails, navigate]);






    return (
        <>
            <div className="min-h-screen  flex flex-col justify-center pb-12 sm:px-2 lg:px-4" style={{ backgroundColor: "#428bca" }}>
                <ToastContainer />
                <UserTypeModal
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    onYesClick={handleYesClick}
                />

                <div className="sm:mx-auto" style={{ margin: "2rem" }}>
                    <div
                        className="bg-white shadow sm:rounded-lg"
                        style={{ paddingTop: "3rem", paddingLeft: "5rem", paddingRight: "5rem", paddingBottom: "3rem" }}
                    >
                        <div
                            className="image-section"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <img
                                src={`${config1?.LITTERA_CDN_BASE_URL}/${clientData.logiN_LOGO}`}
                                className="login-logo"
                                style={{ width: "71px", height: "71px" }}
                            />

                        </div>
                        <div className="sm:mx-auto sm:w-full sm:max-w-md" style={{ marginBottom: "20px" }}>
                            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">

                                {translations["your_account"] || "Sign in to your account"}
                            </h2>
                            <p className="mt-2 text-center text-sm text-gray-600" style={{ display: "none" }}>
                                Or
                                <a
                                    href="#"
                                    className="font-medium  hover:text-blue-500"
                                    style={{ marginLeft: "5px", color: "#428bca" }}
                                >
                                    {translations["create_account"] || "create an account"}

                                </a>
                            </p>
                        </div>
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            {showOTPLogin && (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row', // Always row direction
                                    flexWrap: 'nowrap', // Prevent wrapping
                                }}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mr: 1,
                                            fontSize: { xs: '1.2rem', sm: '1.7rem' } // Adjust font size for mobile view
                                        }}>
                                        Login with
                                    </Typography>
                                    <RadioGroup
                                        row
                                        value={mode}
                                        onChange={(e) => setMode(e.target.value)}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'nowrap', // Prevent wrapping of radio buttons
                                            marginTop: "1px"
                                        }}>
                                        <FormControlLabel value="password" control={<Radio />} label="Password" />
                                        <FormControlLabel value="otp" control={<Radio />} label="OTP" />
                                    </RadioGroup>
                                </Box>
                            )}

                            <div>
                                <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                                    {translations["Email_address"] || "Email address / Mobile number"}
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="contact"
                                        name="contact"
                                        type="text"
                                        required
                                        value={username}
                                        onChange={handleChange}
                                        onBlur={handleBlur} // Check validity on blur
                                        className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-4 border border-gray-300 rounded-md ${isMobile ? 'mobile-font' : ''}`}
                                        style={{ padding: "10px", fontSize: "13px" }}
                                        placeholder={translations["Enter your Email address / Mobile number"] || "Enter your Email address / Mobile number"}
                                    />
                                    {emailError && (
                                        // <p className="text-red-500 text-sm mt-1">{emailError}</p>
                                        <p className="text-red-500 text-sm mt-1" dangerouslySetInnerHTML={{ __html: emailError }} />
                                    )}
                                </div>
                            </div>

                            {mode === "password" ? (
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        {translations["Password"] || "Password"}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-4 border border-gray-300 rounded-md ${isMobile ? 'mobile-font' : ''}`} // Assume isMobile is a boolean indicating if the view is mobil
                                            style={{ padding: "10px", }}
                                            placeholder={translations["Enter_password"] || "Enter your password"}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <label htmlFor="otp" className="block text-gray-700">
                                        {translations["OTP"] || "OTP"}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="otp"
                                            name="otp"
                                            type="text"
                                            required
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-4 border border-gray-300 rounded-md ${isMobile ? 'mobile-font' : ''}`} // Assume isMobile is a boolean indicating if the view is mobil
                                            style={{ padding: "10px", }}
                                            placeholder="Enter OTP"
                                        />
                                    </div>
                                </div>
                            )}

                            {mode === "otp" && showOTPLogin && (
                                <div className="mt-2">
                                    {/* Show the link only if the timer is not active */}
                                    {!isTimerActive && (
                                        <a href="#" onClick={handleGetOTP} className="font-medium text-blue-600 hover:text-blue-500">
                                            {translations["Click_To_Get_OTP"] || "Click To Get OTP"}
                                        </a>
                                    )}
                                    {/* Display the timer when it is active */}
                                    {isTimerActive && (
                                        <div className="mt-2">
                                            <span>{`Resend OTP in ${timer} seconds`}</span>
                                        </div>
                                    )}
                                </div>
                            )}


                            <div>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white "
                                    disabled={loading} style={{ backgroundColor: "#428bca" }}
                                >
                                    {loading ? 'Logging in...' : 'Log In'}
                                </button>
                            </div>
                        </form>

                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm ">
                                    <span className="px-2 bg-gray-100 text-gray-500 thisissocial">
                                        Or continue with
                                    </span>
                                </div>
                            </div>
                            <div className="social thisissocial">
                                <div className="go" onClick={login} style={{ backgroundColor: "#428bca" }}>
                                    <GoogleIcon
                                        className="fab fa-google"
                                        style={{
                                            fontSize: "21px",
                                            color: "white",
                                            marginRight: "10px",
                                        }}
                                    />
                                    Google

                                </div>

                            </div>
                            <ForgetPassword />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
