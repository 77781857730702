import React, { useState, useEffect,useContext} from "react";
import axios from "axios";
import { format } from 'date-fns';
import { Typography, useTheme } from "@mui/material";
import { useConfig } from "../../ConfigContext";
import { TranslationContext } from "../../TranslationContext";
const Activty_Activity = ({ hfEditId }) => {
  const theme= useTheme()
  const { translations } = useContext(TranslationContext);
  const config = useConfig(); // Assuming useConfig() provides access to config values
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [activityLog, setActivityLog] = useState([]);
  const tatTypeId = '131'; // Corrected variable name to follow camelCase convention

  // Function to fetch data from API using Axios
  const fetchActivityLog = async () => {
    try {
      const response = await axios.get(`${config?.REACT_APP_API_URL}/GetAllActions`, {
        params: {
          docid: hfEditId,
          tat_type_id: tatTypeId,
        },
        headers: {
          "Content-Type": "application/json",
          APIKey: process.env.REACT_APP_API_KEY,
        }
      });
      setActivityLog(response.data); // Assuming data is an array of activity log entries returned by the API
      console.log("Activity log data:", response.data);
    } catch (error) {
      console.error('Error fetching activity log:', error);
    }
  };

  useEffect(() => {
    // Fetch data only if hfEditId is valid (not null or undefined)
    if (hfEditId) {
      fetchActivityLog();
    }
  }, [hfEditId]); // Added hfEditId to dependency array to refetch data when it changes

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  // Function to format date string to desired format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yy HH:mm');
  };

  return (
    <div className={`app ${isSidePanelOpen ? "side-panel-open" : ""}`} style={{ marginTop: "20%" }}>
      <div className="rightside-toggle side-panel-toggle" style={{backgroundColor:theme.palette.primary.main}} >
        <button type="button" onClick={toggleSidePanel}>
          <span className="toggle-text">Activity</span>
        </button>
        {isSidePanelOpen && (
          <div className="side-panel" role="show activity log">
            <h4>Activity Log</h4>
            <div className="activity-log" style={{ maxHeight: "400px", overflowY: "auto" }}>
              <ul className="activity-log-list">
                {activityLog.map((item, index) => (
                  <li key={item.id}>
                  <p>{item.docno}</p>
                  <p>{formatDate(item.createdon)}</p>
                  <h3
                    className="dbs-craated-funcation"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      marginTop: "-9px",
                    }}
                  >
                    {translations[item.status_txt] || item.status_txt}
                  </h3>

                  <p> Remark : {item.docremark} </p>
                  <p> By : {item.sendername} </p>
                  <p> Send To: {item.receiverid}</p>
                </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activty_Activity;
