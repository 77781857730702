import { Mail, Phone, PhotoCamera } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { GiCalendar, GiRotaryPhone, GiSmartphone } from 'react-icons/gi'
import { FaCalendar, FaCity, FaCreditCard, FaLocationArrow, FaLocationDot, FaPhone, FaUser } from 'react-icons/fa6'
import { BsCalendar3 } from 'react-icons/bs'
import { LiaUniversitySolid } from 'react-icons/lia'
import { FaAdjust } from 'react-icons/fa'
import { useTheme } from '../../../ThemeProvider'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaEye, FaTimes } from 'react-icons/fa'; // Eye and Close icons
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import FileUploadWithPreview from './FileUploadWithPreview'

const EditPage = ({salutation}) => {
    const { userProfile , showeditpage, setshoweditpage , setuserProfile , uploadingSignature  , setuploadingSignature , setImageFormData , setuploadingProfile } = useFeedback()
    const [selectedDate, setSelectedDate] = useState(null); // Track the selected date
    const [calendardate, setcalendardate] = useState(null); // Track the selected date
    const [isCalendarOpen, setIsCalendarOpen] = useState(false); // Control calendar visibility
    const user = JSON.parse(localStorage.getItem('user'))
    const userdata = userProfile?.items[0] || {};
    const config = useConfig()


    // State for form fields
    const [salutationId, setSalutationId] = useState(userdata?.ag_salutation || "");
    const [firstName, setFirstName] = useState(userdata?.ag_first_name || "");
    const [middleName, setMiddleName] = useState(userdata?.ag_m_name || "");
    const [lastName, setLastName] = useState(userdata?.ag_l_name || "");
    const [gender, setGender] = useState(userdata?.ag_gender || ""); // "0" | "1" | "2"
    const [dob, setDob] = useState(userdata?.ag_dob || "");
    const [address, setAddress] = useState(userdata?.ag_Address || "");
    const [city, setCity] = useState(userdata?.ag_address_city || "");
    const [state, setState] = useState(userdata?.ag_address_state || "");
    const [pincode, setPincode] = useState(userdata?.ag_pincode || "");
    const [altMobileNo, setAltMobileNo] = useState(userdata?.ag_alternative_mobileno || "");
    const [phone, setPhone] = useState(userdata?.ag_phone || "");
    const [idProofType, setIdProofType] = useState(userdata?.iD_PROOF_TYPE || "");
    const [photopath, setphotopath] = useState(userdata?.ag_photo_path || null);
    const [idProof, setIdProof] = useState("");
    const { theme } = useTheme()
    const [uploading, setUploading] = useState(false);
    const { uploadingProfile , imageFormData  } = useFeedback()

    const handleChange = (date) => {
        const dateforinput = formatDate(date)
        const dateforcalendar = formatDateforcalendar(date)
        console.log('actual date' , date)
        console.log('dateforinput' , dateforinput)
        console.log('dateforcalendar' , dateforcalendar)
        console.log('reversedate' , reversedate(dateforinput))
        setSelectedDate(dateforinput)
        
        setcalendardate(dateforcalendar)
        // setSelectedDate(date)
        // setcalendardate(date)
        setIsCalendarOpen(false); // Close the calendar after date selection
    };

    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        return `${day}-${month}-${year} `;
    };

    const formatDateforcalendar = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        return `${month}-${day}-${year} `;
    };

    useEffect(() => {
        const fetchdata = async ()=>{
            const dateforinput = formatDate(userdata?.ag_dob)
            const dateforcalendar = formatDateforcalendar(userdata?.ag_dob)
            setSelectedDate(dateforinput)
            setcalendardate(dateforcalendar)
        }
        fetchdata()
    }, [])

    const getUserProfileData = async () => {

        const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        }
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Agency`, {
              headers: headers,
              params: {
                agencyid:user?.userdetails?.agencyid
              }
          });
          
          return data
        } catch (error) {
          console.log('Error session type', error);
        }
    }
    
    const generateGUID = () => uuidv4();

    function reversedate(date) {
        const cleanedDate = date.trim(); // Remove any leading/trailing spaces
        const [day, month, year] = cleanedDate.split('-'); // Split the input date string by '-'
        return `${year}-${month}-${day}`; // Return in the format yyyy-mm-dd
    }

    const uploadFile = async () => {
        if (!imageFormData) {
            return null;
        }
    
        const uniqueGUID = generateGUID();
        const uploadedFileName = `${uniqueGUID}_${imageFormData.name}`;
    
        try {
            const fileFormData = new FormData();
            fileFormData.append('files', imageFormData, uploadedFileName);
    
            const uploadUrl = `${config?.LITTERA_CDN_BASE_URL}/Upload/UploadFile?Foldername=${config?.LITTERA_CDN_PROFILE_PICK_PATH}`;
            const response = await fetch(uploadUrl, {
                method: 'POST',
                body: fileFormData,
            });
    
            if (!response.ok) {
                throw new Error(`File upload failed: ${response.status}`);
            }
    
            const data = await response.json();
            if (response.status === 200) {
                return uploadedFileName;
            }
        } catch (error) {
            return uploadedFileName;
            console.error('Upload failed', error);
        }
    };
    
    const uploadsignature = async () => {
        if (!uploadingSignature) {
            return null;
        }
    
        const uniqueGUID = generateGUID();
        const uploadedFileName = `${uniqueGUID}_${uploadingSignature?.name}`;
    
        try {
            const fileFormData = new FormData();
            fileFormData.append('files', uploadingSignature, uploadedFileName);
    
            const uploadUrl = `${config?.LITTERA_CDN_BASE_URL}/Upload/UploadFile?Foldername=${config?.LITTERA_CDN_PROFILE_PICK_PATH}`;
            const response = await fetch(uploadUrl, {
                method: 'POST',
                body: fileFormData,
            });
    
            if (!response.ok) {
                throw new Error(`File upload failed: ${response.status}`);
            }
    
            const data = await response.json();
            if (response.status === 200) {
                return uploadedFileName;
            }
        } catch (error) {
            console.error('Upload failed', error);
            return uploadedFileName;
        }
    };

    const handleUpdate = async (uploadedFileName , uploadedSignatureFileName ,e) => {
        
        const bodydata =   {
            agencyId: user?.userdetails?.agencyid,
            userid: user?.userdetails?.userid,
            createdBy: user?.userdetails?.agencyid,
            branchid: user?.userdetails?.branchid,
            agencyName: `${firstName} ${middleName} ${lastName}`,
            hAgencyName: `${firstName} ${middleName} ${lastName}`,
            photopath: uploadedFileName || photopath,
            ag_Address: address,
            ag_Address1: null,
            ag_address_city: city,
            ag_address_state: state,
            ag_pincode: pincode,
            ag_salutation: salutationId,
            ag_first_name: firstName,
            ag_m_name: middleName,
            ag_l_name: lastName,
            ag_hfirst_name: firstName,
            ag_hm_name: middleName,
            ag_hl_name: lastName,
            ag_gender: gender,
            ag_age: null,
            ag_dob: reversedate(selectedDate),
            ag_phone: phone,
            ag_alternative_mobileno: altMobileNo,
            // ag_aadhar: idProof,
            // ag_pan: idProof,
            // ag_gstn: idProof,
            ag_sign_path: uploadedSignatureFileName
        }

        try {
            const { data } = await axios.put(`${config?.REACT_APP_API_URL}/Update_Profile?agencyid=${user?.userdetails?.agencyid}`, bodydata, {
                headers: {
                    Accept: 'application/json, text/plain',
                    APIKey: config?.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                }
            })
            const profiledata = await getUserProfileData() 
            setuserProfile(profiledata)
            setshoweditpage(false)
            setuploadingProfile(null)
            setuploadingSignature(null)
            setImageFormData(null)
            setshoweditpage(false)
            toast.success('Profile Updated Successfully.')
            return data
        } catch (error) {
          console.log('Error session type', error);
        }
    }

    const uploadAndUpdateProfile = async (e) => {
        e.preventDefault();
        
        setUploading(true);
    
        let uploadedFileName = null;
        let uploadedSignatureFileName = null;
    
        // Upload the image if imageFile exists
        if (imageFormData) {
            uploadedFileName = await uploadFile();
        }
        if (uploadingSignature) {
            uploadedSignatureFileName = await uploadsignature();
        }
    
        await handleUpdate(uploadedFileName, uploadedSignatureFileName);
    };

    return (
        <>
            <form onSubmit={uploadAndUpdateProfile}>
                <div className='flex max-sm:py-6  max-sm:px-4  h-full py-14 px-10'>

                    <div className='w-full flex flex-col gap-6'>
                        
                        <span className='flex gap-20  max-xl:gap-4 max-xl:flex-col'>
                            
                            <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                <FaUser fontSize='15px'/>Name :
                            </span>

                            <span className='flex max-xl:flex-col max-xl:gap-4'>

                                <select
                                    className="border-2 p-2 max-xl:w-full w-[5vw] rounded-md"
                                    value={salutationId}
                                    onChange={(e) => setSalutationId(e.target.value)}
                                    >
                                    <option value="" disabled>
                                        Select Salutation
                                    </option>

                                    {salutation?.map((salutation) => (
                                        <option key={salutation?.ts_id} value={salutation?.ts_id}>
                                            {salutation?.ts_name}
                                        </option>
                                    ))}
                                </select>

                                <span className='flex gap-4 justify-between max-xl:flex-col'>
                                    <input 
                                        type="text"  
                                        value={firstName}
                                        required
                                        onChange={(e) => setFirstName(e.target.value)} 
                                        className='w-1/3 max-xl:w-full p-2 border-2 rounded-md' 
                                        placeholder='First Name' 
                                    />
                                    
                                    <input 
                                        type="text" 
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        className='w-1/3 max-xl:w-full p-2 border-2 rounded-md' 
                                        placeholder='Middle Name' 
                                    />

                                    <input 
                                        type="text" 
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className='w-1/3 max-xl:w-full p-2 border-2 rounded-md' 
                                        placeholder='Last Name' 
                                    />
                                </span>
                            </span>

                        </span>

                        <div className='flex max-xl:flex-col gap-6'>

                            <span className="flex gap-20 max-xl:gap-6 max-lg:w-fit items-center">
                                <span className="whitespace-nowrap  max-lg:w-fit flex gap-4 items-center">
                                    <BsCalendar3 fontSize="15px" />
                                    DOB :
                                </span>
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={selectedDate}
                                        onClick={toggleCalendar}
                                        readOnly
                                        className="border p-2 max-lg:w-full ml-1 max-xl:ml-0 "
                                        placeholder="DD/MM/YYYY"
                                    />
                                    {isCalendarOpen && (
                                    <div className="absolute z-10">
                                        <DatePicker
                                            selected={calendardate}
                                            onChange={handleChange}
                                            inline
                                        />
                                    </div>
                                    )}
                                </div>
                            </span>

                            <span className='flex w-fit max-lg:w-fit max-phone:gap-6 max-phone:flex-col max-phone:items-start items-center gap-4'>
                                <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                    <FaAdjust fontSize='15px'/> Gender :
                                </span>
                                <span className='flex gap-4 capitalize '>
                                    <label className='flex items-start gap-2 m-0'>
                                        <input 
                                            type="radio" 
                                            name="gender" 
                                            value="1"
                                            checked={gender == "1"}
                                            onChange={() => setGender("1")}
                                        />
                                        Male
                                    </label>

                                    <label className='flex items-start gap-2 m-0'>
                                        <input 
                                            type="radio" 
                                            name="gender" 
                                            value="0" 
                                            checked={gender == "0"}
                                            onChange={() => setGender("0")}
                                        />
                                        Female
                                    </label>

                                    <label className='flex items-start gap-2 m-0'>
                                        <input 
                                            type="radio" 
                                            name="gender" 
                                            value="2" 
                                            checked={gender == "2"}
                                            onChange={() => setGender("2")}
                                        />
                                        Prefer not to disclose
                                    </label>
                                </span>
                            </span>
                        </div>
                        
                        <span className='w-full gap-14 max-xl:gap-4 flex max-xl:justify-start max-xl:flex-col max-xl:items-start items-center'>
                            <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                <FaLocationDot fontSize='15px'/> Address :
                            </span>
                            <input 
                                type="text" 
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className='w-full p-2 border-2 rounded-md' 
                                placeholder='Address' 
                            />
                        </span>
                        
                        <div className='flex gap-10 max-lg:flex-col max-lg:gap-4'>
                            <span className='flex max-xl:flex-col max-xl:items-start gap-24 max-xl:gap-4 max-lg:w-full items-center'>
                                <span className='max-lg:w-fit flex gap-4 items-center'>
                                    <FaCity fontSize='15px'/> City :
                                </span>
                                <input 
                                    type="text" 
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    className='p-2 border-2 rounded-md max-lg:w-full' 
                                    placeholder='City' 
                                /> 
                            </span>

                            <span className='flex max-xl:flex-col max-xl:items-start max-lg:w-full gap-24 items-center max-xl:gap-4'>
                                <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                    <LiaUniversitySolid fontSize='15px'/> State :
                                </span>
                                <input 
                                    type="text" 
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    className='p-2 border-2  w-[19vw] rounded-md max-lg:w-full' 
                                    placeholder='Enter State' 
                                /> 
                            </span>
                        </div>

                        <div className='flex gap-10 max-lg:flex-col max-lg:gap-4'>
                            <span className='flex max-xl:flex-col max-xl:items-start gap-10 max-xl:gap-4 max-lg:w-full items-center'>
                                <span className='max-lg:w-fit flex gap-4 items-center'>
                                    <FaLocationArrow fontSize='15px'/> Pin Code :
                                </span>
                                <input 
                                    type="text" 
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}                            
                                    className='p-2 border-2 rounded-md  max-lg:w-full' 
                                    placeholder='Enter Pin Code' 
                                /> 
                            </span>

                            <span className='flex max-xl:flex-col max-xl:items-start max-lg:w-full gap-24 items-center max-xl:gap-4 '>
                                <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                    <Mail fontSize='15px'/> Email :
                                </span>
                                <input 
                                    type="text" style={{cursor:"not-allowed"}} 
                                    value={userdata?.ag_email} 
                                    disabled 
                                    className='p-2 border-2 rounded-md w-[19vw] max-lg:w-full' 
                                    placeholder='Enter Email' 
                                /> 
                            </span>
                        </div>

                        <div className='flex gap-10 max-lg:flex-col max-lg:gap-4'>
                            <span className='flex max-xl:flex-col whitespace-nowrap max-xl:items-start gap-9 max-xl:gap-4 max-lg:w-full items-center'>
                                <span className='max-lg:w-fit flex gap-4 items-center'>
                                    <GiSmartphone fontSize='15px'/> Mobile No :
                                </span>
                                <input type="text" style={{cursor:"not-allowed"}} value={userdata?.ag_mobileno} disabled className='p-2 border-2 rounded-md max-lg:w-full' placeholder='Enter Mobile no' /> 
                            </span>

                            <span className='flex max-xl:flex-col max-xl:items-start max-lg:w-full items-center max-xl:gap-4 gap-3'>
                                <span className='whitespace-nowrap  max-lg:w-fit flex gap-4 items-center'>
                                    <FaPhone fontSize='15px'/> Alt Mobile No :
                                </span>
                                <input 
                                    type="text" 
                                    value={altMobileNo}
                                    onChange={(e) => setAltMobileNo(e.target.value)}
                                    className='p-2 border-2 rounded-md w-[19vw] max-lg:w-full' 
                                    placeholder='Enter Alt Phone No' 
                                /> 
                            </span>
                        </div>
                        
                        <div className='flex gap-10 max-lg:flex-col max-lg:gap-4'>
                            <span className='flex max-xl:flex-col whitespace-nowrap max-xl:items-start gap-[45px] max-xl:gap-4 max-lg:w-full items-center'>
                                <span className='max-lg:w-fit flex gap-4 items-center'>
                                    <GiRotaryPhone fontSize='15px'/> Phone :
                                </span>
                                <input 
                                    type="text" 
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className='p-2 border-2 rounded-md max-lg:w-full' 
                                    placeholder='Enter Phone no' 
                                /> 
                            </span>

                            {/* <span className='flex max-xl:flex-col gap-16 max-xl:items-start max-lg:w-full items-center max-xl:gap-4'>
                                <span className='whitespace-nowrap  max-lg:w-fit flex gap-4 items-center'>
                                    <FaPhone fontSize='15px'/> ID Proof :
                                </span>
                                <span className='flex max-lg:flex-col max-lg:w-full max-xl:gap-4'>
                                    <select
                                        className="border-2 p-2 max-xl:w-full w-[5vw] rounded-md"
                                        value={idProofType}
                                        onChange={(e) => setIdProofType(e.target.value)}
                                        >
                                        <option value="" disabled>
                                            Select Type
                                        </option>
                                        <option value={1}>Aadhar No / VID</option>
                                        <option value={2}>Voter ID</option>
                                        <option value={3}>Driving Licence</option>
                                        <option value={4}>Pan Card</option>
                                        <option value={5}>Passport No</option>
                                    </select>
                                
                                    <span className='flex gap-4 justify-between max-xl:flex-col'>
                                        <input
                                            value={idProof}
                                            onChange={(e) => setIdProof(e.target.value)} 
                                            type="text" 
                                            className='p-2 border-2 rounded-md max-lg:w-full' 
                                            placeholder='Enter ID' 
                                        /> 
                                    </span>
                                </span>
                            </span> */}
                        </div>

                        <span className='flex max-xl:gap-4 max-xl:flex-col max-lg:justify-start w-1/3 items-start gap-10'>
                            <span className='whitespace-nowrap max-lg:w-fit flex gap-4 items-center'>
                                <FaCreditCard fontSize='15px'/>Signature :
                            </span>
                            <FileUploadWithPreview/>
                        </span>

                    </div>
                </div>

                <div className={`px-10 py-8 border-t-2 m-0 flex justify-between w-full`}>
                    
                    <button onClick={()=>setshoweditpage(false)} className='bg-gray-400 px-12 text-white py-4 rounded-md '>
                        Cancel
                    </button>
                    
                    <button type='submit' style={{backgroundColor: theme?.palette?.primary?.main}} className='px-12 text-white py-4 rounded-md '>
                        Update Details
                    </button>
                </div>

            </form>
        </>
    )
}


export default EditPage
