import React from 'react'
import TestDescription from '../componentHome/self-test/Test'
import TestNavbar from '../components/teastpage/TestNavbar'

const SelfTestPage = () => {
    return (
        <>
            
                <TestDescription>

                </TestDescription>
            
        </>
    )
}

export default SelfTestPage