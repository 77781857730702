import React, { useEffect, useState } from 'react';
import axios from 'axios';  // Import Axios
import { useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate and useLocation for routing
import { useConfig } from '../ConfigContext';

const PrintData = () => {
  const config = useConfig()
  const navigate = useNavigate();
  const [printData, setPrintData] = useState('');  // State to hold the print data
  const location = useLocation();  // useLocation hook to get current location, e.g., query parameters
  // Function to handle the back navigation
  const BackPage = () => {
    navigate(-1);  // Navigate to the previous page
  };

  // Function to fetch data from API using Axios
  const fetchPrintData = async () => {
    // Extract the `id` from the query parameters of the current URL
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');  // Get the 'id' query parameter

    if (!id) {
      console.error('No id found in the URL');
      return; // If no id is found, exit early
    }

    try {
      // Define the API endpoint with the `id` as a query parameter
      const apiEndpoint = `https://qa.littera.in/litteraCore/api/GET_PRINT_DATA?id=${id}`;

      // Send the GET request with the id and API key in the headers
      const response = await axios.get(apiEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          'APIKey': "UHJhdGhhbXNvZnRTZWNyZXRLZXkxMjM=",  // Replace with the actual API key
        }
      });

      if (response && response.data) {
        // Set the response data (assuming it's HTML or print content)
        setPrintData(response.data);
      }
    } catch (error) {
      console.error('Error fetching print data:', error);
    }
  };

  // Fetch the data when the component mounts (empty dependency array, so it runs only once)
  useEffect(() => {
    console.log("Fetching print data...");
    fetchPrintData();  // Trigger the API call on page load
  }, []);  // Empty dependency array ensures this effect runs only once

  // Trigger print dialog after printData is set
  useEffect(() => {
    if (printData) {
      // Ensure the print dialog is triggered after the content is updated
      window.print();
    }
  }, [printData]);  // This will run every time the printData state is updated

  return (
    <div>
      <button onClick={BackPage}>Back</button>
      {/* Render the print data */}
      {/* Use dangerouslySetInnerHTML to insert HTML content */}
      <div dangerouslySetInnerHTML={{ __html: printData }} />
    </div>
  );
};

export default PrintData;
