
import React, { useEffect, useState } from 'react';
import { FaEye, FaTimes } from 'react-icons/fa'; // Eye and Close icons
import { useConfig } from '../../../ConfigContext';
import { useFeedback } from '../../../Context/FeedbackContext';

const FileUploadWithPreview = () => {
  const { uploadingSignature, setuploadingSignature, showeditpage } = useFeedback(); // From context
  const config = useConfig(); // Holds the preview URL for images
  const { userProfile } = useFeedback(); // Holds the preview URL for images

  const [preview, setPreview] = useState(null); // Holds the preview URL for images
  const [initialImage, setInitialImage] = useState(null); // Store the initial image URL
  const [isFullScreen, setIsFullScreen] = useState(false); // Controls full-screen mode for images
  
  // Get the ag_sign_path (image path) from userProfile
  const userdata = userProfile?.items[0] || null; // Path to the image from userdata

  useEffect(() => {
    // Set the initial image URL when userdata is available
    if (userdata?.ag_sign_path) {
      const initialImageURL = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${userdata?.ag_sign_path}`;
      
      setInitialImage(initialImageURL); // Store the initial image
      setPreview(initialImageURL); // Set preview to initial image URL
    }
  }, [userdata, config]);

  useEffect(() => {
    // When showeditpage changes, show the initial image

 

    setPreview(initialImage);
  }, [showeditpage, initialImage]); 

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setuploadingSignature(selectedFile); // Set the new file in context or state
      const fileURL = URL.createObjectURL(selectedFile); // Generate file URL for preview
      setPreview(fileURL); // Update preview with the new file URL
    }
  };

  const handleRemoveFile = () => {
    setuploadingSignature(null); 
    setPreview(null); 
  };

  const handleFullScreenToggle = () => {
    window.open(preview)
  };

  const renderPreview = () => {
    if (preview) {
      return (
        <div
          className={`relative cursor-pointer 'max-w-full max-h-[400px] mx-auto`}
          onClick={handleFullScreenToggle}
        >
          <img src={preview} alt="File Preview" className="object-contain w-full h-full" />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="relative p-6 max-w-lg mx-auto max-xl:mx-0 bg-white border border-gray-300 rounded-lg shadow-lg space-y-4">
      <div className="flex items-center gap-4 justify-between">
        <label
          htmlFor="file-input"
          className="cursor-pointer whitespace-nowrap bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-all"
        >
          Choose File
        </label>
        <input
          id="file-input"
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />

        {
          (uploadingSignature === null && preview === null) ? null : (
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-700">
                {uploadingSignature?.name || userdata?.ag_sign_path}
              </span>
              <button
                type="button"
                onClick={handleRemoveFile}
                className="text-red-500 hover:text-red-700"
                title="Remove file"
              >
                <FaTimes />
              </button>
            </div>
          )
        }

      </div>

      {preview && (
        <div className="mt-4">
          {renderPreview()}
        </div>
      )}
    </div>
  );
};

export default FileUploadWithPreview;