// import React, { useEffect, useRef } from 'react';
// import { getDocument } from 'pdfjs-dist';
// import 'pdfjs-dist/build/pdf.worker.entry.js';
// import { useConfig } from '../../ConfigContext';


// const PdfViewer = ({ pdf }) => {
//   const config = useConfig()
//   const containerRef = useRef();
//   const isRendering = useRef(false);
//   useEffect(() => {
//     if (isRendering.current) return;
//     const renderPdf = async () => {
//       const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//       if (!url) return;
//       isRendering.current = true;
//       containerRef.current.innerHTML = '';
//       try {
//         const pdf = await getDocument(url).promise;
//         console.log(`PDF loaded: ${pdf.numPages} pages`);
//         for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
//           const page = await pdf.getPage(pageNumber);
//           console.log(`Rendering page ${pageNumber}`);
//           const viewport = page.getViewport({ scale: 1.1 });
//           const canvas = document.createElement('canvas');
//           const context = canvas.getContext('2d');
//           canvas.width = viewport.width;
//           canvas.height = viewport.height;
//           const renderContext = {
//             canvasContext: context,
//             viewport: viewport,
//           };
//           await page.render(renderContext).promise;
//           containerRef.current.appendChild(canvas);
//         }
//       } catch (error) {
//         console.error('Error rendering PDF', error);
//       } finally {
//           isRendering.current = false;
//       }
//     };
//     renderPdf();
//   }, [pdf]);
//   return (
//     <div className='h-full w-full flex justify-center'>
//       <div className='h-full w-full' ref={containerRef}></div>
//     </div>
//   );
// };
// export default PdfViewer;

// import React from 'react';
// import { useConfig } from '../../ConfigContext';


// const PdfViewer = ({ pdf }) => {
//   const config = useConfig()
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;
//   return (
//     <div className='h-[100%] w-[100%] flex justify-center'>
//       <div className='h-full w-full' >
//         <iframe src={url} className='h-full w-full' frameborder="0"></iframe>
//       </div>
//     </div>
//   );
// };
// export default PdfViewer;


// import React, { useEffect, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);

//   const Phoneurl = `https:///docs.google.com/gview?url=${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;
//   const Desktopurl = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       // Check if Ctrl (or Cmd) + S is pressed
//       if ((event.ctrlKey || event.metaKey) && event.key === 's') {
//         event.preventDefault();
//       }
//     };

//     // Add event listener on mount
//     window.addEventListener('keydown', handleKeyDown);

//     // Also add the event listener to the iframe itself (if it's present)
//     if (iframeRef.current) {
//       const iframeDocument = iframeRef.current.contentWindow.document;
//       iframeDocument.addEventListener('keydown', handleKeyDown);
//     }

//     // Cleanup on unmount
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//       if (iframeRef.current) {
//         const iframeDocument = iframeRef.current.contentWindow.document;
//         iframeDocument.removeEventListener('keydown', handleKeyDown);
//       }
//     };
//   }, []);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         <iframe
//           ref={iframeRef}
//           src={Desktopurl}
//           className="h-full w-full"
//           frameBorder="0"
//           title="PDF Viewer"
//         ></iframe>
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// import React, { useEffect, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);
//   const { tabs } = useFeedback();

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   // Unique key to store scroll position for each PDF
//   const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;

//   // Save scroll position when message is received from iframe
//   const handleMessage = (event) => {
//     if (event.origin !== config?.LITTERA_CDN_BASE_URL) return; // Check origin for security
//     if (event.data?.scrollPosition !== undefined) {
//       localStorage.setItem(pdfScrollKey, event.data.scrollPosition.toString());
//     }
//   };

//   useEffect(() => {
//     // Listen for postMessage from iframe
//     window.addEventListener('message', handleMessage);

//     return () => {
//       // Cleanup listener on component unmount
//       window.removeEventListener('message', handleMessage);
//     };
//   }, [pdfScrollKey, url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         <object
//           data={url}
//           type="application/pdf"
//           className="h-full w-full"
//           aria-label="PDF Viewer"
//           title="PDF Viewer"
//           width="100%"
//           height="100%"
//         >
//           <embed
//             src={url}
//             type="application/pdf"
//             className="h-full w-full"
//             width="100%"
//             height="100%"
//           />
//           <p>Your browser does not support PDFs or the PDF could not be loaded.</p>
//         </object>
        
//         {/* <iframe
//           ref={iframeRef}
//           src={url}
//           typeof='application/pdf'
//           className="h-full w-full"
//           frameBorder="0"
//           title="PDF Viewer"
//         ></iframe> */}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// import React, { useEffect, useRef, useState } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const canvasRef = useRef(null);
//   const [loading, setLoading] = useState(true);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render PDF page
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const page = await pdfDocument.getPage(1); // Get the first page (you can add pagination logic)

//       const scale = 1.5;  // Adjust scale for better mobile visibility
//       const viewport = page.getViewport({ scale });

//       const canvas = canvasRef.current;
//       const context = canvas.getContext('2d');

//       canvas.height = viewport.height;
//       canvas.width = viewport.width;

//       // Ensure render completes before starting a new one
//       await page.render({ canvasContext: context, viewport }).promise;

//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (canvasRef.current && url) {
//       renderPdf(url);
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         {isMobile ? (
//           <div className="h-full w-full">
//             <canvas ref={canvasRef} className="h-full w-full" />
//           </div>
//         ) : (
//           <div className="h-full w-full">
//             <canvas ref={canvasRef} className="h-full w-full" />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// working


// import React, { useEffect, useState } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// // No need to manually set the worker source, it's done automatically with Webpack

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // Array to hold canvases for each page
//   const [loading, setLoading] = useState(true);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render PDF pages
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;

//       const totalPages = pdfDocument.numPages; // Total number of pages in the document
//       const pagePromises = [];

//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       // Wait for all pages to be rendered
//       await Promise.all(pagePromises);

//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   // Function to render each page
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better mobile visibility
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page to the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Add the rendered page (canvas) to the pages array
//     setPages((prevPages) => [...prevPages, canvas]);
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full overflow-auto">
//         {loading && <div>Loading...</div>} {/* Show loading text while rendering */}
        
//         {/* Render each page as a separate canvas */}
//         {pages.map((canvas, index) => (
//           <div key={index} className="">
//             <canvas ref={(el) => el && el.getContext('2d').drawImage(canvas, 0, 0)} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// woring









// import React, { useEffect, useRef, useState } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);
//   const { tabs } = useFeedback();

//   // State to track multiple iframes and their URLs
//   const [pdfIframes, setPdfIframes] = useState([]);

//   // Construct the PDF URL
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   // Scroll key for this specific PDF
//   const pdfScrollKey = `pdfScrollPosition_${pdf[0]?.ttsad_ttsam_id}`;

//   // Handle receiving scroll position from iframe
//   const handleMessage = (event) => {
//     if (event.origin !== config?.LITTERA_CDN_BASE_URL) return; // Check origin for security

//     // If the iframe sends its scroll position, store it
//     if (event.data?.scrollPosition !== undefined && event.data?.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id) {
//       localStorage.setItem(pdfScrollKey, event.data.scrollPosition.toString());
//     }
//   };

//   console.log('pdfIframes' , pdfIframes)

//   // Add event listener to listen for iframe scroll messages
//   useEffect(() => {
//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };
//   }, []);

//   // Check if this PDF iframe is already added to state
//   useEffect(() => {
//     const existingTab = tabs.find((tab) => tab.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id);

//     // If the PDF is not in the state, add it
//     if (existingTab && !pdfIframes.some((iframe) => iframe.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id)) {
//       setPdfIframes((prevIframes) => [
//         ...prevIframes,
//         { ttsad_ttsam_id: pdf[0]?.ttsad_ttsam_id, url },
//       ]);
//     }
//   }, [tabs, pdf, pdfIframes, url]);

//   // Function to send scroll position to iframe
//   const sendScrollPositionToIframe = (iframe) => {
//     const scrollPosition = localStorage.getItem(pdfScrollKey);
//     if (iframe && scrollPosition) {
//       iframe.contentWindow.postMessage(
//         { action: 'restoreScrollPosition', scrollPosition },
//         config?.LITTERA_CDN_BASE_URL
//       );
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         {/* Render each iframe separately based on ttsad_ttsam_id */}
//         {pdfIframes.map((iframeData) => (
//           iframeData.ttsad_ttsam_id === pdf[0]?.ttsad_ttsam_id ? (
//             <iframe
//               key={iframeData.ttsad_ttsam_id}
//               src={iframeData.url}
//               className="h-full w-full"
//               frameBorder="0"
//               title={`PDF Viewer - ${iframeData.ttsad_ttsam_id}`}
//               ref={(iframe) => {
//                 iframeRef.current = iframe;
//                 sendScrollPositionToIframe(iframe); // Send the scroll position when iframe is loaded
//               }}
//             ></iframe>
//           ) : null
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;





// // No need to manually set the worker source, it's done automatically with Webpack
// import React, { useEffect, useState } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// // No need to manually set the worker source, it's done automatically with Webpack

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // Array to hold canvases for each page
//   const [loading, setLoading] = useState(true);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render PDF pages
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;

//       const totalPages = pdfDocument.numPages; // Total number of pages in the document
//       const pagePromises = [];

//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       // Wait for all pages to be rendered
//       await Promise.all(pagePromises);

//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   // Function to render each page
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better mobile visibility
//     const viewport = page.getViewport({ scale });

//     // Create a canvas for each page
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Set the dimensions of the canvas to match the PDF page
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page to the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Add the rendered canvas to the pages array
//     setPages((prevPages) => [...prevPages, canvas]);
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full overflow-auto">
//         {loading && <div>Loading...</div>} {/* Show loading text while rendering */}

//         {/* Render each page as a separate canvas */}
//         {pages.map((canvas, index) => (
//           <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//             <canvas
//               // Here we simply use the canvas from the state, no need to draw again
//               ref={(el) => {
//                 if (el) {
//                   // Set the canvas dimensions directly
//                   el.width = canvas.width;
//                   el.height = canvas.height;
//                   // Draw the content onto the canvas once (no additional drawing is needed)
//                   el.getContext('2d').drawImage(canvas, 0, 0);
//                 }
//               }}
//               style={{
//                 display: 'block',
//                 margin: '0 auto',
//                 width: '100%',  // Ensure canvas fits within container width
//                 height: 'auto', // Maintain aspect ratio
//               }}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // State to hold the canvases for each page
//   const [loading, setLoading] = useState(true);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   // const pdfScrollKey = `pdfScrollPosition_${encodeURIComponent(url)}`;

//   // Ref to accumulate page canvases before updating state
//   const pagesRef = useRef([]);

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
  
//       // Enable CORS handling in PDF.js
//       pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  
//       // Create a loading task for the PDF
//       const loadingTask = pdfjsLib.getDocument({
//         url,
//         withCredentials: false,  // Set to `true` if you want to send cookies/credentials
//       });
  
//       const pdfDocument = await loadingTask.promise;
  
//       const totalPages = pdfDocument.numPages;
//       const pagePromises = [];
  
//       // Clear the ref to make sure pages are not duplicated
//       pagesRef.current = [];
  
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }
  
//       // Wait for all pages to be rendered
//       await Promise.all(pagePromises);
  
//       // Once all pages are rendered, update the state
//       setPages(pagesRef.current);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };
  
//   // Function to render each page
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better mobile visibility
//     const viewport = page.getViewport({ scale });

//     // Create a canvas for each page
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Set the dimensions of the canvas to match the PDF page
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page to the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Store the rendered canvas in the ref
//     pagesRef.current.push(canvas);
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full overflow-auto">
        
//       { 
//         loading ? (
        
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//           <CircularProgress />
//         </div>

//         ) : (
        
//         pages?.map((canvas, index) => (
//           <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//             <canvas
//               ref={(el) => {
//                 if (el) {
//                   // Set the canvas dimensions directly
//                   el.width = canvas.width;
//                   el.height = canvas.height;
//                   // Draw the content onto the canvas once (no additional drawing is needed)
//                   el.getContext('2d').drawImage(canvas, 0, 0);
//                 }
//               }}
//               style={{
//                 display: 'block',
//                 margin: '0 auto',
//                 width: '100%',  // Ensure canvas fits within container width
//                 height: 'auto', // Maintain aspect ratio
//               }}
//             />
//           </div>
//         ))
//       )}

//       </div>
//     </div>
//   );
// };

// export default PdfViewer;




// import React, { useEffect, useRef, useState } from 'react';
// import { useConfig } from '../../ConfigContext';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const iframeRef = useRef(null);
//   const [isMobile, setIsMobile] = useState(false);

//   const Phoneurl = `https://docs.google.com/gview?url=${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}&embedded=true`;
//   // const Phoneurl = `https://view.officeapps.live.com/op/view.aspx?src=${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}&embedded=true`;
//   const DesktopUrl = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}#toolbar=0`;

//   // Function to check if the device is mobile or tablet
//   const checkIfMobile = () => {
//     if (window.innerWidth <= 768) {
//       setIsMobile(true);
//     } else {
//       setIsMobile(false);
//     }
//   };

//   // Set up event listener to track window size on load and resize
//   useEffect(() => {
//     checkIfMobile(); // Initial check
//     window.addEventListener('resize', checkIfMobile);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', checkIfMobile);
//     };
//   }, []);

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       // Prevent saving with Ctrl (or Cmd) + S
//       if ((event.ctrlKey || event.metaKey) && event.key === 's') {
//         event.preventDefault();
//       }
//     };

//     // Add event listener on mount
//     window.addEventListener('keydown', handleKeyDown);

//     // Also add the event listener to the iframe itself (if it's present)
//     if (iframeRef.current) {
//       const iframeDocument = iframeRef.current.contentWindow.document;
//       iframeDocument.addEventListener('keydown', handleKeyDown);
//     }

//     // Cleanup on unmount
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//       if (iframeRef.current) {
//         const iframeDocument = iframeRef.current.contentWindow.document;
//         iframeDocument.removeEventListener('keydown', handleKeyDown);
//       }
//     };
//   }, []);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full">
//         <iframe
//           ref={iframeRef}
//           src={isMobile ? Phoneurl : DesktopUrl}
//           className="h-full w-full"
//           frameBorder="0"
//           title="PDF Viewer"
//         ></iframe>
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;






// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack'; // PDF.js (webpack version)
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // State to hold the canvases for each page
//   const [loading, setLoading] = useState(true);
  
//   // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
//   // const proxyUrl = 'https://pdfproxy.onrender.com/';
//   const proxyUrl = 'https://viewer-aig6.onrender.com/';

//   // const url = `${proxyUrl}${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   // const url = `${proxyUrl}${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   // const url = `${proxyUrl}${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pagesRef = useRef([]);

//   // Set up the PDF.js worker source
//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'; // Path to worker file in the public folder

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render the PDF directly from the URL
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       // Directly pass the URL to getDocument
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       // Clear the pagesRef to prevent duplicates
//       pagesRef.current = [];

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       // Wait for all pages to be rendered
//       await Promise.all(pagePromises);

//       // Once all pages are rendered, update the state
//       setPages(pagesRef.current);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   // Function to render each page onto a canvas
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better visibility
//     const viewport = page.getViewport({ scale });

//     // Create a canvas for each page
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Set canvas dimensions
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page on the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Store the rendered canvas in pagesRef
//     pagesRef.current.push(canvas);
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">

//       <div className="h-full w-full overflow-auto">
//         {loading ? (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     // Set the canvas dimensions directly
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     // Draw the content onto the canvas once
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%', // Ensure canvas fits within container width
//                   height: 'auto', // Maintain aspect ratio
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;






// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack'; // PDF.js (webpack version)
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // State to hold the canvases for each page
//   const [loading, setLoading] = useState(true);
  
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pagesRef = useRef([]);

//   // Set up the PDF.js worker source
//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'; // Path to worker file in the public folder

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render the PDF directly from the URL
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       // Directly pass the URL to getDocument
//       // const loadingTask = pdfjsLib.getDocument({
//       //   url,
//       //   withCredentials: false,
//       //   httpHeaders: {
//       //     'Origin': window.location.origin,
//       //   },
//       // });
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       // Clear the pagesRef to prevent duplicates
//       pagesRef.current = [];

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       // Wait for all pages to be rendered
//       await Promise.all(pagePromises);

//       // Once all pages are rendered, update the state
//       setPages(pagesRef.current);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   // Function to render each page onto a canvas
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better visibility
//     const viewport = page.getViewport({ scale });

//     // Create a canvas for each page
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Set canvas dimensions
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page on the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Store the rendered canvas in pagesRef
//     pagesRef.current.push(canvas);
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">

//       <div className="h-full w-full overflow-auto">
//         {loading ? (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     // Set the canvas dimensions directly
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     // Draw the content onto the canvas once
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%', // Ensure canvas fits within container width
//                   height: 'auto', // Maintain aspect ratio
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack'; // PDF.js (webpack version)
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs } = useFeedback();
//   const [isMobile, setIsMobile] = useState(false);
//   const [pages, setPages] = useState([]); // State to hold the canvases for each page
//   const [loading, setLoading] = useState(true);
  
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pagesRef = useRef([]);

//   // Set up the PDF.js worker source
//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'; // Path to worker file in the public folder

//   useEffect(() => {
//     // Check if the device is mobile
//     const checkMobile = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     checkMobile();
//     window.addEventListener('resize', checkMobile);

//     return () => {
//       window.removeEventListener('resize', checkMobile);
//     };
//   }, []);

//   // Function to render the PDF directly from the URL
//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       // Clear the pagesRef to prevent duplicates
//       pagesRef.current = [];

//       // Create an array of page promises
//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       // Wait for all pages to be rendered and then update state
//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages); // Use the resolved rendered pages
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   // Function to render each page onto a canvas
//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; // Adjust scale for better visibility
//     const viewport = page.getViewport({ scale });

//     // Create a canvas for each page
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Set canvas dimensions
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render the page on the canvas
//     await page.render({ canvasContext: context, viewport }).promise;

//     // Return the rendered canvas instead of directly pushing to pagesRef
//     return canvas;
//   };
  

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); // Trigger PDF rendering when URL is ready
//     }
//   }, [url]);

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div className="h-full w-full overflow-auto">
//         {loading ? (
//           <div className='h-full' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     // Set the canvas dimensions directly
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     // Draw the content onto the canvas once
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%', // Ensure canvas fits within container width
//                   height: 'auto', // Maintain aspect ratio
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack'; 
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs , allpdf , setAllpdf } = useFeedback();
//   const [pages, setPages] = useState([]); 
//   const [loading, setLoading] = useState(true);
  
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pagesRef = useRef([]);

//   const containerRef = useRef(null);

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'; 

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       pagesRef.current = [];

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages); 
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; 
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   useEffect(() => {
//     if (url) {
//       renderPdf(url); 
//     }
//   }, [url]);

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;
//       localStorage.setItem('scrollingPostion' , JSON.stringify(scrollTop))
//       console.log('Scroll Position:', scrollTop);
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll} 
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto', 
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const { tabs, allpdf, setAllpdf } = useFeedback();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
  
//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   const pagesRef = useRef([]);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0);

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       pagesRef.current = [];

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages);
//       setLoading(false);

//       // Scroll to the saved position once the pages are rendered
//       if (containerRef.current) {
//         containerRef.current.scrollTop = scrollPosition.current;
//       }
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5; 
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   useEffect(() => {
//     // Check if there is a scroll position saved in localStorage
//     const savedScrollPosition = localStorage.getItem('scrollingPostion');
//     if (savedScrollPosition) {
//       scrollPosition.current = JSON.parse(savedScrollPosition);
//     }

//     if (url) {
//       renderPdf(url);
//     }
//   }, [url]);

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;
//       // Save the scroll position to localStorage on scroll
//       localStorage.setItem('scrollingPostion', JSON.stringify(scrollTop));
//       console.log('Scroll Position:', scrollTop);
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;




// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {

//   const config = useConfig();
//   const { tabs, allpdf, setAllpdf } = useFeedback();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages);
//       setLoading(false);

//       // Scroll to the saved position once the pages are rendered
//       if (containerRef.current) {
//         containerRef.current.scrollTop = scrollPosition.current;
//       }
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5;
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };
  
//   const [ttsamId, setttsamId] = useState(null);

//   useEffect(() => {
//     setttsamId(pdf[0]?.ttsam_id)
//   }, [pdf])

//   useEffect(() => {
//     const savedScrollData = localStorage.getItem('scrollPositions');
//     const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//     const savedScrollPositionObj = scrollPositions?.find(item => item.ttsamId === ttsamId);
//     const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj?.scrollPosition : 0;

//     scrollPosition.current = savedScrollPosition;

//     if (url) {
//       renderPdf(url);
//     }
//   }, [ttsamId]); 

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;

//       const savedScrollData = localStorage.getItem('scrollPositions');
//       const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//       const existingIndex = scrollPositions?.findIndex(item => item.ttsamId === ttsamId);

//       if (existingIndex >= 0) {
//         scrollPositions[existingIndex].scrollPosition = scrollTop;
//       } else {
//         scrollPositions.push({
//           ttsamId: ttsamId,
//           scrollPosition: scrollTop,
//         });
//       }

//       localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
//       console.log('Scroll Position:', scrollTop); 
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;






// import React, { useEffect, useState, useRef } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { useFeedback } from '../../Context/FeedbackContext';
// import * as pdfjsLib from 'pdfjs-dist/webpack';
// import { CircularProgress } from '@mui/material';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages);
//       setLoading(false);

//       // Scroll to the saved position once the pages are rendered
//       if (containerRef.current) {
//         containerRef.current.scrollTop = scrollPosition.current;
//       }
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5;
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   useEffect(() => {
//     const savedScrollData = localStorage.getItem('scrollPositions');
//     const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];
  
//     const savedScrollPositionObj = scrollPositions.find(item => item.ttsamId === pdf[0]?.ttsam_id);
  
//     const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj.scrollPosition : 0;
  
//     scrollPosition.current = savedScrollPosition;
  
//     if (url) {
//       renderPdf(url);
//     }
//   }, [pdf]);
   
//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;

//       const savedScrollData = localStorage.getItem('scrollPositions');
//       const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//       const existingIndex = scrollPositions.findIndex(item => item.ttsamId === pdf[0]?.ttsam_id);

//       if (existingIndex >= 0) {
//         scrollPositions[existingIndex].scrollPosition = scrollTop;
//       } else {
//         scrollPositions.push({
//           ttsamId: pdf[0]?.ttsam_id,
//           scrollPosition: scrollTop,
//         });
//       }

//       localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



// import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { CircularProgress } from '@mui/material';
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0);

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5;
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   useLayoutEffect(() => {
//     // Get the scroll positions from localStorage
//     const savedScrollData = localStorage.getItem('scrollPositions');
//     const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//     // Find the saved scroll position for the current ttsamId
//     const savedScrollPositionObj = scrollPositions.find(item => item.ttsamId === pdf[0]?.ttsam_id);

//     // If found, use the saved scroll position, otherwise default to 0
//     const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj.scrollPosition : 0;

//     scrollPosition.current = savedScrollPosition;

//     if (url) {
//       renderPdf(url);
//     }
//   }, [pdf]);

//   useLayoutEffect(() => {
//     // Apply the scroll position after the PDF is rendered and the container is sized properly
//     if (containerRef.current) {
//       containerRef.current.scrollTop = scrollPosition.current;
//     }
//   }, [pages]); // This will run after the pages are rendered

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;

//       const savedScrollData = localStorage.getItem('scrollPositions');
//       const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//       const existingIndex = scrollPositions.findIndex(item => item.ttsamId === pdf[0]?.ttsam_id);

//       if (existingIndex >= 0) {
//         scrollPositions[existingIndex].scrollPosition = scrollTop;
//       } else {
//         scrollPositions.push({
//           ttsamId: pdf[0]?.ttsam_id,
//           scrollPosition: scrollTop,
//         });
//       }

//       localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;


// best one 

// import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { CircularProgress } from '@mui/material';
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0);
//   const [containerHeight, setContainerHeight] = useState(0); // Track container height

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   const renderPdf = async (url) => {
//     try {
//       setLoading(true);
      
//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);

//       setPages(renderedPages);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5;
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   // Listen for PDF changes and render accordingly
//   useEffect(() => {
//     const savedScrollData = localStorage.getItem('scrollPositions');
//     const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//     const savedScrollPositionObj = scrollPositions.find(item => item.ttsamId === pdf[0]?.ttsam_id);

//     const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj.scrollPosition : 0;

//     scrollPosition.current = savedScrollPosition;

//     if (url) {
//       renderPdf(url);
//     }
//   }, [pdf]);

//   // Track container height changes with ResizeObserver
//   useEffect(() => {
//     const resizeObserver = new ResizeObserver(() => {
//       if (containerRef.current) {
//         setContainerHeight(containerRef.current.clientHeight); // Update height on resize
//       }
//     });

//     if (containerRef.current) {
//       resizeObserver.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         resizeObserver.unobserve(containerRef.current);
//       }
//     };
//   }, []);

//   // Update scroll position once the container is resized and PDF is rendered
//   useLayoutEffect(() => {
//     // Only apply scroll position if the container height is available and updated
//     if (containerHeight > 0 && containerRef.current) {
//       containerRef.current.scrollTop = scrollPosition.current;
//     }
//   }, [pages, containerHeight]); // Apply scroll position after pages render and container height is updated

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;

//       const savedScrollData = localStorage.getItem('scrollPositions');
//       const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//       const existingIndex = scrollPositions.findIndex(item => item.ttsamId === pdf[0]?.ttsam_id);

//       if (existingIndex >= 0) {
//         scrollPositions[existingIndex].scrollPosition = scrollTop;
//       } else {
//         scrollPositions.push({
//           ttsamId: pdf[0]?.ttsam_id,
//           scrollPosition: scrollTop,
//         });
//       }

//       localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;

// best one 



// import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
// import { useConfig } from '../../ConfigContext';
// import { CircularProgress } from '@mui/material';
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// const PdfViewer = ({ pdf }) => {
//   const config = useConfig();
//   const [pages, setPages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const containerRef = useRef(null);
//   const scrollPosition = useRef(0); // Stores scroll position to restore it later
//   const [containerHeight, setContainerHeight] = useState(0); // Track the height of the container

//   const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

//   pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//   // Function to render the PDF pages
//   const renderPdf = async (url) => {
//     try {
//       // setLoading(true);

//       const loadingTask = pdfjsLib.getDocument(url);
//       const pdfDocument = await loadingTask.promise;
//       const totalPages = pdfDocument.numPages;

//       const pagePromises = [];
//       for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
//         pagePromises.push(renderPage(pdfDocument, pageNumber));
//       }

//       const renderedPages = await Promise.all(pagePromises);
//       setPages(renderedPages);
//       // setLoading(false);

//     } catch (error) {
//       console.error('Error rendering PDF:', error);
//       // setLoading(false);
//     }
//   };

//   const renderPage = async (pdfDocument, pageNumber) => {
//     const page = await pdfDocument.getPage(pageNumber);
//     const scale = 1.5;
//     const viewport = page.getViewport({ scale });

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     await page.render({ canvasContext: context, viewport }).promise;

//     return canvas;
//   };

//   // Track the PDF state and scroll position when the pdf changes
//   useEffect(() => {
//     const savedScrollData = localStorage.getItem('scrollPositions');
//     const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//     // Get saved scroll position for the current PDF by ttsamId
//     const savedScrollPositionObj = scrollPositions.find(item => item.ttsamId === pdf[0]?.ttsam_id);
//     const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj.scrollPosition : 0;

//     // Store it in the ref
//     scrollPosition.current = savedScrollPosition;

//     // Render the PDF
//     if (url) {
//       renderPdf(url);
//     }
//   }, [pdf]);

//   // Listen for size changes in the container
//   useEffect(() => {
//     const resizeObserver = new ResizeObserver(() => {
//       if (containerRef.current) {
//         setContainerHeight(containerRef.current.clientHeight); // Update height on resize
//       }
//     });

//     if (containerRef.current) {
//       resizeObserver.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         resizeObserver.unobserve(containerRef.current);
//       }
//     };
//   }, []);

//   // This effect runs when PDF is loaded and container height is set, ensuring scroll position is set
//   useLayoutEffect(() => {
//     if (containerHeight > 0 && pages.length > 0) {
//       // Only apply scroll position after PDF is rendered and container height is available
//       if (containerRef.current) {
//         containerRef.current.scrollTop = scrollPosition.current;
//       }
//     }
//   }, [pages, containerHeight]); // Apply scroll position after pages are rendered and container height is set

//   // Handle scroll events and save scroll position to localStorage
//   const handleScroll = () => {
//     if (containerRef.current) {
//       const scrollTop = containerRef.current.scrollTop;

//       // Get the current scroll positions from localStorage
//       const savedScrollData = localStorage.getItem('scrollPositions');
//       const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

//       // Find the index for the current ttsamId
//       const existingIndex = scrollPositions.findIndex(item => item.ttsamId === pdf[0]?.ttsam_id);

//       if (existingIndex >= 0) {
//         // Update the scroll position if ttsamId exists
//         scrollPositions[existingIndex].scrollPosition = scrollTop;
//       } else {
//         // If ttsamId doesn't exist, add a new entry
//         scrollPositions.push({
//           ttsamId: pdf[0]?.ttsam_id,
//           scrollPosition: scrollTop,
//         });
//       }

//       // Save the updated scroll positions to localStorage
//       localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
//     }
//   };

//   return (
//     <div className="h-[100%] w-[100%] flex justify-center">
//       <div
//         ref={containerRef}
//         className="h-full w-full overflow-auto"
//         onScroll={handleScroll}
//       >
//         {
//            pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         }
//         {/* {loading ? (
//           <div
//             className="h-full"
//             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
//           >
//             <CircularProgress />
//           </div>
//         ) : (
//           pages?.map((canvas, index) => (
//             <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
//               <canvas
//                 ref={(el) => {
//                   if (el) {
//                     el.width = canvas.width;
//                     el.height = canvas.height;
//                     el.getContext('2d').drawImage(canvas, 0, 0);
//                   }
//                 }}
//                 style={{
//                   display: 'block',
//                   margin: '0 auto',
//                   width: '100%',
//                   height: 'auto',
//                 }}
//               />
//             </div>
//           ))
//         )} */}
//       </div>
//     </div>
//   );
// };

// export default PdfViewer;



import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useConfig } from '../../ConfigContext';
import { CircularProgress } from '@mui/material';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const PdfViewer = ({ pdf }) => {
  const config = useConfig();
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);
  const scrollPosition = useRef(0); // Stores scroll position to restore it later
  const [containerHeight, setContainerHeight] = useState(0); // Track the height of the container

  const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;

  pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

  // Function to render the PDF pages
  const renderPdf = async (url) => {
    try {
      setLoading(true);

      const loadingTask = pdfjsLib.getDocument(url);
      const pdfDocument = await loadingTask.promise;
      const totalPages = pdfDocument.numPages;

      const pagePromises = [];
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        pagePromises.push(renderPage(pdfDocument, pageNumber));
      }

      const renderedPages = await Promise.all(pagePromises);
      setPages(renderedPages);
      setLoading(false);

    } catch (error) {
      console.error('Error rendering PDF:', error);
      setLoading(false);
    }
  };

  const renderPage = async (pdfDocument, pageNumber) => {
    const page = await pdfDocument.getPage(pageNumber);
    const scale = 1.5;
    const viewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({ canvasContext: context, viewport }).promise;

    return canvas;
  };

  // Track the PDF state and scroll position when the pdf changes
  useEffect(() => {
    if (!pdf || !pdf[0]?.ttsam_id) return;

    // Check if the scroll position is saved for the current PDF
    const savedScrollData = localStorage.getItem('scrollPositions');
    const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

    // Get saved scroll position for the current PDF by ttsamId
    const savedScrollPositionObj = scrollPositions.find(item => item.ttsamId === pdf[0]?.ttsam_id);
    const savedScrollPosition = savedScrollPositionObj ? savedScrollPositionObj.scrollPosition : 0;

    // Store it in the ref
    scrollPosition.current = savedScrollPosition;

    // Render the PDF
    renderPdf(url);
  }, [pdf[0]?.ttsam_id]); // Only trigger when the ttsamId of the PDF changes

  // Listen for size changes in the container
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight); // Update height on resize
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []); // Only observe on mount and unmount

  // This effect runs when PDF is loaded and container height is set, ensuring scroll position is set
  useLayoutEffect(() => {
    if (containerHeight > 0 && pages.length > 0) {
      // Only apply scroll position after PDF is rendered and container height is available
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollPosition.current;
      }
    }
  }, [pages, containerHeight]); // Apply scroll position after pages are rendered and container height is set

  // Handle scroll events and save scroll position to localStorage
  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;

      // Get the current scroll positions from localStorage
      const savedScrollData = localStorage.getItem('scrollPositions');
      const scrollPositions = savedScrollData ? JSON.parse(savedScrollData) : [];

      // Find the index for the current ttsamId
      const existingIndex = scrollPositions.findIndex(item => item.ttsamId === pdf[0]?.ttsam_id);

      if (existingIndex >= 0) {
        // Update the scroll position if ttsamId exists
        scrollPositions[existingIndex].scrollPosition = scrollTop;
      } else {
        // If ttsamId doesn't exist, add a new entry
        scrollPositions.push({
          ttsamId: pdf[0]?.ttsam_id,
          scrollPosition: scrollTop,
        });
      }

      // Save the updated scroll positions to localStorage
      localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
    }
  };

  return (
    <div className="h-[100%] w-[100%] flex justify-center">
      <div
        ref={containerRef}
        className="h-full w-full overflow-auto"
        onScroll={handleScroll}
      >
        {loading ? (
          <div
            className="h-full"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : (
          pages?.map((canvas, index) => (
            <div key={index} className="page-container" style={{ marginBottom: '20px' }}>
              <canvas
                ref={(el) => {
                  if (el) {
                    el.width = canvas.width;
                    el.height = canvas.height;
                    el.getContext('2d').drawImage(canvas, 0, 0);
                  }
                }}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  width: '100%',
                  height: 'auto',
                }}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PdfViewer;