import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { Collapse, Row, Col, Button, Typography, Tooltip, Modal } from 'antd';
import { UploadOutlined, DownloadOutlined, CommentOutlined, UnlockOutlined, LockOutlined } from '@ant-design/icons';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import { Cancel as CancelIcon } from '@mui/icons-material';
import moment from 'moment';
import { TranslationContext } from '../../TranslationContext';
import UploadModal from './DownloadModal';
import CommentsModal from './CommentsModal';
import UploadAssignment from './UploadAssignment';
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
const { Panel } = Collapse;
const { Text } = Typography;

const AssignmentDetail = () => {
    const { selectedUserType } = useUser();
    const [modalType, setModalType] = useState(null);
    const { translations } = useContext(TranslationContext);
    const { id } = useParams(); // Get the assignmentid from the URL
    const [assignmentDetails, setAssignmentDetails] = useState(null);
    const [error, setError] = useState(null); // Error state to handle API errors
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedParticipantId, setSelectedParticipantId] = useState(null); // New state for participantId
    const [updatedId, setUpdatedId] = useState(null); // New state for participantId
    const [trainingId, setTrainingId] = useState(null); // New state
    const [activeAssignment, setActiveAssignment] = useState([]); // Initialize as an empty array
    const config = useConfig();
    const user = JSON.parse(localStorage.getItem('user'));
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const modalRef = useRef(null);

   

    useEffect(() => {
        const fetchAssignmentDetails = async () => {
            try {
                const response = await axios.get(
                    `${config.REACT_APP_API_URL}/AssignmentDetail?userid=${user.userdetails.agencyid}&assignmentid=${id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'APIKey': config.REACT_APP_API_KEY,
                        }
                    }
                );
                if (response.data && response.data.length > 0) {
                    setAssignmentDetails(response.data[0]);
                } else {
                    setError('No assignment details found.');
                }
            } catch (error) {
                setError('Error fetching assignment details.');
            }
        };

        fetchAssignmentDetails();
    }, [id]);

    useEffect(() => {
        if (isModalVisible1 && modalRef.current) {
            modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [isModalVisible1]); // This will run whenever the modal visibility changes

    // If data is still loading or there's an error, show appropriate message
    if (error) return <p>{error}</p>;
    if (!assignmentDetails) return <p>Loading...</p>;

    const {
        assignmentName, title, ttttt_session_dt, ttttt_session_end_time,
        assesmentQuestions, maxMarks, facultyname, instructions, tag, status,
        assignmentTypeName, taau_status, isOpenended, assignmentID, taau_uploadid, trainingid, ttttt_session_id
    } = assignmentDetails;
    console.log(assignmentDetails)
    const paths = [
        { title: translations['Assignment_list'] || 'Assignment list', url: '/Assignment/AssignmentView' },
        { title: translations['Assignment_detail'] || 'Assignment Details', url: '' }
    ];

    const cleanedText = assesmentQuestions.replace(/<\/?[^>]+(>|$)/g, ''); // Strip HTML tags

    const openModal = async (assignmentID, taau_uploadid, trainingid, ttttt_session_id, type) => {
        // Step 1: Check session restriction
        const userid = user.userdetails.agencyid;
        try {
            const checkSessionResponse = await axios.get(
                `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
                {
                    params: {
                        usertype: selectedUserType,
                        userid: userid,
                        sessionid: ttttt_session_id,
                        trainingid: trainingid,
                    },
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // If session restriction is true, show the modal with the restriction message
            if (checkSessionResponse.data === true) {
                // Display the restriction message modal
                showModal1(translations["current_session_before"] || "You cannot start the current session before completing the previous sessions.");
                return; // Prevent opening the upload modal
            }
            setSelectedParticipantId(assignmentID); // Store the assignment ID in the state
            setUpdatedId(taau_uploadid)
            setTrainingId(trainingid)
            setModalType(type); // Set the modal type (either 'download', 'comment', or 'upload')
            setIsModalVisible(true); // Open the modal

        } catch (error) {
            console.error("Error checking session restriction:", error);
            showModal1(translations["session_check_error"] || "Error checking session restriction.");
        }
    };

    const showModal1 = (message) => {
        setModalContent(message);  // Set the content of the modal
        setIsModalVisible1(true);   // Show the modal
    };

    // Close the modal
    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedParticipantId(null); // Clear the participantId when modal closes
        setUpdatedId(null); // Clear the
        setTrainingId(null)
    };
    const handleModalOk = () => {
        // Close the modal
        setIsModalVisible1(false);

    };
    const handleModalCancel = () => {
        setIsModalVisible1(false);
    };

    return (
        <>
            <Breadcrumb paths={paths} />
            <div className="assignment-details" style={{ backgroundColor: "white", padding: "10px" }}>
                <div style={{ border: "1px solid #c9c0c0", borderRadius: "6px", padding: "10px" }}>
                    <Panel
                        header={
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <h5 style={{ textTransform: 'capitalize' }} id='step1'>
                                        {assignmentName} <sup style={{ color: 'green' }}>({assignmentTypeName})</sup>
                                    </h5>

                                    <Row gutter={[8, 8]} align="middle">
                                        <Col>
                                            <p><strong>Start On:</strong> <Text>{moment(ttttt_session_dt).format('DD/MM/YYYY HH:mm')}</Text></p>
                                        </Col>
                                        <Col>
                                            <Tooltip title={status === '0' ? 'Not started' :
                                                status === '1' ? 'Open' :
                                                    status === '2' ? 'Pause' :
                                                        status === '3' ? 'Closed' :
                                                            status === '-1' ? 'Cancelled' :
                                                                status === '4' ? 'Approved' : 'Unknown'}>
                                                {status === '0' && <LockOutlined style={{ color: 'orange' }} />}
                                                {status === '1' && <UnlockOutlined style={{ color: 'green' }} />}
                                                {status === '2' && <i className="fa fa-pause" style={{ color: 'orange' }} />}
                                                {status === '3' && <LockOutlined style={{ color: 'green' }} />}
                                                {status === '-1' && <CancelIcon style={{ color: 'red' }} />}
                                                {status === '4' && <i className="fa fa-check" style={{ color: 'green' }} />}
                                            </Tooltip>
                                        </Col>
                                    </Row>

                                    <p id='step2'><strong>Last Date:</strong> <Text>{moment(ttttt_session_end_time).format('DD/MM/YYYY HH:mm')}</Text></p>
                                    <p><strong>Description:</strong><div dangerouslySetInnerHTML={{ __html: assesmentQuestions }} /></p>
                                    <p><strong>Marks:</strong> <Text>{maxMarks}</Text></p>
                                    <p><strong>Faculty:</strong> <Text>{facultyname}</Text></p>
                                    <p><strong>Instructions:</strong> <Text>{instructions}</Text></p>
                                    <p id='step3'><strong>Tag:</strong> <Text>{tag}</Text></p>

                                    <Row>

                                        {status === 1 || status === 4 || isOpenended === 1 ? (
                                            <>
                                                <span className="fa fa-download" title="Download Attachment" style={{ margin: "3px", fontSize: "14px" }} onClick={() => openModal(assignmentID, taau_uploadid, trainingid, ttttt_session_id, 'download')}></span>
                                                <span className="fa fa-comments-o" title="Comment" style={{ color: 'chocolate', fontSize: "14px" }} onClick={() => openModal(assignmentID, taau_uploadid, trainingid, ttttt_session_id, 'comment')} ></span>
                                                {taau_status !== "1" && (
                                                    <span className="fa fa-upload" title="Upload Attachment" style={{ margin: "3px", fontSize: "14px" }} onClick={() => openModal(assignmentID, taau_uploadid, trainingid, ttttt_session_id, 'upload')} ></span>
                                                )}
                                            </>
                                        ) : (
                                            <span>-</span>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        }
                    />
                </div>
            </div>
            {isModalVisible && modalType === 'download' && (
                <UploadModal
                    participantId={selectedParticipantId} // Pass the participantId to the modal
                    thistrainings={trainingId}
                    closeModal={closeModal} // Pass closeModal function to modal
                />
            )}

            {isModalVisible && modalType === 'comment' && (
                <CommentsModal
                    participantId={selectedParticipantId} // Pass the participantId to the modal
                    thistrainings={trainingId} // Pass the trainingId to the modal
                    closeModal={closeModal} // Pass closeModal function to modal
                />
            )}
            {isModalVisible && modalType === 'upload' && (
                <UploadAssignment
                    participantId={selectedParticipantId} // Pass the participantId to the modal
                    uploaded={updatedId}
                    closeModal={closeModal} // Pass closeModal function to modal
                />
            )}

            <Modal
                visible={isModalVisible1}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="OK"
                ref={modalRef} // Modal reference

            >
                <p>{modalContent}</p>
                {/* Input field with its own ref */}
                <input
                    type="text"
                    ref={modalRef} // Reference for the input field
                    placeholder="Type something..."
                    style={{ display: "none", marginTop: "20px", width: "100%" }}
                />
            </Modal>
        </>
    );
};

export default AssignmentDetail;
