import React from 'react'
import Userprofile from '../components/Content/UserProfile/Userprofile'
import Breadcrumb from '../componentHome/commonComponent/Breadcrumbs'

const UserProfile = () => {
    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'User Profile', url: '/user-profile' }
    ];

    return <>
    <Breadcrumb paths={paths} />
    <div className='p-6 max-sm:p-4 flex  w-full'>
        <Userprofile/>
    </div>
    </>
}

export default UserProfile