import React from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import TestListTable from '../componentHome/test-list/TestList'

const TestListPage = () => {
    return (
        <>

            <TestListTable></TestListTable>

        </>
    )
}

export default TestListPage