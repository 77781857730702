import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box'; // Assuming you are using MUI for Box
import Typography from '@mui/material/Typography'; // Assuming you are using MUI for Typography

const CountdownTimer = ({ initialTime }) => {
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const sec = seconds % 60; // Renamed to avoid conflict
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    };

    return (
        <Box textAlign="center">
            <Typography variant="h4" color="error">
                {formatTime(time)}
            </Typography>
            <Typography variant="caption" color="textSecondary" style={{ fontSize: "medium" }}>
                HH:MM:SS
            </Typography>
        </Box>
    );
};

export default CountdownTimer;
